import React, { useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  DatePicker,
  TimePicker,
} from "antd";

//import { getAccessTokenApi } from "../../../../../api/auth";

import "./AddMeetForm.scss";

export default function AddMeetForm(props) {
  const {
    setIsVisibleModal,
    buttonName,
    data,
    setIsMeet,
    setDatemeet,
    setStartmeet,
    setEndmeet,
    setObservationsmeet,
  } = props;
  //const token = getAccessTokenApi();
  const [formMeet] = Form.useForm();
  const { TextArea } = Input;
  const format = "HH:mm";

  // const resetForm = () => {
  //   const inputs = document.getElementsByTagName("input");
  //   for (let i = 0; i < inputs.length; i++) {
  //     inputs[i].classList.remove("success");
  //     inputs[i].classList.remove("error");
  //   }

  //   // setHaveData({
  //   //   code: "",
  //   //   name: "",
  //   // });
  // };

  useEffect(() => {
    formMeet.setFieldsValue({
      datereunion: null,
      hour: null,
      observations: null,
    });
  }, [data]);

  const onFinish = (values) => {
    //const token = getAccessTokenApi();
    setIsVisibleModal(false);
    setIsMeet(true);
    setDatemeet(values.datereunion);
    setStartmeet(values.hour[0]);
    setEndmeet(values.hour[1]);
    setObservationsmeet(values.observations);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información ingresada",
      duration: 3,
    });
  };

  return (
    <div className="add-meet-form">
      <Form
        form={formMeet}
        name="meet"
        className="form-edit"
        //onSubmit={addUpdateHave}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{ remember: false }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Fecha para la reunión"
              name="datereunion"
              rules={[
                {
                  required: true,
                  message: "Seleccione la fecha para la reunión",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Hora para la reunión"
              name="hour"
              rules={[
                {
                  required: true,
                  message: "Seleccione la hora para la reunión",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <TimePicker.RangePicker format={format} minuteStep={15} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Observcaiones"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="observations"
              rules={[
                {
                  required: true,
                  message: "Ingrese sus observaciones",
                },
              ]}
            >
              <TextArea
                //prefix={<UserOutlined />}
                placeholder="Observaciones"
                //value={userData.name}
                rows={4}
                maxLength={500}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button type="primary" htmlType="submit" className="btn-submit">
            {buttonName}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
