import React, { useState, useEffect } from "react";
import { Select, Form, Input } from "antd";

import { getActorsApi } from "../../../../api/actor.api";

import "./SelectActorForm.scss";

const { Option } = Select;

export default function SelectActorForm(props) {
  const { atributoData, setAtributoData, deafultValue } = props;
  const [actorData, setActorData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [actorId, setActorId] = useState();
  const [other, setOther] = useState(false);

  useEffect(() => {
    getActorsApi().then((response) => {
      setActorData(response.actors);
      setActorId(atributoData);
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value) => {
    setActorId(value);
    setAtributoData(value);
    if (value === 4) {
      setOther(true);
    } else {
      setOther(false);
    }
  };

  useEffect(() => {
    console.log(atributoData);
    if (atributoData) {
      if (atributoData === 4) {
        setOther(true);
      } else {
        setOther(false);
      }
    } else {
      setOther(false);
    }
  }, [atributoData]);

  return (
    <div className="select-actor-form">
      <Form.Item
        label="Tipo de actor"
        name="actorId"
        rules={[{ required: true, message: "Seleccione un tipo de actor" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        style={{ marginBottom: other ? "5px" : "24px" }}
      >
        <Select
          placeholder="Seleccione un tipo de actor"
          value={actorId}
          onChange={handleAtributoChange}
          loading={loadingData}
          //disabled={disabled}
        >
          {actorData && actorData.map
            ? actorData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
      {other && (
        <Form.Item
          //label="Otros"
          name="actorother"
          rules={[{ required: true, message: "Ingrese el tipo de actor" }]}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          initialValue={deafultValue}
        >
          <Input
            //prefix={<UserOutlined />}
            placeholder="Tipo de actor"
            //value={userData.name}
            maxLength={200}
          />
        </Form.Item>
      )}
    </div>
  );
}
