import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";

import { getEmployeesApi } from "../../../../api/employee.api";

import "./SelectEmployeeForm.scss";

const { Option } = Select;

export default function SelectEmployeeForm(props) {
  const { atributoData, setAtributoData, name, label } = props;
  const [employeeData, setEmployeeData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  //const [employeeId, setEmployeeId] = useState();

  useEffect(() => {
    getEmployeesApi().then((response) => {
      setEmployeeData(response.employees);
      //setEmployeeId(atributoData);
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value) => {
    //setEmployeeId(value);
    setAtributoData(value);
  };

  return (
    <div className="select-employee-form">
      <Form.Item
        label={label}
        name={name}
        rules={[
          { required: true, message: "Seleccione la cantidad de empleados" },
        ]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        //className="biglabel"
      >
        <Select
          placeholder="Seleccione la cantidad de empleados"
          //value={employeeId}
          onChange={handleAtributoChange}
          //disabled={disabled}
        >
          {employeeData && employeeData.map
            ? employeeData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
