import React, { useState } from "react";
import jwtDecode from "jwt-decode";
import { Form, Input, Button, notification } from "antd";
import MailOutlined from "@ant-design/icons/MailOutlined";
import LockOutlined from "@ant-design/icons/LockOutlined";
import {
  emailValidation,
  minLengthValidation,
} from "../../../utils/formValidation";
import { signInApi } from "../../../api/user.api";

import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../../utils/constants";

import "./LoginForm.scss";
//import SignIn from "../../../pages/Admin/SignIn/SignIn";

export default function LoginForm() {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const [formValid, setFormValid] = useState({
    email: false,
    password: false,
  });

  const [logging, setLogging] = useState(false);

  const changeForm = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const inputValidation = (e) => {
    const { type, name } = e.target;

    if (type === "email") {
      setFormValid({
        ...formValid,
        [name]: emailValidation(e.target),
      });
    }

    if (type === "password") {
      setFormValid({
        ...formValid,
        [name]: minLengthValidation(e.target, 6),
      });
    }
  };

  const login = async (e) => {
    //e.preventDefault();
    setLogging(true);
    const passwordVal = inputs.password;
    if (!inputs.email || !passwordVal) {
      notification["error"]({
        message: "Todos los campos son obligatorios",
      });
      setLogging(false);
    } else {
      const result = await signInApi(inputs);
      if (!result) {
        notification["error"]({
          message: "El servidor no se ha iniciado",
        });
        setLogging(false);
      } else if (result.message) {
        notification["error"]({
          message: result.message,
        });
        setLogging(false);
      } else {
        const { accessToken, refreshToken } = result;
        if (accessToken && refreshToken) {
          localStorage.setItem(ACCESS_TOKEN, accessToken);
          localStorage.setItem(REFRESH_TOKEN, refreshToken);

          notification["success"]({
            message: "Acceso correcto.",
          });
          const userlogged = jwtDecode(accessToken);
          if (userlogged.role === 4) {
            setLogging(false);
            window.location.href = "/admin";
          } else if (userlogged.role === 1) {
            setLogging(false);
            window.location.href = "/user";
          } else if (userlogged.role === 2) {
            setLogging(false);
            window.location.href = "/reciclador";
          } else if (userlogged.role === 3) {
            setLogging(false);
            window.location.href = "/proyecto";
          } else {
            setLogging(false);
            window.location.href = "/user";
          }
        } else {
          notification["error"]({
            message: "El servidor no se ha iniciado",
          });
          setLogging(false);
        }
      }
    }
    //console.log(result);
  };

  return (
    <Form className="login-form" onChange={changeForm} onFinish={login}>
      <br />
      <Form.Item>
        <Input
          prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          type="email"
          name="email"
          placeholder="Correo electronico"
          className="login-form__input"
          onChange={inputValidation}
        />
      </Form.Item>
      <Form.Item>
        <Input
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          type="password"
          name="password"
          placeholder="Clave"
          className="login-form__input"
          onChange={inputValidation}
        />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          loading={logging}
          className="login-form__button"
        >
          Entrar
        </Button>
      </Form.Item>
    </Form>
  );
}
