import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";

import { getTransportsApi } from "../../../../api/transport.api";

import "./SelectTransportForm.scss";

const { Option } = Select;

export default function SelectTransportForm(props) {
  const { name, nameOther, edit, values } = props;
  const [transportData, setTransportData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [transportId, setTransportId] = useState();

  useEffect(() => {
    getTransportsApi().then((response) => {
      setTransportData(response.transports);
      //setTransportId(atributoData);
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value) => {
    setTransportId(value);
    //setAtributoData(value);
  };

  return (
    <div className="select-transport-form">
      <Form.Item
        label={"Transporte"}
        name={name}
        rules={[
          { required: true, message: "Seleccione un tipo de transporte" },
        ]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        //className="biglabel"
      >
        <Select
          placeholder="Seleccione un tipo de transporte"
          //value={transportId}
          onChange={handleAtributoChange}
          //disabled={disabled}
        >
          {transportData && transportData.map
            ? transportData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
