import { basePath } from "./config";

export async function getKnowledgetypesApi() {
  const url = `${basePath}/knowledgetypes`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      //Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export function UpdateKnowledgetypeApi(token, type, typeId) {
  const url = `${basePath}/knowledgetype/update/${typeId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(type),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function AddKnowledgetypeApi(token, type) {
  const url = `${basePath}/knowledgetype/create`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(type),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function DeleteKnowledgetypeApi(token, typeId) {
  const url = `${basePath}/knowledgetype/delete/${typeId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
