import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  PageHeader,
  Input,
  Layout,
  Col,
  Form,
  Row,
  notification,
  Radio,
  Card,
  Avatar,
  Result,
  Switch,
  Image,
} from "antd";
import {
  ArrowRightOutlined,
  LoginOutlined,
  UserOutlined,
  ToolOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  ShopOutlined,
  InteractionOutlined,
  RestOutlined,
  MailOutlined,
  LockOutlined,
  AimOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import Logo from "../assets/img/png/conecta2.png";
// import LogoBid from "../assets/img/png/bidlab.png";
// import LogoCiti from "../assets/img/png/citifundation.png";
import LogoEmprende from "../assets/img/png/emprendimientoec.png";
import LogoAEI from "../assets/img/png/aei.png";
import LogoAvina from "../assets/img/png/avina.png";
import LogoInnova from "../assets/img/png/innovac.png";
import SelectCityProvinceForm from "../components/Admin/City/SelectCityForm/SelectCityProvinceForm";
import "./Eventos.scss";
import SelectTypeForm from "../components/Admin/Type/SelectTypeForm";
import SelectCommunityForm from "../components/Admin/Community/SelectCommunityForm";
import SelectKnowledgetypeForm from "../components/Admin/Knowledge/SelectKnowledgeForm/SelectKnowledgeTypeForm";
import SelectRevenueListForm from "../components/Admin/Revenue/SelectRevenueForm/SelectRevenueListForm";

import SelectStateForm from "../components/Admin/State/SelectStateForm";
import SelectWeightForm from "../components/Admin/Weight/SelectWeightForm";
import SelectTransportForm from "../components/Admin/Transport/SelectTransportForm";
import SelectResidualForm from "../components/Admin/Residual/SelectResidualForm";
import SelectUtilityForm from "../components/Admin/Utility/SelectUtilityForm";
import SelectSolutionForm from "../components/Admin/Solution/SelectSolutionForm";

import { emailValidation, minLengthValidation } from "../utils/formValidation";
import Pin from "../assets/img/png/pin.png";
// import { returnMailImage } from "../utils/functions";

import { apiKeyGoogle } from "../api/config";

import { AddUserApi } from "../api/user.api";

import { getEventByUrlApi } from "../api/event.api";

import {
  AddEntrepreneurshipApi,
  getEntrepreneurshipByIdApi,
  UpdateEntrepreneurshipExtrasApi,
  AddEntrepreneurshipEventApi,
} from "../api/entrepreneurship.api";

import { BulkAddHaveApi } from "../api/have.api";
import { BulkAddNeedApi } from "../api/need.api";
import { BulkAddHaveresidualApi } from "../api/haveresidual.api";
import { BulkAddNeedresidualApi } from "../api/needresidual.api";
import SelectAgreementForm from "../components/Admin/Agreement/SelectAgreementForm";

import { useNavigate } from "react-router-dom";

import { AddOtherApi } from "../api/other.api";
import { signInApi } from "../api/user.api";
import jwtDecode from "jwt-decode";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../utils/constants";
import GoogleMapReact from "google-map-react";

const { Header, Content } = Layout;

// const { Step } = Steps;

const DefaultLocation = { lat: -0.5012984084076219, lng: -76.97750904233827 };
const DefaultZoom = 13;

export default function Eventos() {
  const [type, setType] = useState();

  const [community, setCommunity] = useState();
  const [event, setEvent] = useState();
  const [formPersonal] = Form.useForm();

  const [userData, setUserData] = useState({});
  const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const [loadings, setLoadings] = useState(false);

  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [searchlocation, setSearchLocation] = useState(false);

  const [city, setCity] = useState();
  const [province, setProvince] = useState();
  const [parroquia, setParroquia] = useState();

  const [role, setRole] = useState("1");
  const [solution, setSolution] = useState();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [login, setLogin] = useState(false);
  const [loginVisible, setLoginVisible] = useState(false);
  const [loginRole, setLoginRole] = useState(4);

  const { evento } = useParams();

  useEffect(() => {
    setTimeout(() => {
      currentLocation();
    }, 2000);
    console.log(evento);
    getEventByUrlApi(evento).then((response) => {
      setEvent(response.event);
    });
  }, []);

  useEffect(() => {
    console.log(province);
    formPersonal.setFieldsValue({
      cityId: null,
      cityother: null,
      parroquiaId: null,
      parroquiaother: null,
    });
  }, [province]);

  useEffect(() => {
    formPersonal.setFieldsValue({
      parroquiaId: null,
      parroquiaother: null,
    });
  }, [city]);

  const onChangeRadio = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setRole(e.target.value);
  };

  function currentLocation() {
    setSearchLocation(true);
    var options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  function success(pos) {
    var crd = pos.coords;
    // setDefaultLocation({
    //   lat: crd.latitude,
    //   lng: crd.longitude,
    // });
    setLocation({
      lat: crd.latitude,
      lng: crd.longitude,
    });
    setSearchLocation(false);
  }

  function error(err) {
    setSearchLocation(false);
    notification["success"]({
      message: `No se puede obtener la ubicación (Error: ${err.message})`,
    });
    // console.warn('ERROR(' + err.code + '): ' + err.message);
  }

  function handleChangeLocation(coords) {
    console.log(coords.lat);
    console.log(coords.lng);
    setLocation({ lat: coords.lat, lng: coords.lng });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  const onChangeLogin = (checked: boolean) => {
    console.log(`switch to ${checked}`);
    setLogin(checked);
  };

  const onFinish = (values) => {
    setLoadings(true);
    const userAdd = {
      name: values.name,
      lastname: values.lastname,
      email: values.mail,
      roleId: role,
      phone: values.phone,
    };
    setUserData(userAdd);

    AddUserApi(userAdd).then((result) => {
      if (result.status) {
        setUserData(result.user);
        // notification["success"]({
        //   message: result.message,
        // });
        //next();
        const entrepreneurshipAdd = {
          name: values.businessname,
          userId: result.user.id,
          direction: values.direction,
          cityId: values.cityId,
          provinceId: values.provinceId,
          parroquiaId: values.parroquiaId,
          cityother: values.cityother,
          parroquiaother: values.parroquiaother,
          solutionId: values.solutionId,
          solutionother: values.solutionother,
          lat: location.lat,
          lon: location.lng,
        };
        setEntrepreneurshipData(entrepreneurshipAdd);

        AddEntrepreneurshipApi(entrepreneurshipAdd).then((result2) => {
          //console.log(result);
          if (result2.status) {
            setEntrepreneurshipData(result2.entrepreneurship);

            if (role === "1") {
              UpdateEntrepreneurshipExtrasApi(
                {
                  types: values.type,
                  communities: values.community,
                },
                result2.entrepreneurship.id
              ).then((resultExtra) => {
                if (resultExtra.status) {
                } else {
                }
              });
            } else if (role === "1") {
              UpdateEntrepreneurshipExtrasApi(
                {
                  types: values.type,
                },
                result2.entrepreneurship.id
              ).then((resultExtra) => {
                if (resultExtra.status) {
                } else {
                }
              });
            }

            if (values.communityOther) {
              AddOtherApi({
                table: "community",
                description: values.communityOther,
                entrepreneurshipId: result2.entrepreneurship.id,
              });
            }

            if (values.typeOther) {
              AddOtherApi({
                table: "type",
                description: values.typeOther,
                entrepreneurshipId: result2.entrepreneurship.id,
              });
            }

            if (values.haves) {
              var datahaves = values.haves.map((el) =>
                Object.assign({}, el, {
                  entrepreneurshipId: result2.entrepreneurship.id,
                })
              );
              BulkAddHaveApi({ haves: datahaves }).then((result) => {
                //console.log(result);
                if (result.status) {
                  //next();
                  //setLoadings(false);
                } else {
                  notification["error"]({
                    message: result.message,
                  });
                  //setLoadings(false);
                }
              });
            }

            if (values.needs) {
              var dataneeds = values.needs.map((el) =>
                Object.assign({}, el, {
                  entrepreneurshipId: result2.entrepreneurship.id,
                })
              );
              BulkAddNeedApi({ needs: dataneeds }).then((result) => {
                //console.log(result);
                if (result.status) {
                  //next();
                  //setLoadings(false);
                } else {
                  notification["error"]({
                    message: result.message,
                  });
                  //setLoadings(false);
                }
              });
            }

            if (values.haveresiduals || values.needresiduals) {
              if (values.needresiduals) {
                var dataneedresiduals = values.needresiduals.map((el) =>
                  Object.assign({}, el, {
                    entrepreneurshipId: result2.entrepreneurship.id,
                  })
                );
                BulkAddNeedresidualApi({
                  needresiduals: dataneedresiduals,
                }).then((result) => {
                  //console.log(result);
                  if (result.status) {
                    if (values.haveresiduals) {
                      var datahaveresiduals = values.haveresiduals.map((el) =>
                        Object.assign({}, el, {
                          entrepreneurshipId: result2.entrepreneurship.id,
                        })
                      );
                      BulkAddHaveresidualApi({
                        haveresiduals: datahaveresiduals,
                      }).then((result) => {
                        //console.log(result);
                        if (result.status) {
                        } else {
                          notification["error"]({
                            message: result.message,
                          });
                          //setLoadings(false);
                        }
                      });
                    } else {
                      //next();
                      //setLoadings(false);
                    }
                  } else {
                    notification["error"]({
                      message: result.message,
                    });
                    //setLoadings(false);
                  }
                });
              } else if (values.haveresiduals) {
                var datahaveresiduals = values.haveresiduals.map((el) =>
                  Object.assign({}, el, {
                    entrepreneurshipId: result2.entrepreneurship.id,
                  })
                );
                BulkAddHaveresidualApi({
                  haveresiduals: datahaveresiduals,
                }).then((result) => {
                  //console.log(result);
                  if (result.status) {
                    //next();
                    //setLoadings(false);
                  } else {
                    notification["error"]({
                      message: result.message,
                    });
                    //setLoadings(false);
                  }
                });
              }
            }

            AddEntrepreneurshipEventApi({
              entrepreneurshipId: result2.entrepreneurship.id,
              eventId: event.id,
            });

            notification["success"]({
              message: result2.message,
            });
            setVisible(true);
            setLoadings(false);
          } else {
            notification["error"]({
              message: result2.message,
            });
            setLoadings(false);
          }
        });
        //setLoadings(false);
      } else {
        notification["error"]({
          message: result.message,
        });
        setLoadings(false);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revise los errores en la información ingresada",
      duration: 3,
    });
  };

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const [formValid, setFormValid] = useState({
    email: false,
    password: false,
  });

  const [logging, setLogging] = useState(false);

  const changeForm = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const inputValidation = (e) => {
    const { type, name } = e.target;

    if (type === "email") {
      setFormValid({
        ...formValid,
        [name]: emailValidation(e.target),
      });
    }

    if (type === "password") {
      setFormValid({
        ...formValid,
        [name]: minLengthValidation(e.target, 6),
      });
    }
  };

  const onFinishLogin = async (e) => {
    //e.preventDefault();
    setLogging(true);
    const passwordVal = inputs.password;
    if (!inputs.email || !passwordVal) {
      notification["error"]({
        message: "Todos los campos son obligatorios",
      });
      setLogging(false);
    } else {
      const result = await signInApi(inputs);
      if (!result) {
        notification["error"]({
          message: "El servidor no se ha iniciado",
        });
        setLogging(false);
      } else if (result.message) {
        notification["error"]({
          message: result.message,
        });
        setLogging(false);
      } else {
        const { accessToken, refreshToken } = result;
        if (accessToken && refreshToken) {
          localStorage.setItem(ACCESS_TOKEN, accessToken);
          localStorage.setItem(REFRESH_TOKEN, refreshToken);

          const userlogged = jwtDecode(accessToken);
          setUserData(userlogged);
          setLoginRole(userlogged.role);
          getEntrepreneurshipByIdApi(accessToken, userlogged.id).then(
            (response) => {
              setLoginVisible(true);
              setLogging(false);

              AddEntrepreneurshipEventApi({
                entrepreneurshipId: response.entrepreneurship.id,
                eventId: event.id,
              });
              notification["success"]({
                message: "Registro correcto.",
              });
            }
          );

          // const userlogged = jwtDecode(accessToken);
          // if (userlogged.role === 4) {
          //   setLogging(false);
          //   window.location.href = "/admin";
          // } else if (userlogged.role === 1) {
          //   setLogging(false);
          //   window.location.href = "/user";
          // } else if (userlogged.role === 2) {
          //   setLogging(false);
          //   window.location.href = "/reciclador";
          // } else if (userlogged.role === 3) {
          //   setLogging(false);
          //   window.location.href = "/proyecto";
          // } else {
          //   setLogging(false);
          //   window.location.href = "/user";
          // }
        } else {
          notification["error"]({
            message: "El servidor no se ha iniciado",
          });
          setLogging(false);
        }
      }
    }
    //console.log(result);
  };

  const completeLogin = async (e) => {
    if (userData.role === 4) {
      setLogging(false);
      window.location.href = "/admin";
    } else if (userData.role === 1) {
      setLogging(false);
      window.location.href = "/user";
    } else if (userData.role === 2) {
      setLogging(false);
      window.location.href = "/reciclador";
    } else if (userData.role === 3) {
      setLogging(false);
      window.location.href = "/proyecto";
    } else {
      setLogging(false);
      window.location.href = "/user";
    }
  };

  return (
    <Layout className="eventos">
      <Header className="eventos__header">
        <Row gutter={24}>
          <Col xs={6} sm={6} md={4} lg={4} xl={4}>
            <img alt="conecta2" src={Logo} style={{ width: "170px" }}></img>
          </Col>
          <Col xs={8} sm={10} md={12} lg={16} xl={16}></Col>
          <Col
            xs={10}
            sm={8}
            md={8}
            lg={4}
            xl={4}
            style={{ textAlign: "rigth" }}
          >
            <Button
              type="link"
              style={{ marginTop: "19px", color: "black", fontSize: "19px" }}
              onClick={() => navigate(`/admin/login`)}
            >
              Ingresar <LoginOutlined />
            </Button>
          </Col>
        </Row>
      </Header>
      {!visible && !loginVisible && (
        <Content className="eventos__content">
          <div className="eventos__content__steps">
            <div
              style={{
                textAlign: "center",
                backgroundColor: "transparent",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            >
              Creando redes inteligentes
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "normal",
                }}
              >
                de economía colaborativa y circular
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "normal",
                  marginBottom: "-20px",
                }}
              >
                Con el apoyo de:
              </p>
              {/* <img alt="LogoBid" src={LogoBid} style={{ width: "100px" }}></img>
              <img
                alt="LogoCiti"
                src={LogoCiti}
                style={{ width: "100px" }}
              ></img> */}
              <img
                alt="LogoInnova"
                src={LogoInnova}
                style={{ width: "140px" }}
              ></img>
              <img
                alt="LogoAvina"
                src={LogoAvina}
                style={{ width: "140px" }}
              ></img>
              <img alt="LogoAEI" src={LogoAEI} style={{ width: "140px" }}></img>
              <img
                alt="LogoEmprende"
                src={LogoEmprende}
                style={{ width: "140px" }}
              ></img>
            </div>
            <Row gutter={16}>
              <Col span={8}>
                <Card
                  size="small"
                  title="Nombre del evento"
                  headStyle={{ backgroundColor: "#ffd5d3" }}
                  bodyStyle={{ fontWeight: "Bold", fontSize: "16px" }}
                >
                  {event ? event.name : "No existe el evento"}
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  size="small"
                  title="Fecha del evento"
                  headStyle={{ backgroundColor: "#ffd5d3" }}
                  bodyStyle={{ fontWeight: "Bold", fontSize: "16px" }}
                >
                  {event ? event.eventday.substring(0, 10) : ""}
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  size="small"
                  title="Ciudad del evento"
                  headStyle={{ backgroundColor: "#ffd5d3" }}
                  bodyStyle={{ fontWeight: "Bold", fontSize: "16px" }}
                >
                  {event ? event.city.name : ""}
                </Card>
              </Col>
            </Row>
            <br />
            <Row gutter={16}>
              <Col span={24} style={{ textAlign: "center" }}>
                Si ya tienes una cuenta en CONECTA2, presiona aquí{" "}
                <Switch onChange={onChangeLogin} />, caso contrario completa la
                información:
              </Col>
            </Row>

            {!login && (
              <>
                <PageHeader
                  className="site-page-header"
                  // onBack={() => null}
                  style={{ textAlign: "center" }}
                  title={
                    <>
                      <Avatar
                        icon={<ShopOutlined />}
                        style={{ backgroundColor: "#f42c1e", color: "white" }}
                      />

                      <span>Selección del perfil</span>
                    </>
                  }
                  // subTitle="This is a subtitle"
                />
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Radio.Group
                    defaultValue={role}
                    size="large"
                    onChange={onChangeRadio}
                  >
                    <Radio.Button value="1">Empresas</Radio.Button>
                    <Radio.Button value="3">
                      Proyectos o iniciativas
                    </Radio.Button>
                    <Radio.Button value="2">Recicladores</Radio.Button>
                  </Radio.Group>
                </div>
                <br />
                <Form
                  name="personal"
                  form={formPersonal}
                  // labelCol={{ span: 8 }}
                  // wrapperCol={{ span: 16 }}
                  initialValues={{
                    haves: [
                      {
                        entrepreneurshipId: undefined,
                        knowledgetypeId: undefined,
                        knowledgeId: undefined,
                        //hours: undefined,
                        //years: undefined,
                      },
                    ],
                    needs: [
                      {
                        entrepreneurshipId: undefined,
                        knowledgetypeId: undefined,
                        knowledgeId: undefined,
                        //hours: undefined,
                      },
                    ],
                    haveresiduals: [
                      {
                        entrepreneurshipId: undefined,
                        residualId: undefined,
                        //hours: undefined,
                        //years: undefined,
                      },
                    ],
                    needresiduals: [
                      {
                        entrepreneurshipId: undefined,
                        residualId: undefined,
                        //hours: undefined,
                        //years: undefined,
                      },
                    ],
                    remember: false,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <PageHeader
                    className="site-page-header"
                    // onBack={() => null}
                    style={{ textAlign: "center" }}
                    title={
                      <>
                        <Avatar
                          icon={<UserOutlined />}
                          style={{ backgroundColor: "#f42c1e", color: "white" }}
                        />
                        <span>Datos de contacto</span>
                      </>
                    }
                    // subTitle="This is a subtitle"
                  />
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                      <Form.Item
                        label="Nombres"
                        name="name"
                        rules={[
                          { required: true, message: "Ingrese sus nombres" },
                        ]}
                        wrapperCol={{ span: 24 }}
                        labelCol={{ span: 24 }}
                      >
                        <Input
                          //prefix={<UserOutlined />}
                          placeholder="Nombres completos"
                          maxLength={50}
                          //value={userData.name}
                          onChange={
                            (e) => {}
                            //setUserData({ ...userData, name: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                      <Form.Item
                        label="Apellidos"
                        name="lastname"
                        rules={[
                          { required: true, message: "Ingrese sus apellidos" },
                        ]}
                        wrapperCol={{ span: 24 }}
                        labelCol={{ span: 24 }}
                      >
                        <Input
                          //prefix={<UserOutlined />}
                          placeholder="Apellidos completos"
                          //value={userData.name2}
                          maxLength={50}
                          onChange={
                            (e) => {}
                            //setUserData({ ...userData, name2: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                      <Form.Item
                        label="Teléfono"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Ingrese su número de teléfono",
                          },
                        ]}
                        wrapperCol={{ span: 24 }}
                        labelCol={{ span: 24 }}
                      >
                        <Input
                          //prefix={<UserOutlined />}
                          placeholder="Teléfono/celular"
                          //value={userData.name2}
                          maxLength={10}
                          onChange={
                            (e) => {}
                            //setUserData({ ...userData, name2: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    {/* </Row>
          <Row gutter={24}> */}
                    <Col xs={24} sm={24} md={16} lg={8} xl={8}>
                      <Form.Item
                        label="Correo"
                        name="mail"
                        rules={[
                          {
                            required: true,
                            message: "Ingrese su correo electrónico",
                          },
                          {
                            type: "email",
                            message: "El correo ingresado no es correcto",
                          },
                        ]}
                        wrapperCol={{ span: 24 }}
                        labelCol={{ span: 24 }}
                      >
                        <Input
                          //prefix={<UserOutlined />}
                          placeholder="Correo electrónico"
                          //value={userData.name}
                          maxLength={50}
                          onChange={
                            (e) => {}
                            //setUserData({ ...userData, name: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <SelectCityProvinceForm
                        // atributoData={city}
                        // setAtributoData={setCity}
                        //handleChange={handleChangeYotengo}
                        name={"cityId"}
                        provincename={"provinceId"}
                        nameOther={"cityother"}
                        edit={false}
                        values={entrepreneurshipData}
                        parroquianame={"parroquiaId"}
                        parroquiaOther={"parroquiaother"}
                        city={city}
                        province={province}
                        parroquia={parroquia}
                        setCity={setCity}
                        setProvince={setProvince}
                        setParroquia={setParroquia}
                      ></SelectCityProvinceForm>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        label="Dirección"
                        name="direction"
                        rules={[
                          {
                            required: true,
                            message: "Ingrese la dirección",
                          },
                        ]}
                        wrapperCol={{ span: 24 }}
                        labelCol={{ span: 24 }}
                      >
                        <Input
                          //prefix={<UserOutlined />}
                          placeholder="Dirección"
                          maxLength={300}
                          //value={userData.name}
                          onChange={
                            (e) => {}
                            //setUserData({ ...userData, name: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                      <Form.Item
                        label={
                          role === "1"
                            ? "Nombre de la empresa"
                            : role === "2"
                            ? "Reciclador / organización / empresa"
                            : "Nombre de la organización"
                        }
                        wrapperCol={{ span: 24 }}
                        labelCol={{ span: 24 }}
                        name="businessname"
                        rules={[
                          {
                            required: true,
                            message: "Ingrese el nombre",
                          },
                        ]}
                      >
                        <Input
                          //prefix={<UserOutlined />}
                          placeholder="Nombre"
                          //value={userData.name}
                          maxLength={50}
                          onChange={
                            (e) => {}
                            //setUserData({ ...userData, name: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    {role === "3" ? (
                      <Col xs={24} sm={24} md={6} lg={5} xl={5}>
                        <Form.Item
                          label="Nombre del proyecto sostenible"
                          wrapperCol={{ span: 24 }}
                          labelCol={{ span: 24 }}
                          name="projectname"
                          rules={[
                            {
                              required: true,
                              message:
                                "Ingrese el nombre del proyecto sostenible",
                            },
                          ]}
                        >
                          <Input
                            //prefix={<UserOutlined />}
                            placeholder="Nombre del proyecto sostenible"
                            //value={userData.name}
                            maxLength={50}
                            onChange={
                              (e) => {}
                              //setUserData({ ...userData, name: e.target.value })
                            }
                          />
                        </Form.Item>
                      </Col>
                    ) : (
                      <></>
                    )}
                    {role === "1" ? (
                      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <SelectCommunityForm
                          atributoData={community}
                          setAtributoData={setCommunity}
                        ></SelectCommunityForm>
                      </Col>
                    ) : (
                      <></>
                    )}
                    {role === "1" || role === "3" ? (
                      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <SelectTypeForm
                          atributoData={type}
                          setAtributoData={setType}
                        ></SelectTypeForm>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                  <PageHeader
                    className="site-page-header"
                    // onBack={() => null}
                    style={{ textAlign: "center" }}
                    title={
                      <>
                        <Avatar
                          icon={<PushpinOutlined />}
                          style={{ backgroundColor: "#f42c1e", color: "white" }}
                        />
                        <span>UBICACIÓN</span>
                      </>
                    }
                    // subTitle="This is a subtitle"
                  />
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Card
                        title={
                          <span>
                            Por favor, en el mapa presione en su ubicación de
                            contacto para mover el puntero
                          </span>
                        }
                        extra={
                          <Button
                            type="dashed"
                            onClick={() => currentLocation()}
                            icon={<AimOutlined />}
                            size="small"
                            className="mini"
                            loading={searchlocation}
                          >
                            buscar mi ubicación
                          </Button>
                        }
                        bodyStyle={{ padding: "0" }}
                      >
                        {/* <MapPicker
                  defaultLocation={defaultLocation}
                  zoom={zoom}
                  mapTypeId="roadmap"
                  style={{ height: "300px" }}
                  onChangeLocation={handleChangeLocation}
                  onChangeZoom={handleChangeZoom}
                  // apiKey="AIzaSyDy8AE5RqF-ozGiI8J7r-U1vnb3AwQCY-o"
                  apiKey={apiKeyGoogle}
                /> */}
                        <div style={{ height: "450px", width: "100%" }}>
                          <GoogleMapReact
                            defaultZoom={zoom}
                            defaultCenter={defaultLocation}
                            bootstrapURLKeys={{
                              key: apiKeyGoogle,
                              language: "es",
                              region: "ec",
                              libraries: [],
                            }}
                            onClick={handleChangeLocation}
                            center={location}
                          >
                            <Image
                              width={48}
                              src={Pin}
                              lat={location.lat}
                              lng={location.lng}
                              preview={false}
                              style={{
                                marginTop: "-48px",
                                marginLeft: "-24px",
                              }}
                            />
                          </GoogleMapReact>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <br></br>
                  <Row gutter={24}>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      xl={{ span: 24, offset: 0 }}
                    >
                      <br /> Nota: En el caso de no contar con un recurso, puede
                      continuar el registro quitando la fila con el botón{" "}
                      <MinusCircleOutlined />
                      <br />
                    </Col>
                  </Row>
                  {role === "1" ? (
                    <div name="yotengo">
                      <PageHeader
                        className="site-page-header"
                        // onBack={() => null}
                        style={{ textAlign: "center" }}
                        title={
                          <>
                            <Avatar
                              icon={<ToolOutlined />}
                              style={{
                                backgroundColor: "#f42c1e",
                                color: "white",
                              }}
                            />
                            <span>
                              Recursos subutilizados de la cadena de valor
                            </span>
                            <br></br>
                            <span style={{ fontSize: "16px" }}>(YO TENGO)</span>
                            {/* <br></br>
                <span style={{ fontSize: "12px", fontWeight: "normal" }}>
                  Conocimientos (Administrativos, operativos, financieros,
                  comunicación, marketing...)
                </span> */}
                          </>
                        }
                        // subTitle="This is a subtitle"
                      />
                      <Row gutter={24}>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 24, offset: 0 }}
                          xl={{ span: 24, offset: 0 }}
                        >
                          <Form.List name="haves">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map((field) => (
                                  <Row gutter={24} key={field.key}>
                                    <Col
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      lg={2}
                                      xl={2}
                                      style={{
                                        textAlign: "rigth",
                                        paddingTop: "25px",
                                      }}
                                    >
                                      <MinusCircleOutlined
                                        onClick={() => remove(field.name)}
                                      />
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                      <SelectKnowledgetypeForm
                                        nametype={[
                                          field.name,
                                          "knowledgetypeId",
                                        ]}
                                        name={[field.name, "knowledgeId"]}
                                        nameOther={[field.name, "other"]}
                                        otherType={[field.name, "othertype"]}
                                      ></SelectKnowledgetypeForm>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                      <Form.Item
                                        label="Descripción corta"
                                        name={[field.name, "description"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Ingrese la descripción corta",
                                          },
                                        ]}
                                        wrapperCol={{ span: 24 }}
                                        labelCol={{ span: 24 }}
                                      >
                                        <Input
                                          //prefix={<UserOutlined />}
                                          placeholder="Descripción corta"
                                          maxLength={300}
                                          //value={userData.name}
                                          onChange={
                                            (e) => {}
                                            //setUserData({ ...userData, name: e.target.value })
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                                      <SelectRevenueListForm
                                        name={[field.name, "revenueId"]}
                                      ></SelectRevenueListForm>
                                    </Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                                      <SelectAgreementForm
                                        name={[field.name, "agreementId"]}
                                      ></SelectAgreementForm>
                                    </Col>
                                  </Row>

                                  // </Space>
                                ))}
                                <br></br>
                                <Row gutter={24}>
                                  <Col
                                    xs={{ span: 24, offset: 0 }}
                                    sm={{ span: 24, offset: 0 }}
                                    md={{ span: 24, offset: 0 }}
                                    lg={{ span: 16, offset: 4 }}
                                    xl={{ span: 16, offset: 4 }}
                                  >
                                    <Form.Item>
                                      <Button
                                        type="dashed"
                                        onClick={() => {
                                          if (fields.length >= 3) {
                                            notification["error"]({
                                              message:
                                                "Puede agregar hasta 3 recursos que tenga",
                                              duration: 3,
                                            });
                                            return false;
                                          }
                                          // add({
                                          //   entrepreneurshipId:
                                          //     entrepreneurshipData.id,
                                          // });
                                          add();
                                        }}
                                        block
                                        icon={<PlusOutlined />}
                                      >
                                        Agregar recursos que tenga
                                      </Button>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Form.List>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <></>
                  )}
                  {role === "1" || role === "3" ? (
                    <div name="yonecesito">
                      <PageHeader
                        className="site-page-header"
                        // onBack={() => null}
                        style={{ textAlign: "center" }}
                        title={
                          <>
                            {role === "3" ? (
                              <>
                                <Avatar
                                  icon={<ToolOutlined />}
                                  style={{
                                    backgroundColor: "#f42c1e",
                                    color: "white",
                                  }}
                                />
                                <span>
                                  Recursos subutilizados de la cadena de valor
                                </span>
                                <br></br>
                              </>
                            ) : (
                              <></>
                            )}
                            <span style={{ fontSize: "16px" }}>
                              (YO NECESITO)
                            </span>
                          </>
                        }
                        // subTitle="This is a subtitle"
                      />
                      <Row gutter={24}>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 24, offset: 0 }}
                          xl={{ span: 24, offset: 0 }}
                        >
                          <Form.List name="needs">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map((field) => (
                                  // <Space
                                  //   key={key}
                                  //   style={{
                                  //     display: "flex",
                                  //     marginBottom: 8,
                                  //     width: "100%",
                                  //   }}
                                  //   align="baseline"
                                  // >
                                  <Row gutter={24} key={field.key}>
                                    <Col
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      lg={2}
                                      xl={2}
                                      style={{
                                        textAlign: "rigth",
                                        paddingTop: "25px",
                                      }}
                                    >
                                      <MinusCircleOutlined
                                        onClick={() => remove(field.name)}
                                      />
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                      <SelectKnowledgetypeForm
                                        // atributoData={city}
                                        // setAtributoData={setCity}
                                        //handleChange={handleChangeYonecesito}
                                        name={[field.name, "knowledgeId"]}
                                        nametype={[
                                          field.name,
                                          "knowledgetypeId",
                                        ]}
                                        nameOther={[field.name, "other"]}
                                        otherType={[field.name, "othertype"]}
                                      ></SelectKnowledgetypeForm>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                      <Form.Item
                                        label="Descripción corta"
                                        name={[field.name, "description"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Ingrese la descripción corta",
                                          },
                                        ]}
                                        wrapperCol={{ span: 24 }}
                                        labelCol={{ span: 24 }}
                                      >
                                        <Input
                                          //prefix={<UserOutlined />}
                                          placeholder="Descripción corta"
                                          maxLength={300}
                                          //value={userData.name}
                                          onChange={
                                            (e) => {}
                                            //setUserData({ ...userData, name: e.target.value })
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                                      <SelectRevenueListForm
                                        // atributoData={city}
                                        // setAtributoData={setCity}
                                        //handleChange={handleChangeYotengo}
                                        name={[field.name, "revenueId"]}
                                        //nameOther={[field.name, "other"]}
                                        //typeData={}
                                      ></SelectRevenueListForm>
                                    </Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                                      <SelectAgreementForm
                                        // atributoData={city}
                                        // setAtributoData={setCity}
                                        //handleChange={handleChangeYotengo}
                                        name={[field.name, "agreementId"]}
                                        //nameOther={[field.name, "other"]}
                                        //typeData={}
                                      ></SelectAgreementForm>
                                    </Col>
                                  </Row>

                                  // </Space>
                                ))}
                                <br></br>
                                <Row gutter={24}>
                                  <Col
                                    xs={{ span: 24, offset: 0 }}
                                    sm={{ span: 24, offset: 0 }}
                                    md={{ span: 24, offset: 0 }}
                                    lg={{ span: 16, offset: 4 }}
                                    xl={{ span: 16, offset: 4 }}
                                  >
                                    <Form.Item>
                                      <Button
                                        type="dashed"
                                        onClick={() => {
                                          if (fields.length >= 3) {
                                            notification["error"]({
                                              message:
                                                "Puede agregar hasta 3 recursos que tenga",
                                              duration: 3,
                                            });
                                            return false;
                                          }
                                          // add({
                                          //   entrepreneurshipId:
                                          //     entrepreneurshipData.id,
                                          // });
                                          add();
                                        }}
                                        block
                                        icon={<PlusOutlined />}
                                      >
                                        Agregar recursos que tenga
                                      </Button>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Form.List>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    name="encuesta"
                    // labelCol={{ span: 8 }}
                    // wrapperCol={{ span: 16 }}
                  >
                    <PageHeader
                      className="site-page-header"
                      // onBack={() => null}
                      style={{ textAlign: "center" }}
                      title={
                        role === "1" ? (
                          <>
                            <Avatar
                              icon={<InteractionOutlined />}
                              style={{
                                backgroundColor: "#f42c1e",
                                color: "white",
                              }}
                            />
                            <span>Recursos residuales</span>
                            <br />
                            <span style={{ fontSize: "16px" }}>(YO TENGO)</span>
                          </>
                        ) : (
                          <></>
                        )
                      }
                      // subTitle="This is a subtitle"
                    />

                    {role === "1" ? (
                      <div>
                        <Row gutter={24}>
                          {/* <Col
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            lg={{ span: 24, offset: 0 }}
                            xl={{ span: 24, offset: 0 }}
                          >
                            {" "}
                            Nota: En el caso de no contar con un recurso
                            residual, puede continuar el registro quitando la
                            fila con el botón <MinusCircleOutlined />
                            <br />
                            <br />
                          </Col> */}
                          <Col
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            lg={{ span: 24, offset: 0 }}
                            xl={{ span: 24, offset: 0 }}
                          >
                            <Form.List name="haveresiduals">
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map((field) => (
                                    <Row gutter={24} key={field.key}>
                                      <Col
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                        style={{
                                          textAlign: "rigth",
                                          paddingTop: "25px",
                                        }}
                                      >
                                        <MinusCircleOutlined
                                          onClick={() => remove(field.name)}
                                        />
                                      </Col>
                                      <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                                        <SelectResidualForm
                                          name={[field.name, "residualId"]}
                                          nameOther={[field.name, "other"]}
                                        ></SelectResidualForm>
                                      </Col>
                                      <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                                        <SelectStateForm
                                          name={[field.name, "stateId"]}
                                          nameOther={[field.name, "other"]}
                                        ></SelectStateForm>
                                      </Col>
                                      <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                                        <SelectRevenueListForm
                                          name={[field.name, "revenueId"]}
                                          nameOther={[field.name, "other"]}
                                          //typeData={}
                                        ></SelectRevenueListForm>
                                      </Col>
                                      <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                                        <SelectWeightForm
                                          name={[field.name, "weightId"]}
                                          nameOther={[field.name, "other"]}
                                        ></SelectWeightForm>
                                      </Col>
                                      <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                                        <SelectTransportForm
                                          name={[field.name, "transportId"]}
                                          nameOther={[field.name, "other"]}
                                        ></SelectTransportForm>
                                      </Col>
                                      <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                                        <SelectUtilityForm
                                          name={[field.name, "utilityId"]}
                                          nameOther={[field.name, "other"]}
                                        ></SelectUtilityForm>
                                      </Col>
                                      <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                                        <SelectAgreementForm
                                          name={[field.name, "agreementId"]}
                                          nameOther={[field.name, "other"]}
                                        ></SelectAgreementForm>
                                      </Col>
                                    </Row>

                                    // </Space>
                                  ))}
                                  <br></br>
                                  <Row gutter={24}>
                                    <Col
                                      xs={{ span: 24, offset: 0 }}
                                      sm={{ span: 24, offset: 0 }}
                                      md={{ span: 24, offset: 0 }}
                                      lg={{ span: 16, offset: 4 }}
                                      xl={{ span: 16, offset: 4 }}
                                    >
                                      <Form.Item>
                                        <Button
                                          type="dashed"
                                          onClick={() => {
                                            if (fields.length >= 3) {
                                              notification["error"]({
                                                message:
                                                  "Puede agregar hasta 3 recursos necesarios",
                                                duration: 3,
                                              });
                                              return false;
                                            }
                                            // add({
                                            //   entrepreneurshipId:
                                            //     entrepreneurshipData.id,
                                            // });
                                            add();
                                          }}
                                          block
                                          icon={<PlusOutlined />}
                                        >
                                          Agregar recurso residual que tengo
                                        </Button>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </Form.List>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div>
                      <PageHeader
                        className="site-page-header"
                        // onBack={() => null}
                        style={{ textAlign: "center" }}
                        title={
                          role === "1" ? (
                            <>
                              <span style={{ fontSize: "16px" }}>
                                (YO NECESITO)
                              </span>
                            </>
                          ) : (
                            <>
                              <Avatar
                                icon={<InteractionOutlined />}
                                style={{
                                  backgroundColor: "#f42c1e",
                                  color: "white",
                                }}
                              />
                              <span>RECURSOS RESIDUALES</span>
                              <br />
                              <span style={{ fontSize: "16px" }}>
                                (YO NECESITO)
                              </span>
                            </>
                          )
                        }
                        // subTitle="This is a subtitle"
                      />
                      <Row gutter={24}>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 24, offset: 0 }}
                          xl={{ span: 24, offset: 0 }}
                        >
                          <Form.List name="needresiduals">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map((field) => (
                                  <Row gutter={24} key={field.key}>
                                    <Col
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      lg={2}
                                      xl={2}
                                      style={{
                                        textAlign: "rigth",
                                        paddingTop: "25px",
                                      }}
                                    >
                                      <MinusCircleOutlined
                                        onClick={() => remove(field.name)}
                                      />
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                                      <SelectResidualForm
                                        name={[field.name, "residualId"]}
                                        nameOther={[field.name, "other"]}
                                      ></SelectResidualForm>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                                      <SelectStateForm
                                        name={[field.name, "stateId"]}
                                        nameOther={[field.name, "other"]}
                                      ></SelectStateForm>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                                      <SelectRevenueListForm
                                        name={[field.name, "revenueId"]}
                                        nameOther={[field.name, "other"]}
                                        //typeData={}
                                      ></SelectRevenueListForm>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                                      <SelectWeightForm
                                        name={[field.name, "weightId"]}
                                        nameOther={[field.name, "other"]}
                                      ></SelectWeightForm>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                                      <SelectTransportForm
                                        name={[field.name, "transportId"]}
                                        nameOther={[field.name, "other"]}
                                      ></SelectTransportForm>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                                      <SelectUtilityForm
                                        name={[field.name, "utilityId"]}
                                        nameOther={[field.name, "other"]}
                                      ></SelectUtilityForm>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                                      <SelectAgreementForm
                                        name={[field.name, "agreementId"]}
                                        nameOther={[field.name, "other"]}
                                      ></SelectAgreementForm>
                                    </Col>
                                  </Row>

                                  // </Space>
                                ))}
                                <br></br>
                                <Row gutter={24}>
                                  <Col
                                    xs={{ span: 24, offset: 0 }}
                                    sm={{ span: 24, offset: 0 }}
                                    md={{ span: 24, offset: 0 }}
                                    lg={{ span: 16, offset: 4 }}
                                    xl={{ span: 16, offset: 4 }}
                                  >
                                    <Form.Item>
                                      <Button
                                        type="dashed"
                                        onClick={() => {
                                          if (fields.length >= 3) {
                                            notification["error"]({
                                              message:
                                                "Puede agregar hasta 3 recursos que tenga",
                                              duration: 3,
                                            });
                                            return false;
                                          }
                                          // add({
                                          //   entrepreneurshipId:
                                          //     entrepreneurshipData.id,
                                          // });
                                          add();
                                        }}
                                        block
                                        icon={<PlusOutlined />}
                                      >
                                        Agregar recurso residual que necesito
                                      </Button>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Form.List>
                        </Col>
                      </Row>
                    </div>
                    {role === "1" || role === "3" ? (
                      <div>
                        <PageHeader
                          className="site-page-header"
                          // onBack={() => null}
                          style={{ textAlign: "center" }}
                          title={
                            <>
                              <Avatar
                                icon={<RestOutlined />}
                                style={{
                                  backgroundColor: "#f42c1e",
                                  color: "white",
                                }}
                              />
                              <span>
                                PROYECTO SOSTENIBLE QUE NECESITARÍA TU EMPRESA
                              </span>
                            </>
                          }
                          // subTitle="This is a subtitle"
                        />
                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <SelectSolutionForm
                              atributoData={solution}
                              setAtributoData={setSolution}
                            ></SelectSolutionForm>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <></>
                    )}
                    <br></br>
                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Form.Item wrapperCol={{ span: 24 }}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{ width: "100%" }}
                            loading={loadings}
                            className={"btnBig"}
                          >
                            Enviar <ArrowRightOutlined />
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </>
            )}
            {login && !loginVisible && (
              <Form
                className="login-form"
                onChange={changeForm}
                onFinish={onFinishLogin}
              >
                <br />
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Form.Item>
                      <Input
                        prefix={
                          <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        type="email"
                        name="email"
                        placeholder="Correo electronico"
                        className="login-form__input"
                        onChange={inputValidation}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Form.Item>
                      <Input
                        prefix={
                          <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        type="password"
                        name="password"
                        placeholder="Clave"
                        className="login-form__input"
                        onChange={inputValidation}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        loading={logging}
                        style={{ marginTop: "2px" }}
                        className="login-form__button"
                      >
                        Registrarme en el evento
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          </div>
        </Content>
      )}

      {loginVisible && (
        <Content className="eventos__finish">
          <div className="eventos__finish__steps">
            <Result
              status="success"
              title={`¡${userData.name}, su registro se ha completado exitosamente!`}
              subTitle="Ingresa a la plataforma para conectar con otros emprendedores."
              extra={[
                <Button
                  type="primary"
                  key="console"
                  style={{ height: "60px", width: "180px" }}
                  onClick={completeLogin}
                  className={"btnBig"}
                >
                  Ir a mi cuenta
                </Button>,
                // <Button key="buy">Buy Again</Button>,
              ]}
            />
          </div>
        </Content>
      )}

      {visible && !loginVisible && (
        <Content className="eventos__finish">
          <div className="eventos__finish__steps">
            <Result
              status="success"
              title={`¡${userData.name}, su registro se ha completado exitosamente!`}
              subTitle="Recibirá un correo con los datos de acceso (revise la bandeja de correo no deseado y/o spam). Ahora se puede conectar con otros emprendedores."
              extra={[
                <Button
                  type="primary"
                  key="console"
                  style={{ height: "60px", width: "180px" }}
                  onClick={() => navigate(`/admin/login`)}
                  className={"btnBig"}
                >
                  Ir a mi cuenta
                </Button>,
                // <Button key="buy">Buy Again</Button>,
              ]}
            />
          </div>
        </Content>
      )}
    </Layout>
  );
}
