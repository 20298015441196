import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  InputNumber,
  Radio,
} from "antd";

import { getAccessTokenApi } from "../../../../../api/auth";
import {
  UpdateHaveresidualApi,
  AddHaveresidualApi,
  DeleteHaveresidualApi,
} from "../../../../../api/haveresidual.api";
import {
  UpdateNeedresidualApi,
  AddNeedresidualApi,
  DeleteNeedresidualApi,
} from "../../../../../api/needresidual.api";
//import useAuth from "../../../../../hooks/useAuth";

import "./EditResidualForm.scss";
import SelectResidualForm from "../../../../../components/Admin/Residual/SelectResidualForm";
import SelectStateForm from "../../../../../components/Admin/State/SelectStateForm";
import SelectRevenueListForm from "../../../../../components/Admin/Revenue/SelectRevenueForm/SelectRevenueListForm";
import SelectWeightForm from "../../../../../components/Admin/Weight/SelectWeightForm";
import SelectTransportForm from "../../../../../components/Admin/Transport/SelectTransportForm";
import SelectUtilityForm from "../../../../../components/Admin/Utility/SelectUtilityForm";
import SelectAgreementForm from "../../../../../components/Admin/Agreement/SelectAgreementForm";

import { getEntrepreneurshipByIdApi } from "../../../../../api/entrepreneurship.api";

export default function EditResidualForm(props) {
  const {
    residual,
    setIsVisibleModal,
    setReloadResiduals,
    buttonName,
    addSlag,
    entrepreneurshipData,
    have,
  } = props;
  const [residualData, setResidualData] = useState({});
  //const { user } = useAuth();
  //const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const token = getAccessTokenApi();
  const [formResidual] = Form.useForm();

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    // setResidualData({
    //   code: "",
    //   name: "",
    // });
  };

  if (addSlag === "add") {
    formResidual.setFieldsValue({
      entrepreneurshipId: null,
      residualId: null,
      stateId: null,
      weightId: null,
      revenueId: null,
      transportId: null,
      utilityId: null,
      agreementId: null,
    });
  }

  useEffect(() => {
    if (residual) {
      formResidual.setFieldsValue(residual);
    } else {
      formResidual.setFieldsValue({
        entrepreneurshipId: null,
        residualId: null,
        stateId: null,
        weightId: null,
        revenueId: null,
        transportId: null,
        utilityId: null,
        agreementId: null,
      });
    }
    // getEntrepreneurshipByIdApi(token, user.id).then((response) => {
    //   setEntrepreneurshipData(response.entrepreneurship);
    // });
  }, [residual, addSlag]);

  const addUpdateResidual = (e) => {
    e.preventDefault();
    let residualUpdate = residualData;
    if (!residualUpdate.name || !residualUpdate.code) {
      notification["error"]({
        message: "El nombre y el código es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddHaveresidualApi(token, residualUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadResiduals(true);
        setIsVisibleModal(false);
        //resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateHaveresidualApi(token, residualUpdate, residual.id).then(
        (result) => {
          notification["success"]({
            message: result.message,
          });
          setReloadResiduals(true);
          setIsVisibleModal(false);
          setResidualData({
            ...residualData,
          });
        }
      );
    } else {
      DeleteHaveresidualApi(token, residual.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadResiduals(true);
        setIsVisibleModal(false);
      });
    }
  };

  const onFinish = (values) => {
    const token = getAccessTokenApi();
    if (addSlag === "add") {
      if (have) {
        AddHaveresidualApi(token, {
          ...values,
          entrepreneurshipId: entrepreneurshipData.id,
        }).then((result) => {
          notification["success"]({
            message: result.message,
          });
          setReloadResiduals(true);
          setIsVisibleModal(false);
          //resetForm();
        });
      } else {
        AddNeedresidualApi(token, {
          ...values,
          entrepreneurshipId: entrepreneurshipData.id,
        }).then((result) => {
          notification["success"]({
            message: result.message,
          });
          setReloadResiduals(true);
          setIsVisibleModal(false);
          //resetForm();
        });
      }
    } else if (addSlag === "edit") {
      if (have) {
        UpdateHaveresidualApi(
          token,
          { ...values, entrepreneurshipId: entrepreneurshipData.id },
          residual.id
        ).then((result) => {
          notification["success"]({
            message: result.message,
          });
          setReloadResiduals(true);
          setIsVisibleModal(false);
          setResidualData({
            ...residualData,
          });
        });
      } else {
        UpdateNeedresidualApi(
          token,
          { ...values, entrepreneurshipId: entrepreneurshipData.id },
          residual.id
        ).then((result) => {
          notification["success"]({
            message: result.message,
          });
          setReloadResiduals(true);
          setIsVisibleModal(false);
          setResidualData({
            ...residualData,
          });
        });
      }
    } else {
      console.log("Delete residual");
      console.log(have);
      console.log(residual);

      if (have) {
        DeleteHaveresidualApi(token, residual.id).then((result) => {
          notification["success"]({
            message: result.message,
          });
          setReloadResiduals(true);
          setIsVisibleModal(false);
        });
      } else {
        DeleteNeedresidualApi(token, residual.id).then((result) => {
          notification["success"]({
            message: result.message,
          });
          setReloadResiduals(true);
          setIsVisibleModal(false);
        });
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información ingresada",
      duration: 3,
    });
  };

  return (
    <div className="edit-residual-form">
      <Form
        form={formResidual}
        name="residual"
        className="form-edit"
        //onSubmit={addUpdateResidual}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{ remember: false }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <SelectResidualForm
              name={"residualId"}
              nameOther={"other"}
              edit={addSlag === "edit" || addSlag === "delete"}
              values={residual}
            ></SelectResidualForm>
          </Col>
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            <SelectStateForm
              name={"stateId"}
              nameOther={"other"}
              edit={addSlag === "edit" || addSlag === "delete"}
              values={residual}
            ></SelectStateForm>
          </Col>
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            <SelectRevenueListForm
              name={"revenueId"}
              nameOther={"other"}
              edit={addSlag === "edit" || addSlag === "delete"}
              values={residual}
            ></SelectRevenueListForm>
          </Col>
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            <SelectWeightForm
              name={"weightId"}
              nameOther={"other"}
              edit={addSlag === "edit" || addSlag === "delete"}
              values={residual}
            ></SelectWeightForm>
          </Col>
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            <SelectTransportForm
              name={"transportId"}
              nameOther={"other"}
              edit={addSlag === "edit" || addSlag === "delete"}
              values={residual}
            ></SelectTransportForm>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <SelectUtilityForm
              name={"utilityId"}
              nameOther={"other"}
              edit={addSlag === "edit" || addSlag === "delete"}
              values={residual}
            ></SelectUtilityForm>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <SelectAgreementForm
              name={"agreementId"}
              nameOther={"other"}
              edit={addSlag === "edit" || addSlag === "delete"}
              values={residual}
            ></SelectAgreementForm>
          </Col>

          {/* <Col xs={22} sm={22} md={7} lg={7} xl={7}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="years"
              label="Años de experiencia"
              rules={[
                {
                  required: true,
                  message: "Ingrese los años de experiencia",
                },
              ]}
            >
              <InputNumber placeholder="Años de experiencia" maxLength={4} />
            </Form.Item>
          </Col> */}
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
