import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  InputNumber,
  Radio,
  DatePicker,
} from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateEventApi,
  AddEventApi,
  DeleteEventApi,
} from "../../../../api/event.api";
//import useAuth from "../../../../../hooks/useAuth";

import "./EditEventForm.scss";
//import SelectKnowledgeForm from "../../../../../components/Admin/Knowledge/SelectKnowledgeForm";
import SelectCityOnlyForm from "../../City/SelectCityForm/SelectCityOnlyForm";
import moment from "moment";

export default function EditEventForm(props) {
  const { event, setIsVisibleModal, setReloadEvents, buttonName, addSlag } =
    props;
  const [eventData, setEventData] = useState({});
  const [city, setCity] = useState();
  const [province, setProvince] = useState();
  //const { user } = useAuth();
  //const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const token = getAccessTokenApi();
  const [formEvent] = Form.useForm();

  const url = window.location.origin;
  // const pathname = window.location.pathname;
  // const protocol = window.location.protocol;
  // const hostname = window.location.hostname;

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    // setEventData({
    //   code: "",
    //   name: "",
    // });
  };

  useEffect(() => {
    if (event) {
      if (event.eventday && event.eventday !== undefined) {
        event.eventday = moment(event.eventday);
      }

      formEvent.setFieldsValue(event);
    } else {
      formEvent.setFieldsValue({
        name: null,
        description: null,
        eventday: null,
        url: null,
        cityId: null,
      });
    }

    // getEntrepreneurshipByIdApi(token, user.id).then((response) => {
    //   setEntrepreneurshipData(response.entrepreneurship);
    // });
  }, [event]);

  useEffect(() => {
    if (addSlag === "add") {
      formEvent.setFieldsValue({
        name: null,
        description: null,
        eventday: null,
        url: null,
        cityId: null,
      });
    }
    // getEntrepreneurshipByIdApi(token, user.id).then((response) => {
    //   setEntrepreneurshipData(response.entrepreneurship);
    // });
  }, []);

  const onFinish = (values) => {
    const token = getAccessTokenApi();
    if (addSlag === "add") {
      AddEventApi(token, {
        ...values,
      }).then((result) => {
        if (result.status) {
          notification["success"]({
            message: result.message,
          });
          setReloadEvents(true);
          setIsVisibleModal(false);
        } else {
          notification["error"]({
            message: result.message,
          });
        }

        //resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateEventApi(token, { ...values }, event.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadEvents(true);
        setIsVisibleModal(false);
        setEventData({
          ...eventData,
        });
      });
    } else {
      DeleteEventApi(token, event.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadEvents(true);
        setIsVisibleModal(false);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información ingresada",
      duration: 3,
    });
  };

  return (
    <div className="edit-event-form">
      <Form
        form={formEvent}
        name="event"
        className="form-edit"
        //onSubmit={addUpdateEvent}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{ remember: false }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item
              label="Nombre del evento"
              name={"name"}
              rules={[
                {
                  required: true,
                  message: "Ingrese el nombre del evento",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Nombre del evento"
                maxLength={300}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <Form.Item
              label="Descripción corta"
              name={"description"}
              rules={[
                {
                  required: true,
                  message: "Ingrese la descripción corta",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Descripción corta"
                maxLength={300}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <SelectCityOnlyForm
              // atributoData={city}
              // setAtributoData={setCity}
              //handleChange={handleChangeYotengo}
              name={"cityId"}
              provincename={"provinceId"}
              nameOther={"cityother"}
              edit={false}
              values={event}
              city={city}
              province={province}
              setCity={setCity}
              setProvince={setProvince}
            ></SelectCityOnlyForm>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              label="Fecha del evento"
              name="eventday"
              rules={[
                {
                  required: true,
                  message: "Seleccione la fecha del evento",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Url para el evento"
              name={"url"}
              rules={[
                {
                  required: true,
                  message: "Ingrese la url para el evento",
                },
                {
                  pattern: /^[a-z0-9]*$/,
                  message: "El Url tiene un formato incorrecto",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                addonBefore={url + "/networking/"}
                //prefix={<UserOutlined />}
                placeholder="Url para el evento"
                maxLength={300}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
