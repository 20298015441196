import React, { useState, useEffect } from "react";
import { Select, Form, Input } from "antd";

import { GetKnowledgeByTypeApi } from "../../../../api/knowledge.api";

import "./SelectKnowledgeForm.scss";

const { Option } = Select;

export default function SelectKnowledgeForm(props) {
  const { name, nameOther, edit, values, knowledgetypeId } = props;
  const [knowledgeData, setKnowledgeData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [knowledgeId, setKnowledgeId] = useState();
  const [other, setOther] = useState(false);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    console.log(knowledgetypeId);
    if (knowledgetypeId !== undefined && knowledgetypeId > 0) {
      GetKnowledgeByTypeApi(knowledgetypeId).then((response) => {
        setKnowledgeData(response.knowledges);
        //setKnowledgeId(atributoData);
        //setKnowledgeId(null);
      });
      //setKnowledgeId(null);
      setLoadingData(false);
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [loadingData, knowledgetypeId]);

  useEffect(() => {
    //console.log(values);
    if (edit ?? false) {
      if (values.knowledgeId >= 33) {
        setOther(true);
      } else {
        setOther(false);
      }
    } else {
      setOther(false);
    }
  }, [values]);

  // const handleAtributoChange = (value) => {
  //   setKnowledgeId(value);
  //   setAtributoData(value);
  // };

  const handleAtributoChange = (value) => {
    console.log(value);
    setKnowledgeId(value);
    if (value >= 33) {
      setOther(true);
    } else {
      setOther(false);
    }
  };

  return (
    <div className="select-knowledge-form">
      <Form.Item
        label="Recursos"
        name={name}
        rules={[{ required: true, message: "Seleccione un recurso" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        //style={other && { marginBottom: "5px" }}
        style={{ marginBottom: other ? "5px" : "24px" }}
        disabled={!enabled}
        //value={knowledgeId}
      >
        <Select
          placeholder="Seleccione un recurso"
          //value={knowledgeId}
          //onChange={handleChange}
          onChange={handleAtributoChange}
          disabled={!enabled}
        >
          {knowledgeData && knowledgeData.map
            ? knowledgeData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
      {other && (
        <Form.Item
          //label="Otros"
          name={nameOther}
          rules={[{ required: true, message: "Ingrese el recurso" }]}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
        >
          <Input
            //prefix={<UserOutlined />}
            placeholder="Recurso"
            //value={userData.name}
            maxLength={200}
          />
        </Form.Item>
      )}
    </div>
  );
}
