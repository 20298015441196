import React, { useState, useEffect } from "react";
import { Navigate, Routes, Route, BrowserRouter } from "react-router-dom";
import { Layout } from "antd";
import MenuTop from "../components/Admin/MenuTop";
import MenuSiderUser from "../components/Home/User/MenuSider";
import AdminSignIn from "../pages/Admin/SignIn";
//import Admin from "../pages/Admin";
//import UseAuth from "../hooks/useAuth";

//import { getAccessToken, getRefreshToken } from "../api/auth";

import "./LayoutUser.scss";
import useAuth from "../hooks/useAuth";
import { getAccessTokenApi } from "../api/auth";
import HomeUser from "../pages/User";
import PersonalUser from "../pages/User/Personal";
import EmprendimientoUser from "../pages/User/Emprendimiento";
import Error404 from "../pages/Error404";
import HaveUser from "../pages/User/Have";
import NeedUser from "../pages/User/Need";
import ResidualUser from "../pages/User/Residual";
import ChangeUser from "../pages/User/Change";
import ConnectionsUser from "../pages/User/Conexiones";
import ChatUser from "../pages/User/Chat";
import CalendarUser from "../pages/User/Calendar";
import SolutionUser from "../pages/User/Solution";
import EventsUser from "../pages/User/Eventos";
import { getUsersInfoApi } from "../api/user.api";

export default function LayoutUser(props) {
  const { socket } = props;
  const token = getAccessTokenApi();
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const { Header, Content, Footer } = Layout;
  const [data, setData] = useState();
  const [complete, setComplete] = useState();
  const { user, isLoading } = useAuth(); //hook
  const [reloadPerfil, setReloadPerfil] = useState(true);

  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    if (width < 756) {
      setMenuCollapsed(true);
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (reloadPerfil) {
        getUsersInfoApi(token, user.id).then((response) => {
          //console.log(response);
          setData(response.user);

          var tabStart = {
            user: 20,
            empresa: 0,
            have: 0,
            need: 0,
            haveresidual: 0,
            needresidual: 0,
            total: 0,
          };
          if (response.user.entrepreneurships.length > 0) {
            if (response.user.ci === null || response.user.ci === undefined) {
              tabStart.user = 10;
            }
            if (
              response.user.entrepreneurships[0].ruc === null ||
              response.user.entrepreneurships[0].ruc === undefined
            ) {
              tabStart.empresa = 10;
            } else {
              tabStart.empresa = 20;
            }
            if (response.user.entrepreneurships[0].haves.length > 0) {
              tabStart.have = 15;
            }
            if (response.user.entrepreneurships[0].needs.length > 0) {
              tabStart.need = 15;
            }
            if (response.user.entrepreneurships[0].haveresiduals.length > 0) {
              tabStart.haveresidual = 15;
            }
            if (response.user.entrepreneurships[0].needresiduals.length > 0) {
              tabStart.needresidual = 15;
            }
          }

          tabStart.total =
            tabStart.user +
            tabStart.empresa +
            tabStart.have +
            tabStart.need +
            tabStart.haveresidual +
            tabStart.needresidual;

          setComplete(tabStart);
        });
        setReloadPerfil(false);
      }
    }
  }, [token, user, reloadPerfil]);

  //console.log(user);
  if (!user && !isLoading) {
    return (
      <>
        <Routes>
          <Route path="/admin/login" element={<AdminSignIn />}></Route>
          {/* <Navigate to="/admin/login"></Navigate> */}
        </Routes>

        <Navigate to="/admin/login"></Navigate>
      </>
    );
  } else if (user && !isLoading && user.role !== 1) {
    return (
      <>
        <Routes>
          <Route path="/admin/login" element={<AdminSignIn />}></Route>
          {/* <Navigate to="/admin/login"></Navigate> */}
        </Routes>

        <Navigate to="/admin/login"></Navigate>
      </>
    );
  }

  if (user && !isLoading) {
    return (
      <Layout>
        <MenuSiderUser
          menuCollapsed={menuCollapsed}
          data={data}
          complete={complete}
        />
        <Layout
          className="layout-user"
          //style={{ marginLeft: menuCollapsed ? "80px" : "256px" }}
        >
          <Header className="layout-user__header">
            <MenuTop
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            />
          </Header>

          <Content className="layout-user__content">
            {/* <LoadRoutes routes={routes} /> */}
            <Routes>
              <Route path="/user/*" element={<Error404 />} />
              <Route
                path="/user"
                element={
                  <HomeUser
                    socket={socket}
                    //setData={setData}
                    //setComplete={setComplete}
                    complete={complete}
                  />
                }
              />
              <Route
                path="/user/personal"
                element={
                  <PersonalUser
                    socket={socket}
                    setReloadPerfil={setReloadPerfil}
                  />
                }
              />
              <Route
                path="/user/solution"
                element={
                  <SolutionUser
                    socket={socket}
                    setReloadPerfil={setReloadPerfil}
                  />
                }
              />
              <Route
                path="/user/emprendimiento"
                element={
                  <EmprendimientoUser
                    socket={socket}
                    setReloadPerfil={setReloadPerfil}
                  />
                }
              />
              <Route
                path="/user/yotengo"
                element={
                  <HaveUser socket={socket} setReloadPerfil={setReloadPerfil} />
                }
              />
              <Route
                path="/user/yonecesito"
                element={
                  <NeedUser socket={socket} setReloadPerfil={setReloadPerfil} />
                }
              />
              <Route
                path="/user/residual"
                element={
                  <ResidualUser
                    socket={socket}
                    setReloadPerfil={setReloadPerfil}
                  />
                }
              />
              <Route
                path="/user/change"
                element={<ChangeUser socket={socket} />}
              />
              <Route
                path="/user/conexiones"
                element={<ConnectionsUser socket={socket} />}
              />
              <Route path="/user/chat" element={<ChatUser socket={socket} />} />
              <Route
                path="/user/calendar"
                element={<CalendarUser socket={socket} />}
              />
              <Route
                path="/user/events"
                element={<EventsUser socket={socket} />}
              />
            </Routes>
          </Content>
          <Footer className="layout-user__footer">
            CONECTA2 EC - 2023 COPYRIGHT©
          </Footer>
        </Layout>
      </Layout>
    );
  }

  return null;
}

// function LoadRoutes(routes) {
//   return (
//     // <BrowserRouter>
//     //   <Routes>
//     //     <Route path="/" element={<Home />} />
//     //     <Route path="users" element={<Users />}>
//     //       <Route path="me" element={<OwnUserProfile />} />
//     //       <Route path=":id" element={<UserProfile />} />
//     //     </Route>
//     //   </Routes>
//     // </BrowserRouter>
//     <Routes>
//       {routes.map((route, index) => (
//         <Route
//           key={index}
//           path={route.path}
//           //exact={route.exact}
//           //component={route.component}
//           element={route.component}
//         />
//       ))}
//     </Routes>
//   );
// }
