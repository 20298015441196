import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Row, Col, DatePicker, Button } from "antd";
import { Pie, Bar } from "@ant-design/charts";
import { getAccessTokenApi } from "../../../api/auth";

import "./Indicadores.scss";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

import { getNeedGroupApi } from "../../../api/need.api";
import { getHaveGroupApi } from "../../../api/have.api";

export default function Conocimientos() {
  const token = getAccessTokenApi();
  //const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [disabled2, setDisabled2] = useState(true);
  const [disabled3, setDisabled3] = useState(true);
  const [disabled4, setDisabled4] = useState(true);

  useEffect(() => {
    // if (fincaId.length > 0) {
    //   if (fechaInicial && fechaFinal) {
    //     getFincasAmbitoAvgApi(token, fechaInicial, fechaFinal, fincaId).then(
    //       (response) => {
    //         setData(response.fincas);
    //       }
    //     );
    //   } else {
    //     getFincasAmbitoAvgApi(token, 0, 0, fincaId).then((response) => {
    //       setData(response.fincas);
    //     });
    //   }
    // } else {
    //   if (fechaInicial && fechaFinal) {
    //     getFincasAmbitoAvgApi(token, fechaInicial, fechaFinal, 0).then(
    //       (response) => {
    //         setData(response.fincas);
    //       }
    //     );
    //   } else {
    //     getFincasAmbitoAvgApi(token, 0, 0, 0).then((response) => {
    //       setData(response.fincas);
    //     });
    //   }
    // }
    getNeedGroupApi(token, true).then((response) => {
      console.log(response.needs);
      setData(response.needs);
    });
    getNeedGroupApi(token, false).then((response3) => {
      console.log(response3.needs);
      setData3(response3.needs);
    });
    getHaveGroupApi(token, true).then((response2) => {
      console.log(response2.haves);
      setData2(response2.haves);
    });
    getHaveGroupApi(token, false).then((response4) => {
      console.log(response4.haves);
      setData4(response4.haves);
    });
  }, [token]);

  const config = {
    // appendPadding: 10,
    // angleField: "count",
    // colorField: "name",
    // radius: 0.75,
    // label: {
    //   type: "spider",
    //   labelHeight: 28,
    //   content: "{name}:{value}\n{percentage}",
    // },
    // interactions: [
    //   {
    //     type: "element-selected",
    //   },
    //   {
    //     type: "element-active",
    //   },
    // ],
    xField: "count",
    yField: "name",
    seriesField: "name",
    legend: {
      position: "top-left",
    },
  };

  // function onChange(date, dateString) {
  //   if (date) {
  //     setFechaInicial(date[0].startOf("month").format("YYYY-MM-DD"));
  //     setFechaFinal(date[1].endOf("month").format("YYYY-MM-DD"));
  //   } else {
  //     setFechaInicial(null);
  //     setFechaFinal(null);
  //   }
  // }

  const ref = useRef([]);

  // export image
  const downloadImage = () => {
    ref.current[0]?.downloadImage();
  };

  const downloadImage3 = () => {
    ref.current[2]?.downloadImage();
  };

  // export image
  const downloadImage2 = () => {
    ref.current[1]?.downloadImage();
  };

  const downloadImage4 = () => {
    ref.current[3]?.downloadImage();
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Gráficas</Breadcrumb.Item>
        <Breadcrumb.Item>Recursos</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <Row gutter={24}>
          {/* <Row gutter={16}>
          <Col span={12}>
            <RangePicker
              style={{ width: "100%" }}
              picker="month"
              onChange={onChange}
            />
          </Col>
          <Col span={12}>
            { <SelectMultiFinca setFincaId={setFincaId}></SelectMultiFinca> }
          </Col>
        </Row> */}
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <br />
            <h3>Cantidad de recursos en demanda (Actividades primarias)</h3>

            <Bar
              data={data ?? []}
              {...config}
              onReady={(plot) => {
                ref.current[0] = plot;
                setDisabled(false);
              }}
            />
            <br />
            <Button
              disabled={disabled}
              htmlType="button"
              className="btn-submit"
              onClick={downloadImage}
            >
              Guardar gráfico
            </Button>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <br />
            <h3>Cantidad de recursos en demanda (Actividades de apoyo)</h3>

            <Bar
              data={data3 ?? []}
              {...config}
              onReady={(plot) => {
                ref.current[2] = plot;
                setDisabled3(false);
              }}
            />
            <br />
            <Button
              disabled={disabled3}
              htmlType="button"
              className="btn-submit"
              onClick={downloadImage3}
            >
              Guardar gráfico
            </Button>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <br />
            <h3>Cantidad de recursos en oferta (Actividades primarias)</h3>

            <Bar
              data={data2 ?? []}
              {...config}
              onReady={(plot) => {
                ref.current[1] = plot;
                setDisabled2(false);
              }}
            />
            <br />
            <Button
              disabled={disabled2}
              htmlType="button"
              className="btn-submit"
              onClick={downloadImage2}
            >
              Guardar gráfico
            </Button>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <br />
            <h3>Cantidad de recursos en oferta (Actividades de apoyo)</h3>

            <Bar
              data={data4 ?? []}
              {...config}
              onReady={(plot) => {
                ref.current[3] = plot;
                setDisabled4(false);
              }}
            />
            <br />
            <Button
              disabled={disabled4}
              htmlType="button"
              className="btn-submit"
              onClick={downloadImage4}
            >
              Guardar gráfico
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
