import React, { useState, useEffect } from "react";
import { Select, Form, Input } from "antd";

import { getCommunitysApi } from "../../../../api/community.api";

import "./SelectCommunityForm.scss";

const { Option } = Select;

export default function SelectCommunityOneForm(props) {
  const { atributoData, setAtributoData } = props;
  const [communityData, setCommunityData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [communityId, setCommunityId] = useState();
  const [other, setOther] = useState(false);

  useEffect(() => {
    getCommunitysApi().then((response) => {
      setCommunityData(response.communitys);
      //setCommunityId(atributoData);
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value) => {
    setCommunityId(value);
    setAtributoData(value ?? 0);
    var isOther = value === 10;
    if (isOther) {
      setOther(true);
    } else {
      setOther(false);
    }
  };

  // useEffect(() => {
  //   //console.log(values);
  //   if (atributoData) {
  //     var isOther = atributoData.find((element) => {
  //       return element === 10;
  //     });
  //     if (isOther) {
  //       setOther(true);
  //     } else {
  //       setOther(false);
  //     }
  //   } else {
  //     setOther(false);
  //   }
  // }, [atributoData]);

  return (
    <div className="select-community-form">
      <Form.Item
        label="Comunidad - Asociación"
        name="community"
        //rules={[{ required: true, message: "Seleccione una comunidad" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        style={{ marginBottom: other ? "5px" : "24px" }}
        //initialValue={atributoData}
      >
        <Select
          placeholder="Seleccione la comunidad - asociación"
          value={communityId}
          onChange={handleAtributoChange}
          allowClear={true}
          //mode="multiple"
          //disabled={disabled}
        >
          {communityData && communityData.map
            ? communityData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
