import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Row, Col, DatePicker, Button } from "antd";
import { Bar } from "@ant-design/charts";
import { getAccessTokenApi } from "../../../api/auth";

import "./Indicadores.scss";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

import { getNeedGroupApi } from "../../../api/need.api";
import { getHaveGroupApi } from "../../../api/have.api";

export default function Oferta() {
  const token = getAccessTokenApi();
  //const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [disabled2, setDisabled2] = useState(true);
  const [groupHave, setGroupHave] = useState();
  const [groupNeed, setGroupNeed] = useState();
  const [groupHave2, setGroupHave2] = useState();
  const [groupNeed2, setGroupNeed2] = useState();
  const [loadingN, setLoadingN] = useState(true);
  const [loadingH, setLoadingH] = useState(true);

  useEffect(() => {
    // if (fincaId.length > 0) {
    //   if (fechaInicial && fechaFinal) {
    //     getFincasAmbitoAvgApi(token, fechaInicial, fechaFinal, fincaId).then(
    //       (response) => {
    //         setData(response.fincas);
    //       }
    //     );
    //   } else {
    //     getFincasAmbitoAvgApi(token, 0, 0, fincaId).then((response) => {
    //       setData(response.fincas);
    //     });
    //   }
    // } else {
    //   if (fechaInicial && fechaFinal) {
    //     getFincasAmbitoAvgApi(token, fechaInicial, fechaFinal, 0).then(
    //       (response) => {
    //         setData(response.fincas);
    //       }
    //     );
    //   } else {
    //     getFincasAmbitoAvgApi(token, 0, 0, 0).then((response) => {
    //       setData(response.fincas);
    //     });
    //   }
    // }
    getNeedGroupApi(token, true).then((response) => {
      const data1 = [];
      console.log(response.needs);
      let needs1 = response.needs;
      needs1.map((need) =>
        data1.push({
          name: need.name,
          count: parseInt(need.count),
          type: "Demanda",
        })
      );
      setGroupNeed(data1);
      setLoadingN(false);
    });
    getNeedGroupApi(token, false).then((response) => {
      const data1 = [];
      console.log(response.needs);
      let needs1 = response.needs;
      needs1.map((need) =>
        data1.push({
          name: need.name,
          count: parseInt(need.count),
          type: "Demanda",
        })
      );
      setGroupNeed2(data1);
      setLoadingN(false);
    });
    getHaveGroupApi(token, true).then((response) => {
      const data2 = [];
      //console.log(response.haves);
      let haves1 = response.haves;
      haves1.map((have) =>
        data2.push({
          name: have.name,
          count: parseInt(have.count),
          type: "Oferta",
        })
      );

      setGroupHave(data2);
      setLoadingH(false);
    });
    getHaveGroupApi(token, false).then((response) => {
      const data2 = [];
      //console.log(response.haves);
      let haves1 = response.haves;
      haves1.map((have) =>
        data2.push({
          name: have.name,
          count: parseInt(have.count),
          type: "Oferta",
        })
      );

      setGroupHave2(data2);
      setLoadingH(false);
    });
  }, [token]);

  const config = {
    isStack: true,
    xField: "count",
    yField: "name",
    seriesField: "type",
    height: 400,
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    content: "{value} horas",
  };
  //console.log(config);

  // function onChange(date, dateString) {
  //   if (date) {
  //     setFechaInicial(date[0].startOf("month").format("YYYY-MM-DD"));
  //     setFechaFinal(date[1].endOf("month").format("YYYY-MM-DD"));
  //   } else {
  //     setFechaInicial(null);
  //     setFechaFinal(null);
  //   }
  // }

  const ref = useRef([]);

  // export image
  const downloadImage = () => {
    ref.current[0]?.downloadImage();
  };
  const downloadImage2 = () => {
    ref.current[1]?.downloadImage();
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Gráficas</Breadcrumb.Item>
        <Breadcrumb.Item>Oferta y demanda</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        {/* <Row gutter={16}>
          <Col span={12}>
            <RangePicker
              style={{ width: "100%" }}
              picker="month"
              onChange={onChange}
            />
          </Col>
          <Col span={12}>
            { <SelectMultiFinca setFincaId={setFincaId}></SelectMultiFinca> }
          </Col>
        </Row> */}

        <br />
        <h2>
          Porcentaje de recursos en oferta y demanda (actividades primarias)
        </h2>
        <Bar
          data={[...(groupHave ?? []), ...(groupNeed ?? [])]}
          {...config}
          onReady={(plot) => {
            ref.current[0] = plot;
            setDisabled(false);
          }}
        />
        <br />
        <Button
          disabled={disabled}
          htmlType="button"
          className="btn-submit"
          onClick={downloadImage}
        >
          Guardar gráfico
        </Button>

        <br />
        <h2>
          Porcentaje de recursos en oferta y demanda (actividades de apoyo)
        </h2>
        <Bar
          data={[...(groupHave2 ?? []), ...(groupNeed2 ?? [])]}
          {...config}
          onReady={(plot) => {
            ref.current[1] = plot;
            setDisabled2(false);
          }}
        />
        <br />
        <Button
          disabled={disabled2}
          htmlType="button"
          className="btn-submit"
          onClick={downloadImage2}
        >
          Guardar gráfico
        </Button>
      </div>
    </div>
  );
}
