import React, { useState, useEffect } from "react";
import { Select, Form, Input } from "antd";

import { getTypesApi } from "../../../../api/type.api";

import "./SelectTypeForm.scss";

const { Option } = Select;

export default function SelectTypeAllForm(props) {
  const { atributoData, setAtributoData, deafultValue } = props;
  const [typeData, setTypeData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [typeId, setTypeId] = useState();
  const [other, setOther] = useState(false);

  useEffect(() => {
    getTypesApi().then((response) => {
      setTypeData(response.types);
      //setTypeId(atributoData);
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value) => {
    setTypeId(value);
    setAtributoData(value ?? 0);
  };

  // useEffect(() => {
  //   //console.log(values);
  //   if (atributoData) {
  //     var isOther = atributoData.find((element) => {
  //       return element === 18;
  //     });
  //     if (isOther) {
  //       setOther(true);
  //     } else {
  //       setOther(false);
  //     }
  //   } else {
  //     setOther(false);
  //   }
  // }, [atributoData]);

  return (
    <div className="select-type-form">
      <Form.Item
        label="Sector económico"
        name="type"
        //rules={[{ required: true, message: "Seleccione un type" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        //style={other && { marginBottom: "5px" }}
        style={{ marginBottom: other ? "5px" : "24px" }}
        //initialValue={atributoData}
      >
        <Select
          placeholder="Seleccione el sector"
          value={typeId}
          onChange={handleAtributoChange}
          //mode="multiple"
          //disabled={disabled}
          allowClear={true}
        >
          {typeData && typeData.map
            ? typeData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
      {other && (
        <Form.Item
          //label="Otros"
          name="typeOther"
          rules={[{ required: true, message: "Seleccione el sector" }]}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          initialValue={deafultValue}
        >
          <Input
            //prefix={<UserOutlined />}
            placeholder="Type"
            //value={userData.name}
            maxLength={200}
          />
        </Form.Item>
      )}
    </div>
  );
}
