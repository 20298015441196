import React, { useState, useEffect } from "react";
import {
  Result,
  Button,
  PageHeader,
  Steps,
  Input,
  Layout,
  Col,
  Form,
  Row,
  Avatar,
  DatePicker,
  // Checkbox,
  notification,
  // Radio,
  Card,
  Image,
} from "antd";
import {
  ArrowRightOutlined,
  // ArrowLeftOutlined,
  LoginOutlined,
  UserOutlined,
  // BulbOutlined,
  ToolOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  ApiOutlined,
  // ForkOutlined,
  // RiseOutlined,
  // LaptopOutlined,
  // OrderedListOutlined,
  // ExperimentOutlined,
  PushpinOutlined,
  AimOutlined,
  // CheckCircleOutlined,
  ShopOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import Logo from "../assets/img/png/conecta2.png";
//import SelectCityForm from "../components/Admin/City/SelectCityForm/SelectCityForm";
import SelectCityProvinceForm from "../components/Admin/City/SelectCityForm/SelectCityProvinceForm";
import "./Home.scss";
import SelectGenreForm from "../components/Admin/Genre/SelectGenreForm/SelectGenreForm";
import SelectTypeForm from "../components/Admin/Type/SelectTypeForm";
import SelectSectorForm from "../components/Admin/Sector/SelectSectorForm";
// import SelectStageForm from "../components/Admin/Stage/SelectStageForm/SelectStageForm";
// import SelectProcessForm from "../components/Admin/Process/SelectProcessForm";
//import SelectCommunityForm from "../components/Admin/Community/SelectCommunityForm";
// import SelectAcademyForm from "../components/Admin/Academy/SelectAcademyForm";
import SelectKnowledgetypeForm from "../components/Admin/Knowledge/SelectKnowledgeForm/SelectKnowledgeTypeForm";
// import SelectResourceForm from "../components/Admin/Resource/SelectResourceForm";
// import SelectSupplierForm from "../components/Admin/Supplier/SelectSupplierForm";
// import SelectRevenueForm from "../components/Admin/Revenue/SelectRevenueForm";
import SelectRevenueListForm from "../components/Admin/Revenue/SelectRevenueForm/SelectRevenueListForm";
// import SelectEmployeeForm from "../components/Admin/Employee/SelectEmployeeForm";
// import SelectKnowledgeForm from "../components/Admin/Knowledge/SelectKnowledgeForm/SelectKnowledgeForm";

import SelectStateForm from "../components/Admin/State/SelectStateForm";
import SelectWeightForm from "../components/Admin/Weight/SelectWeightForm";
import SelectTransportForm from "../components/Admin/Transport/SelectTransportForm";
import SelectResidualForm from "../components/Admin/Residual/SelectResidualForm";
import SelectUtilityForm from "../components/Admin/Utility/SelectUtilityForm";

//import SelectKnowledgetypeForm from "../components/Admin/Knowledgetype/SelectKnowledgetypeForm/SelectKnowledgetypeForm";
//import MapPicker from "react-google-map-picker";
import Pin from "../assets/img/png/pin.png";
import { returnMailImage } from "../utils/functions";

import { apiKeyGoogle } from "../api/config";

import {
  AddUserApi,
  getPotentialsApi,
  MailPotentialsApi,
} from "../api/user.api";
import {
  AddEntrepreneurshipApi,
  // UpdateEntrepreneurshipNeedsApi,
  // UpdateEntrepreneurshipApi,
  // AddEntrepreneurshipInitialApi,
  UpdateEntrepreneurshipExtrasApi,
} from "../api/entrepreneurship.api";
import { BulkAddHaveApi } from "../api/have.api";
import { BulkAddNeedApi } from "../api/need.api";
import { BulkAddHaveresidualApi } from "../api/haveresidual.api";
import { BulkAddNeedresidualApi } from "../api/needresidual.api";
// import SelectQuestion1Form from "../components/Admin/Question/SelectQuestion1Form";
import SelectProvinceForm from "../components/Admin/Province/SelectProvinceForm";
import SelectAgreementForm from "../components/Admin/Agreement/SelectAgreementForm";

import SelectSolutionForm from "../components/Admin/Solution/SelectSolutionForm";

import { useNavigate } from "react-router-dom";

import { AddOtherApi } from "../api/other.api";

import GoogleMapReact from "google-map-react";
// import SelectCantonForm from "../components/Admin/Canton/SelectCantonForm";

const { Header, Footer, Sider, Content } = Layout;

const { Step } = Steps;

const DefaultLocation = { lat: -0.5012984084076219, lng: -76.97750904233827 };
const DefaultZoom = 13;

export default function Proyectos() {
  const [current, setCurrent] = useState(0);
  const [percent, setPercent] = useState(0);
  const [city, setCity] = useState();
  const [province, setProvince] = useState();
  const [genre, setGenre] = useState();
  const [type, setType] = useState();
  const [sector, setSector] = useState();
  const [formYotengo] = Form.useForm();
  const [formYonecesito] = Form.useForm();
  const [formEmprendimiento] = Form.useForm();
  const [userData, setUserData] = useState({});
  const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const [loadings, setLoadings] = useState(false);
  const [solution, setSolution] = useState();
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [searchlocation, setSearchLocation] = useState(false);
  const [parroquia, setParroquia] = useState();
  const [knowledgetype, setKnowledgetype] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      currentLocation();
    }, 2000);
  }, []);

  useEffect(() => {
    console.log(province);
    formEmprendimiento.setFieldsValue({
      cityId: null,
      cityother: null,
      parroquiaId: null,
      parroquiaother: null,
    });
  }, [province]);

  useEffect(() => {
    formEmprendimiento.setFieldsValue({
      parroquiaId: null,
      parroquiaother: null,
    });
  }, [city]);

  // useEffect(() => {
  //   formYonecesito.add({
  //     haves: [{ knowledgeId: undefined, hours: undefined, years: undefined }],
  //   });
  // }, [formYonecesito]);

  // useEffect(() => {
  //   formYotengo.add({
  //     haves: [{ knowledgeId: undefined, hours: undefined, years: undefined }],
  //   });
  // }, [formYotengo]);

  function currentLocation() {
    setSearchLocation(true);
    var options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    navigator.geolocation.getCurrentPosition(success, error, options);
    // navigator.geolocation.getCurrentPosition(function (position) {
    //   // console.log("Latitude is :", position.coords.latitude);
    //   // console.log("Longitude is :", position.coords.longitude);
    //   setDefaultLocation({
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   });
    //   setLocation({
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   });
    //   setSearchLocation(false);
    // });
  }

  function success(pos) {
    var crd = pos.coords;
    // setDefaultLocation({
    //   lat: crd.latitude,
    //   lng: crd.longitude,
    // });
    setLocation({
      lat: crd.latitude,
      lng: crd.longitude,
    });
    setSearchLocation(false);
    // console.log('Your current position is:');
    // console.log('Latitude : ' + crd.latitude);
    // console.log('Longitude: ' + crd.longitude);
    // console.log('More or less ' + crd.accuracy + ' meters.');
  }

  function error(err) {
    setSearchLocation(false);
    notification["success"]({
      message: `No se puede obtener la ubicación (Error: ${err.message})`,
    });
    // console.warn('ERROR(' + err.code + '): ' + err.message);
  }

  // function handleChangeLocation(lat, lng) {
  //   setLocation({ lat: lat, lng: lng });
  // }

  function handleChangeLocation(coords) {
    console.log(coords.lat);
    console.log(coords.lng);
    setLocation({ lat: coords.lat, lng: coords.lng });
  }

  const onFinish = (values) => {
    setLoadings(true);
    const userAdd = {
      name: values.name,
      lastname: values.lastname,
      ci: values.ci,
      email: values.mail,
      //university: values.university,
      //career: values.career,
      //year: values.year,
      roleId: 3,
      //cityId: values.cityId,
      //cantonId: values.cantonId,
      genreId: values.genreId,
      //provinceId: values.provinceId,
      //lat: location.lat,
      //lon: location.lng,
      phone: values.phone,
    };

    if (values.solutionother) {
      userAdd.solutionother = values.solutionother;
    }

    setUserData(userAdd);

    AddUserApi(userAdd).then((result) => {
      if (result.status) {
        setUserData(result.user);
        notification["success"]({
          message: result.message,
        });
        next();
        setLoadings(false);
      } else {
        notification["error"]({
          message: result.message,
        });
        setLoadings(false);
      }
    });

    // console.log("Success values:", values);
    // console.log("Success:", userData);
    //next();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revise los errores en la información ingresada",
      duration: 3,
    });
  };

  const onFinishEmprendimiento = (values) => {
    console.log("Success:", values);
    setLoadings(true);

    const entrepreneurshipAdd = {
      name: values.businessname,
      description: values.description,
      //invoice: values.invoice ?? false,
      creation: values.creation,
      partner: values.partner,
      //invoiceanual: values.invoiceanual,
      //employees: values.employees,
      //stageId: values.stageId,
      //processId: values.processId,
      //academyId: values.academy,
      userId: userData.id,
      //communities: values.community,
      //sectors: values.sector,
      //types: values.type,
      direction: values.direction,
      ruc: values.ruc,
      cityId: values.cityId,
      provinceId: values.provinceId,
      parroquiaId: values.parroquiaId,
      lat: location.lat,
      lon: location.lng,
      //direction: values.direction,
      university: values.university,
      solutionId: values.solutionId,
      projectname: values.projectname,
      cityother: values.cityother,
      parroquiaother: values.parroquiaother,
      solutionother: values.solutionother,
    };
    setEntrepreneurshipData(entrepreneurshipAdd);

    AddEntrepreneurshipApi(entrepreneurshipAdd).then((result) => {
      //console.log(result);
      if (result.status) {
        setEntrepreneurshipData(result.entrepreneurship);

        UpdateEntrepreneurshipExtrasApi(
          {
            sectors: values.sector,
            types: values.type,
            //communities: values.community,
          },
          result.entrepreneurship.id
        ).then((resultExtra) => {
          if (resultExtra.status) {
            // setUserData(result.user);
            // notification["success"]({
            //   message: result.message,
            // });
            // next();
            // setLoadings(false);
          } else {
            // notification["error"]({
            //   message: result.message,
            // });
            // setLoadings(false);
          }
        });

        // if (values.communityOther) {
        //   AddOtherApi({
        //     table: "community",
        //     description: values.communityOther,
        //     entrepreneurshipId: result.entrepreneurship.id,
        //   });
        // }

        // if (values.processOther) {
        //   AddOtherApi({
        //     table: "process",
        //     description: values.processOther,
        //     entrepreneurshipId: result.entrepreneurship.id,
        //   });
        // }

        // if (values.solutionOther) {
        //   AddOtherApi({
        //     table: "solution",
        //     description: values.solutionOther,
        //     entrepreneurshipId: result.entrepreneurship.id,
        //   });
        // }

        if (values.sectorOther) {
          AddOtherApi({
            table: "sector",
            description: values.sectorOther,
            entrepreneurshipId: result.entrepreneurship.id,
          });
        }

        if (values.typeOther) {
          AddOtherApi({
            table: "type",
            description: values.typeOther,
            entrepreneurshipId: result.entrepreneurship.id,
          });
        }

        notification["success"]({
          message: result.message,
        });
        next();
        setLoadings(false);
      } else {
        notification["error"]({
          message: result.message,
        });
        setLoadings(false);
      }
    });
    //next();
  };

  const onFinishFailEdemprendimiento = (errorInfo) => {
    console.log("Failed:", errorInfo);
    notification["error"]({
      message: "Revise los errores en la información ingresada",
      duration: 3,
    });
  };

  const onFinishYotengo = (values) => {
    console.log("Success:", values);
    // return;
    setLoadings(true);
    if (values.haves) {
      BulkAddHaveApi(values).then((result) => {
        //console.log(result);
        if (result.status) {
          next();
          setLoadings(false);
        } else {
          notification["error"]({
            message: result.message,
          });
          setLoadings(false);
        }
      });

      // if (values.resourceOther) {
      //   AddOtherApi({
      //     table: "resource",
      //     description: values.resourceOther,
      //     entrepreneurshipId: entrepreneurshipData.id,
      //   });
      // }
    } else {
      next();
    }

    //prev();
  };

  const onFinishFailYotengo = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message.error("Revisa los errores en la información ingresada");
    notification["error"]({
      message: "Revise los errores en la información ingresada",
      duration: 3,
    });
  };

  const onFinishYonecesito = (values) => {
    // console.log(values);
    // return;
    setLoadings(true);
    if (values.needs) {
      BulkAddNeedApi(values).then((result) => {
        //console.log(result);
        if (result.status) {
          next();
          setLoadings(false);
        } else {
          notification["error"]({
            message: result.message,
          });
          setLoadings(false);
        }
      });
    } else {
      next();
    }
    // const editEntrepreneurship = {
    //   resources: values.partner,
    //   suppliers: values.supplier,
    //   partner: values.partner ?? false,
    // };

    // if (values.resourceOther) {
    //   AddOtherApi({
    //     table: "resource",
    //     description: values.resourceOther,
    //     entrepreneurshipId: entrepreneurshipData.id,
    //   });
    // }

    // if (values.supplierOther) {
    //   AddOtherApi({
    //     table: "supplier",
    //     description: values.supplierOther,
    //     entrepreneurshipId: entrepreneurshipData.id,
    //   });
    // }

    // UpdateEntrepreneurshipNeedsApi(values, entrepreneurshipData.id).then(
    //   (result) => {
    //     if (result.status) {
    //       // setUserData(result.user);
    //       // notification["success"]({
    //       //   message: result.message,
    //       // });
    //       next();
    //       setLoadings(false);
    //     } else {
    //       notification["error"]({
    //         message: result.message,
    //       });
    //       setLoadings(false);
    //     }
    //   }
    // );
  };

  const onFinishFailResidual = (errorInfo) => {
    console.log("Failed:", errorInfo);
    notification["error"]({
      message: "Revise los errores en la información ingresada",
      duration: 3,
    });
  };

  const onFinishResidual = (values) => {
    console.log("Success:", values);
    //return;
    //var initials = [];

    setLoadings(true);
    if (values.haveresiduals || values.needresiduals) {
      if (values.needresiduals) {
        BulkAddNeedresidualApi(values).then((result) => {
          //console.log(result);
          if (result.status) {
            if (values.haveresiduals) {
              BulkAddHaveresidualApi(values).then((result) => {
                //console.log(result);
                if (result.status) {
                  next();
                  setLoadings(false);
                } else {
                  notification["error"]({
                    message: result.message,
                  });
                  setLoadings(false);
                }
              });
            } else {
              next();
              setLoadings(false);
            }
          } else {
            notification["error"]({
              message: result.message,
            });
            setLoadings(false);
          }
        });
      } else if (values.haveresiduals) {
        BulkAddHaveresidualApi(values).then((result) => {
          //console.log(result);
          if (result.status) {
            next();
            setLoadings(false);
          } else {
            notification["error"]({
              message: result.message,
            });
            setLoadings(false);
          }
        });
      }
    } else {
      next();
    }
  };

  const onFinishFailYonecesito = (errorInfo) => {
    console.log("Failed:", errorInfo);
    notification["error"]({
      message: "Revise los errores en la información ingresada",
      duration: 3,
    });
  };

  const next = () => {
    setCurrent(current + 1);
    setPercent(0);
    // if (current === 1) {
    //   //   const fields = formYotengo.getFieldsValue()
    //   // const { projects } = fields
    //   // Object.assign(projects[key], { type: value })
    //   // formYotengo.setFieldsValue({
    //   //   knowledgeId: undefined,
    //   //   hours: undefined,
    //   //   years: undefined,
    //   // });

    //   // formYotengo.add({
    //   //   haves: [{ knowledgeId: undefined, hours: undefined, years: undefined }],
    //   // });
    // } else if (current === 2) {
    //   // formYonecesito.setFieldsValue({
    //   //   knowledgeId: undefined,
    //   //   hours: undefined,
    //   // });
    //   // formYonecesito.add({
    //   //   haves: [{ knowledgeId: undefined, hours: undefined, years: undefined }],
    //   // });
    // }
  };

  const prev = () => {
    setCurrent(current - 1);
    setPercent(100);
  };

  // const handleChangeYotengo = () => {
  //   formYotengo.setFieldsValue({
  //     yotengo: [],
  //   });
  // };

  // const handleChangeYonecesito = () => {
  //   formYotengo.setFieldsValue({
  //     yotengo: [],
  //   });
  // };

  const onChangeSteps = (value) => {
    console.log("onChange:", current);
    //setCurrent(value);
  };

  const steps = [
    {
      title: "Contacto",
      content: (
        <Form
          name="personal"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <Avatar
                  icon={<UserOutlined />}
                  style={{ backgroundColor: "#f42c1e", color: "white" }}
                />
                <span>PERSONA DE CONTACTO DEL PROYECTO</span>
              </>
            }
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Cédula"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="ci"
                rules={[
                  { required: true, message: "Ingrese su cédula de identidad" },
                  {
                    type: "string",
                    min: 10,
                    message: "Su cédula de identidad no es correcta",
                  },
                ]}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Cédula de identidad"
                  //value={userData.name}
                  maxLength={10}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={9} xl={9}>
              <Form.Item
                label="Nombres"
                name="name"
                rules={[{ required: true, message: "Ingrese sus nombres" }]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Nombres completos"
                  maxLength={50}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={9} xl={9}>
              <Form.Item
                label="Apellidos"
                name="lastname"
                rules={[{ required: true, message: "Ingrese sus apellidos" }]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Apellidos completos"
                  //value={userData.name2}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name2: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Teléfono"
                name="phone"
                rules={[
                  { required: true, message: "Ingrese su número de teléfono" },
                ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Teléfono/celular"
                  //value={userData.name2}
                  maxLength={10}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name2: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            {/* </Row>
          <Row gutter={24}> */}
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <Form.Item
                label="Correo"
                name="mail"
                rules={[
                  { required: true, message: "Ingrese su correo electrónico" },
                  {
                    type: "email",
                    message: "El correo ingresado no es correcto",
                  },
                ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Correo electrónico"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <SelectGenreForm
                atributoData={genre}
                setAtributoData={setGenre}
              ></SelectGenreForm>
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={7} xl={7}>
              <Form.Item
                label="Centro de estudio"
                name="university"
                rules={[
                  {
                    required: true,
                    message:
                      "Ingrese su centro de estudio - Universidad - Tecnología",
                  },
                ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Centro de estudio - Universidad - Tecnología"
                  maxLength={50}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col> */}
            {/* <Col xs={24} sm={24} md={12} lg={6} xl={7}>
              <Form.Item
                label="Carrera cursada"
                name="career"
                rules={[
                  {
                    required: true,
                    message: "Ingrese su carrera cursada",
                  },
                ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Carrera cursada"
                  maxLength={50}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col> */}
            {/* <Col xs={24} sm={24} md={12} lg={5} xl={4}>
              <Form.Item
                label="Año de graduación"
                name="year"
                // rules={[
                //   {
                //     required: true,
                //     message: "Ingrese su año de graduación",
                //   },
                // ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <InputNumber
                  //prefix={<UserOutlined />}
                  placeholder="Año de graduación"
                  maxLength={4}
                  //value={userData.name}
                  //defaultValue={1999}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col> */}
          </Row>
          {/* <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectProvinceForm
                atributoData={province}
                setAtributoData={setProvince}
              ></SelectProvinceForm>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectCantonForm
                atributoData={canton}
                provinceData={province}
                setAtributoData={setCanton}
              ></SelectCantonForm>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectCityForm
                atributoData={city}
                provinceData={province}
                setAtributoData={setCity}
              ></SelectCityForm>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectGenreForm
                atributoData={genre}
                setAtributoData={setGenre}
              ></SelectGenreForm>
            </Col>
          </Row> */}

          <br></br>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  loading={loadings}
                  className={"btnBig"}
                >
                  Siguiente <ArrowRightOutlined />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ),
      icon: <UserOutlined />,
    },
    {
      title: "Empresa",
      content: (
        <Form
          name="emprendimiento"
          form={formEmprendimiento}
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          initialValues={{ remember: false }}
          onFinish={onFinishEmprendimiento}
          onFinishFailed={onFinishFailEdemprendimiento}
          autoComplete="off"
        >
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <Avatar
                  icon={<ShopOutlined />}
                  style={{ backgroundColor: "#f42c1e", color: "white" }}
                />
                <span>DATOS DEL PROYECTO SOSTENIBLE</span>
              </>
            }
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={6} lg={5} xl={5}>
              <Form.Item
                label="Nombre de la organización"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="businessname"
                rules={[
                  {
                    required: true,
                    message: "Ingrese el nombre de la organización",
                  },
                ]}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Nombre de la organización"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={5} xl={5}>
              <Form.Item
                label="Nombre del proyecto sostenible"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="projectname"
                rules={[
                  {
                    required: true,
                    message: "Ingrese el nombre del proyecto sostenible",
                  },
                ]}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Nombre del proyecto sostenible"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                label="Breve descripción"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Ingrese una breve descripción",
                  },
                ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Breve descripción"
                  maxLength={300}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Form.Item
                label="Fecha de inicio del proyecto en Ecuador"
                name="creation"
                rules={[
                  {
                    required: true,
                    message:
                      "Seleccione la fecha de inicio del proyecto en Ecuador",
                  },
                ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            {/* <Col xs={24} sm={24} md={8} lg={5} xl={5}>
              <SelectProvinceForm
                atributoData={province}
                setAtributoData={setProvince}
              ></SelectProvinceForm>
            </Col> */}
            {/* <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectCantonForm
                atributoData={canton}
                provinceData={province}
                setAtributoData={setCanton}
              ></SelectCantonForm>
            </Col> */}
            {/* <Col xs={24} sm={24} md={8} lg={5} xl={5}>
              <SelectCityForm
                atributoData={city}
                provinceData={province}
                setAtributoData={setCity}
              ></SelectCityForm>
            </Col> */}
            <Col xs={24} sm={24} md={12} lg={10} xl={10}>
              <SelectCityProvinceForm
                // atributoData={city}
                // setAtributoData={setCity}
                //handleChange={handleChangeYotengo}
                // province={"provinceId"}
                // name={"cityId"}
                // nameOther={"cityother"}
                // parroquia={"parroquiaId"}
                // parroquiaOther={"parroquiaother"}

                name={"cityId"}
                provincename={"provinceId"}
                nameOther={"cityother"}
                edit={false}
                values={entrepreneurshipData}
                parroquianame={"parroquiaId"}
                parroquiaOther={"parroquiaother"}
                city={city}
                province={province}
                parroquia={parroquia}
                setCity={setCity}
                setProvince={setProvince}
                setParroquia={setParroquia}
              ></SelectCityProvinceForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                label="Dirección del proyecto"
                name="direction"
                rules={[
                  {
                    required: true,
                    message: "Ingrese la dirección del proyecto",
                  },
                ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Dirección del proyecto"
                  maxLength={300}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Universidad - Institución educativa"
                name="university"
                rules={[
                  {
                    required: true,
                    message: "Ingrese la Universidad - Institución educativa",
                  },
                ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Universidad - Institución educativa"
                  maxLength={300}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <SelectCommunityForm
                atributoData={community}
                setAtributoData={setCommunity}
              ></SelectCommunityForm>
            </Col> */}
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <SelectSectorForm
                atributoData={sector}
                setAtributoData={setSector}
              ></SelectSectorForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <SelectTypeForm
                atributoData={type}
                setAtributoData={setType}
              ></SelectTypeForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <SelectSolutionForm
                atributoData={solution}
                setAtributoData={setSolution}
              ></SelectSolutionForm>
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={7} xl={7}>
              <SelectTypeForm
                atributoData={type}
                setAtributoData={setType}
              ></SelectTypeForm>
            </Col> */}
            {/* <Col xs={24} sm={24} md={12} lg={7} xl={7}>
              <SelectSectorForm
                atributoData={sector}
                setAtributoData={setSector}
              ></SelectSectorForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <SelectStageForm
                atributoData={stage}
                setAtributoData={setStage}
              ></SelectStageForm>
            </Col> */}

            {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="Número de sedes en Ecuador"
                name="partner"
                rules={[
                  {
                    required: true,
                    message: "Ingrese el número de sedes",
                  },
                ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <InputNumber
                  //prefix={<UserOutlined />}
                  placeholder="Número de sedes"
                  maxLength={4}
                  //value={userData.name}
                  //defaultValue={1999}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                label="RUC"
                name="ruc"
                rules={[
                  {
                    required: true,
                    message: "Ingrese el RUC",
                  },
                  {
                    type: "string",
                    min: 13,
                    message: "El RUC de su empresa no es correcta",
                  },
                ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="RUC"
                  maxLength={13}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            {/* </Row>
          <Row gutter={24}> */}
            {/* <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <SelectProcessForm
                atributoData={process}
                setAtributoData={setProcess}
              ></SelectProcessForm>
            </Col> */}
            {/* <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <SelectCommunityForm
                atributoData={community}
                setAtributoData={setCommunity}
              ></SelectCommunityForm>
            </Col> */}

            {/* <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <SelectAcademyForm
                atributoData={academy}
                setAtributoData={setAcademy}
              ></SelectAcademyForm>
            </Col> */}
          </Row>
          {/* <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                name="invoice"
                valuePropName="checked"
                label="¿Estás facturando?"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Checkbox checked={checkNick} onChange={onCheckboxChange}>
                  Seleccione si ya estás entregando facturas
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={5} xl={5}>
              {checkNick && (
                <Form.Item
                  name="invoiceanual"
                  label="¿Cuánto facturas por año?"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: checkNick,
                      message: "Ingrese las facturas por año",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Ingrese las facturas por año"
                    maxLength={6}
                  />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} sm={24} md={12} lg={5} xl={5}>
              {checkNick && (
                <Form.Item
                  name="employees"
                  label="Número de empleados"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: checkNick,
                      message: "Ingrese el número de empleados",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Número de empleados"
                    maxLength={6}
                  />
                </Form.Item>
              )}
            </Col>
          </Row> */}
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <Avatar
                  icon={<PushpinOutlined />}
                  style={{ backgroundColor: "#f42c1e", color: "white" }}
                />
                <span>UBICACIÓN</span>
              </>
            }
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card
                title={
                  <span>
                    Por favor, en el mapa presione en su ubicación de contacto
                    para mover el puntero
                  </span>
                }
                extra={
                  <Button
                    type="dashed"
                    onClick={() => currentLocation()}
                    icon={<AimOutlined />}
                    size="small"
                    className="mini"
                    loading={searchlocation}
                  >
                    buscar mi ubicación
                  </Button>
                }
                bodyStyle={{ padding: "0" }}
              >
                {/* <MapPicker
                  defaultLocation={defaultLocation}
                  zoom={zoom}
                  mapTypeId="roadmap"
                  style={{ height: "300px" }}
                  onChangeLocation={handleChangeLocation}
                  onChangeZoom={handleChangeZoom}
                  // apiKey="AIzaSyDy8AE5RqF-ozGiI8J7r-U1vnb3AwQCY-o"
                  apiKey={apiKeyGoogle}
                /> */}
                <div style={{ height: "450px", width: "100%" }}>
                  <GoogleMapReact
                    defaultZoom={zoom}
                    defaultCenter={defaultLocation}
                    bootstrapURLKeys={{
                      key: apiKeyGoogle,
                      language: "es",
                      region: "ec",
                      libraries: [],
                    }}
                    onClick={handleChangeLocation}
                    center={location}
                  >
                    <Image
                      width={48}
                      src={Pin}
                      lat={location.lat}
                      lng={location.lng}
                      preview={false}
                      style={{ marginTop: "-48px", marginLeft: "-24px" }}
                    />
                  </GoogleMapReact>
                </div>
              </Card>
            </Col>
          </Row>
          <br></br>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  loading={loadings}
                  className={"btnBig"}
                >
                  Siguiente <ArrowRightOutlined />
                </Button>
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button type="deafult">
                  <ArrowLeftOutlined /> Anteior
                </Button>
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      ),
      icon: <UserOutlined />,
    },
    // {
    //   title: "Tengo recursos",
    //   content: (
    //     <Form
    //       form={formYotengo}
    //       name="yotengo"
    //       // labelCol={{ span: 8 }}
    //       // wrapperCol={{ span: 16 }}
    //       initialValues={{
    //         haves: [
    //           {
    //             entrepreneurshipId: entrepreneurshipData.id,
    //             knowledgeTypeId: undefined,
    //             knowledgeId: undefined,
    //             hours: undefined,
    //             years: undefined,
    //           },
    //         ],
    //       }}
    //       onFinish={onFinishYotengo}
    //       onFinishFailed={onFinishFailYotengo}
    //       autoComplete="off"
    //     >
    //       <PageHeader
    //         className="site-page-header"
    //         // onBack={() => null}
    //         style={{ textAlign: "center" }}
    //         title={
    //           <>
    //             <ToolOutlined style={{ fontSize: "40px" }} />
    //             <br></br>
    //             <span style={{ fontSize: "14px" }}>
    //               OPTIMIZACIÓN DE RECURSOS PARA MEJORAR LA CADENA DE VALOR
    //             </span>
    //             <br></br>
    //             <span>(YO TENGO)</span>
    //             {/* <br></br>
    //             <span style={{ fontSize: "12px", fontWeight: "normal" }}>
    //               Conocimientos (Administrativos, operativos, financieros,
    //               comunicación, marketing...)
    //             </span> */}
    //           </>
    //         }
    //         // subTitle="This is a subtitle"
    //       />
    //       <Row gutter={24}>
    //         <Col
    //           xs={{ span: 24, offset: 0 }}
    //           sm={{ span: 24, offset: 0 }}
    //           md={{ span: 24, offset: 0 }}
    //           lg={{ span: 24, offset: 0 }}
    //           xl={{ span: 24, offset: 0 }}
    //         >
    //           <Form.List name="haves">
    //             {(fields, { add, remove }) => (
    //               <>
    //                 {fields.map((field) => (
    //                   // <Space
    //                   //   key={key}
    //                   //   style={{
    //                   //     display: "flex",
    //                   //     marginBottom: 8,
    //                   //     width: "100%",
    //                   //   }}
    //                   //   align="baseline"
    //                   // >
    //                   <Row gutter={24} key={field.key}>
    //                     <Col
    //                       xs={2}
    //                       sm={2}
    //                       md={2}
    //                       lg={2}
    //                       xl={2}
    //                       style={{ textAlign: "rigth", paddingTop: "25px" }}
    //                     >
    //                       <MinusCircleOutlined
    //                         onClick={() => remove(field.name)}
    //                       />
    //                     </Col>
    //                     <Col xs={24} sm={24} md={8} lg={8} xl={8}>
    //                       <SelectKnowledgetypeForm
    //                         // atributoData={city}
    //                         // setAtributoData={setCity}
    //                         //handleChange={handleChangeYotengo}
    //                         nametype={[field.name, "knowledgetypeId"]}
    //                         name={[field.name, "knowledgeId"]}
    //                         nameOther={[field.name, "other"]}
    //                       ></SelectKnowledgetypeForm>
    //                     </Col>
    //                     <Col xs={24} sm={24} md={6} lg={6} xl={6}>
    //                       <Form.Item
    //                         label="Descripción corta"
    //                         name={[field.name, "description"]}
    //                         rules={[
    //                           {
    //                             required: true,
    //                             message: "Ingrese la descripción corta",
    //                           },
    //                         ]}
    //                         wrapperCol={{ span: 24 }}
    //                         labelCol={{ span: 24 }}
    //                       >
    //                         <Input
    //                           //prefix={<UserOutlined />}
    //                           placeholder="Descripción corta"
    //                           maxLength={300}
    //                           //value={userData.name}
    //                           onChange={
    //                             (e) => {}
    //                             //setUserData({ ...userData, name: e.target.value })
    //                           }
    //                         />
    //                       </Form.Item>
    //                     </Col>
    //                     <Col xs={24} sm={24} md={4} lg={4} xl={4}>
    //                       <SelectRevenueListForm
    //                         // atributoData={city}
    //                         // setAtributoData={setCity}
    //                         //handleChange={handleChangeYotengo}
    //                         name={[field.name, "revenueId"]}
    //                         //nameOther={[field.name, "other"]}
    //                         //typeData={}
    //                       ></SelectRevenueListForm>
    //                     </Col>
    //                     <Col xs={24} sm={24} md={4} lg={4} xl={4}>
    //                       <SelectAgreementForm
    //                         // atributoData={city}
    //                         // setAtributoData={setCity}
    //                         //handleChange={handleChangeYotengo}
    //                         name={[field.name, "agreementId"]}
    //                         //nameOther={[field.name, "other"]}
    //                         //typeData={}
    //                       ></SelectAgreementForm>
    //                     </Col>
    //                     {/* <Col xs={24} sm={24} md={6} lg={6} xl={6}>
    //                       <Form.Item
    //                         wrapperCol={{ span: 24 }}
    //                         labelCol={{ span: 24 }}
    //                         {...field}
    //                         name={[field.name, "hours"]}
    //                         label="Horas entregadas"
    //                         rules={[
    //                           {
    //                             required: true,
    //                             message: "Seleccione las horas entregadas",
    //                           },
    //                         ]}
    //                       >
    //                         <Radio.Group>
    //                           <Radio value={2}>2</Radio>
    //                           <Radio value={4}>4</Radio>
    //                           <Radio value={6}>6</Radio>
    //                           <Radio value={8}>8</Radio>
    //                         </Radio.Group>
    //                       </Form.Item>
    //                     </Col> */}
    //                     {/* <Col xs={22} sm={22} md={6} lg={6} xl={6}>
    //                       <Form.Item
    //                         wrapperCol={{ span: 24 }}
    //                         labelCol={{ span: 24 }}
    //                         {...field}
    //                         name={[field.name, "years"]}
    //                         label="Años de experiencia"
    //                         rules={[
    //                           {
    //                             required: true,
    //                             message: "Seleccione los años de experiencia",
    //                           },
    //                         ]}
    //                       >
    //                           <Radio.Group>
    //                           <Radio value={1}>1</Radio>
    //                           <Radio value={2}>2</Radio>
    //                           <Radio value={4}>4</Radio>
    //                           <Radio value={6}>6</Radio>
    //                           <Radio value={8}>8</Radio>
    //                           <Radio value={10}>10 o más</Radio>
    //                         </Radio.Group>
    //                       </Form.Item>
    //                     </Col> */}
    //                   </Row>

    //                   // </Space>
    //                 ))}
    //                 <br></br>
    //                 <Row gutter={24}>
    //                   <Col
    //                     xs={{ span: 24, offset: 0 }}
    //                     sm={{ span: 24, offset: 0 }}
    //                     md={{ span: 24, offset: 0 }}
    //                     lg={{ span: 16, offset: 4 }}
    //                     xl={{ span: 16, offset: 4 }}
    //                   >
    //                     <Form.Item>
    //                       <Button
    //                         type="dashed"
    //                         onClick={() => {
    //                           if (fields.length >= 3) {
    //                             notification["error"]({
    //                               message:
    //                                 "Puede agregar hasta 3 recursos que tenga",
    //                               duration: 3,
    //                             });
    //                             return false;
    //                           }
    //                           add({
    //                             entrepreneurshipId: entrepreneurshipData.id,
    //                           });
    //                         }}
    //                         block
    //                         icon={<PlusOutlined />}
    //                       >
    //                         Agregar recursos que tenga
    //                       </Button>
    //                     </Form.Item>
    //                   </Col>
    //                 </Row>
    //               </>
    //             )}
    //           </Form.List>
    //         </Col>
    //       </Row>
    //       <br></br>
    //       <Row gutter={24}>
    //         <Col xs={24} sm={24} md={12} lg={6} xl={6}>
    //           <Form.Item wrapperCol={{ span: 24 }}>
    //             <Button
    //               type="primary"
    //               htmlType="submit"
    //               style={{ width: "100%" }}
    //               className={"btnBig"}
    //             >
    //               Siguiente <ArrowRightOutlined />
    //             </Button>
    //           </Form.Item>
    //         </Col>
    //         {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
    //           <Form.Item wrapperCol={{ span: 24 }}>
    //             <Button type="deafult">
    //               <ArrowLeftOutlined /> Anteior
    //             </Button>
    //           </Form.Item>
    //         </Col> */}
    //       </Row>
    //     </Form>
    //   ),
    //   icon: <UserOutlined />,
    // },
    {
      title: "Necesito recursos",
      content: (
        <Form
          form={formYonecesito}
          name="yonecesito"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          initialValues={{
            needs: [
              {
                entrepreneurshipId: entrepreneurshipData.id,
                knowledgeId: undefined,
                hours: undefined,
              },
            ],
          }}
          onFinish={onFinishYonecesito}
          onFinishFailed={onFinishFailYonecesito}
          autoComplete="off"
        >
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <Avatar
                  icon={<ApiOutlined />}
                  style={{ backgroundColor: "#f42c1e", color: "white" }}
                />
                <span style={{ fontSize: "14px" }}>
                  OPTIMIZACIÓN DE RECURSOS PARA MEJORAR LA CADENA DE VALOR
                </span>
                <br></br>
                <span>(YO NECESITO)</span>
              </>
            }
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <Form.List name="needs">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      // <Space
                      //   key={key}
                      //   style={{
                      //     display: "flex",
                      //     marginBottom: 8,
                      //     width: "100%",
                      //   }}
                      //   align="baseline"
                      // >
                      <Row gutter={24} key={field.key}>
                        <Col
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          style={{ textAlign: "rigth", paddingTop: "25px" }}
                        >
                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <SelectKnowledgetypeForm
                            // atributoData={city}
                            // setAtributoData={setCity}
                            //handleChange={handleChangeYonecesito}
                            name={[field.name, "knowledgeId"]}
                            nametype={[field.name, "knowledgetypeId"]}
                            nameOther={[field.name, "other"]}
                          ></SelectKnowledgetypeForm>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                          <Form.Item
                            label="Descripción corta"
                            name={[field.name, "description"]}
                            rules={[
                              {
                                required: true,
                                message: "Ingrese la descripción corta",
                              },
                            ]}
                            wrapperCol={{ span: 24 }}
                            labelCol={{ span: 24 }}
                          >
                            <Input
                              //prefix={<UserOutlined />}
                              placeholder="Descripción corta"
                              maxLength={300}
                              //value={userData.name}
                              onChange={
                                (e) => {}
                                //setUserData({ ...userData, name: e.target.value })
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                          <SelectRevenueListForm
                            // atributoData={city}
                            // setAtributoData={setCity}
                            //handleChange={handleChangeYotengo}
                            name={[field.name, "revenueId"]}
                            //nameOther={[field.name, "other"]}
                            //typeData={}
                          ></SelectRevenueListForm>
                        </Col>
                        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                          <SelectAgreementForm
                            // atributoData={city}
                            // setAtributoData={setCity}
                            //handleChange={handleChangeYotengo}
                            name={[field.name, "agreementId"]}
                            //nameOther={[field.name, "other"]}
                            //typeData={}
                          ></SelectAgreementForm>
                        </Col>
                        {/* <Col xs={22} sm={22} md={10} lg={10} xl={10}>
                          <Form.Item
                            wrapperCol={{ span: 24 }}
                            labelCol={{ span: 24 }}
                            {...field}
                            name={[field.name, "hours"]}
                            label="Horas necesarias"
                            rules={[
                              {
                                required: true,
                                message: "Seleccione las horas necesarias",
                              },
                            ]}
                          >
                            
                            <Radio.Group>
                              <Radio value={2}>2</Radio>
                              <Radio value={4}>4</Radio>
                              <Radio value={6}>6</Radio>
                              <Radio value={8}>8</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col> */}
                      </Row>

                      // </Space>
                    ))}
                    <br></br>
                    <Row gutter={24}>
                      <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 16, offset: 4 }}
                        xl={{ span: 16, offset: 4 }}
                      >
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              if (fields.length >= 3) {
                                notification["error"]({
                                  message:
                                    "Puede agregar hasta 3 recursos necesarios",
                                  duration: 3,
                                });
                                return false;
                              }
                              add({
                                entrepreneurshipId: entrepreneurshipData.id,
                              });
                            }}
                            block
                            icon={<PlusOutlined />}
                          >
                            Agregar recurso que necesito
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          {/* <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <ForkOutlined style={{ fontSize: "30px" }} />{" "}
                <span>Recursos para crecer</span>
              </>
            }
            // subTitle="This is a subtitle"
          /> */}
          {/* <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={4} xl={4}>
              <Form.Item
                name="needpartner"
                valuePropName="checked"
                label="Necesita socios?"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Checkbox
                  checked={checkPartner}
                  onChange={onCheckboxPartnetChange}
                >
                  Si busco socios
                </Checkbox>
              </Form.Item>
            </Col> */}
          {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <SelectResourceForm
                atributoData={resource}
                setAtributoData={setResource}
              ></SelectResourceForm>
            </Col> */}
          {/* <Col xs={24} sm={24} md={12} lg={5} xl={5}>
              <SelectSupplierForm
                atributoData={supplier}
                setAtributoData={setSupplier}
              ></SelectSupplierForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <SelectRevenueForm
                atributoData={revenue4}
                setAtributoData={setRevenue4}
                name={"question6"}
                label={
                  "¿Cuál sería el valor de inversión que necesita para hacer crecer su negocio durante este año?"
                }
              ></SelectRevenueForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={7} xl={7}>
              <Form.Item
                label="¿En qué empleará el capital?"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="capital"
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Describa en qué empleará el capital"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col> */}
          {/* </Row> */}
          {/* <b>Recursos de apoyo</b>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label="Organizativo"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="resource1"
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Describa el recurso organizativo que necesita"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label="Administrativo"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="resource2"
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Describa el recurso administrativo que necesita"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label="Financiero"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="resource3"
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Describa el recurso financiero que necesita"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label="Productivo"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="resource4"
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Describa el recurso productivo que necesita"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label="Comercial"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="resource5"
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Describa el recurso comercial que necesita"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          */}
          <br></br>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  className={"btnBig"}
                >
                  Siguiente <ArrowRightOutlined />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ),
      icon: <UserOutlined />,
    },
    {
      title: "Recursos residuales",
      content: (
        <Form
          name="encuesta"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          initialValues={{
            haveresiduals: [
              {
                entrepreneurshipId: entrepreneurshipData.id,
                knowledgeId: undefined,
                hours: undefined,
                years: undefined,
              },
            ],
            needresiduals: [
              {
                entrepreneurshipId: entrepreneurshipData.id,
                knowledgeId: undefined,
                hours: undefined,
                years: undefined,
              },
            ],
          }}
          onFinish={onFinishResidual}
          onFinishFailed={onFinishFailResidual}
          autoComplete="off"
        >
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <Avatar
                  icon={<InteractionOutlined />}
                  style={{ backgroundColor: "#f42c1e", color: "white" }}
                />
                <span>RECURSOS RESIDUALES</span>
                {/* <br></br>
                <span style={{ fontSize: "14px" }}>
                  Necesitamos conocer los datos de facturación de su
                  emprendimiento, inversión, creación de empleo y exportación,
                  para medir su crecimiento y canalizar ayudas e inversiones.
                </span> */}
              </>
            }
            // subTitle="This is a subtitle"
          />
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <span>YO TENGO</span>
              </>
            }
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <Form.List name="haveresiduals">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Row gutter={24} key={field.key}>
                        <Col
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          style={{ textAlign: "rigth", paddingTop: "25px" }}
                        >
                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                          <SelectResidualForm
                            name={[field.name, "residualId"]}
                            nameOther={[field.name, "other"]}
                          ></SelectResidualForm>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                          <SelectStateForm
                            name={[field.name, "stateId"]}
                            nameOther={[field.name, "other"]}
                          ></SelectStateForm>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                          <SelectRevenueListForm
                            name={[field.name, "revenueId"]}
                            nameOther={[field.name, "other"]}
                            //typeData={}
                          ></SelectRevenueListForm>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                          <SelectWeightForm
                            name={[field.name, "weightId"]}
                            nameOther={[field.name, "other"]}
                          ></SelectWeightForm>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                          <SelectTransportForm
                            name={[field.name, "transportId"]}
                            nameOther={[field.name, "other"]}
                          ></SelectTransportForm>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                          <SelectUtilityForm
                            name={[field.name, "utilityId"]}
                            nameOther={[field.name, "other"]}
                          ></SelectUtilityForm>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                          <SelectAgreementForm
                            name={[field.name, "agreementId"]}
                            nameOther={[field.name, "other"]}
                          ></SelectAgreementForm>
                        </Col>
                      </Row>

                      // </Space>
                    ))}
                    <br></br>
                    <Row gutter={24}>
                      <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 16, offset: 4 }}
                        xl={{ span: 16, offset: 4 }}
                      >
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              if (fields.length >= 3) {
                                notification["error"]({
                                  message:
                                    "Puede agregar hasta 3 recursos necesarios",
                                  duration: 3,
                                });
                                return false;
                              }
                              add({
                                entrepreneurshipId: entrepreneurshipData.id,
                              });
                            }}
                            block
                            icon={<PlusOutlined />}
                          >
                            Agregar recurso residual que tengo
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          {/* <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <span>YO NECESITO</span>
              </>
            }
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <Form.List name="needresiduals">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      // <Space
                      //   key={key}
                      //   style={{
                      //     display: "flex",
                      //     marginBottom: 8,
                      //     width: "100%",
                      //   }}
                      //   align="baseline"
                      // >
                      <Row gutter={24} key={field.key}>
                        <Col
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          style={{ textAlign: "rigth", paddingTop: "25px" }}
                        >
                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                          <SelectResidualForm
                            name={[field.name, "residualId"]}
                            nameOther={[field.name, "other"]}
                          ></SelectResidualForm>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                          <SelectStateForm
                            name={[field.name, "stateId"]}
                            nameOther={[field.name, "other"]}
                          ></SelectStateForm>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                          <SelectRevenueListForm
                            name={[field.name, "revenueId"]}
                            nameOther={[field.name, "other"]}
                            //typeData={}
                          ></SelectRevenueListForm>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                          <SelectWeightForm
                            name={[field.name, "weightId"]}
                            nameOther={[field.name, "other"]}
                          ></SelectWeightForm>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                          <SelectTransportForm
                            name={[field.name, "transportId"]}
                            nameOther={[field.name, "other"]}
                          ></SelectTransportForm>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                          <SelectUtilityForm
                            name={[field.name, "utilityId"]}
                            nameOther={[field.name, "other"]}
                          ></SelectUtilityForm>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                          <SelectAgreementForm
                            name={[field.name, "agreementId"]}
                            nameOther={[field.name, "other"]}
                          ></SelectAgreementForm>
                        </Col>
                       
                      </Row>

                      // </Space>
                    ))}
                    <br></br>
                    <Row gutter={24}>
                      <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 16, offset: 4 }}
                        xl={{ span: 16, offset: 4 }}
                      >
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              if (fields.length >= 3) {
                                notification["error"]({
                                  message:
                                    "Puede agregar hasta 3 recursos que tenga",
                                  duration: 3,
                                });
                                return false;
                              }
                              add({
                                entrepreneurshipId: entrepreneurshipData.id,
                              });
                            }}
                            block
                            icon={<PlusOutlined />}
                          >
                            Agregar recurso residual que necesito
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Col>
          </Row> */}
          {/* <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
            >
              <SelectRevenueForm
                atributoData={revenue1}
                setAtributoData={setRevenue1}
                name={"question1"}
                label={"1. ¿Cuánto facturó el año pasado?"}
              ></SelectRevenueForm>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
            >
              <SelectRevenueForm
                atributoData={revenue2}
                setAtributoData={setRevenue2}
                name={"question2"}
                label={"2. ¿Cuánto espera facturar durante este año?"}
              ></SelectRevenueForm>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
            >
              <SelectEmployeeForm
                atributoData={employee1}
                setAtributoData={setEmployee1}
                name={"question3"}
                label={
                  "3. ¿Cuántos empleados tiene actualmente su emprendimiento?"
                }
              ></SelectEmployeeForm>
            </Col>

            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
            >
              <SelectEmployeeForm
                atributoData={employee2}
                setAtributoData={setEmployee2}
                name={"question4"}
                label={
                  "4. ¿Cuántos nuevos empleados podría contratar el próximo año?"
                }
              ></SelectEmployeeForm>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
            >
              <SelectRevenueForm
                atributoData={revenue3}
                setAtributoData={setRevenue3}
                name={"question5"}
                label={
                  "5. ¿Cuál fue el monto de inversión que realizó en máquinas, equipos o infraestructura el año pasado?"
                }
              ></SelectRevenueForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                name="export"
                valuePropName="checked"
                label="¿Está exportando?"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                //className={"biglabel"}
              >
                <Checkbox
                  checked={checkExport}
                  onChange={onCheckboxExportChange}
                >
                  Marque la casilla si ya está exportando sus productos.
                </Checkbox>
              </Form.Item>
            </Col>
            {checkExport && (
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 6, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
              >
                <SelectRevenueForm
                  atributoData={lastYear}
                  setAtributoData={setLastYear}
                  name={"lastyear"}
                  label={"¿Cuánto exportó el año pasado?"}
                ></SelectRevenueForm>
              </Col>
            )}
            {checkExport && (
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 6, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
              >
                <SelectRevenueForm
                  atributoData={thisYear}
                  setAtributoData={setThisYear}
                  name={"thisyear"}
                  label={"¿Cuánto planifica exportar este año?"}
                ></SelectRevenueForm>
              </Col>
            )}
            {checkExport && (
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 6, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
              >
                <Form.Item
                  label="¿A qué países?"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese los países a los que exportó",
                    },
                  ]}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  //className={"biglabel"}
                >
                  <Input
                    //prefix={<UserOutlined />}
                    placeholder="Países"
                    maxLength={300}
                    //value={userData.name}
                    onChange={
                      (e) => {}
                      //setUserData({ ...userData, name: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <br />
          <br />
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <LaptopOutlined style={{ fontSize: "40px" }} />
                <br></br>
                <span>Conocimientos y habilidades</span>
                <br></br>
                <span style={{ fontSize: "14px" }}>
                  Necesitamos conocer sus competencias, habilidades blandas y
                  conocimientos en herramientas digitales, para canalizar
                  procesos formativos y apoyo de mentores.
                </span>
              </>
            }
            // subTitle="This is a subtitle"
          />
          <br></br>
          <Row gutter={24}>
            <Col span={24}>
              <CheckCircleOutlined style={{ fontSize: "20px" }} />{" "}
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                Competencias
              </span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question1"}
                question={1}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 6, offset: 0 }}
                spanFixxl={{ span: 4, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <br></br>
          <Row gutter={24}>
            <Col span={24}>
              <CheckCircleOutlined style={{ fontSize: "20px" }} />{" "}
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                Habilidades blandas
              </span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question2"}
                question={2}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 6, offset: 0 }}
                spanFixxl={{ span: 4, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <br></br>
          <Row gutter={24}>
            <Col span={24}>
              <CheckCircleOutlined style={{ fontSize: "20px" }} />{" "}
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                Herramientas digitales
              </span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question3"}
                question={3}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 6, offset: 0 }}
                spanFixxl={{ span: 4, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <br />
          <br />
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <OrderedListOutlined style={{ fontSize: "40px" }} />
                <br></br>
                <span>Etapa de la empresa</span>
                <br></br>
                <span style={{ fontSize: "14px" }}>
                  Cuéntenos en que etapa se encuentra su emprendimiento, con
                  esta información canalizaremos procesos formativos, acceso ha
                  mercado y apoyo económico
                </span>
              </>
            }
            //subTitle="Seleccione todas las etapas que has pasado"
          ></PageHeader>
          <Row gutter={24}>
            <Col span={24}>
              <CheckCircleOutlined style={{ fontSize: "16px" }} />{" "}
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                ¿Ha pasado estos hitos de las etapas de la empresa?
              </span>
              <br></br>
              <br></br>
              <span
                style={{
                  fontSize: "14px",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                Seleccione todas las etapas que ha pasado
              </span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question4"}
                question={4}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 6, offset: 0 }}
                spanFixxl={{ span: 4, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question5"}
                question={5}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 6, offset: 0 }}
                spanFixxl={{ span: 4, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question6"}
                question={6}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 6, offset: 0 }}
                spanFixxl={{ span: 4, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <br />
          <br />
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <ExperimentOutlined style={{ fontSize: "40px" }} />
                <br></br>
                <span>Innovación</span>
                <br></br>
                <span style={{ fontSize: "14px" }}>
                  Los emprendimientos con alto contenido de innovación son los
                  que escalan en el mercado
                </span>
              </>
            }
            //subTitle="Seleccione todas las etapas que has pasado"
          ></PageHeader>
          <Row gutter={24}>
            <Col span={24}>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                Vamos a conocer el grado de innovación de sus productos y
                servicios
              </span>
              <br></br>
              <br></br>
              <span
                style={{
                  fontSize: "14px",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                Marque la casilla si su respuesta es “Sí”
              </span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question7"}
                question={7}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 12, offset: 0 }}
                spanFixxl={{ span: 12, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question8"}
                question={8}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 12, offset: 0 }}
                spanFixxl={{ span: 12, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question9"}
                question={9}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 12, offset: 0 }}
                spanFixxl={{ span: 12, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question10"}
                question={10}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 12, offset: 0 }}
                spanFixxl={{ span: 12, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
           */}
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  className={"btnBig"}
                >
                  Finalizar registro <ArrowRightOutlined />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ),
      icon: <UserOutlined />,
    },
    {
      title: "Completo",
      content: (
        <Result
          status="success"
          title={`¡${userData.name}, su registro se ha completado exitosamente!`}
          subTitle="Recibirá un correo con los datos de acceso (revise la bandeja de correo no deseado y/o spam). Ahora se puede conectar con otros emprendedores."
          extra={[
            <Button
              type="primary"
              key="console"
              style={{ height: "60px", width: "180px" }}
              onClick={() => navigate(`/admin/login`)}
              className={"btnBig"}
            >
              Ir a mi cuenta
            </Button>,
            // <Button key="buy">Buy Again</Button>,
          ]}
        />
      ),
      icon: <UserOutlined />,
    },
  ];

  return (
    <Layout className="home">
      <Header className="home__header">
        <Row gutter={24}>
          <Col xs={6} sm={6} md={4} lg={4} xl={4}>
            <img alt="conecta2" src={Logo} style={{ width: "170px" }}></img>
          </Col>
          <Col xs={8} sm={10} md={12} lg={16} xl={16}></Col>
          <Col
            xs={10}
            sm={8}
            md={8}
            lg={4}
            xl={4}
            style={{ textAlign: "rigth" }}
          >
            <Button
              type="link"
              style={{ marginTop: "19px", color: "black", fontSize: "19px" }}
              onClick={() => navigate(`/admin/login`)}
            >
              Ingresar <LoginOutlined />
            </Button>
          </Col>
        </Row>
      </Header>
      <Content className="home__content">
        <div className="home__content__steps">
          <div
            style={{
              textAlign: "center",
              backgroundColor: "transparent",
              fontSize: "25px",
              fontWeight: "bold",
            }}
          >
            REGISTRO PARA PROYECTOS SOSTENIBLES
          </div>
          <br />
          <Steps current={current} onChange={onChangeSteps}>
            {steps.map((item) => (
              <Step key={item.title} disabled={true} title={item.title} />
            ))}
          </Steps>
          <br></br>
          <div className="steps-content">{steps[current].content}</div>
          <br></br>
          {/* <div className="steps-action">
            {current < steps.length - 2 && (
              <Button
                type="primary"
                style={{ margin: "0 8px" }}
                onClick={() => next()}
              >
                Siguiente <ArrowRightOutlined />
              </Button>
            )}
            {current === steps.length - 2 && (
              <Button
                type="primary"
                style={{ margin: "0 8px" }}
                // onClick={() => message.success("Registro completado!")}
                onClick={() => next()}
              >
                Finalizar <CheckOutlined />
              </Button>
            )}
            {current > 0 && current < steps.length - 1 && (
              <Button icon={<ArrowLeftOutlined />} onClick={() => prev()}>
                Anterior
              </Button>
            )}
          </div> */}
        </div>
      </Content>
    </Layout>
  );
}
