import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";

import {
  Card,
  Row,
  Breadcrumb,
  List,
  Col,
  Avatar,
  Progress,
  Tag,
  Button,
  notification,
  Empty,
} from "antd";
import {
  SolutionOutlined,
  UserAddOutlined,
  ClockCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";

import "./Conexiones.scss";

import useAuth from "../../../hooks/useAuth";
import { getPotentialsDetailsApi } from "../../../api/user.api";
import {
  AddFriendApi,
  UpdateFriendApi,
  getFriendsRequestsApi,
} from "../../../api/friend.api";
import noAvatar from "../../../assets/img/png/no-avatar.png";
import ApiOutlined from "@ant-design/icons/ApiOutlined";
import UsergroupAddOutlined from "@ant-design/icons/UsergroupAddOutlined";

const Connections = ({ socket }) => {
  const token = getAccessTokenApi();
  const [loadings, setLoadings] = useState(true);
  const [loadingsNeed, setLoadingsNeed] = useState(true);
  const [potentials, setPotentials] = useState();
  const [potentialsNeed, setPotentialsNeed] = useState();
  const { user, isLoading } = useAuth();
  const [initLoading, setInitLoading] = useState(true);
  const [initLoadingNeed, setInitLoadingNeed] = useState(true);
  const [offset, setOffset] = useState(0);
  const [offsetNeed, setOffsetNeed] = useState(0);
  const [more, setMore] = useState(true);
  const [moreNeed, setMoreNeed] = useState(true);
  const [loadingFriend, setLoadingFriend] = useState(false);
  const [requests, setRequests] = useState();
  const [lastsocket, setLastsocket] = useState();
  const [sendrequest, setSendrequest] = useState();

  // useEffect(() => {
  //   console.log(socket);
  //   if (socket) {
  //     socket.emit("user", user.id);
  //     socket.emit("message", { origin: user.id, destiny: 2, content: "Hola" });
  //     console.log("emit");
  //   }
  // }, [socket, user.id]);
  useEffect(() => {
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
        getPotentialsDetailsApi(token, user.id, offset, 40, 10).then(
          (response) => {
            setPotentials(response.users.filter((item) => item.porcentaje > 0));
          }
        );

        getPotentialsDetailsApi(token, user.id, offsetNeed, 10, 40).then(
          (response) => {
            setPotentialsNeed(
              response.users.filter((item) => item.porcentaje > 0)
            );
          }
        );

        getFriendsRequestsApi(token, user.id).then((response) => {
          setRequests(response.users);
        });
      }
    });
    socket.on("messageResponse", (data) => {
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
  }, [socket]);

  useEffect(() => {
    //if (loadings) {
    getPotentialsDetailsApi(token, user.id, offset, 40, 10).then((response) => {
      //console.log(response);
      setPotentials(response.users.filter((item) => item.porcentaje > 0));
      setLoadings(false);
      setInitLoading(false);
    });

    getPotentialsDetailsApi(token, user.id, offsetNeed, 10, 40).then(
      (response) => {
        //console.log(response);
        setPotentialsNeed(response.users.filter((item) => item.porcentaje > 0));
        setLoadingsNeed(false);
        setInitLoadingNeed(false);
      }
    );

    getFriendsRequestsApi(token, user.id).then((response) => {
      //console.log(response);
      setRequests(response.users);
    });
    // }
    // });
  }, [token, sendrequest]);

  const onLoadMore = () => {
    setLoadings(true);
    getPotentialsDetailsApi(token, user.id, offset + 5, 40, 10).then(
      (response) => {
        if (response.users.length < 5) {
          setMore(false);
        }
        const newData = potentials.concat(
          response.users.filter((item) => item.porcentaje > 0)
        );
        setPotentials(newData);
        setLoadings(false);
        setOffset(offset + 5);
      }
    );
    // setList(
    //   data.concat([...new Array(count)].map(() => ({ loading: true, name: {}, picture: {} }))),
    // );
    // fetch(fakeDataUrl)
    //   .then(res => res.json())
    //   .then(res => {
    //     const newData = data.concat(res.results);
    //     setData(newData);
    //     setList(newData);
    //     setLoading(false);
    //     // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
    //     // In real scene, you can using public method of react-virtualized:
    //     // https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
    //     window.dispatchEvent(new Event('resize'));
    //   });
  };

  const onLoadMoreNeed = () => {
    setLoadingsNeed(true);
    getPotentialsDetailsApi(token, user.id, offsetNeed + 5, 10, 40).then(
      (response) => {
        if (response.users.length < 5) {
          setMoreNeed(false);
        }
        const newData = potentialsNeed.concat(
          response.users.filter((item) => item.porcentaje > 0)
        );
        setPotentialsNeed(newData);
        setLoadingsNeed(false);
        setOffsetNeed(offsetNeed + 5);
      }
    );
  };

  const onConection = (item) => {
    setLoadingFriend(true);
    AddFriendApi(token, {
      message: "",
      status: 0,
      friendId: item.id,
      userId: user.id,
      name: item.name,
      emprendedor: `${user.name} ${user.lastname}`,
      email: item.email,
    }).then((response) => {
      console.log(response);
      setLoadingFriend(false);
      if (response.status) {
        notification["success"]({
          message: response.message,
        });
        socket.emit("friendrequest", {
          origin: user.id,
          destiny: item.id,
          content: `${user.name} te ha enviado una solicitud de conexión, revisa en Conexiones`,
        });
        setSendrequest(response);
      } else {
        notification["error"]({
          message: response.message,
        });
      }
    });
  };

  const onConectionAccept = (friendid, userid) => {
    //console.log(item);
    setLoadingFriend(true);
    UpdateFriendApi(token, { status: 1 }, friendid).then((response) => {
      console.log(response);
      setLoadingFriend(false);
      if (response.status) {
        notification["success"]({
          message: response.message,
        });
        socket.emit("friendrequest", {
          origin: user.id,
          destiny: userid,
          content: `${user.name} ha aceptado tu solicitud de conexión`,
        });
        setSendrequest(response);
      } else {
        notification["error"]({
          message: response.message,
        });
      }
    });
  };

  const loadMore =
    !initLoading && !loadings && more ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore} icon={<ApiOutlined />}>
          Cargar más conexiones
        </Button>
      </div>
    ) : null;

  const loadMoreNeed =
    !initLoadingNeed && !loadingsNeed && moreNeed ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMoreNeed} icon={<ApiOutlined />}>
          Cargar más conexiones
        </Button>
      </div>
    ) : null;

  return (
    <div>
      <div className="Conexiones">
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Proyecto</Breadcrumb.Item>
          <Breadcrumb.Item>Conexiones</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="site-statistic-demo-card">
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              //itemLayout="horizontal"
              header={<div>Solicitudes de conexión recibidas</div>}
              bordered
              itemLayout="horizontal"
              size="small"
              dataSource={requests}
              locale={{
                emptyText: (
                  <Empty
                    //image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    description={"No tiene solicitudes"}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  ></Empty>
                ),
              }}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Button
                      loading={loadingFriend}
                      type="primary"
                      onClick={() =>
                        onConectionAccept(item.friends[0].friend.id, item.id)
                      }
                    >
                      <ClockCircleOutlined /> Aceptar solicitud
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<Avatar src={noAvatar} />}
                    title={
                      <>
                        {item.name.toUpperCase() +
                          " " +
                          item.lastname.toUpperCase()}
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card title={<span>A quién puedo ayudar:</span>}>
              <List
                //itemLayout="horizontal"
                itemLayout="vertical"
                size="large"
                dataSource={potentials}
                loading={loadings}
                loadMore={loadMore}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      // <Button type="secondary" onClick={() => {}}>
                      //   <SolutionOutlined /> Ver perfil
                      // </Button>,
                      item.statussend !== null && item.statussend >= 0 ? (
                        <Button
                          loading={loadingFriend}
                          type="primary"
                          disabled={
                            item.statussend !== null && item.statussend >= 0
                          }
                          onClick={() => onConection(item)}
                        >
                          {item.statussend === 0 || item.statussend === 2 ? (
                            <>
                              <ClockCircleOutlined /> Solicitud enviada
                            </>
                          ) : item.statussend === 1 ? (
                            <>
                              <CheckOutlined /> Conectados
                            </>
                          ) : (
                            <>
                              <UserAddOutlined /> Conectar
                            </>
                          )}
                        </Button>
                      ) : item.statusreceive !== null &&
                        item.statusreceive >= 0 ? (
                        <Button
                          loading={loadingFriend}
                          type="primary"
                          disabled={
                            item.statusreceive !== null &&
                            item.statusreceive > 0
                          }
                          onClick={() =>
                            onConectionAccept(item.friendId, item.id)
                          }
                        >
                          {item.statusreceive === 0 ||
                          item.statusreceive === 2 ? (
                            <>
                              <ClockCircleOutlined /> Aceptar solicitud
                            </>
                          ) : item.statusreceive === 1 ? (
                            <>
                              <CheckOutlined /> Conectados
                            </>
                          ) : (
                            <>
                              <UserAddOutlined /> Conectar
                            </>
                          )}
                        </Button>
                      ) : (
                        <Button
                          loading={loadingFriend}
                          type="primary"
                          onClick={() => onConection(item)}
                        >
                          <>
                            <UserAddOutlined /> Conectar
                          </>
                        </Button>
                      ),
                    ]}
                    extra={
                      <Progress
                        type="circle"
                        width={80}
                        percent={item.porcentaje >= 100 ? 100 : item.porcentaje}
                        strokeColor={
                          item.porcentaje > 50
                            ? "#1ABC9C"
                            : item.porcentaje > 30
                            ? "#F5B041"
                            : "#F9E79F"
                        }
                        size="small"
                      />
                    }
                  >
                    <List.Item.Meta
                      avatar={<Avatar src={noAvatar} />}
                      title={
                        <>
                          {item.name.toUpperCase() +
                            " " +
                            item.lastname.toUpperCase()}
                          &nbsp;&nbsp;
                          {item.provincename != null && (
                            <Tag color="#1D8348">{item.provincename}</Tag>
                          )}
                          {item.cityname != null && (
                            <Tag color="#58D68D">{item.cityname}</Tag>
                          )}
                          {item.entrepreneurships.length > 0 &&
                            item.entrepreneurships[0].communities.length > 0 &&
                            item.entrepreneurships[0].communities.map(
                              (community) => (
                                <Tag key={community.name} color="#E59866">
                                  {community.name}
                                </Tag>
                              )
                            )}
                          {item.entrepreneurships.length > 0 &&
                            item.entrepreneurships[0].types.length > 0 &&
                            item.entrepreneurships[0].types.map((type) => (
                              <Tag key={type.name} color="#8E44AD">
                                {type.name}
                              </Tag>
                            ))}
                          {/* {item.entrepreneurships.length > 0 &&
                            item.havepartners && (
                              <Tag color="#2980B9">
                                <UsergroupAddOutlined /> Busco socios
                              </Tag>
                            )} */}
                        </>
                      }
                      description={
                        <>
                          {item.stage != null && (
                            <>
                              Etapa:&nbsp;&nbsp;
                              <Tag color="cyan">{item.stage}</Tag>
                              <br />
                            </>
                          )}
                          {/* {item.process != null && (
                            <>
                              Proceso:&nbsp;&nbsp;
                              <Tag color="geekblue">{item.process}</Tag>
                              <br />
                            </>
                          )} */}
                          {item.entrepreneurships.length > 0 &&
                            item.entrepreneurships[0].haves.length > 0 && (
                              <>
                                Yo tengo:&nbsp;&nbsp;
                                {item.entrepreneurships[0].haves.map((have) => (
                                  <Tag key={have.id} color="lime">
                                    {have.knowledge.name +
                                      ", " +
                                      // have.years +
                                      " años de experiencia"}
                                  </Tag>
                                ))}
                                <br />
                              </>
                            )}
                          {item.entrepreneurships.length > 0 &&
                            item.entrepreneurships[0].needs.length > 0 && (
                              <>
                                Yo necesito:&nbsp;&nbsp;
                                {item.entrepreneurships[0].needs.map((need) => (
                                  <Tag key={need.id} color="orange">
                                    {need.knowledge.name +
                                      ", " +
                                      // need.hours +
                                      " horas requeridas"}
                                  </Tag>
                                ))}
                              </>
                            )}

                          {/* Yo tengo:&nbsp;&nbsp;
                          <Tag color="lime">Coaching, 1 año de experiencia</Tag>
                          <br />
                          Yo necesito:&nbsp;&nbsp;
                          <Tag color="orange">Coaching, 4 horas</Tag> */}
                        </>
                      }
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card title={<span>Quién me puede ayudar:</span>}>
              <List
                //itemLayout="horizontal"
                itemLayout="vertical"
                size="large"
                dataSource={potentialsNeed}
                loading={loadingsNeed}
                loadMore={loadMoreNeed}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      // <Button type="secondary" onClick={() => {}}>
                      //   <SolutionOutlined /> Ver perfil
                      // </Button>,
                      item.statussend !== null && item.statussend >= 0 ? (
                        <Button
                          loading={loadingFriend}
                          type="primary"
                          disabled={
                            item.statussend !== null && item.statussend >= 0
                          }
                          onClick={() => onConection(item)}
                        >
                          {item.statussend === 0 || item.statussend === 2 ? (
                            <>
                              <ClockCircleOutlined /> Solicitud enviada
                            </>
                          ) : item.statussend === 1 ? (
                            <>
                              <CheckOutlined /> Conectados
                            </>
                          ) : (
                            <>
                              <UserAddOutlined /> Conectar
                            </>
                          )}
                        </Button>
                      ) : item.statusreceive !== null &&
                        item.statusreceive >= 0 ? (
                        <Button
                          loading={loadingFriend}
                          type="primary"
                          disabled={
                            item.statusreceive !== null &&
                            item.statusreceive > 0
                          }
                          onClick={() =>
                            onConectionAccept(item.friendId, item.id)
                          }
                        >
                          {item.statusreceive === 0 ||
                          item.statusreceive === 2 ? (
                            <>
                              <ClockCircleOutlined /> Aceptar solicitud
                            </>
                          ) : item.statusreceive === 1 ? (
                            <>
                              <CheckOutlined /> Conectados
                            </>
                          ) : (
                            <>
                              <UserAddOutlined /> Conectar
                            </>
                          )}
                        </Button>
                      ) : (
                        <Button
                          loading={loadingFriend}
                          type="primary"
                          onClick={() => onConection(item)}
                        >
                          <>
                            <UserAddOutlined /> Conectar
                          </>
                        </Button>
                      ),
                    ]}
                    extra={
                      <Progress
                        type="circle"
                        width={80}
                        percent={item.porcentaje >= 100 ? 100 : item.porcentaje}
                        strokeColor={
                          item.porcentaje > 50
                            ? "#1ABC9C"
                            : item.porcentaje > 30
                            ? "#F5B041"
                            : "#F9E79F"
                        }
                        size="small"
                      />
                    }
                  >
                    <List.Item.Meta
                      avatar={<Avatar src={noAvatar} />}
                      title={
                        <>
                          {item.name.toUpperCase() +
                            " " +
                            item.lastname.toUpperCase()}
                          &nbsp;&nbsp;
                          {item.provincename != null && (
                            <Tag color="#1D8348">{item.provincename}</Tag>
                          )}
                          {/* {item.cantonname != null && (
                            <Tag color="#28B463">{item.cantonname}</Tag>
                          )} */}
                          {item.cityname != null && (
                            <Tag color="#58D68D">{item.cityname}</Tag>
                          )}
                          {item.entrepreneurships.length > 0 &&
                            item.entrepreneurships[0].communities.length > 0 &&
                            item.entrepreneurships[0].communities.map(
                              (community) => (
                                <Tag key={community.name} color="#E59866">
                                  {community.name}
                                </Tag>
                              )
                            )}
                          {item.entrepreneurships.length > 0 &&
                            item.entrepreneurships[0].types.length > 0 &&
                            item.entrepreneurships[0].types.map((type) => (
                              <Tag key={type.name} color="#8E44AD">
                                {type.name}
                              </Tag>
                            ))}
                          {/* {item.entrepreneurships.length > 0 &&
                            item.havepartners && (
                              <Tag color="#2980B9">
                                <UsergroupAddOutlined /> Busco socios
                              </Tag>
                            )} */}
                          {/* {item.entrepreneurships.length > 0 &&
                            item.entrepreneurships[0].types.length > 0 &&
                            item.entrepreneurships[0].types.map((type) => (
                              <Tag key={type.name} color="#2980B9">
                                {type.name}
                              </Tag>
                            ))} */}
                        </>
                      }
                      description={
                        <>
                          {/* {item.stage != null && (
                            <>
                              Etapa:&nbsp;&nbsp;
                              <Tag color="cyan">{item.stage}</Tag>
                              <br />
                            </>
                          )} */}
                          {/* {item.process != null && (
                            <>
                              Proceso:&nbsp;&nbsp;
                              <Tag color="geekblue">{item.process}</Tag>
                              <br />
                            </>
                          )} */}
                          {item.entrepreneurships.length > 0 &&
                            item.entrepreneurships[0].haves.length > 0 && (
                              <>
                                Yo tengo:&nbsp;&nbsp;
                                {item.entrepreneurships[0].haves.map((have) => (
                                  <Tag key={have.id} color="lime">
                                    {have.knowledge.name +
                                      ", " +
                                      //have.years +
                                      " años de experiencia"}
                                  </Tag>
                                ))}
                                <br />
                              </>
                            )}
                          {item.entrepreneurships.length > 0 &&
                            item.entrepreneurships[0].needs.length > 0 && (
                              <>
                                Yo necesito:&nbsp;&nbsp;
                                {item.entrepreneurships[0].needs.map((need) => (
                                  <Tag key={need.id} color="orange">
                                    {need.knowledge.name +
                                      ", " +
                                      //need.hours +
                                      " horas requeridas"}
                                  </Tag>
                                ))}
                              </>
                            )}
                          {/* Yo tengo:&nbsp;&nbsp;
                          <Tag color="lime">Coaching, 1 año de experiencia</Tag>
                          <br />
                          Yo necesito:&nbsp;&nbsp;
                          <Tag color="orange">Coaching, 4 horas</Tag> */}
                        </>
                      }
                    />
                    {/* <Progress
                      type="circle"
                      width={80}
                      percent={item.porcentaje >= 100 ? 100 : item.porcentaje}
                      strokeColor={
                        item.porcentaje > 50
                          ? "#1ABC9C"
                          : item.porcentaje > 30
                          ? "#F5B041"
                          : "#F9E79F"
                      }
                      size="small"
                    /> */}
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Connections;
