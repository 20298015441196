import React, { useState } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { v4 as uuidv4 } from "uuid";
import { Descriptions } from "antd";
//import { CloseCircleOutlined } from "@ant-design/icons";

export default function CustomMarker(props) {
  const {
    position,
    clusterer,
    name,
    lastname,
    email,
    emprendimiento,
    perfil,
    ciudad,
    id,
  } = props;
  //const [showinfo, setShowinfo] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  //   const {
  //     InfoBox,
  //   } = require("react-google-maps/lib/components/addons/InfoBox");

  return (
    <Marker
      position={position}
      clusterer={clusterer}
      icon={{
        url: require("../../assets/img/png/pin.png"),
        anchor: new window.google.maps.Point(20, 40),
        scaledSize: new window.google.maps.Size(40, 40),
      }}
      onClick={() => handleActiveMarker(id)}
    >
      {activeMarker === id ? (
        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
          <Descriptions
            //title="Responsive Descriptions"
            bordered
            column={{
              xxl: 3,
              xl: 2,
              lg: 2,
              md: 2,
              sm: 1,
              xs: 1,
            }}
            size="small"
          >
            <Descriptions.Item label="Nombre">{name}</Descriptions.Item>
            <Descriptions.Item label="Apellido">{lastname}</Descriptions.Item>
            <Descriptions.Item label="Emprendimiento">
              {emprendimiento}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{email}</Descriptions.Item>
            <Descriptions.Item label="Perfil">{perfil}</Descriptions.Item>
            <Descriptions.Item label="Ciudad">{ciudad}</Descriptions.Item>
          </Descriptions>
        </InfoWindow>
      ) : null}
    </Marker>
  );
}
