import React, { useState, useEffect } from "react";
import {
  Result,
  Button,
  PageHeader,
  Steps,
  Input,
  Layout,
  Col,
  Form,
  Row,
  InputNumber,
  DatePicker,
  Checkbox,
  notification,
  Radio,
} from "antd";
import {
  ArrowRightOutlined,
  UserOutlined,
  BulbOutlined,
  ToolOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  ApiOutlined,
  ForkOutlined,
  RiseOutlined,
  LaptopOutlined,
  OrderedListOutlined,
  ExperimentOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import "./Actualizacion.scss";
import SelectTypeForm from "../../../../components/Admin/Type/SelectTypeForm";
import SelectSectorForm from "../../../../components/Admin/Sector/SelectSectorForm";
import SelectStageForm from "../../../../components/Admin/Stage/SelectStageForm/SelectStageForm";
import SelectProcessForm from "../../../../components/Admin/Process/SelectProcessForm";
import SelectCommunityForm from "../../../../components/Admin/Community/SelectCommunityForm";
import SelectKnowledgeForm from "../../../../components/Admin/Knowledge/SelectKnowledgeForm";
import SelectSupplierForm from "../../../../components/Admin/Supplier/SelectSupplierForm";
import SelectRevenueForm from "../../../../components/Admin/Revenue/SelectRevenueForm";
import SelectEmployeeForm from "../../../../components/Admin/Employee/SelectEmployeeForm";
//import MapPicker from "react-google-map-picker";
import {
  AddEntrepreneurshipApi,
  UpdateEntrepreneurshipNeedsApi,
  UpdateEntrepreneurshipApi,
  AddEntrepreneurshipInitialApi,
  UpdateEntrepreneurshipExtrasApi,
} from "../../../../api/entrepreneurship.api";
import { getPotentialsApi, MailPotentialsApi } from "../../../../api/user.api";
import { returnMailImage } from "../../../../utils/functions";
import { BulkAddHaveApi } from "../../../../api/have.api";
import { BulkAddNeedApi } from "../../../../api/need.api";
import SelectQuestion1Form from "../../../../components/Admin/Question/SelectQuestion1Form";
import { useNavigate } from "react-router-dom";

import { AddOtherApi } from "../../../../api/other.api";

const { Content } = Layout;

const { Step } = Steps;

export default function Actualizacion(props) {
  const {
    userData,
    tabStart,
    setIsVisibleModal,
    setUpdateInfo,
    hasEntrepreneurshipData,
  } = props;
  const [current, setCurrent] = useState(tabStart);
  const [percent, setPercent] = useState(0);
  const [type, setType] = useState();
  const [sector, setSector] = useState();
  const [stage, setStage] = useState();
  const [process, setProcess] = useState();
  const [community, setCommunity] = useState();
  const [checkPartner, setCheckPartner] = useState(false);
  const [formYotengo] = Form.useForm();
  const [formYonecesito] = Form.useForm();
  const [supplier, setSupplier] = useState();
  const [revenue1, setRevenue1] = useState();
  const [revenue2, setRevenue2] = useState();
  const [revenue3, setRevenue3] = useState();
  const [revenue4, setRevenue4] = useState();
  const [thisYear, setThisYear] = useState();
  const [lastYear, setLastYear] = useState();
  const [employee1, setEmployee1] = useState();
  const [employee2, setEmployee2] = useState();
  const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const [loadings, setLoadings] = useState(false);
  const [checkExport, setCheckExport] = useState(false);
  const navigate = useNavigate();

  const onCheckboxPartnetChange = (e) => {
    setCheckPartner(e.target.checked);
  };

  const onCheckboxExportChange = (e) => {
    setCheckExport(e.target.checked);
  };

  const onFinishEmprendimiento = (values) => {
    setLoadings(true);

    const entrepreneurshipAdd = {
      name: values.businessname,
      description: values.description,
      //invoice: values.invoice ?? false,
      creation: values.creation,
      partner: values.partner,
      //invoiceanual: values.invoiceanual,
      //employees: values.employees,
      stageId: values.stageId,
      processId: values.processId,
      //academyId: values.academy,
      userId: userData.id,
      communities: values.community,
      sectors: values.sector,
      types: values.type,
      medals: values.medals,
    };
    setEntrepreneurshipData(entrepreneurshipAdd);

    AddEntrepreneurshipApi(entrepreneurshipAdd).then((result) => {
      //console.log(result);
      if (result.status) {
        setEntrepreneurshipData(result.entrepreneurship);

        UpdateEntrepreneurshipExtrasApi(
          {
            sectors: values.sector,
            types: values.type,
            communities: values.community,
          },
          result.entrepreneurship.id
        ).then((resultExtra) => {
          if (resultExtra.status) {
            // setUserData(result.user);
            // notification["success"]({
            //   message: result.message,
            // });
            // next();
            // setLoadings(false);
          } else {
            // notification["error"]({
            //   message: result.message,
            // });
            // setLoadings(false);
          }
        });

        if (values.communityOther) {
          AddOtherApi({
            table: "community",
            description: values.communityOther,
            entrepreneurshipId: result.entrepreneurship.id,
          });
        }

        if (values.processOther) {
          AddOtherApi({
            table: "process",
            description: values.processOther,
            entrepreneurshipId: result.entrepreneurship.id,
          });
        }

        if (values.sectorOther) {
          AddOtherApi({
            table: "sector",
            description: values.sectorOther,
            entrepreneurshipId: result.entrepreneurship.id,
          });
        }

        if (values.typeOther) {
          AddOtherApi({
            table: "type",
            description: values.typeOther,
            entrepreneurshipId: result.entrepreneurship.id,
          });
        }

        notification["success"]({
          message: result.message,
        });
        next();
        setLoadings(false);
      } else {
        notification["error"]({
          message: result.message,
        });
        setLoadings(false);
      }
    });
    //next();
  };

  const onFinishFailEdemprendimiento = (errorInfo) => {
    console.log("Failed:", errorInfo);
    notification["error"]({
      message: "Revise los errores en la información ingresada",
      duration: 3,
    });
  };

  const onFinishYotengo = (values) => {
    //console.log("Success:", values);
    setLoadings(true);
    if (values.haves) {
      BulkAddHaveApi(values).then((result) => {
        //console.log(result);
        if (result.status) {
          next();
          setLoadings(false);
        } else {
          notification["error"]({
            message: result.message,
          });
          setLoadings(false);
        }
      });
    } else {
      next();
    }

    //prev();
  };

  const onFinishFailYotengo = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message.error("Revisa los errores en la información ingresada");
    notification["error"]({
      message: "Revise los errores en la información ingresada",
      duration: 3,
    });
  };

  const onFinishYonecesito = (values) => {
    // console.log(values);
    // return;
    setLoadings(true);
    if (values.needs) {
      BulkAddNeedApi(values).then((result) => {
        //console.log(result);
        if (result.status) {
          //next();
          //setLoadings(false);
        } else {
          notification["error"]({
            message: result.message,
          });
          //setLoadings(false);
        }
      });
    }
    // const editEntrepreneurship = {
    //   resources: values.partner,
    //   suppliers: values.supplier,
    //   partner: values.partner ?? false,
    // };

    if (values.resourceOther) {
      AddOtherApi({
        table: "resource",
        description: values.resourceOther,
        entrepreneurshipId: hasEntrepreneurshipData
          ? userData.entrepreneurships[0].id
          : entrepreneurshipData.id,
      });
    }

    if (values.supplierOther) {
      AddOtherApi({
        table: "supplier",
        description: values.supplierOther,
        entrepreneurshipId: hasEntrepreneurshipData
          ? userData.entrepreneurships[0].id
          : entrepreneurshipData.id,
      });
    }

    UpdateEntrepreneurshipNeedsApi(
      values,
      hasEntrepreneurshipData
        ? userData.entrepreneurships[0].id
        : entrepreneurshipData.id
    ).then((result) => {
      if (result.status) {
        // setUserData(result.user);
        // notification["success"]({
        //   message: result.message,
        // });
        next();
        setLoadings(false);
      } else {
        notification["error"]({
          message: result.message,
        });
        setLoadings(false);
      }
    });
  };

  const onFinishFailEncuesta = (errorInfo) => {
    console.log("Failed:", errorInfo);
    notification["error"]({
      message: "Revise los errores en la información ingresada",
      duration: 3,
    });
  };

  const onFinishEncuesta = (values) => {
    console.log("Success:", values);
    var initials = [];

    setLoadings(true);
    initials = [
      ...(values.question1group ?? []),
      ...(values.question2group ?? []),
      ...(values.question3group ?? []),
      ...(values.question4group ?? []),
      ...(values.question5group ?? []),
      ...(values.question6group ?? []),
      ...(values.question7group ?? []),
      ...(values.question8group ?? []),
      ...(values.question9group ?? []),
      ...(values.question10group ?? []),
    ];
    if (initials.length > 0) {
      AddEntrepreneurshipInitialApi({
        entrepreneurshipId: hasEntrepreneurshipData
          ? userData.entrepreneurships[0].id
          : entrepreneurshipData.id,
        initials: initials,
      });
    }

    UpdateEntrepreneurshipApi(
      values,
      hasEntrepreneurshipData
        ? userData.entrepreneurships[0].id
        : entrepreneurshipData.id
    ).then((result) => {
      console.log(result);
      if (result.status) {
        // setUserData(result.user);
        // notification["success"]({
        //   message: result.message,
        // });
        next();
        setLoadings(false);
        getPotentialsApi(userData.id, 5, 40, 10).then((response) => {
          getPotentialsApi(userData.id, 5, 10, 40).then((response2) => {
            MailPotentialsApi({
              name: userData.name,
              email: userData.email,
              emprendedor1:
                response.users[0]?.name.toUpperCase() +
                " " +
                response.users[0]?.lastname.toUpperCase(),
              valor1: returnMailImage(response.users[0]?.porcentaje),
              emprendedor2:
                response.users[1]?.name.toUpperCase() +
                " " +
                response.users[1]?.lastname.toUpperCase(),
              valor2: returnMailImage(response.users[1]?.porcentaje),
              emprendedor3:
                response.users[2]?.name.toUpperCase() +
                " " +
                response.users[2]?.lastname.toUpperCase(),
              valor3: returnMailImage(response.users[2]?.porcentaje),
              emprendedor4:
                response2.users[0]?.name.toUpperCase() +
                " " +
                response2.users[0]?.lastname.toUpperCase(),
              valor4: returnMailImage(response2.users[0]?.porcentaje),
              emprendedor5:
                response2.users[1]?.name.toUpperCase() +
                " " +
                response2.users[1]?.lastname.toUpperCase(),
              valor5: returnMailImage(response2.users[1]?.porcentaje),
              emprendedor6:
                response2.users[2]?.name.toUpperCase() +
                " " +
                response2.users[2]?.lastname.toUpperCase(),
              valor6: returnMailImage(response2.users[2]?.porcentaje),
            });
          });
        });
      } else {
        notification["error"]({
          message: result.message,
        });
        setLoadings(false);
      }
    });
    //next();
  };

  const onFinishFailYonecesito = (errorInfo) => {
    console.log("Failed:", errorInfo);
    notification["error"]({
      message: "Revise los errores en la información ingresada",
      duration: 3,
    });
  };

  const next = () => {
    setCurrent(current + 1);
    setPercent(0);
  };

  const prev = () => {
    setCurrent(current - 1);
    setPercent(100);
  };

  const onChangeSteps = (value) => {
    console.log("onChange:", current);
    //setCurrent(value);
  };

  const steps = [
    {
      title: "Emprendimiento",
      content: (
        <Form
          name="emprendimiento"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          initialValues={{ remember: false }}
          onFinish={onFinishEmprendimiento}
          onFinishFailed={onFinishFailEdemprendimiento}
          autoComplete="off"
        >
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <BulbOutlined style={{ fontSize: "30px" }} />
                <br></br>
                <span>Emprendimiento</span>
              </>
            }
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item
                label="Nombre del emprendimiento"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="businessname"
                rules={[
                  {
                    required: true,
                    message: "Ingrese el nombre del emprendimiento",
                  },
                ]}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Nombre del emprendimiento"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={14} xl={14}>
              <Form.Item
                label="Descripción del emprendimiento"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Ingrese la descripción del emprendimiento",
                  },
                ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Descripción del emprendimiento"
                  maxLength={300}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Form.Item
                label="Inicio de actividades"
                name="creation"
                rules={[
                  {
                    required: true,
                    message: "Seleccione la fecha de inicio de actividades",
                  },
                ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={7} xl={7}>
              <SelectTypeForm
                atributoData={type}
                setAtributoData={setType}
              ></SelectTypeForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={7} xl={7}>
              <SelectSectorForm
                atributoData={sector}
                setAtributoData={setSector}
              ></SelectSectorForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <SelectStageForm
                atributoData={stage}
                setAtributoData={setStage}
              ></SelectStageForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={4} xl={4}>
              <Form.Item
                label="Número de socios"
                name="partner"
                rules={[
                  {
                    required: true,
                    message: "Ingrese el número de socios",
                  },
                ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <InputNumber
                  //prefix={<UserOutlined />}
                  placeholder="Número de socios"
                  maxLength={4}
                  //value={userData.name}
                  //defaultValue={1999}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <SelectProcessForm
                atributoData={process}
                setAtributoData={setProcess}
              ></SelectProcessForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <SelectCommunityForm
                atributoData={community}
                setAtributoData={setCommunity}
              ></SelectCommunityForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                label="Logros - Reconocimientos del emprendimiento"
                name="medals"
                // rules={[
                //   {
                //     required: true,
                //     message:
                //       "Ingrese los logros - reconocimientos de tu emprendimiento",
                //   },
                // ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Logros - Reconocimientos"
                  maxLength={300}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <SelectAcademyForm
                atributoData={academy}
                setAtributoData={setAcademy}
              ></SelectAcademyForm>
            </Col> */}
          </Row>
          {/* <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                name="invoice"
                valuePropName="checked"
                label="¿Estás facturando?"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Checkbox checked={checkNick} onChange={onCheckboxChange}>
                  Seleccione si ya estás entregando facturas
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={5} xl={5}>
              {checkNick && (
                <Form.Item
                  name="invoiceanual"
                  label="¿Cuánto facturas por año?"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: checkNick,
                      message: "Ingrese las facturas por año",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Ingrese las facturas por año"
                    maxLength={6}
                  />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} sm={24} md={12} lg={5} xl={5}>
              {checkNick && (
                <Form.Item
                  name="employees"
                  label="Número de empleados"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: checkNick,
                      message: "Ingrese el número de empleados",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Número de empleados"
                    maxLength={6}
                  />
                </Form.Item>
              )}
            </Col>
          </Row> */}
          <br></br>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  loading={loadings}
                  className={"btnBig"}
                >
                  Siguiente <ArrowRightOutlined />
                </Button>
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button type="deafult">
                  <ArrowLeftOutlined /> Anteior
                </Button>
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      ),
      icon: <UserOutlined />,
    },
    {
      title: "Yo tengo",
      content: (
        <Form
          form={formYotengo}
          name="yotengo"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          initialValues={{
            haves: [
              {
                entrepreneurshipId: hasEntrepreneurshipData
                  ? userData.entrepreneurships[0].id
                  : entrepreneurshipData.id,
                knowledgeId: undefined,
                hours: undefined,
                years: undefined,
              },
            ],
          }}
          onFinish={onFinishYotengo}
          onFinishFailed={onFinishFailYotengo}
          autoComplete="off"
        >
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <ToolOutlined style={{ fontSize: "40px" }} />
                <br></br>
                <span>Conocimiento que tengo</span>
                <br></br>
                <span style={{ fontSize: "14px" }}>
                  Seleccione máximo 3 conocimientos que tenga para
                  intercambiarlos en el ecosistema
                </span>
                <br></br>
                <span style={{ fontSize: "12px", fontWeight: "normal" }}>
                  Conocimientos (Administrativos, operativos, financieros,
                  comunicación, marketing...)
                </span>
              </>
            }
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <Form.List name="haves">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      // <Space
                      //   key={key}
                      //   style={{
                      //     display: "flex",
                      //     marginBottom: 8,
                      //     width: "100%",
                      //   }}
                      //   align="baseline"
                      // >
                      <Row gutter={24} key={field.key}>
                        <Col
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          style={{ textAlign: "rigth", paddingTop: "25px" }}
                        >
                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <SelectKnowledgeForm
                            // atributoData={city}
                            // setAtributoData={setCity}
                            //handleChange={handleChangeYotengo}
                            name={[field.name, "knowledgeId"]}
                            nameOther={[field.name, "other"]}
                          ></SelectKnowledgeForm>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                          <Form.Item
                            wrapperCol={{ span: 24 }}
                            labelCol={{ span: 24 }}
                            {...field}
                            name={[field.name, "hours"]}
                            label="Horas entregadas"
                            rules={[
                              {
                                required: true,
                                message: "Seleccione las horas entregadas",
                              },
                            ]}
                          >
                            {/* <Input placeholder="Horas requeridas" /> */}
                            {/* <InputNumber
                              placeholder="Horas requeridas"
                              maxLength={4}
                            /> */}
                            <Radio.Group>
                              <Radio value={2}>2</Radio>
                              <Radio value={4}>4</Radio>
                              <Radio value={6}>6</Radio>
                              <Radio value={8}>8</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col xs={22} sm={22} md={8} lg={8} xl={8}>
                          <Form.Item
                            wrapperCol={{ span: 24 }}
                            labelCol={{ span: 24 }}
                            {...field}
                            name={[field.name, "years"]}
                            label="Años de experiencia"
                            rules={[
                              {
                                required: true,
                                message: "Seleccione los años de experiencia",
                              },
                            ]}
                          >
                            {/* <Input placeholder="Años de experiencia" /> */}
                            {/* <InputNumber
                              placeholder="Años de experiencia"
                              maxLength={4}
                            /> */}
                            <Radio.Group>
                              <Radio value={1}>1</Radio>
                              <Radio value={2}>2</Radio>
                              <Radio value={4}>4</Radio>
                              <Radio value={6}>6</Radio>
                              <Radio value={8}>8</Radio>
                              <Radio value={10}>10 o más</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>

                      // </Space>
                    ))}
                    <br></br>
                    <Row gutter={24}>
                      <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 16, offset: 4 }}
                        xl={{ span: 16, offset: 4 }}
                      >
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              if (fields.length >= 3) {
                                notification["error"]({
                                  message:
                                    "Puede agregar hasta 3 conocimientos que tenga",
                                  duration: 3,
                                });
                                return false;
                              }
                              add({
                                entrepreneurshipId: hasEntrepreneurshipData
                                  ? userData.entrepreneurships[0].id
                                  : entrepreneurshipData.id,
                              });
                            }}
                            block
                            icon={<PlusOutlined />}
                          >
                            Agregar conocimiento que tengo
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <br></br>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  className={"btnBig"}
                >
                  Siguiente <ArrowRightOutlined />
                </Button>
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button type="deafult">
                  <ArrowLeftOutlined /> Anteior
                </Button>
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      ),
      icon: <UserOutlined />,
    },
    {
      title: "Yo necesito",
      content: (
        <Form
          form={formYonecesito}
          name="yonecesito"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          initialValues={{
            needs: [
              {
                entrepreneurshipId: hasEntrepreneurshipData
                  ? userData.entrepreneurships[0].id
                  : entrepreneurshipData.id,
                knowledgeId: undefined,
                hours: undefined,
              },
            ],
          }}
          onFinish={onFinishYonecesito}
          onFinishFailed={onFinishFailYonecesito}
          autoComplete="off"
        >
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <ApiOutlined style={{ fontSize: "30px" }} />
                <br></br>
                <span>Conocimiento que necesito</span>
                <br></br>
                <span style={{ fontSize: "14px" }}>
                  Seleccione máximo 3 conocimientos que necesite para
                  intercambiarlos en el ecosistema
                </span>
                <br></br>
                <span style={{ fontSize: "12px", fontWeight: "normal" }}>
                  Conocimientos (Administrativos, operativos, financieros,
                  comunicación, marketing...)
                </span>
              </>
            }
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 14, offset: 5 }}
              xl={{ span: 14, offset: 5 }}
            >
              <Form.List name="needs">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      // <Space
                      //   key={key}
                      //   style={{
                      //     display: "flex",
                      //     marginBottom: 8,
                      //     width: "100%",
                      //   }}
                      //   align="baseline"
                      // >
                      <Row gutter={24} key={field.key}>
                        <Col
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          style={{ textAlign: "rigth", paddingTop: "25px" }}
                        >
                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <SelectKnowledgeForm
                            // atributoData={city}
                            // setAtributoData={setCity}
                            //handleChange={handleChangeYonecesito}
                            name={[field.name, "knowledgeId"]}
                            nameOther={[field.name, "other"]}
                          ></SelectKnowledgeForm>
                        </Col>
                        <Col xs={22} sm={22} md={10} lg={10} xl={10}>
                          <Form.Item
                            wrapperCol={{ span: 24 }}
                            labelCol={{ span: 24 }}
                            {...field}
                            name={[field.name, "hours"]}
                            label="Horas necesarias"
                            rules={[
                              {
                                required: true,
                                message: "Seleccione las horas necesarias",
                              },
                            ]}
                          >
                            {/* <Input placeholder="Horas requeridas" /> */}
                            {/* <InputNumber
                              placeholder="Horas necesarias"
                              maxLength={4}
                            /> */}
                            <Radio.Group>
                              <Radio value={2}>2</Radio>
                              <Radio value={4}>4</Radio>
                              <Radio value={6}>6</Radio>
                              <Radio value={8}>8</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>

                      // </Space>
                    ))}
                    <br></br>
                    <Row gutter={24}>
                      <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 16, offset: 4 }}
                        xl={{ span: 16, offset: 4 }}
                      >
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              if (fields.length >= 3) {
                                notification["error"]({
                                  message:
                                    "Puede agregar hasta 3 conocimientos necesarios",
                                  duration: 3,
                                });
                                return false;
                              }
                              add({
                                entrepreneurshipId: hasEntrepreneurshipData
                                  ? userData.entrepreneurships[0].id
                                  : entrepreneurshipData.id,
                              });
                            }}
                            block
                            icon={<PlusOutlined />}
                          >
                            Agregar conocimiento que necesito
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <ForkOutlined style={{ fontSize: "30px" }} />{" "}
                <span>Recursos para crecer</span>
              </>
            }
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={4} xl={4}>
              <Form.Item
                name="needpartner"
                valuePropName="checked"
                label="Necesita socios?"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Checkbox
                  checked={checkPartner}
                  onChange={onCheckboxPartnetChange}
                >
                  Si busco socios
                </Checkbox>
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <SelectResourceForm
                atributoData={resource}
                setAtributoData={setResource}
              ></SelectResourceForm>
            </Col> */}
            <Col xs={24} sm={24} md={12} lg={5} xl={5}>
              <SelectSupplierForm
                atributoData={supplier}
                setAtributoData={setSupplier}
              ></SelectSupplierForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <SelectRevenueForm
                atributoData={revenue4}
                setAtributoData={setRevenue4}
                name={"question6"}
                label={
                  "¿Cuál sería el valor de inversión que necesita para hacer crecer su negocio durante este año?"
                }
              ></SelectRevenueForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={7} xl={7}>
              <Form.Item
                label="¿En qué empleará el capital?"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="capital"
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Describa en qué empleará el capital"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <b>Recursos de apoyo</b>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label="Organizativo"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="resource1"
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Describa el recurso organizativo que necesita"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label="Administrativo"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="resource2"
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Describa el recurso administrativo que necesita"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label="Financiero"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="resource3"
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Describa el recurso financiero que necesita"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label="Productivo"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="resource4"
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Describa el recurso productivo que necesita"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label="Comercial"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="resource5"
              >
                <Input
                  //prefix={<UserOutlined />}
                  placeholder="Describa el recurso comercial que necesita"
                  //value={userData.name}
                  maxLength={50}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <br></br>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  className={"btnBig"}
                >
                  Siguiente <ArrowRightOutlined />
                </Button>
              </Form.Item>
            </Col>
            {/* <Button icon={<ArrowLeftOutlined />} onClick={() => prev()}>
              Anterior
            </Button> */}
            {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button type="deafult">
                  <ArrowLeftOutlined /> Anteior
                </Button>
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      ),
      icon: <UserOutlined />,
    },
    {
      title: "Diagnóstico",
      content: (
        <Form
          name="encuesta"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          initialValues={{ remember: false }}
          onFinish={onFinishEncuesta}
          onFinishFailed={onFinishFailEncuesta}
          autoComplete="off"
        >
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <RiseOutlined style={{ fontSize: "40px" }} />
                <br></br>
                <span>Encuesta de rendimiento inicial</span>
                <br></br>
                <span style={{ fontSize: "14px" }}>
                  Necesitamos conocer los datos de facturación de su
                  emprendimiento, inversión, creación de empleo y exportación,
                  para medir su crecimiento y canalizar ayudas e inversiones.
                </span>
              </>
            }
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
            >
              <SelectRevenueForm
                atributoData={revenue1}
                setAtributoData={setRevenue1}
                name={"question1"}
                label={"1. ¿Cuánto facturó el año pasado?"}
              ></SelectRevenueForm>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
            >
              <SelectRevenueForm
                atributoData={revenue2}
                setAtributoData={setRevenue2}
                name={"question2"}
                label={"2. ¿Cuánto espera facturar durante este año?"}
              ></SelectRevenueForm>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
            >
              <SelectEmployeeForm
                atributoData={employee1}
                setAtributoData={setEmployee1}
                name={"question3"}
                label={
                  "3. ¿Cuántos empleados tiene actualmente su emprendimiento?"
                }
              ></SelectEmployeeForm>
            </Col>

            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
            >
              <SelectEmployeeForm
                atributoData={employee2}
                setAtributoData={setEmployee2}
                name={"question4"}
                label={
                  "4. ¿Cuántos nuevos empleados podría contratar el próximo año?"
                }
              ></SelectEmployeeForm>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
            >
              <SelectRevenueForm
                atributoData={revenue3}
                setAtributoData={setRevenue3}
                name={"question5"}
                label={
                  "5. ¿Cuál fue el monto de inversión que realizó en máquinas, equipos o infraestructura el año pasado?"
                }
              ></SelectRevenueForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                name="export"
                valuePropName="checked"
                label="¿Está exportando?"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                //className={"biglabel"}
              >
                <Checkbox
                  checked={checkExport}
                  onChange={onCheckboxExportChange}
                >
                  Marque la casilla si ya está exportando sus productos.
                </Checkbox>
              </Form.Item>
            </Col>
            {checkExport && (
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 6, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
              >
                <SelectRevenueForm
                  atributoData={lastYear}
                  setAtributoData={setLastYear}
                  name={"lastyear"}
                  label={"¿Cuánto exportó el año pasado?"}
                ></SelectRevenueForm>
              </Col>
            )}
            {checkExport && (
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 6, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
              >
                <SelectRevenueForm
                  atributoData={thisYear}
                  setAtributoData={setThisYear}
                  name={"thisyear"}
                  label={"¿Cuánto planifica exportar este año?"}
                ></SelectRevenueForm>
              </Col>
            )}
            {checkExport && (
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 6, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
              >
                <Form.Item
                  label="¿A qué países?"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese los países a los que exportó",
                    },
                  ]}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  //className={"biglabel"}
                >
                  <Input
                    //prefix={<UserOutlined />}
                    placeholder="Países"
                    maxLength={300}
                    //value={userData.name}
                    onChange={
                      (e) => {}
                      //setUserData({ ...userData, name: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <br />
          <br />
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <LaptopOutlined style={{ fontSize: "40px" }} />
                <br></br>
                <span>Conocimientos y habilidades</span>
                <br></br>
                <span style={{ fontSize: "14px" }}>
                  Necesitamos conocer sus competencias, habilidades blandas y
                  conocimientos en herramientas digitales, para canalizar
                  procesos formativos y apoyo de mentores.
                </span>
              </>
            }
            // subTitle="This is a subtitle"
          />
          <br></br>
          <Row gutter={24}>
            <Col span={24}>
              <CheckCircleOutlined style={{ fontSize: "20px" }} />{" "}
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                Competencias
              </span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question1"}
                question={1}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 6, offset: 0 }}
                spanFixxl={{ span: 4, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <br></br>
          <Row gutter={24}>
            <Col span={24}>
              <CheckCircleOutlined style={{ fontSize: "20px" }} />{" "}
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                Habilidades blandas
              </span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question2"}
                question={2}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 6, offset: 0 }}
                spanFixxl={{ span: 4, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <br></br>
          <Row gutter={24}>
            <Col span={24}>
              <CheckCircleOutlined style={{ fontSize: "20px" }} />{" "}
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                Herramientas digitales
              </span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question3"}
                question={3}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 6, offset: 0 }}
                spanFixxl={{ span: 4, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <br />
          <br />
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <OrderedListOutlined style={{ fontSize: "40px" }} />
                <br></br>
                <span>Etapa del emprendimiento</span>
                <br></br>
                <span style={{ fontSize: "14px" }}>
                  Cuéntenos en que etapa se encuentra su emprendimiento, con
                  esta información canalizaremos procesos formativos, acceso ha
                  mercado y apoyo económico
                </span>
              </>
            }
            //subTitle="Seleccione todas las etapas que has pasado"
          ></PageHeader>
          <Row gutter={24}>
            <Col span={24}>
              <CheckCircleOutlined style={{ fontSize: "16px" }} />{" "}
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                ¿Ha pasado estos hitos de las etapas del emprendimiento?
              </span>
              <br></br>
              <br></br>
              <span
                style={{
                  fontSize: "14px",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                Seleccione todas las etapas que ha pasado
              </span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question4"}
                question={4}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 6, offset: 0 }}
                spanFixxl={{ span: 4, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question5"}
                question={5}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 6, offset: 0 }}
                spanFixxl={{ span: 4, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question6"}
                question={6}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 6, offset: 0 }}
                spanFixxl={{ span: 4, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <br />
          <br />
          <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <ExperimentOutlined style={{ fontSize: "40px" }} />
                <br></br>
                <span>Innovación</span>
                <br></br>
                <span style={{ fontSize: "14px" }}>
                  Los emprendimientos con alto contenido de innovación son los
                  que escalan en el mercado
                </span>
              </>
            }
            //subTitle="Seleccione todas las etapas que has pasado"
          ></PageHeader>
          <Row gutter={24}>
            <Col span={24}>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                Vamos a conocer el grado de innovación de sus productos y
                servicios
              </span>
              <br></br>
              <br></br>
              <span
                style={{
                  fontSize: "14px",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                Marque la casilla si su respuesta es “Sí”
              </span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question7"}
                question={7}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 12, offset: 0 }}
                spanFixxl={{ span: 12, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question8"}
                question={8}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 12, offset: 0 }}
                spanFixxl={{ span: 12, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question9"}
                question={9}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 12, offset: 0 }}
                spanFixxl={{ span: 12, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <SelectQuestion1Form
                name={"group-question10"}
                question={10}
                spanFixXs={{ span: 24, offset: 0 }}
                spanFixsm={{ span: 12, offset: 0 }}
                spanFixmd={{ span: 12, offset: 0 }}
                spanFixlg={{ span: 12, offset: 0 }}
                spanFixxl={{ span: 12, offset: 0 }}
              ></SelectQuestion1Form>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  className={"btnBig"}
                >
                  Finalizar registro <ArrowRightOutlined />
                </Button>
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button type="deafult">
                  <ArrowLeftOutlined /> Anteior
                </Button>
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      ),
      icon: <UserOutlined />,
    },
    {
      title: "Completo",
      content: (
        <Result
          status="success"
          title={`¡${userData.name}, su registro se ha completado exitosamente!`}
          subTitle="Ahora se puede conectar con otros emprendedores."
          extra={[
            <Button
              type="primary"
              key="console"
              style={{ height: "60px", width: "180px" }}
              onClick={() => {
                setIsVisibleModal(false);
                setUpdateInfo(true);
              }}
              className={"btnBig"}
            >
              Ver a mi dashboard
            </Button>,
            // <Button key="buy">Buy Again</Button>,
          ]}
        />
      ),
      icon: <UserOutlined />,
    },
  ];

  return (
    <>
      <Steps current={current} onChange={onChangeSteps}>
        {steps.map((item) => (
          <Step key={item.title} disabled={true} title={item.title} />
        ))}
      </Steps>
      <br></br>
      <div className="steps-content">{steps[current].content}</div>
    </>
  );
}
