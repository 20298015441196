import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";

import {
  Table,
  Button,
  Empty,
  Breadcrumb,
  Affix,
  Row,
  Col,
  Form,
  PageHeader,
  Checkbox,
  notification,
  Skeleton,
  Avatar,
} from "antd";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import ForkOutlined from "@ant-design/icons/ForkOutlined";
import InteractionOutlined from "@ant-design/icons/InteractionOutlined";
import ApiOutlined from "@ant-design/icons/ApiOutlined";

import "./Residual.scss";
import { getNeedresidualsApi } from "../../../api/needresidual.api";
import { getHaveresidualsApi } from "../../../api/haveresidual.api";

import ModalUser from "../../../components/Admin/ModalUser";
import EditResidualForm from "../../../components/Home/User/Residual/EditResidualForm";
import {
  getEntrepreneurshipByIdApi,
  UpdateEntrepreneurshipResidualsApi,
} from "../../../api/entrepreneurship.api";
import useAuth from "../../../hooks/useAuth";

import SelectResourceForm from "../../../components/Admin/Resource/SelectResourceForm";
import SelectSupplierForm from "../../../components/Admin/Supplier/SelectSupplierForm";

import { AddOtherApi, getOtherByIdApi } from "../../../api/other.api";
import SelectRevenueForm from "../../../components/Admin/Revenue/SelectRevenueForm";

export default function Residual({ socket, setReloadPerfil }) {
  const [needresiduals, setNeedresiduals] = useState([]);
  const [haveresiduals, setHaveresiduals] = useState([]);
  const token = getAccessTokenApi();
  const [reloadResiduals, setReloadResiduals] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [loadingDataHave, setLoadingDataHave] = useState(true);
  const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const { user } = useAuth();
  const [checkPartner, setCheckPartner] = useState(false);
  const [resource, setResource] = useState();
  const [supplier, setSupplier] = useState();
  const [loadings, setLoadings] = useState(false);
  const [formYonecesito] = Form.useForm();
  const [revenue4, setRevenue4] = useState();
  const [selectSupplierControl, SetSelectSupplierControl] = useState(
    <Skeleton.Input active={true} />
  );
  const [selectResourceControl, SetSelectResourceControl] = useState(
    <Skeleton.Input active={true} />
  );

  const onCheckboxPartnetChange = (e) => {
    setCheckPartner(e.target.checked);
  };

  const [lastsocket, setLastsocket] = useState();

  useEffect(() => {
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
    socket.on("messageResponse", (data) => {
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
  }, [socket]);

  useEffect(() => {
    getEntrepreneurshipByIdApi(token, user.id).then((response) => {
      //console.log(response.entrepreneurship);
      setEntrepreneurshipData(response.entrepreneurship);

      //setCheckPartner(true);
      // const arrSuppliers = [];
      //console.log(response.entrepreneurship.suppliers);
      // Object.keys(response.entrepreneurship.suppliers).forEach((key) =>
      //   arrSuppliers
      //     .push(response.entrepreneurship.suppliers[key].id)
      //     .toString()
      // );
      // var isOtherS = arrSuppliers.find((element) => {
      //   return element === 9;
      // });
      // if (isOtherS) {
      //   getOtherByIdApi(token, response.entrepreneurship.id, "supplier").then(
      //     (response) => {
      //       SetSelectSupplierControl(
      //         <SelectSupplierForm
      //           atributoData={arrSuppliers}
      //           setAtributoData={setSupplier}
      //           supplierValue={response.other.description}
      //         ></SelectSupplierForm>
      //       );
      //       console.log(response.other.description);
      //     }
      //   );
      // } else {
      //   SetSelectSupplierControl(
      //     <SelectSupplierForm
      //       atributoData={arrSuppliers}
      //       setAtributoData={setSupplier}
      //       supplierValue={""}
      //     ></SelectSupplierForm>
      //   );
      // }
      // const arrResources = [];
      // Object.keys(response.entrepreneurship.resources).forEach((key) =>
      //   arrResources
      //     .push(response.entrepreneurship.resources[key].id)
      //     .toString()
      // );

      // var isOther = arrResources.find((element) => {
      //   return element === 4;
      // });
      // if (isOther) {
      //   getOtherByIdApi(token, response.entrepreneurship.id, "resource").then(
      //     (response) => {
      //       SetSelectResourceControl(
      //         <SelectResourceForm
      //           atributoData={arrResources}
      //           setAtributoData={setSupplier}
      //           resourceValue={response.other.description}
      //         ></SelectResourceForm>
      //       );
      //       console.log(response.other.description);
      //     }
      //   );
      // } else {
      //   SetSelectResourceControl(
      //     <SelectResourceForm
      //       atributoData={arrResources}
      //       setAtributoData={setSupplier}
      //       resourceValue={""}
      //     ></SelectResourceForm>
      //   );
      // }

      // SetSelectResourceControl(
      //   <SelectResourceForm
      //     atributoData={arrResources}
      //     setAtributoData={setSupplier}
      //     resourceValue={""}
      //   ></SelectResourceForm>
      // );

      // getNeedresidualsApi(token, response.entrepreneurship.id).then(
      //   (response) => {
      //     console.log(response.needresiduals);
      //     setNeedresiduals(response.needresiduals);
      //     setLoadingData(false);
      //   }
      // );
      getHaveresidualsApi(token, response.entrepreneurship.id).then(
        (response) => {
          console.log(response.haveresiduals);
          setHaveresiduals(response.haveresiduals);
          setLoadingDataHave(false);
          setReloadPerfil(true);
        }
      );
    });

    setReloadResiduals(false);
  }, [token, reloadResiduals, user.id]);

  // useEffect(() => {
  //   formYonecesito.setFieldsValue(entrepreneurshipData);
  //   console.log(entrepreneurshipData.partners ?? false);

  //   setCheckPartner(entrepreneurshipData.partners ?? false);
  // }, [entrepreneurshipData]);

  const addResidual = (have) => {
    // if (needresiduals.length >= 3) {
    //   notification["error"]({
    //     message: "Puedes agregar hasta 3 recursos que necesites",
    //     duration: 3,
    //   });
    //   return false;
    // }
    setIsVisibleModal(true);
    setModalTitle("Agregar recurso residual");
    setmodalContent(
      <EditResidualForm
        residual={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadResiduals={setReloadResiduals}
        buttonName="Agregar recurso residual"
        addSlag={"add"}
        entrepreneurshipData={entrepreneurshipData}
        have={have}
      />
    );
  };

  const editResidual = (record, have) => {
    setIsVisibleModal(true);
    setModalTitle("Editar recurso residual");
    setmodalContent(
      <EditResidualForm
        residual={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadResiduals={setReloadResiduals}
        buttonName="Editar recurso"
        addSlag={"edit"}
        entrepreneurshipData={entrepreneurshipData}
        have={have}
      />
    );
  };

  const deleteResidual = (record, have) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar recurso que necesito");
    setmodalContent(
      <EditResidualForm
        residual={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadResiduals={setReloadResiduals}
        buttonName="Eliminar recurso"
        addSlag={"delete"}
        entrepreneurshipData={entrepreneurshipData}
        have={have}
      />
    );
  };

  const columns = [
    {
      title: "Código",
      width: 50,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      responsive: ["md"],
    },
    {
      title: "Tipo",
      width: 150,
      dataIndex: ["residual1", "name"],
      key: "residualId",
    },
    {
      title: "Otro",
      width: 100,
      dataIndex: "other",
      key: "other",
      responsive: ["md"],
    },
    {
      title: "Estado",
      width: 150,
      dataIndex: ["state", "name"],
      key: "stateId",
    },
    {
      title: "Valor estimado",
      width: 130,
      dataIndex: ["revenue1", "name"],
      key: "revenueId",
    },
    {
      title: "Peso",
      width: 130,
      dataIndex: ["weight", "name"],
      key: "weightId",
    },
    {
      title: "Transporte",
      width: 130,
      dataIndex: ["transport", "name"],
      key: "transportId",
    },
    {
      title: "Uso",
      width: 130,
      dataIndex: ["utility", "name"],
      key: "utilityId",
    },
    {
      title: "Acuerdo",
      width: 130,
      dataIndex: ["agreement", "name"],
      key: "agreementId",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 120,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editResidual(record, true)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => deleteResidual(record, true)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  // const onFinishReisdual = (values) => {
  //   setLoadings(true);

  //   console.log({ ...values, residualpartner: values.partners });
  //   UpdateEntrepreneurshipResidualsApi(
  //     { ...values, residualpartner: values.partners },
  //     entrepreneurshipData.id
  //   ).then((result) => {
  //     if (result.status) {
  //       if (values.resourceOther) {
  //         AddOtherApi({
  //           table: "resource",
  //           description: values.resourceOther,
  //           entrepreneurshipId: entrepreneurshipData.id,
  //         });
  //       }

  //       if (values.supplierOther) {
  //         AddOtherApi({
  //           table: "supplier",
  //           description: values.supplierOther,
  //           entrepreneurshipId: entrepreneurshipData.id,
  //         });
  //       }

  //       notification["success"]({
  //         message: "Recursos para crecer actualizados correctamente",
  //       });
  //       setLoadings(false);
  //     } else {
  //       notification["error"]({
  //         message: result.message,
  //       });
  //       setLoadings(false);
  //     }
  //   });
  // };

  // const onFinishFailYonecesito = (errorInfo) => {
  //   console.log("Failed:", errorInfo);
  //   notification["error"]({
  //     message: "Revisa los errores en la información ingresada",
  //     duration: 3,
  //   });
  // };

  return (
    <div className="residual">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Proyecto</Breadcrumb.Item>
        <Breadcrumb.Item>Perfil</Breadcrumb.Item>
        <Breadcrumb.Item>Recursos residuales</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        className="site-page-header header-left"
        // onBack={() => null}
        style={{ textAlign: "left", justifyContent: "start" }}
        // onBack={() => null}
        //style={{ textAlign: "center" }}
        title={
          <>
            <Avatar
              icon={<InteractionOutlined />}
              style={{ backgroundColor: "#f42c1e", color: "white" }}
            />
            <span> Recurso residual que tengo</span>
            <br></br>
            <span style={{ fontSize: "14px" }}>
              Recursos residuales que tenga para compartir en el ecosistema.
            </span>
          </>
        }
        // subTitle="This is a subtitle"
      />
      <Affix offsetTop={80}>
        <Button type="primary" onClick={() => addResidual(true)}>
          Agregar recurso residual que tengo
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={haveresiduals}
        loading={loadingDataHave}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No tienes recursos residuales registrados"
            />
          ),
        }}
      />
      {/* <PageHeader
        className="site-page-header header-left"
        // onBack={() => null}
        style={{ textAlign: "left", justifyContent: "start" }}
        // onBack={() => null}
        //style={{ textAlign: "center" }}
        title={
          <>
            <InteractionOutlined style={{ fontSize: "30px" }} />
            <span> Recurso residual que necesito</span>
            <br></br>
            <span style={{ fontSize: "14px" }}>
              Recursos residuales que necesite para buscar en el ecosistema.
            </span>
          </>
        }
        // subTitle="This is a subtitle"
      />
      <Affix offsetTop={120}>
        <Button type="primary" onClick={addResidual}>
          Agregar recurso residual que necesito
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={needresiduals}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No tienes recursos residuales registrados"
            />
          ),
        }}
      /> */}
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
