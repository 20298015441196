import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Row, Col, DatePicker, Button } from "antd";
import { Pie } from "@ant-design/charts";
import { getAccessTokenApi } from "../../../api/auth";

import "./Indicadores.scss";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

import { getSectorsGroupApi } from "../../../api/sector.api";
import SelectProvinceAllForm from "../../../components/Admin/Province/SelectProvinceForm/SelectProvinceAllForm";

export default function Sectores() {
  const token = getAccessTokenApi();
  const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [province, setProvince] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    // if (fincaId.length > 0) {
    //   if (fechaInicial && fechaFinal) {
    //     getFincasAmbitoAvgApi(token, fechaInicial, fechaFinal, fincaId).then(
    //       (response) => {
    //         setData(response.fincas);
    //       }
    //     );
    //   } else {
    //     getFincasAmbitoAvgApi(token, 0, 0, fincaId).then((response) => {
    //       setData(response.fincas);
    //     });
    //   }
    // } else {
    //   if (fechaInicial && fechaFinal) {
    //     getFincasAmbitoAvgApi(token, fechaInicial, fechaFinal, 0).then(
    //       (response) => {
    //         setData(response.fincas);
    //       }
    //     );
    //   } else {
    //     getFincasAmbitoAvgApi(token, 0, 0, 0).then((response) => {
    //       setData(response.fincas);
    //     });
    //   }
    // }
    getSectorsGroupApi(token, province).then((response) => {
      console.log(response);
      let sector1 = response.sectors;
      var subtotal = 0;
      sector1.map((sector) => (subtotal = subtotal + sector.value));
      setTotal(subtotal);
      setData(response.sectors);
    });
  }, [token, province]);

  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.75,
    label: {
      type: "spider",
      labelHeight: 28,
      content: "{name} - {value}\n{percentage}",
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  // const config = {
  //   appendPadding: 10,
  //   data,
  //   angleField: "value",
  //   colorField: "type",
  //   radius: 0.9,
  //   label: {
  //     type: "inner",
  //     offset: "-30%",
  //     content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
  //     style: {
  //       fontSize: 14,
  //       textAlign: "center",
  //     },
  //   },
  //   interactions: [
  //     {
  //       type: "element-active",
  //     },
  //   ],
  // };

  // function onChange(date, dateString) {
  //   if (date) {
  //     setFechaInicial(date[0].startOf("month").format("YYYY-MM-DD"));
  //     setFechaFinal(date[1].endOf("month").format("YYYY-MM-DD"));
  //   } else {
  //     setFechaInicial(null);
  //     setFechaFinal(null);
  //   }
  // }

  const ref = useRef();

  // export image
  const downloadImage = () => {
    ref.current?.downloadImage();
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Gráficas</Breadcrumb.Item>
        <Breadcrumb.Item>Tipo de actor</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        {/* <Row gutter={16}>
          <Col span={12}>
            <RangePicker
              style={{ width: "100%" }}
              picker="month"
              onChange={onChange}
            />
          </Col>
          <Col span={12}>
            { <SelectMultiFinca setFincaId={setFincaId}></SelectMultiFinca> }
          </Col>
        </Row> */}

        <br />
        <h2>Tipo de actor ({total} totales)</h2>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <SelectProvinceAllForm
              atributoData={province}
              setAtributoData={setProvince}
            ></SelectProvinceAllForm>
          </Col>
        </Row>
        <Pie
          {...config}
          onReady={(plot) => {
            ref.current = plot;
            setDisabled(false);
          }}
        />
        <br />
        <Button
          disabled={disabled}
          htmlType="button"
          className="btn-submit"
          onClick={downloadImage}
        >
          Guardar gráfico
        </Button>
      </div>
    </div>
  );
}
