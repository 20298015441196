import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";
import { getUsersActiveApi } from "../../../api/user.api";
import ListUsers from "../../../components/Admin/Users/ListUsers";
import { Breadcrumb } from "antd";

import "./Users.scss";

export default function Users() {
  const [usersActive, setUsersActive] = useState([]);
  const [usersInactive, setUsersInactive] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [city, setCity] = useState(0);
  const [province, setProvince] = useState(0);
  const [role, setRole] = useState(0);
  const [community, setCommunity] = useState(0);
  const token = getAccessTokenApi();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getUsersActiveApi(token, true, city, role, community, province).then(
      (response) => {
        console.log(response.users);
        setUsersActive(response.users);
        setLoading(false);
      }
    );
    getUsersActiveApi(token, false, city, role, community, province).then(
      (response) => {
        setUsersInactive(response.users);
      }
    );
    setReloadUsers(false);
  }, [token, reloadUsers, city, role, community, province]);

  return (
    <div className="users">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Registros</Breadcrumb.Item>
      </Breadcrumb>
      <ListUsers
        usersActive={usersActive}
        usersInactive={usersInactive}
        setReloadUsers={setReloadUsers}
        city={city}
        setCity={setCity}
        role={role}
        setRole={setRole}
        community={community}
        setCommunity={setCommunity}
        province={province}
        setProvince={setProvince}
        loading={loading}
      />
    </div>
  );
}
