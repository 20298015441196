import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";

import { GetCityByProvinceApi } from "../../../../api/city.api";

import "./SelectCityForm.scss";

const { Option } = Select;

export default function SelectAllCityForm(props) {
  const { atributoData, setAtributoData, provinceData } = props;
  const [cityData, setCityData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(false);
  const [cityId, setCityId] = useState();
  const [enabled, setEnabled] = useState(false);

  // useEffect(() => {
  //   setLoadingData(true);
  //   getCitysApi().then((response) => {
  //     setCityData(response.citys);
  //     setCityId(atributoData);
  //     setLoadingData(false);
  //     setEnabled(true);
  //   });
  // }, []);
  useEffect(() => {
    if (provinceData > 0) {
      setLoadingData(true);
      GetCityByProvinceApi(provinceData).then((response) => {
        setCityData(response.citys);
        setCityId(atributoData);
        setLoadingData(false);
        setEnabled(true);
      });
    } else {
      setEnabled(false);
    }
  }, [provinceData]);

  const handleAtributoChange = (value) => {
    //console.log(value ?? 0);
    setCityId(value);
    setAtributoData(value ?? 0);
  };

  return (
    <div className="select-city-form">
      <Form.Item
        label="Ciudad"
        name="cityId"
        //rules={[{ required: true, message: "Seleccione una ciudad" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        disabled={!enabled}
      >
        <Select
          placeholder="Seleccione la ciudad"
          value={cityId}
          onChange={handleAtributoChange}
          showSearch
          loading={loadingData}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          disabled={!enabled}
          allowClear={true}
        >
          {cityData && cityData.map
            ? cityData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
