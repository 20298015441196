import React, { useState, useEffect } from "react";
import { Select, Form, Input } from "antd";

import { getKnowledgesApi } from "../../../../api/knowledge.api";

import "./SelectKnowledgeForm.scss";

const { Option } = Select;

export default function SelectKnowledgeForm(props) {
  const { name, atributoData, setAtributoData, title } = props;
  const [knowledgeData, setKnowledgeData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  //const [knowledgeId, setKnowledgeId] = useState();
  const [other, setOther] = useState(false);

  useEffect(() => {
    getKnowledgesApi().then((response) => {
      setKnowledgeData(response.knowledges);
      //setKnowledgeId(atributoData);
    });
    setLoadingData(false);
  }, [loadingData]);

  // useEffect(() => {
  //   //console.log(values);
  //   if (edit ?? false) {
  //     if (values.knowledgeId === 41) {
  //       setOther(true);
  //     } else {
  //       setOther(false);
  //     }
  //   } else {
  //     setOther(false);
  //   }
  // }, [values]);

  // const handleAtributoChange = (value) => {
  //   setKnowledgeId(value);
  //   setAtributoData(value);
  // };

  const handleAtributoChange = (value) => {
    console.log(value);
    setAtributoData(value ?? 0);
    // if (value === 41) {
    //   setOther(true);
    // } else {
    //   setOther(false);
    // }
  };

  return (
    <div className="select-knowledge-form">
      <Form.Item
        label={title}
        name={name}
        //rules={[{ required: true, message: "Seleccione un conocimiento" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        //style={other && { marginBottom: "5px" }}
        style={{ marginBottom: other ? "5px" : "24px" }}
      >
        <Select
          placeholder="Seleccione un conocimiento"
          //value={knowledgeId}
          //onChange={handleChange}
          onChange={handleAtributoChange}
          //disabled={disabled}
          allowClear={true}
        >
          {knowledgeData && knowledgeData.map
            ? knowledgeData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
