import React, { useState, useEffect } from "react";
import { Select, Form, Input, Col, Row } from "antd";

import { getProvincesApi } from "../../../../api/province.api";
import { GetCityByProvinceApi } from "../../../../api/city.api";
import SelectCityForm from "./SelectCityForm";
import SelectParroquiaForm from "./SelectParroquiaForm";

import "./SelectCityForm.scss";

const { Option } = Select;

export default function SelectCityProvinceForm(props) {
  const {
    name,
    provincename,
    nameOther,
    edit,
    values,
    parroquianame,
    parroquiaOther,
    city,
    parroquia,
    province,
    setCity,
    setParroquia,
    setProvince,
  } = props;
  const [knowledgeData, setKnowledgeData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  //const [knowledgeId, setKnowledgeId] = useState();
  //const [city, setCity] = useState(false);
  const [enabled, setEnabled] = useState(false);
  //const [provinceCityId, setProvinceCityId] = useState();
  const [provinceCityData, setProvinceCityData] = useState({});

  useEffect(() => {
    console.log(values);
    getProvincesApi().then((response) => {
      setProvinceCityData(response.provinces);
      // if (edit && values) {
      //   setProvince(values.provinceId);
      // }
    });
    setLoadingData(false);
  }, [edit, loadingData, values]);

  // useEffect(() => {
  //   console.log(values);

  //   if (edit) {
  //     setProvinceCityId(values.provinceId);
  //   } else {
  //     setProvinceCityId(null);
  //   }
  // }, [values]);

  const handleAtributoChange = (value) => {
    console.log("1d");
    console.log(value);
    setProvince(value);
  };

  return (
    <div className="select-knowledge-form">
      <Row gutter={24}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item
            label="Provincia"
            name={provincename}
            rules={[{ required: true, message: "Seleccione una provincia" }]}
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 24 }}
            //value={provinceCityId}
          >
            <Select
              placeholder="Seleccione una provincia"
              //value={province}
              onChange={handleAtributoChange}
              showSearch
              optionFilterProp="children"
              loading={loadingData}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              //disabled={disabled}
            >
              {provinceCityData && provinceCityData.map
                ? provinceCityData.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <SelectCityForm
            name={name}
            nameOther={nameOther}
            province={province}
            values={values}
            edit={edit}
            setCity={setCity}
            city={city}
            // parroquia={parroquia}
            // setParroquia={setParroquia}
          />
          {/* <Form.Item
            label="Recurso"
            name={name}
            rules={[{ required: true, message: "Seleccione un recurso" }]}
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 24 }}
            //style={other && { marginBottom: "5px" }}
            style={{ marginBottom: other ? "5px" : "24px" }}
            disabled={!enabled}
          >
            <Select
              placeholder="Seleccione un recurso"
              //value={knowledgeId}
              //onChange={handleChange}
              //onChange={handleAtributoChange}
              disabled={!enabled}
            >
              {knowledgeData && knowledgeData.map
                ? knowledgeData.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
          </Form.Item>
          {other && (
            <Form.Item
              //label="Otros"
              name={nameOther}
              rules={[{ required: true, message: "Ingrese el recurso" }]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Recurso"
                //value={userData.name}
                maxLength={200}
              />
            </Form.Item>
          )} */}
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <SelectParroquiaForm
            name={parroquianame}
            nameOther={parroquiaOther}
            city={city}
            values={values}
            edit={edit}
            parroquia={parroquia}
            setParroquia={setParroquia}
          />
        </Col>
      </Row>
    </div>
  );
}
