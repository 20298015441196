import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";
import GoogleMapReact from "google-map-react";

import {
  Button,
  PageHeader,
  Form,
  Row,
  InputNumber,
  Card,
  Breadcrumb,
  Input,
  Col,
  notification,
  DatePicker,
  Skeleton,
  Image,
  Spin,
  Avatar,
} from "antd";
import {
  SaveOutlined,
  UserOutlined,
  PushpinOutlined,
  AimOutlined,
  BulbOutlined,
} from "@ant-design/icons";

import "./Emprendimiento.scss";
//import { getEntrepreneurshipByIdApi } from "../../../api/entrepreneurship.api";
import {
  getEntrepreneurshipByIdApi,
  UpdateEntrepreneurshipApi,
} from "../../../api/entrepreneurship.api";
import { AddOtherApi, getOtherByIdApi } from "../../../api/other.api";

import SelectTypeForm from "../../../components/Admin/Type/SelectTypeForm";
//import SelectSectorForm from "../../../components/Admin/Sector/SelectSectorForm";
import SelectSectorForm from "../../../components/Admin/Sector/SelectSectorForm";
//import SelectCommunityForm from "../../../components/Admin/Community/SelectCommunityForm";
// import SelectCityForm from "../../../components/Admin/City/SelectCityForm/SelectCityForm";
// import SelectProvinceForm from "../../../components/Admin/Province/SelectProvinceForm";
import SelectCityProvinceForm from "../../../components/Admin/City/SelectCityForm/SelectCityProvinceForm";
import SelectSolutionForm from "../../../components/Admin/Solution/SelectSolutionForm";
import { apiKeyGoogle } from "../../../api/config";
import Pin from "../../../assets/img/png/pin.png";

import useAuth from "../../../hooks/useAuth";
import moment from "moment";

const DefaultLocation = { lat: -0.5012984084076219, lng: -76.97750904233827 };
const DefaultZoom = 13;

const Emprendimiento = ({ socket, setReloadPerfil }) => {
  const { user, isLoading } = useAuth(); //hook
  const [formEmprendimiento] = Form.useForm();
  const token = getAccessTokenApi();
  const [reloadEmprendimiento, setReloadEmprendimiento] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const [type, setType] = useState({});
  //const [sector, setSector] = useState();
  const [solution, setSolution] = useState();
  const [loadings, setLoadings] = useState(false);
  const [sector, setSector] = useState();
  const [city, setCity] = useState();
  const [province, setProvince] = useState();
  const [parroquia, setParroquia] = useState();

  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [loadingsMap, setLoadingsMap] = useState(true);
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [searchlocation, setSearchLocation] = useState(false);

  const [SelectTypeControl, SetSelectTypeControl] = useState(
    <Skeleton.Input active={true} />
  );
  const [SelectSectorControl, SetSelectSectorControl] = useState(
    <Skeleton.Input active={true} />
  );
  // const [SelectSolutionControl, SetSelectSolutionControl] = useState(
  //   <Skeleton.Input active={true} />
  // );
  const [SelectProcessControl, SetSelectProcessControl] = useState(
    <Skeleton.Input active={true} />
  );

  const [lastsocket, setLastsocket] = useState();

  useEffect(() => {
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
    socket.on("messageResponse", (data) => {
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
  }, [socket]);

  useEffect(() => {
    getEntrepreneurshipByIdApi(token, user.id).then((response) => {
      setEntrepreneurshipData(response.entrepreneurship);
      console.log(response.entrepreneurship);
      let entrepreneurshipData = response.entrepreneurship;
      if (
        entrepreneurshipData.creation &&
        entrepreneurshipData.creation !== undefined
      ) {
        entrepreneurshipData.creation = moment(entrepreneurshipData.creation);
      }
      formEmprendimiento.setFieldsValue(entrepreneurshipData);

      const arrTypes = [];
      Object.keys(entrepreneurshipData.types).forEach((key) =>
        arrTypes.push(entrepreneurshipData.types[key].id).toString()
      );
      var isOtherTypes = arrTypes.find((element) => {
        return element === 17;
      });
      if (isOtherTypes) {
        getOtherByIdApi(token, response.entrepreneurship.id, "type").then(
          (response) => {
            SetSelectTypeControl(
              <SelectTypeForm
                atributoData={arrTypes}
                setAtributoData={setType}
                deafultValue={response.other.description}
              ></SelectTypeForm>
            );
            console.log(response.other.description);
          }
        );
      } else {
        SetSelectTypeControl(
          <SelectTypeForm
            atributoData={arrTypes}
            setAtributoData={setType}
            deafultValue={""}
          ></SelectTypeForm>
        );
      }

      const arrSectors = [];
      Object.keys(entrepreneurshipData.sectors).forEach((key) =>
        arrSectors.push(entrepreneurshipData.sectors[key].id).toString()
      );
      var isOtherSectors = arrSectors.find((element) => {
        return element === 18;
      });
      if (isOtherSectors) {
        getOtherByIdApi(token, response.entrepreneurship.id, "sector").then(
          (response) => {
            console.log(response);
            SetSelectSectorControl(
              <SelectSectorForm
                atributoData={arrSectors}
                setAtributoData={setSector}
                deafultValue={response.other.description}
              ></SelectSectorForm>
            );
            console.log(response.other.description);
          }
        );
      } else {
        SetSelectSectorControl(
          <SelectSectorForm
            atributoData={arrSectors}
            setAtributoData={setSector}
            deafultValue={""}
          ></SelectSectorForm>
        );
      }

      // if (entrepreneurshipData.solutionId === 7) {
      //   getOtherByIdApi(token, response.entrepreneurship.id, "solution").then(
      //     (response) => {
      //       SetSelectSolutionControl(
      //         <SelectSolutionForm
      //           atributoData={entrepreneurshipData.solutionId}
      //           setAtributoData={setSolution}
      //           deafultValue={response.other.description}
      //         ></SelectSolutionForm>
      //       );
      //       console.log(response.other.description);
      //     }
      //   );
      // } else {
      //   SetSelectSolutionControl(
      //     <SelectSolutionForm
      //       atributoData={entrepreneurshipData.solutionId}
      //       setAtributoData={setSolution}
      //       deafultValue={""}
      //     ></SelectSolutionForm>
      //   );
      // }

      // if (entrepreneurshipData.processId === 7) {
      //   getOtherByIdApi(token, response.entrepreneurship.id, "process").then(
      //     (response) => {
      //       setProcess(entrepreneurshipData.processId);
      //       SetSelectProcessControl(
      //         <SelectProcessForm
      //           atributoData={entrepreneurshipData.processId}
      //           setAtributoData={setProcess}
      //           deafultValue={response.other.description}
      //         ></SelectProcessForm>
      //       );
      //       console.log(response.other.description);
      //     }
      //   );
      // } else {
      //   SetSelectProcessControl(
      //     <SelectProcessForm
      //       atributoData={process}
      //       setAtributoData={setProcess}
      //       deafultValue={""}
      //     ></SelectProcessForm>
      //   );
      // }

      // setGenre(response.user.genreId);
      //setLoadingData(false);
      // setProvince(response.entrepreneurship.provinceId);
      // setCity(response.entrepreneurship.cityId);
      setSolution(response.entrepreneurship.solutionId);
      const initialLocation = {
        lat: response.entrepreneurship.lat,
        lng: response.entrepreneurship.lon,
      };
      setLocation(initialLocation);
      if (loadingsMap) {
        setLoadingsMap(false);
      }
      setLoadingData(false);
    });

    setReloadEmprendimiento(false);
  }, [token, user.id]);

  useEffect(() => {
    console.log(province);
    formEmprendimiento.setFieldsValue({
      cityId: null,
      cityother: null,
      parroquiaId: null,
      parroquiaother: null,
    });
  }, [province]);

  useEffect(() => {
    formEmprendimiento.setFieldsValue({
      parroquiaId: null,
      parroquiaother: null,
    });
  }, [city]);

  const onFinishEmprendimiento = (values) => {
    console.log("Success:", values);
    setLoadings(true);

    // const entrepreneurshipAdd = {
    //   name: values.businessname,
    //   description: values.description,
    //   //invoice: values.invoice ?? false,
    //   creation: values.creation,
    //   partners: values.partner,
    //   //invoiceanual: values.invoiceanual,
    //   //employees: values.employees,
    //   stageId: values.stage,
    //   processId: values.process,
    //   //academyId: values.academy,
    //   userId: userData.id,
    //   communities: values.community,
    //   sectors: values.sector,
    //   types: values.type,
    //   medals: values.medals,
    // };
    //setEntrepreneurshipData(values);

    UpdateEntrepreneurshipApi(values, entrepreneurshipData.id).then(
      (result) => {
        //console.log(result);
        if (result.status) {
          //setEntrepreneurshipData(result.entrepreneurship);

          // values.community.forEach((d) => {
          //   //console.log(d);
          //   AddEntrepreneurshipComunityApi({
          //     entrepreneurshipId: result.entrepreneurship.id,
          //     communityId: d,
          //   });
          // });

          // if (values.communityOther) {
          //   AddOtherApi({
          //     table: "community",
          //     description: values.communityOther,
          //     entrepreneurshipId: entrepreneurshipData.id,
          //   });
          // }

          // if (values.processOther) {
          //   AddOtherApi({
          //     table: "process",
          //     description: values.processOther,
          //     entrepreneurshipId: entrepreneurshipData.id,
          //   });
          // }

          if (values.sectorOther) {
            AddOtherApi({
              table: "sector",
              description: values.sectorOther,
              entrepreneurshipId: entrepreneurshipData.id,
            });
          }

          if (values.typeOther) {
            AddOtherApi({
              table: "type",
              description: values.typeOther,
              entrepreneurshipId: entrepreneurshipData.id,
            });
          }

          // if (values.solutionOther) {
          //   AddOtherApi({
          //     table: "solution",
          //     description: values.solutionOther,
          //     entrepreneurshipId: entrepreneurshipData.id,
          //   });
          // }

          notification["success"]({
            message: result.message,
          });
          //next();
          setLoadings(false);
          setReloadPerfil(true);
        } else {
          notification["error"]({
            message: result.message,
          });
          setLoadings(false);
        }
      }
    );
    //next();
  };

  const onFinishFailEdemprendimiento = (errorInfo) => {
    console.log("Failed:", errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información ingresada",
      duration: 3,
    });
  };

  function currentLocation() {
    setSearchLocation(true);
    var options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    navigator.geolocation.getCurrentPosition(success, error, options);
    // navigator.geolocation.getCurrentPosition(function (position) {
    //   // console.log("Latitude is :", position.coords.latitude);
    //   // console.log("Longitude is :", position.coords.longitude);
    //   setDefaultLocation({
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   });
    //   setLocation({
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   });
    //   setSearchLocation(false);
    // });
  }

  function success(pos) {
    var crd = pos.coords;
    // setDefaultLocation({
    //   lat: crd.latitude,
    //   lng: crd.longitude,
    // });
    setLocation({
      lat: crd.latitude,
      lng: crd.longitude,
    });
    setSearchLocation(false);
    // console.log('Your current position is:');
    // console.log('Latitude : ' + crd.latitude);
    // console.log('Longitude: ' + crd.longitude);
    // console.log('More or less ' + crd.accuracy + ' meters.');
  }

  function error(err) {
    setSearchLocation(false);
    notification["success"]({
      message: `No se puede obtener la ubicación (Error: ${err.message})`,
    });
    // console.warn('ERROR(' + err.code + '): ' + err.message);
  }

  // function handleChangeLocation(ev) {
  //   console.log(ev);
  //   setLocation({ lat: ev.lat, lng: ev.lng });
  // }

  function handleChangeLocation(coords) {
    console.log(coords.lat);
    console.log(coords.lng);
    setLocation({ lat: coords.lat, lng: coords.lng });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  return (
    <div className="emprendimiento">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Proyecto</Breadcrumb.Item>
        <Breadcrumb.Item>Perfil</Breadcrumb.Item>
        <Breadcrumb.Item>Datos de la empresa</Breadcrumb.Item>
      </Breadcrumb>
      <Form
        name="emprendimiento"
        form={formEmprendimiento}
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        initialValues={{ remember: false }}
        onFinish={onFinishEmprendimiento}
        onFinishFailed={onFinishFailEdemprendimiento}
        autoComplete="off"
      >
        <PageHeader
          className="site-page-header header-left"
          // onBack={() => null}
          style={{ textAlign: "left", justifyContent: "start" }}
          title={
            <>
              <Avatar
                icon={<BulbOutlined />}
                style={{ backgroundColor: "#f42c1e", color: "white" }}
              />
              <span> Empresa</span>
            </>
          }
          // subTitle="This is a subtitle"
        />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={6} lg={5} xl={5}>
            <Form.Item
              label="Nombre de la organización"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="name"
              rules={[
                {
                  required: true,
                  message: "Ingrese el nombre de la organización",
                },
              ]}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Nombre de la organización"
                //value={userData.name}
                maxLength={50}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={5} xl={5}>
            <Form.Item
              label="Nombre del proyecto sostenible"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="projectname"
              rules={[
                {
                  required: true,
                  message: "Ingrese el nombre del proyecto sostenible",
                },
              ]}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Nombre del proyecto sostenible"
                //value={userData.name}
                maxLength={50}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              label="Breve descripción"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Ingrese una breve descripción",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Breve descripción"
                maxLength={300}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={4} xl={4}>
            <Form.Item
              label="Fecha de inicio del proyecto en Ecuador"
              name="creation"
              rules={[
                {
                  required: true,
                  message:
                    "Seleccione la fecha de inicio del proyecto en Ecuador",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={8} lg={5} xl={5}>
            <SelectProvinceForm
              atributoData={province}
              setAtributoData={setProvince}
            ></SelectProvinceForm>
          </Col>
          <Col xs={24} sm={24} md={8} lg={5} xl={5}>
            <SelectCityForm
              atributoData={city}
              provinceData={province}
              setAtributoData={setCity}
            ></SelectCityForm>
          </Col> */}
          <Col xs={24} sm={24} md={12} lg={10} xl={10}>
            <SelectCityProvinceForm
              name={"cityId"}
              provincename={"provinceId"}
              nameOther={"cityother"}
              edit={true}
              values={entrepreneurshipData}
              parroquianame={"parroquiaId"}
              parroquiaOther={"parroquiaother"}
              city={city}
              province={province}
              parroquia={parroquia}
              setCity={setCity}
              setProvince={setProvince}
              setParroquia={setParroquia}
            ></SelectCityProvinceForm>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              label="Dirección del proyecto"
              name="direction"
              rules={[
                {
                  required: true,
                  message: "Ingrese la dirección del proyecto",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Dirección del proyecto"
                maxLength={300}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item
              label="Universidad - Institución educativa"
              name="university"
              rules={[
                {
                  required: true,
                  message: "Ingrese la Universidad - Institución educativa",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Universidad - Institución educativa"
                maxLength={300}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {SelectTypeControl}
          </Col> */}
          {/* <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {SelectSectorControl}
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <SelectStageForm
              atributoData={stage}
              setAtributoData={setStage}
            ></SelectStageForm>
          </Col> */}
          {/* <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              label="Número de socios"
              name="partner"
              rules={[
                {
                  required: true,
                  message: "Ingrese el número de socios",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <InputNumber
                //prefix={<UserOutlined />}
                placeholder="Número de socios"
                maxLength={4}
                //value={userData.name}
                //defaultValue={1999}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col> */}

          {/* <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {SelectProcessControl}
          </Col> */}
          {/* <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {SelectCommunityControl}
          </Col> */}
          {/* <Col xs={24} sm={24} md={12} lg={16} xl={16}>
            <Form.Item
              label="Logros - Reconocimientos del emprendimiento"
              name="medals"
              // rules={[
              //   {
              //     required: true,
              //     message:
              //       "Ingrese los logros - reconocimientos de tu emprendimiento",
              //   },
              // ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Logros - Reconocimientos"
                maxLength={300}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col> */}
          {/* <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <SelectAcademyForm
                atributoData={academy}
                setAtributoData={setAcademy}
              ></SelectAcademyForm>
            </Col> */}

          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            {SelectSectorControl}
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            {SelectTypeControl}
          </Col>
          {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            {SelectSolutionControl}
          </Col> */}
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <SelectSolutionForm
              atributoData={solution}
              setAtributoData={setSolution}
            ></SelectSolutionForm>
          </Col>

          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item
              label="Ruc de la empresa"
              name="ruc"
              rules={[
                {
                  required: true,
                  message: "Ingrese el Ruc de la empresa",
                },
                {
                  type: "string",
                  min: 13,
                  message: "El RUC de su empresa no es correcta",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Ruc de la empresa"
                maxLength={13}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                name="invoice"
                valuePropName="checked"
                label="¿Estás facturando?"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Checkbox checked={checkNick} onChange={onCheckboxChange}>
                  Seleccione si ya estás entregando facturas
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={5} xl={5}>
              {checkNick && (
                <Form.Item
                  name="invoiceanual"
                  label="¿Cuánto facturas por año?"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: checkNick,
                      message: "Ingrese las facturas por año",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Ingrese las facturas por año"
                    maxLength={6}
                  />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} sm={24} md={12} lg={5} xl={5}>
              {checkNick && (
                <Form.Item
                  name="employees"
                  label="Número de empleados"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: checkNick,
                      message: "Ingrese el número de empleados",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Número de empleados"
                    maxLength={6}
                  />
                </Form.Item>
              )}
            </Col>
          </Row> */}

        <PageHeader
          className="site-page-header header-left"
          // onBack={() => null}
          style={{ textAlign: "left", justifyContent: "start" }}
          // onBack={() => null}
          title={
            <>
              <PushpinOutlined style={{ fontSize: "30px" }} />{" "}
              <span> Ubicación</span>
            </>
          }
          // subTitle="This is a subtitle"
        />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card
              title={
                <span>
                  Por favor, en el mapa presione en su ubicación de contacto
                  para mover el puntero
                </span>
              }
              extra={
                <Button
                  type="dashed"
                  onClick={() => currentLocation()}
                  icon={<AimOutlined />}
                  size="small"
                  className="mini"
                  loading={searchlocation}
                >
                  buscar mi ubicación
                </Button>
              }
              bodyStyle={{ padding: "0" }}
              //style={{ height: "300px", width: "100%" }}
            >
              <div style={{ height: "300px", width: "100%" }}>
                <GoogleMapReact
                  defaultZoom={zoom}
                  defaultCenter={defaultLocation}
                  bootstrapURLKeys={{
                    key: apiKeyGoogle,
                    language: "es",
                    region: "ec",
                    libraries: [],
                  }}
                  onClick={handleChangeLocation}
                  center={location}
                >
                  {!loadingData ? (
                    <Image
                      width={48}
                      src={Pin}
                      lat={location.lat}
                      lng={location.lng}
                      preview={false}
                      style={{ marginTop: "-48px", marginLeft: "-24px" }}
                    />
                  ) : (
                    // <GoogleMapReact.Marker
                    //   key={"mylocation"}
                    //   text={"Mi ubicación"}
                    //   lat={location.lat}
                    //   lng={location.lng}
                    // />
                    <Spin width={100} lat={location.lat} lng={location.lng} />
                  )}
                </GoogleMapReact>
              </div>
            </Card>
          </Col>
        </Row>
        <br></br>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loadings}
                className="btnBig"
              >
                Guardar <SaveOutlined />
              </Button>
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button type="deafult">
                  <ArrowLeftOutlined /> Anteior
                </Button>
              </Form.Item>
            </Col> */}
        </Row>
      </Form>
    </div>
  );
};

export default Emprendimiento;
