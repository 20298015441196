import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Form, Card, Row, Col } from "antd";
import { getAccessTokenApi } from "../../../api/auth";
//import Pin from "../../../assets/img/png/pin.png";
//import { apiKeyGoogle } from "../../../api/config";
import { getUsersMapApi } from "../../../api/user.api";
//import GoogleMapReact from "google-map-react";
//import styled from "styled-components";
//import NewMarker from "../../../components/Common/newmarker";
//import Map from "../../../components/Common/Map";
import MapContainer from "../../../components/Common/MapContainer";

import "./Indicadores.scss";
import SelectSectorAllForm from "../../../components/Admin/Sector/SelectSectorForm/SelectSectorAllForm";
import SelectCommunityAllForm from "../../../components/Admin/Community/SelectCommunityForm/SelectCommunityAllForm";
import SelectTypeAllForm from "../../../components/Admin/Type/SelectTypeForm/SelectTypeAllForm";
import SelectResidualAllForm from "../../../components/Admin/Residual/SelectResidualForm/SelectResidualAllForm";
//import { LoadScript } from "@react-google-maps/api";
// import {
//   GoogleMap,
//   MarkerClusterer,
//   useJsApiLoader,
// } from "@react-google-maps/api";

//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

//import { getNeedGroupApi } from "../../../api/need.api";
//import Pin from "../../../assets/img/png/pin.png";
const DefaultLocation = { lat: -1.831239, lng: -78.183406 };
// const DefaultZoom = 12;
// const {
//   MarkerClusterer,
// } = require("react-google-maps/lib/components/addons/MarkerClusterer");

export default function MapaUsuario() {
  const token = getAccessTokenApi();
  //const { RangePicker } = DatePicker;
  //const [data, setData] = useState([]);
  //const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  //const [location, setLocation] = useState(defaultLocation);
  //const [zoom, setZoom] = useState(DefaultZoom);
  //const [searchlocation, setSearchLocation] = useState(false);
  //const [users, setUsers] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [locations, setlocations] = useState([]);
  //const [heatmapVisible, sethHeatmapVisible] = useState(false);
  const [sector, setSector] = useState(0);
  const [stage, setStage] = useState(0);
  const [community, setCommunity] = useState(0);
  const [type, setType] = useState(0);
  const [residual, setResidual] = useState(0);
  // const [markers, setMarkers] = useState();
  // const [markers1, setMarkers1] = useState();
  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: apiKeyGoogle,
  //   libraries: ["geometry", "drawing", "places", "visualization"],
  // });

  //const [map, setMap] = useState(null);
  // const [info, setInfo] = useState({
  //   key: 0,
  //   name: "",
  //   lastname: "",
  //   email: "",
  //   emprendimiento: "",
  //   perfil: "",
  //   ciudad: "",
  // });
  // const [showinfo, setShowinfo] = useState(false);

  //const google = window.google;
  // const {
  //   InfoBox,
  // } = require("react-google-maps/lib/components/addons/InfoBox");
  //const ref = useRef();
  //const clustererRef = useRef();
  // var CMap = withScriptjs(
  //   withGoogleMap((props) => (
  //     <GoogleMap
  //       defaultZoom={DefaultZoom}
  //       defaultCenter={DefaultLocation}
  //       //libraries={["visualization"]}
  //     >
  //       {/* {props.children} */}
  //       <MarkerClusterer
  //         onLoad={(clusterer) => (clustererRef.current = clusterer)}
  //       >
  //         {props.markers}
  //       </MarkerClusterer>
  //     </GoogleMap>
  //   ))
  // );

  useEffect(() => {
    //setLoadingData(true);
    getUsersMapApi(token, type, community, residual, sector, false, false).then(
      (response) => {
        // console.log(stage);
        // console.log(sector);
        // console.log(response.length);
        //setUsers(response.users);

        const data1 = [];
        let users1 = response.users ?? [];
        users1.map((user) =>
          data1.push({
            location: {
              lat: user.entrepreneurships[0]?.lat,
              lng: user.entrepreneurships[0]?.lon,
            },
            name: user.name,
            lastname: user.lastname,
            email: user.email,
            emprendimiento: user.entrepreneurships[0]?.name,
            perfil: user.rolename,
            ciudad: user.entrepreneurships[0]?.city.name,
            id: user.id,
          })
        );
        setlocations(data1);

        setLoadingData(false);
      }
    );
  }, [token, sector, type, residual, community]);

  // function mapFitBounds() {
  //   // console.log("mapFitBounds:map> ", map);
  //   if (!map || !users) return;

  //   const bounds = new window.google.maps.LatLngBounds();
  //   users.map((loc) => {
  //     bounds.extend(new window.google.maps.LatLng(loc.lat, loc.lon));
  //   });

  //   map.fitBounds(bounds);
  // }

  // React.useEffect(() => {
  //   if (map) {
  //     // map.panTo(...)
  //     mapFitBounds();
  //   }
  // }, [map]);

  // const toggleHeatMap = () => {
  //   sethHeatmapVisible(!heatmapVisible);
  //   // if (ref.current !== undefined) {
  //   //   ref.current.heatmap.setMap(!heatmapVisible ? ref.current.map_ : null);
  //   // }
  // };

  // const config = {
  //   heatmap: {
  //     positions: heatmapVisible,
  //     options: { radius: 40, opacity: 0.8 },
  //   },
  // };

  // const onLoad = React.useCallback(function callback(map) {
  //   // const bounds = new window.google.maps.LatLngBounds(defaultLocation);
  //   // //map.fitBounds(bounds);
  //   // map.setZoom(zoom);
  //   setMap(map);
  // }, []);

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null);
  // }, []);

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Mapa</Breadcrumb.Item>
        <Breadcrumb.Item>Todos los usuarios</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <Form
          name="filters"
          initialValues={{ remember: false }}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectCommunityAllForm
                atributoData={community}
                setAtributoData={setCommunity}
              ></SelectCommunityAllForm>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectSectorAllForm
                atributoData={sector}
                setAtributoData={setSector}
              ></SelectSectorAllForm>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectTypeAllForm
                atributoData={type}
                setAtributoData={setType}
              ></SelectTypeAllForm>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectResidualAllForm
                atributoData={residual}
                setAtributoData={setResidual}
              ></SelectResidualAllForm>
            </Col>

            {/* <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectTypeAllForm
                atributoData={stage}
                setAtributoData={setStage}
              ></SelectTypeAllForm>
            </Col> */}
          </Row>
        </Form>

        <Card
          title={<span>Mapa de todo el ecosistema</span>}
          bodyStyle={{ padding: "0" }}
        >
          {!loadingData ? (
            <MapContainer
              defaultCenter={DefaultLocation}
              locations={locations}
            />
          ) : (
            <></>
          )}
        </Card>
      </div>
    </div>
  );
}
