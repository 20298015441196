import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";
import { useNavigate } from "react-router-dom";

import { Table, Button, Empty, Breadcrumb, Affix } from "antd";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import TeamOutlined from "@ant-design/icons/TeamOutlined";

import "./Eventos.scss";
import { getEventsApi } from "../../../api/event.api";

import ModalUser from "../../../components/Admin/ModalUser";
import EditEventForm from "../../../components/Admin/Event/EditEventForm";
import moment from "moment";

export default function Event() {
  const [event, setEvent] = useState([]);
  const token = getAccessTokenApi();
  const [reloadEvents, setReloadEvents] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const navigate = useNavigate();
  const url = window.location.origin;

  useEffect(() => {
    getEventsApi(token, true).then((response) => {
      setEvent(response.events);
      setLoadingData(false);
    });

    setReloadEvents(false);
  }, [token, reloadEvents]);

  const addEvent = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nuevo evento");
    setmodalContent(
      <EditEventForm
        event={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadEvents={setReloadEvents}
        buttonName="Agregar evento"
        addSlag={"add"}
      />
    );
  };

  const editEvent = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar evento");
    setmodalContent(
      <EditEventForm
        event={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadEvents={setReloadEvents}
        buttonName="Editar evento"
        addSlag={"edit"}
      />
    );
  };

  const deleteEvent = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar evento");
    setmodalContent(
      <EditEventForm
        event={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadEvents={setReloadEvents}
        buttonName="Eliminar evento"
        addSlag={"delete"}
      />
    );
  };

  const columns = [
    {
      title: "Nombre",
      width: 200,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Descripción",
      width: 200,
      dataIndex: "description",
      key: "description",
      fixed: "left",
    },
    {
      title: "Fecha del evento",
      width: 100,
      dataIndex: "eventday",
      key: "eventday",
      fixed: "left",
      render: (text) => <span> {moment(text).format("YYYY-MM-DD")}</span>,
    },
    {
      title: "Ciudad",
      width: 100,
      dataIndex: ["city", "name"],
      key: "cityId",
      fixed: "left",
    },
    {
      title: "Url",
      width: 120,
      dataIndex: "url",
      key: "url",
      fixed: "left",
      render: (text) => (
        <a target="_blank" href={url + "/networking/" + text} rel="noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Registros",
      width: 100,
      dataIndex: "entrepreneurships",
      key: "entrepreneurships",
      fixed: "left",
      render: (text, record) => (
        <>{record.entrepreneurships.length} registro/s</>
      ),
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 120,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editEvent(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            className="margin-right-minimun"
            onClick={() => deleteEvent(record)}
          >
            <DeleteOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() =>
              navigate(`/admin/events/users/${record.url}/${record.id}`)
            }
          >
            <TeamOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="event">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Eventos</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addEvent}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={event}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen eventos"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={600}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
