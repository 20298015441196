import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";

import {
  Table,
  Button,
  Empty,
  Breadcrumb,
  Affix,
  Row,
  Col,
  Form,
  PageHeader,
  Checkbox,
  notification,
  Skeleton,
  Avatar,
} from "antd";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import ForkOutlined from "@ant-design/icons/ForkOutlined";
import SaveOutlined from "@ant-design/icons/SaveOutlined";
import ApiOutlined from "@ant-design/icons/ApiOutlined";

import "./Need.scss";
import { getNeedsApi } from "../../../api/need.api";

import ModalUser from "../../../components/Admin/ModalUser";
import EditNeedForm from "../../../components/Home/User/Need/EditNeedForm";
import {
  getEntrepreneurshipByIdApi,
  UpdateEntrepreneurshipNeedsApi,
} from "../../../api/entrepreneurship.api";
import useAuth from "../../../hooks/useAuth";

import SelectResourceForm from "../../../components/Admin/Resource/SelectResourceForm";
import SelectSupplierForm from "../../../components/Admin/Supplier/SelectSupplierForm";

import { AddOtherApi, getOtherByIdApi } from "../../../api/other.api";
import SelectRevenueForm from "../../../components/Admin/Revenue/SelectRevenueForm";

export default function Need({ socket, setReloadPerfil }) {
  const [needs, setNeeds] = useState([]);
  const token = getAccessTokenApi();
  const [reloadNeeds, setReloadNeeds] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const { user } = useAuth();
  const [checkPartner, setCheckPartner] = useState(false);
  const [resource, setResource] = useState();
  const [supplier, setSupplier] = useState();
  const [loadings, setLoadings] = useState(false);
  const [formYonecesito] = Form.useForm();
  const [revenue4, setRevenue4] = useState();
  const [selectSupplierControl, SetSelectSupplierControl] = useState(
    <Skeleton.Input active={true} />
  );
  const [selectResourceControl, SetSelectResourceControl] = useState(
    <Skeleton.Input active={true} />
  );

  const onCheckboxPartnetChange = (e) => {
    setCheckPartner(e.target.checked);
  };

  const [lastsocket, setLastsocket] = useState();

  useEffect(() => {
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
    socket.on("messageResponse", (data) => {
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
  }, [socket]);

  useEffect(() => {
    getEntrepreneurshipByIdApi(token, user.id).then((response) => {
      setEntrepreneurshipData(response.entrepreneurship);

      //setCheckPartner(true);
      // const arrSuppliers = [];
      //console.log(response.entrepreneurship.suppliers);
      // Object.keys(response.entrepreneurship.suppliers).forEach((key) =>
      //   arrSuppliers
      //     .push(response.entrepreneurship.suppliers[key].id)
      //     .toString()
      // );
      // var isOtherS = arrSuppliers.find((element) => {
      //   return element === 9;
      // });
      // if (isOtherS) {
      //   getOtherByIdApi(token, response.entrepreneurship.id, "supplier").then(
      //     (response) => {
      //       SetSelectSupplierControl(
      //         <SelectSupplierForm
      //           atributoData={arrSuppliers}
      //           setAtributoData={setSupplier}
      //           supplierValue={response.other.description}
      //         ></SelectSupplierForm>
      //       );
      //       console.log(response.other.description);
      //     }
      //   );
      // } else {
      //   SetSelectSupplierControl(
      //     <SelectSupplierForm
      //       atributoData={arrSuppliers}
      //       setAtributoData={setSupplier}
      //       supplierValue={""}
      //     ></SelectSupplierForm>
      //   );
      // }
      // const arrResources = [];
      // Object.keys(response.entrepreneurship.resources).forEach((key) =>
      //   arrResources
      //     .push(response.entrepreneurship.resources[key].id)
      //     .toString()
      // );

      // var isOther = arrResources.find((element) => {
      //   return element === 4;
      // });
      // if (isOther) {
      //   getOtherByIdApi(token, response.entrepreneurship.id, "resource").then(
      //     (response) => {
      //       SetSelectResourceControl(
      //         <SelectResourceForm
      //           atributoData={arrResources}
      //           setAtributoData={setSupplier}
      //           resourceValue={response.other.description}
      //         ></SelectResourceForm>
      //       );
      //       console.log(response.other.description);
      //     }
      //   );
      // } else {
      //   SetSelectResourceControl(
      //     <SelectResourceForm
      //       atributoData={arrResources}
      //       setAtributoData={setSupplier}
      //       resourceValue={""}
      //     ></SelectResourceForm>
      //   );
      // }

      // SetSelectResourceControl(
      //   <SelectResourceForm
      //     atributoData={arrResources}
      //     setAtributoData={setSupplier}
      //     resourceValue={""}
      //   ></SelectResourceForm>
      // );

      getNeedsApi(token, response.entrepreneurship.id).then((response) => {
        console.log(response.needs);
        setNeeds(response.needs);
        setLoadingData(false);
        setReloadPerfil(true);
      });
    });

    setReloadNeeds(false);
  }, [token, reloadNeeds, user.id]);

  // useEffect(() => {
  //   formYonecesito.setFieldsValue(entrepreneurshipData);
  //   console.log(entrepreneurshipData.partners ?? false);

  //   setCheckPartner(entrepreneurshipData.partners ?? false);
  // }, [entrepreneurshipData]);

  const addNeed = () => {
    // if (needs.length >= 3) {
    //   notification["error"]({
    //     message: "Puedes agregar hasta 3 recursos que necesites",
    //     duration: 3,
    //   });
    //   return false;
    // }
    setIsVisibleModal(true);
    setModalTitle("Agregar recurso que necesito");
    setmodalContent(
      <EditNeedForm
        need={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadNeeds={setReloadNeeds}
        buttonName="Agregar recurso"
        addSlag={"add"}
        entrepreneurshipData={entrepreneurshipData}
      />
    );
  };

  const editNeed = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar recurso que necesito");
    setmodalContent(
      <EditNeedForm
        need={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadNeeds={setReloadNeeds}
        buttonName="Editar recurso"
        addSlag={"edit"}
        entrepreneurshipData={entrepreneurshipData}
      />
    );
  };

  const deleteNeed = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar recurso que necesito");
    setmodalContent(
      <EditNeedForm
        need={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadNeeds={setReloadNeeds}
        buttonName="Eliminar recurso"
        addSlag={"delete"}
        entrepreneurshipData={entrepreneurshipData}
      />
    );
  };

  const columns = [
    {
      title: "Código",
      width: 50,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      responsive: ["md"],
    },
    {
      title: "Tipo",
      width: 150,
      dataIndex: ["knowledgetype", "name"],
      key: "knowledgetypeId",
    },
    {
      title: "Otro tipo",
      width: 100,
      dataIndex: "othertype",
      key: "othertype",
      responsive: ["md"],
    },
    {
      title: "Recurso",
      width: 150,
      dataIndex: ["knowledge", "name"],
      key: "knowledgeId",
    },
    {
      title: "Otro recurso",
      width: 100,
      dataIndex: "other",
      key: "other",
      responsive: ["md"],
    },
    {
      title: "Descripción",
      width: 300,
      dataIndex: "description",
      key: "description",
      responsive: ["md"],
    },
    {
      title: "Valor estimado",
      width: 130,
      dataIndex: ["revenue", "name"],
      key: "revenueId",
    },
    {
      title: "Acuerdo",
      width: 130,
      dataIndex: ["agreement", "name"],
      key: "agreementId",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 120,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editNeed(record)}
          >
            <EditOutlined />
          </Button>
          <Button size="small" type="danger" onClick={() => deleteNeed(record)}>
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const onFinishYonecesito = (values) => {
    setLoadings(true);

    console.log({ ...values, needpartner: values.partners });
    UpdateEntrepreneurshipNeedsApi(
      { ...values, needpartner: values.partners },
      entrepreneurshipData.id
    ).then((result) => {
      if (result.status) {
        if (values.resourceOther) {
          AddOtherApi({
            table: "resource",
            description: values.resourceOther,
            entrepreneurshipId: entrepreneurshipData.id,
          });
        }

        if (values.supplierOther) {
          AddOtherApi({
            table: "supplier",
            description: values.supplierOther,
            entrepreneurshipId: entrepreneurshipData.id,
          });
        }

        notification["success"]({
          message: "Recursos para crecer actualizados correctamente",
        });
        setLoadings(false);
      } else {
        notification["error"]({
          message: result.message,
        });
        setLoadings(false);
      }
    });
  };

  const onFinishFailYonecesito = (errorInfo) => {
    console.log("Failed:", errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información ingresada",
      duration: 3,
    });
  };

  return (
    <div className="need">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Perfil</Breadcrumb.Item>
        <Breadcrumb.Item>Yo necesito</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        className="site-page-header header-left"
        // onBack={() => null}
        style={{ textAlign: "left", justifyContent: "start" }}
        // onBack={() => null}
        //style={{ textAlign: "center" }}
        title={
          <>
            <Avatar
              icon={<ApiOutlined />}
              style={{ backgroundColor: "#f42c1e", color: "white" }}
            />
            <span> Recurso que necesito</span>
            <br></br>
            <span style={{ fontSize: "14px" }}>
              Recursos que necesite para buscar en el ecosistema.
            </span>
            <br></br>
            <span style={{ fontSize: "12px", fontWeight: "normal" }}>
              Recursos (Administrativos, operativos, financieros, comunicación,
              marketing...)
            </span>
          </>
        }
        // subTitle="This is a subtitle"
      />
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addNeed}>
          Agregar recurso que necesito
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={needs}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No tienes recursos registrados"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={1200}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
