import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Menu, Tooltip } from "antd";

import HomeOutlined from "@ant-design/icons/HomeOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import ArrowRightOutlined from "@ant-design/icons/ArrowRightOutlined";
import PieChartOutlined from "@ant-design/icons/PieChartOutlined";
import FileTextOutlined from "@ant-design/icons/FileTextOutlined";
import GlobalOutlined from "@ant-design/icons/GlobalOutlined";
import ShareAltOutlined from "@ant-design/icons/ShareAltOutlined";

//import FileOutlined from "@ant-design/icons/FileOutlined";

// import BankOutlined from "@ant-design/icons/BankOutlined";
// import BarsOutlined from "@ant-design/icons/BarsOutlined";

import "./MenuSider.scss";

function MenuSiderUser(props) {
  const { Sider } = Layout;
  //const { SubMenu } = Menu;
  const { menuCollapsed } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [key, setKey] = useState(location.pathname);
  const [group, setGroup] = useState([]);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem("Dashboard", "/admin", <HomeOutlined />),
    getItem("Registros", "/admin/user/registros", <UserOutlined />, [
      getItem("Listado", "/admin/users", <ArrowRightOutlined />),
    ]),
    getItem("Reportes", "/admin/reportes", <FileTextOutlined />, [
      getItem("Estado registros", "/admin/registros", <ArrowRightOutlined />),
    ]),
    getItem("Gráficas", "/admin/graficas", <PieChartOutlined />, [
      getItem("Tipo de actor", "/admin/sectores", <ArrowRightOutlined />),
      //getItem("Etapas", "/admin/etapas", <ArrowRightOutlined />),
      getItem("Provincias", "/admin/provincias", <ArrowRightOutlined />),
      getItem("Ciudades", "/admin/ciudades", <ArrowRightOutlined />),
      getItem("Parroquias", "/admin/parroquias", <ArrowRightOutlined />),
      getItem("Recursos", "/admin/conocimientos", <ArrowRightOutlined />),
      getItem("Oferta y demanda", "/admin/oferta", <ArrowRightOutlined />),
      getItem(
        "Sostenibilidad",
        "/admin/sostenibilidad",
        <ArrowRightOutlined />
      ),
      getItem("Conexiones", "/admin/conexiones", <ArrowRightOutlined />),
      //getItem("Capital y socios", "/admin/capital", <ArrowRightOutlined />),
    ]),
    getItem("Mapas", "/admin/mapas", <GlobalOutlined />, [
      getItem(
        "Todos los usuarios",
        "/admin/mapusuario",
        <ArrowRightOutlined />
      ),
      // getItem(
      //   "Emprendimientos",
      //   "/admin/mapemprendimiento",
      //   <ArrowRightOutlined />
      // ),
    ]),
    getItem("Eventos", "/admin/eventos", <ShareAltOutlined />, [
      getItem("Administrar", "/admin/eventos/admin", <ArrowRightOutlined />),
      // getItem(
      //   "Emprendimientos",
      //   "/admin/mapemprendimiento",
      //   <ArrowRightOutlined />
      // ),
    ]),
    // getItem("Diagnóstico", "/admin/diagnosticos", <DashboardOutlined />, [
    //   getItem("General", "/admin/diagnostico", <ArrowRightOutlined />),
    //   getItem(
    //     "Habilidades blandas",
    //     "/admin/diagnosticoblandas",
    //     <ArrowRightOutlined />
    //   ),
    //   getItem(
    //     "Etapas del emprendimiento",
    //     "/admin/diagnosticoetapas",
    //     <ArrowRightOutlined />
    //   ),
    //   getItem("Facturación", "/admin/facturacion", <ArrowRightOutlined />),
    //   getItem("Empleo", "/admin/empleo", <ArrowRightOutlined />),
    //   getItem("Inversión", "/admin/inversion", <ArrowRightOutlined />),
    // ]),
  ];
  const onClick = (e) => {
    console.log("click ", e);
    setGroup(e.keyPath);
    setKey(e.key);
    navigate(e.key);
    // if (e.key === "/admin/mapusuario" || e.key === "/admin/mapemprendimiento") {
    //   setGroup("/admin/mapas");
    //   navigate(0);
    // }
  };
  //console.log(location);
  return (
    <Sider width={256} theme="light" collapsed={menuCollapsed}>
      <Menu
        onClick={onClick}
        style={{
          marginTop: 65,
        }}
        //inlineCollapsed={false}
        defaultSelectedKeys={[key]}
        defaultOpenKeys={group}
        mode="inline"
        items={items}
      />
    </Sider>
  );
}

export default MenuSiderUser;
