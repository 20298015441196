import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";

import {
  Button,
  PageHeader,
  Form,
  Row,
  Breadcrumb,
  Input,
  Col,
  notification,
  Avatar,
} from "antd";
import { SaveOutlined, LockOutlined } from "@ant-design/icons";

import "./Change.scss";
import { ChangePassUserApi } from "../../../api/user.api";

import useAuth from "../../../hooks/useAuth";

const Change = ({ socket }) => {
  const { user, isLoading } = useAuth(); //hook
  const [formChange] = Form.useForm();
  const token = getAccessTokenApi();
  const [loadings, setLoadings] = useState(false);
  const [lastsocket, setLastsocket] = useState();

  useEffect(() => {
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
    socket.on("messageResponse", (data) => {
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
  }, [socket]);

  const onFinish = (values) => {
    setLoadings(true);

    ChangePassUserApi(
      token,
      { password: values.password, passwordNew: values.passwordNew },
      user.id
    ).then((result) => {
      if (result.status) {
        notification["success"]({
          message: result.message,
        });
        setLoadings(false);
        formChange.resetFields();
      } else {
        notification["error"]({
          message: result.message,
        });
        setLoadings(false);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revisa los errores en el formulario de cambio de contraseña",
      duration: 3,
    });
  };

  return (
    <div className="change">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Reciclaje</Breadcrumb.Item>
        <Breadcrumb.Item>Perfil</Breadcrumb.Item>
        <Breadcrumb.Item>Cambio de contraseña</Breadcrumb.Item>
      </Breadcrumb>
      <Form
        form={formChange}
        name="change"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <PageHeader
          className="site-page-header header-left"
          // onBack={() => null}
          style={{ textAlign: "left", justifyContent: "start" }}
          title={
            <>
              <Avatar
                icon={<LockOutlined />}
                style={{ backgroundColor: "#f42c1e", color: "white" }}
              />
              <span> Cambio de contraseña</span>
            </>
          }
          // subTitle="This is a subtitle"
        />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              label="Contraseña actual"
              name="password"
              rules={[
                { required: true, message: "Ingrese su contraseña actual" },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input.Password
                placeholder="Contraseña actual"
                maxLength={50}
                //value={userData.name}
                onChange={(e) => {}}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              label="Contraseña nueva"
              name="passwordNew"
              rules={[
                { required: true, message: "Ingrese su contraseña nueva" },
                {
                  min: 6,
                  message: "Su contraseña debe tener al menos 6 caracteres",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              hasFeedback
            >
              <Input.Password
                placeholder="Contraseña nueva"
                maxLength={50}
                //value={userData.name}
                onChange={(e) => {}}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              label="Repita su contraseña nueva"
              name="passwordNewrepeat"
              dependencies={["passwordNew"]}
              rules={[
                { required: true, message: "Repita su contraseña nueva" },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("passwordNew") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error("Las contraseñas nuevas no son iguales!")
                    );
                  },
                }),
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              hasFeedback
            >
              <Input.Password
                placeholder="Repite tu contraseña nueva"
                maxLength={50}
                //value={userData.name}
                onChange={(e) => {}}
              />
            </Form.Item>
          </Col>
        </Row>

        <br></br>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                className="btnBig"
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loadings}
              >
                Guardar <SaveOutlined />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Change;
