import React from "react";
//import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import "antd/dist/antd.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import esEs from "antd/lib/locale/es_ES";
import "moment/locale/es-mx";
//import "./theme/theme.less";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ConfigProvider locale={esEs}>
    <App />
  </ConfigProvider>
  // </React.StrictMode>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <ConfigProvider locale={esEs}>
//       <App />
//     </ConfigProvider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
