import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";

import {
  Space,
  Breadcrumb,
  notification,
  Badge,
  Calendar,
  Tabs,
  List,
  Empty,
  Button,
  Avatar,
  Card,
  Tag,
  Modal,
  Rate,
  Radio,
} from "antd";
import {
  LikeOutlined,
  CalendarOutlined,
  UserOutlined,
  DislikeOutlined,
} from "@ant-design/icons";

import "./Calendar.scss";
import {
  getMeetingsApi,
  UpdateMessageStatusApi,
} from "../../../api/message.api";
import { AddRateApi } from "../../../api/rate.api";

import { getHavesApi } from "../../../api/have.api";
import { getNeedsApi } from "../../../api/need.api";
import { getHaveresidualsApi } from "../../../api/haveresidual.api";
import { getNeedresidualsApi } from "../../../api/needresidual.api";
import { getEntrepreneurshipByIdApi } from "../../../api/entrepreneurship.api";

import useAuth from "../../../hooks/useAuth";
import moment from "moment";

const CalendarUser = ({ socket }) => {
  const { user, isLoading } = useAuth(); //hook
  //const [formChange] = Form.useForm();
  const token = getAccessTokenApi();
  const [loadings, setLoadings] = useState(true);
  const [lastsocket, setLastsocket] = useState();
  const [meet, setMeet] = useState();
  const { TabPane } = Tabs;
  const { Meta } = Card;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  //const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalText2, setModalText2] = useState("");
  const [user1, setUser1] = useState("");
  const [user2, setUser2] = useState("");
  const [actor, setActor] = useState(0);
  const [rate, setRate] = useState(3);
  const [resource, setResource] = useState(0);
  const [resourceSelected, setResourceSelected] = useState(0);
  const [observations, setObservations] = useState("");
  const [message, setMessage] = useState();
  const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const options = [
    {
      label: "Yo entregué recursos",
      value: 1,
    },
    {
      label: "Yo recibí recursos",
      value: 2,
    },
  ];
  const options2 = [
    {
      label: "Recurso empresarial",
      value: 1,
    },
    {
      label: "Recurso residual",
      value: 2,
    },
  ];
  const [options3, setOptions3] = useState([
    {
      label: "Complete las preguntas 1 y 2",
      value: 0,
      disabled: true,
    },
  ]);

  const onChangeActor = ({ target: { value } }) => {
    console.log("radio4 checked", value);
    setActor(value);
  };

  const onChangeRate = (value) => {
    console.log("rate checked", value);
    setRate(value);
  };

  const onChangeResource = ({ target: { value } }) => {
    console.log("radio4 checked", value);
    setResource(value);
  };

  const onChangeResourceSelected = ({ target: { value } }) => {
    console.log("radio4 checked", value);
    setResourceSelected(value);
  };

  const showModal = (item) => {
    console.log(item);
    setModalText(item.content);
    setUser1(item.user1);
    setUser2(item.user2);
    setObservations(item.observations);
    setMessage(item);
    setOpen(true);
  };

  const handleOk = () => {
    setLoading(false);
    setOpen(false);
    setOpen2(true);
    setActor(0);
    setResource(0);
    setOptions3([
      {
        label: "Complete las preguntas 1 y 2",
        value: 0,
        disabled: true,
      },
    ]);
  };

  const handleOk2 = () => {
    if (actor > 0 && resourceSelected > 0) {
      setLoading2(true);
      UpdateMessageStatusApi(token, message.id, 2).then((response) => {
        if (response.status) {
          var rateData = {
            rate: rate,
            status: 1,
            userId: user.id,
            observations: "Acuerdo concretado",
            messageId: message.id,
          };

          if (actor === 1) {
            rateData.haveresidualId = resourceSelected;
          } else if (actor === 2) {
            rateData.needId = resourceSelected;
          }

          AddRateApi(token, rateData).then((response) => {
            notification["info"]({
              message: "Gracias por calificar el acuerdo",
              duration: 10,
            });
            setLoading2(false);
            setOpen2(false);
            setLoadings(true);
          });
        } else {
          notification["error"]({
            message: response.message,
            duration: 10,
          });
          setLoading(false);
          //setOpen(false);
        }
        console.log(response);
      });
    } else {
      notification["error"]({
        message: "Complete todas las preguntas",
      });
    }
  };

  const handleNo = () => {
    setLoading(true);
    UpdateMessageStatusApi(token, message.id, 2).then((response) => {
      if (response.status) {
        AddRateApi(token, {
          rate: 0,
          status: 1,
          userId: user.id,
          observations: "No se concretó el acuerdo",
          messageId: message.id,
        }).then((response) => {
          notification["info"]({
            message: "Gracias por calificar el acuerdo",
            duration: 10,
          });
          setLoading(false);
          setOpen(false);
          setLoadings(true);
        });
      } else {
        notification["error"]({
          message: response.message,
          duration: 10,
        });
        setLoading(false);
        //setOpen(false);
      }
      console.log(response);
    });

    //setTimeout(() => {}, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleCancel2 = () => {
    setOpen2(false);
  };

  useEffect(() => {
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
    socket.on("messageResponse", (data) => {
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
  }, [socket]);

  useEffect(() => {
    if (loadings) {
      getMeetingsApi(token, user.id).then((response) => {
        console.log(response);

        const data = [];
        let meetings = response.messages ?? [];
        meetings.map((item) => {
          //console.log(item.friend.users1[0]);
          if (moment(item.start).isAfter(moment())) {
            data.push({
              id: item.id,
              type: item.statusreunion === 0 ? "error" : "success",
              status: item.statusreunion === 0 ? "No aceptado" : "Aceptado",
              color: item.statusreunion === 0 ? "red" : "green",
              content: item.message,
              start: item.start,
              end: item.end,
              message: item.message,
              observations: item.observations,
              user1:
                item.friend.users1[0].name +
                " " +
                item.friend.users1[0].lastname,
              user2:
                item.friend.users2[0].name +
                " " +
                item.friend.users2[0].lastname,
              rate: false,
              //value: 0,
            });
          } else {
            console.log(item.statusreunion);
            data.push({
              id: item.id,
              type:
                item.statusreunion === 0
                  ? "error"
                  : item.rate == null || item.statusreunion === 1
                  ? "processing"
                  : item.statusreunion === 2
                  ? "default"
                  : "warning",
              status:
                item.statusreunion === 0 ? (
                  "No aceptado, pasado"
                ) : item.rate == null || item.statusreunion === 1 ? (
                  "Por calificar"
                ) : item.statusreunion === 2 ? (
                  <Rate
                    disabled
                    defaultValue={item.rate ? item.rate.rate : 0}
                    count={3}
                  />
                ) : (
                  "Pasado"
                ),
              color:
                item.statusreunion === 0
                  ? "red"
                  : item.rate == null || item.statusreunion === 1
                  ? "blue"
                  : item.statusreunion === 2
                  ? "white"
                  : "yellow",
              content: item.message,
              start: item.start,
              end: item.end,
              message: item.message,
              observations: item.observations,
              user1:
                item.friend.users1[0].name +
                " " +
                item.friend.users1[0].lastname,
              user2:
                item.friend.users2[0].name +
                " " +
                item.friend.users2[0].lastname,
              rate:
                item.statusreunion === 0
                  ? false
                  : item.rate == null || item.statusreunion === 1
                  ? true
                  : false,
              //value: item.rate ? item.rate.rate : 0,
            });
          }
        });

        setLoadings(false);
        setMeet(data);
      });
    }
    // });
  }, [token, loadings]);

  useEffect(() => {
    getEntrepreneurshipByIdApi(token, user.id).then((response) => {
      setEntrepreneurshipData(response.entrepreneurship);

      // getHavesApi(token, response.entrepreneurship.id).then((response) => {
      //   console.log(response.haves);
      //   setHaves(response.haves);
      //   setLoadingData(false);
      // });
    });
    // });
  }, [token]);

  useEffect(() => {
    setResourceSelected(0);
    if (actor > 0) {
      if (actor === 2) {
        getNeedsApi(token, entrepreneurshipData.id).then((response) => {
          const data1 = [];
          console.log(response.needs);
          let items1 = response.needs;
          items1.map((item) =>
            data1.push({
              label: `${item.knowledge.name} - ${item.description} (${item.revenue.name})`,
              value: item.id,
            })
          );
          setOptions3(data1);
        });
      } else if (actor === 1) {
        getHaveresidualsApi(token, entrepreneurshipData.id).then((response) => {
          const data1 = [];
          console.log(response.haveresiduals);
          let items1 = response.haveresiduals;
          items1.map((item) =>
            data1.push({
              label: `${item.residual1.name} - ${item.state.name} (${item.revenue1.name}, ${item.weight.name} Kg)`,
              value: item.id,
            })
          );
          setOptions3(data1);
        });
      }
    }
  }, [actor]);

  const getListData = (value) => {
    let listData;
    if (meet) {
      listData = meet.filter((a) =>
        moment(a.start).startOf("day").isSame(value.startOf("day"))
      );
    }
    return listData || [];
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge
              status={item.type}
              text={item.observations}
              title={item.content}
              onClick={(e) => console.log(e)}
            />
            <span style={{ fontSize: "9px", display: "flex" }}>
              {moment(item.start).format("HH:mm - ")}
              {moment(item.end).format("HH:mm")}
            </span>
            <span style={{ fontSize: "9px", display: "flex" }}>
              {item.user1}
              {", "}
              {item.user2}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <div className="calendaruser">
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Emprendedor</Breadcrumb.Item>
          <Breadcrumb.Item>Calendario</Breadcrumb.Item>
        </Breadcrumb>
        <Tabs defaultActiveKey="1" size={350}>
          <TabPane tab="Eventos" key="1">
            <List
              //className="users-active"
              loading={loadings}
              itemLayout="vertical"
              size="small"
              dataSource={meet}
              pagination={{
                // onChange: page => {
                //   console.log(page);
                // },
                pageSize: 10,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} de ${total} registros`,
              }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No existen reuniones programadas"
                  />
                ),
              }}
              renderItem={(item) => (
                // <List.Item
                //   style={{ marginBottom: "25px" }}
                //   actions={[
                //     <Button
                //       type="principal"
                //       title="Calificar reunión"
                //       onClick={() => {}}
                //     >
                //       <LikeOutlined /> Calificar
                //     </Button>,
                //   ]}
                //   extra={
                //     <Badge
                //       status={item.type}
                //       text={item.status}
                //       title={item.status}
                //       onClick={(e) => console.log(e)}
                //     />
                //   }
                // >
                //   <List.Item.Meta
                //     avatar={<Avatar size={48} icon={<CalendarOutlined />} />}
                //     title={<span>{item.message}</span>}
                //     // {
                //     //   <b>{user.lastname}</b> +
                //     //   "," +
                //     //   <span>${user.name ? user.name : "-"}</span>
                //     // }
                //     description={item.observations}
                //   />
                // </List.Item>
                <List.Item>
                  <Badge.Ribbon text={item.status} color={item.color}>
                    <Card
                      bordered={false}
                      size="small"
                      actions={
                        item.rate
                          ? [
                              <Button
                                type="principal"
                                title="Calificar reunión"
                                onClick={() => {
                                  showModal(item);
                                }}
                              >
                                <LikeOutlined /> Calificar
                              </Button>,
                            ]
                          : []
                      }
                    >
                      <Meta
                        avatar={
                          <Avatar size={48} icon={<CalendarOutlined />} />
                        }
                        title={item.message}
                        description={item.observations}
                      />
                      <br />
                      <Tag icon={<UserOutlined />} color="#2db7f5">
                        {item.user1}
                      </Tag>
                      <Tag icon={<UserOutlined />} color="#2db7f5">
                        {item.user2}
                      </Tag>
                    </Card>
                  </Badge.Ribbon>
                </List.Item>
              )}
            />
          </TabPane>
          <TabPane tab="Calendario" key="2">
            <Space>
              <Badge status="success" text="Aceptado" />
              <Badge status="error" text="No aceptado" />
              <Badge status="default" text="Pasado" />
              {/* <Badge status="processing" text="Processing" /> */}
              <Badge status="processing" text="Por calificar" />
            </Space>
            <br />
            <br />
            <Calendar
              dateCellRender={dateCellRender}
              //monthCellRender={monthCellRender}
            />
          </TabPane>
        </Tabs>
      </div>
      <Modal
        title="Calificar acuerdo concretado"
        visible={open2}
        onOk={handleOk}
        style={{ top: 10 }}
        //confirmLoading={confirmLoading}
        width={640}
        onCancel={handleCancel2}
        footer={[
          <Button key="back" onClick={handleCancel2} type="dashed">
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="default"
            loading={loading2}
            onClick={handleOk2}
          >
            <LikeOutlined /> Enviar
          </Button>,
        ]}
      >
        <p>
          Por favor complete las siguientes preguntas para calificar el acuerdo
          en la {modalText}
        </p>
        <p>{observations}</p>
        <Tag icon={<UserOutlined />} color="#2db7f5">
          {user1}
        </Tag>
        <Tag icon={<UserOutlined />} color="#2db7f5">
          {user2}
        </Tag>
        <br />
        <br />
        <br />
        <p>1.- Seleccione el tipo de actor que fue en el acuerdo</p>
        <Radio.Group
          options={options}
          onChange={onChangeActor}
          value={actor}
          optionType="button"
          buttonStyle="solid"
        />
        <br />
        <br />
        {/* <p>
          2.- Seleccione el tipo de recursos entregado/recibido en el acuerdo
        </p>
        <Radio.Group
          options={options2}
          onChange={onChangeResource}
          value={resource}
          optionType="button"
          buttonStyle="solid"
        />
        <br />
        <br /> */}
        <p>2.- Seleccione el recurso entregado/recibido en el acuerdo</p>
        <Radio.Group
          options={options3}
          onChange={onChangeResourceSelected}
          value={resourceSelected}
          optionType="button"
          buttonStyle="solid"
        />
        <br />
        <br />
        <p>
          3.- De forma general califique la satisfacción del acuerdo de 1 a 3
        </p>
        <Rate
          allowClear={false}
          count={3}
          defaultValue={3}
          onChange={onChangeRate}
        />
      </Modal>
      <Modal
        title="Calificar acuerdo"
        visible={open}
        onOk={handleOk}
        //confirmLoading={confirmLoading}
        width={640}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel} type="dashed">
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="default"
            loading={loading}
            onClick={handleOk}
          >
            <LikeOutlined /> Si se dió el acuerdo
          </Button>,
          <Button
            key="link"
            type="default"
            loading={loading}
            onClick={handleNo}
          >
            <DislikeOutlined /> No se dió el acuerdo
          </Button>,
        ]}
      >
        <p>Por favor confirme si se llegó a dar el acuerdo en la {modalText}</p>
        <p>{observations}</p>
        <Tag icon={<UserOutlined />} color="#2db7f5">
          {user1}
        </Tag>
        <Tag icon={<UserOutlined />} color="#2db7f5">
          {user2}
        </Tag>
      </Modal>
    </>
  );
};

export default CalendarUser;
