import React, { useState } from "react";
import { Navigate, Routes, Route, useNavigate } from "react-router-dom";
import { Layout } from "antd";
import MenuTop from "../components/Admin/MenuTop";
import MenuSider from "../components/Admin/MenuSider";
//import UseAuth from "../hooks/useAuth";

//import { getAccessToken, getRefreshToken } from "../api/auth";
import AdminHome from "../pages/Admin";
import AdminSignIn from "../pages/Admin/SignIn";
import AdminUsers from "../pages/Admin/Users";
import AdminSectores from "../pages/Admin/Reportes/Sectores";
import AdminDiagnostico from "../pages/Admin/Reportes/Diagnostico";
import AdminDiagnosticoBlandas from "../pages/Admin/Reportes/DiagnosticoBlandas";
import AdminDiagnosticoEtapas from "../pages/Admin/Reportes/DiagnosticoEtapas";
import AdminEtapas from "../pages/Admin/Reportes/Etapas";
import AdminCiudades from "../pages/Admin/Reportes/Ciudades";
import AdminParroquias from "../pages/Admin/Reportes/Parroquias";
import AdminProvincias from "../pages/Admin/Reportes/Provincias";
import AdminRegistros from "../pages/Admin/Reportes/Registros";
import AdminConocimientos from "../pages/Admin/Reportes/Conocimientos";
import AdminOferta from "../pages/Admin/Reportes/Oferta";
import AdminSostenibilidad from "../pages/Admin/Reportes/Sostenibilidad";
import AdminCapital from "../pages/Admin/Reportes/Capital";
import AdminFacturacion from "../pages/Admin/Reportes/Facturacion";
import AdminEmpleados from "../pages/Admin/Reportes/Empleo";
import AdminInversion from "../pages/Admin/Reportes/Inversion";
import AdminMapaUsuario from "../pages/Admin/Reportes/MapaUsuario";
import AdminMapaEmprendimientos from "../pages/Admin/Reportes/MapaUsuarioEmprendimientos";
import AdminEventos from "../pages/Admin/Eventos";
import AdminRegistrosEvento from "../pages/Admin/Reportes/RegistrosEvento";
import AdminConexiones from "../pages/Admin/Reportes/Conexiones";

import "./LayoutAdmin.scss";
import useAuth from "../hooks/useAuth";

export default function LayoutAdmin(props) {
  //const { routes } = props;
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const { Header, Content, Footer } = Layout;

  const { user, isLoading } = useAuth(); //hook
  const navigate = useNavigate();

  console.log(user);
  if (!user && !isLoading) {
    return (
      <>
        <Routes>
          <Route path="/admin/login" element={<AdminSignIn />}></Route>
          {/* <Navigate to="/admin/login"></Navigate> */}
        </Routes>

        <Navigate to="/admin/login"></Navigate>
      </>
    );
  } else if (user && !isLoading && user.role !== 4) {
    return (
      <>
        <Routes>
          <Route path="/admin/login" element={<AdminSignIn />}></Route>
          {/* <Navigate to="/admin/login"></Navigate> */}
        </Routes>

        <Navigate to="/admin/login"></Navigate>
      </>
    );
  }

  if (user && !isLoading) {
    //navigate("/user");
    return (
      <Layout>
        <MenuSider menuCollapsed={menuCollapsed} />
        <Layout
          className="layout-admin"
          // style={{ marginLeft: menuCollapsed ? "80px" : "200px" }}
        >
          <Header className="layout-admin__header">
            <MenuTop
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            />
          </Header>

          <Content className="layout-admin__content">
            {/* <LoadRoutes routes={routes} /> */}
            <Routes>
              <Route path="/admin" element={<AdminHome />} />
              {/* <Route path="/admin/login" element={<AdminSignIn />} /> */}
              <Route path="/admin/users" element={<AdminUsers />} />
              <Route path="/admin/sectores" element={<AdminSectores />} />
              <Route path="/admin/diagnostico" element={<AdminDiagnostico />} />
              <Route
                path="/admin/diagnosticoblandas"
                element={<AdminDiagnosticoBlandas />}
              />
              <Route
                path="/admin/diagnosticoetapas"
                element={<AdminDiagnosticoEtapas />}
              />
              <Route path="/admin/etapas" element={<AdminEtapas />} />
              <Route path="/admin/provincias" element={<AdminProvincias />} />
              <Route path="/admin/ciudades" element={<AdminCiudades />} />
              <Route path="/admin/parroquias" element={<AdminParroquias />} />
              <Route path="/admin/registros" element={<AdminRegistros />} />
              <Route
                path="/admin/events/users/:url/:id"
                element={<AdminRegistrosEvento />}
              />
              <Route
                path="/admin/conocimientos"
                element={<AdminConocimientos />}
              />
              <Route path="/admin/oferta" element={<AdminOferta />} />
              <Route path="/admin/capital" element={<AdminCapital />} />
              <Route path="/admin/facturacion" element={<AdminFacturacion />} />
              <Route path="/admin/empleo" element={<AdminEmpleados />} />
              <Route path="/admin/inversion" element={<AdminInversion />} />
              <Route path="/admin/mapusuario" element={<AdminMapaUsuario />} />
              <Route
                path="/admin/sostenibilidad"
                element={<AdminSostenibilidad />}
              />
              <Route path="/admin/conexiones" element={<AdminConexiones />} />
              <Route
                path="/admin/mapemprendimiento"
                element={<AdminMapaEmprendimientos />}
              />
              <Route path="/admin/eventos/admin" element={<AdminEventos />} />
            </Routes>
          </Content>
          <Footer className="layout-admin__footer">
            CONECTA2 EC - 2023 COPYRIGHT©
          </Footer>
        </Layout>
      </Layout>
    );
  }

  return null;
}

// function LoadRoutes({ routes }) {
//   return (
//     <Routes>
//       {routes.map((route, index) => (
//         <Route
//           key={index}
//           path={route.path}
//           exact={route.exact}
//           //component={route.component}
//           element={<route.component />}
//         />
//       ))}
//     </Routes>
//   );
// }
