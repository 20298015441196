import React, { useState, useEffect } from "react";
import { Select, Form, Input } from "antd";

import { GetParroquiaByCityApi } from "../../../../api/parroquia.api";

import "./SelectCityForm.scss";

const { Option } = Select;

export default function SelectParroquiaForm(props) {
  const { name, nameOther, edit, values, city, parroquia, setParroquia } =
    props;
  const [parroquiaData, setParroquiaData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(false);
  //const [parroquiaId, setParroquiaId] = useState();
  const [enabled, setEnabled] = useState(false);
  const [other, setOther] = useState(false);
  // const [first, setFirst] = useState(true);
  // const [second, setSecond] = useState(true);

  useEffect(() => {
    console.log("city []");
    console.log(values);
    if (values && values.cityId !== undefined && values.cityId > 0) {
      setLoadingData(true);
      // if (edit && first) {
      //   setParroquia(null);
      // }
      //setFirst(false);
      GetParroquiaByCityApi(values.cityId).then((response) => {
        setParroquiaData(response.parroquias);

        // if (first && initialparroquia > 0) {
        //   setParroquiaId(initialparroquia);
        // }
        // setFirst(false);
        // setLoadingData(false);
        // setEnabled(true);
        // if (edit ?? false) {
        //   setParroquiaId(values.parroquiaId);
        //   if (values.parroquiaId === 4 || values.parroquiaId === 5 || values.parroquiaId === 6) {
        //     setOther(true);
        //   } else {
        //     setOther(false);
        //   }
        // } else {
        //   setOther(false);
        // }
        setLoadingData(false);
        setEnabled(true);
        if (edit) {
          //setParroquia(values.parroquiaId);
          if (values.parroquiaId >= 40) {
            setOther(true);
          } else {
            setOther(false);
          }
        } else {
          setOther(false);
        }
      });
    } else {
      //setParroquia(null);
      setEnabled(false);
      setOther(false);
    }
  }, [values]);

  useEffect(() => {
    console.log("parroquia city");
    console.log(city);
    // console.log(first);
    // console.log(second);
    if (city !== undefined && city > 0) {
      setLoadingData(true);

      //setFirst(false);
      GetParroquiaByCityApi(city).then((response) => {
        setParroquiaData(response.parroquias);

        setLoadingData(false);
        setEnabled(true);
        //setParroquia(null);
        setOther(false);
      });
    }
  }, [city]);

  const handleAtributoChange = (value) => {
    setParroquia(value);
    //setAtributoData(value);
    if (value >= 40) {
      setOther(true);
    } else {
      setOther(false);
    }
  };

  // useEffect(() => {
  //   //console.log(values);
  //   if (edit ?? false) {
  //     if (values.parroquiaId === 4 || values.parroquiaId === 5 || values.parroquiaId === 6) {
  //       setOther(true);
  //     } else {
  //       setOther(false);
  //     }
  //   } else {
  //     setOther(false);
  //   }
  // }, [values]);

  return (
    <div className="select-parroquia-form">
      <Form.Item
        label="Parroquia"
        name={name}
        rules={[{ required: true, message: "Seleccione una parroquia" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        style={{ marginBottom: other ? "5px" : "24px" }}
        disabled={!enabled}
        //value={parroquia}
        //initialValue={parroquiaId}
      >
        <Select
          placeholder="Seleccione la parroquia"
          //value={parroquia}
          onChange={handleAtributoChange}
          // showSearch
          // loading={loadingData}
          // optionFilterProp="children"
          // filterOption={(input, option) =>
          //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
          //   0
          // }
          allowClear
          disabled={!enabled}
        >
          {parroquiaData && parroquiaData.map
            ? parroquiaData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
      {other && (
        <Form.Item
          //label="Otros"
          name={nameOther}
          rules={[{ required: true, message: "Ingrese la parroquia" }]}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          //initialValue={deafultValue}
        >
          <Input
            //prefix={<UserOutlined />}
            placeholder="Parroquia"
            //value={userData.name}
            maxLength={200}
          />
        </Form.Item>
      )}
    </div>
  );
}
