import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Breadcrumb, Empty, Affix, Row, Col, Card, Button } from "antd";
import { Table } from "ant-table-extensions";
import { getAccessTokenApi } from "../../../api/auth";
import "./Indicadores.scss";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";
import ExportOutlined from "@ant-design/icons/ExportOutlined";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";

import { getEntrepreneurshipByEventApi } from "../../../api/entrepreneurship.api";
import { getEventByUrlApi } from "../../../api/event.api";

export default function RegistrosEvento() {
  const token = getAccessTokenApi();
  const [usersActive, setUsersActive] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [event, setEvent] = useState();
  const { id, url } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getEventByUrlApi(url).then((response) => {
      setEvent(response.event);
    });
    getEntrepreneurshipByEventApi(token, id).then((response) => {
      //console.log(response.users);
      setUsersActive(response.entrepreneurships);
      setLoadingData(false);
    });
  }, [token]);

  const columns = [
    {
      title: "Nombres",
      width: 200,
      dataIndex: ["user", "name"],
      key: "name",
    },
    {
      title: "Apellidos",
      width: 200,
      dataIndex: ["user", "lastname"],
      key: "lastname",
    },
    {
      title: "Correo",
      width: 200,
      dataIndex: ["user", "email"],
      key: "email",
    },
    {
      title: "Teléfono",
      width: 120,
      dataIndex: ["user", "phone"],
      key: "phone",
    },
    {
      title: "Perfil",
      width: 120,
      dataIndex: ["user", "role", "name"],
      key: "role",
    },
    {
      title: "Empresa",
      width: 200,
      dataIndex: "name",
      key: "entreprenurship",
    },
  ];

  const fields: ITableExportFields = {
    user: {
      header: "Usuario",
      formatter: (_fieldValue, record) => {
        return record.user.name;
      },
    },
    lastname: {
      header: "Apellido",
      formatter: (_fieldValue, record) => {
        return record.user.lastname;
      },
    },
    email: {
      header: "Correo",
      formatter: (_fieldValue, record) => {
        return record.user.email;
      },
    },
    phone: {
      header: "Usuario",
      formatter: (_fieldValue, record) => {
        return record.user.phone;
      },
    },
    role: {
      header: "Usuario",
      formatter: (_fieldValue, record) => {
        return record.user.role.name;
      },
    },
    name: "Empresa",
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Eventos</Breadcrumb.Item>
        <Breadcrumb.Item>Registros</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button
          type="primary"
          onClick={() => navigate(-1)}
          icon={<ArrowLeftOutlined />}
        >
          Regresar a los eventos
        </Button>
      </Affix>
      <div className="site-statistic-demo-card">
        <br />
        <h2>Registros en el evento</h2>
        <Row gutter={16}>
          <Col span={8}>
            <Card
              size="small"
              title="Nombre del evento"
              headStyle={{ backgroundColor: "#ffd5d3" }}
              bodyStyle={{ fontWeight: "Bold", fontSize: "16px" }}
            >
              {event ? event.name : "No existe el evento"}
            </Card>
          </Col>
          <Col span={8}>
            <Card
              size="small"
              title="Fecha del evento"
              headStyle={{ backgroundColor: "#ffd5d3" }}
              bodyStyle={{ fontWeight: "Bold", fontSize: "16px" }}
            >
              {event ? event.eventday.substring(0, 10) : ""}
            </Card>
          </Col>
          <Col span={8}>
            <Card
              size="small"
              title="Ciudad del evento"
              headStyle={{ backgroundColor: "#ffd5d3" }}
              bodyStyle={{ fontWeight: "Bold", fontSize: "16px" }}
            >
              {event ? event.city.name : ""}
            </Card>
          </Col>
        </Row>
        <br />
        <Table
          columns={columns}
          dataSource={usersActive}
          loading={loadingData}
          rowKey="id"
          //scroll={{ x: 1500 }}
          size="small"
          //onChange={onChange}
          exportable
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} de ${total} registros`,
          }}
          exportableProps={{
            fields,
            fileName: "Registros",
            btnProps: {
              type: "primary",
              icon: <ExportOutlined />,
              children: <span>Exportar</span>,
            },
            children: <span>Exportar</span>,
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No existen registros"
              />
            ),
          }}
        />
      </div>
    </div>
  );
}
