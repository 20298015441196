import { basePath } from "./config";

export async function getParroquiasApi() {
  const url = `${basePath}/parroquias`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      //Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export function UpdateParroquiaApi(token, parroquia, parroquiaId) {
  const url = `${basePath}/parroquia/update/${parroquiaId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(parroquia),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function AddParroquiaApi(token, parroquia) {
  const url = `${basePath}/parroquia/create`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(parroquia),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function DeleteParroquiaApi(token, parroquiaId) {
  const url = `${basePath}/parroquia/delete/${parroquiaId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function GetParroquiaByCityApi(cityId) {
  const url = `${basePath}/parroquia/city/${cityId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      //Authorization: token,
    },
    //body: JSON.stringify(entrepreneurship),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getParroquiasGroupApi(token) {
  const url = `${basePath}/parroquia/group`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
