import React, { useState, useEffect } from "react";
//import MapPicker from "react-google-map-picker";
import GoogleMapReact from "google-map-react";
import { getAccessTokenApi } from "../../../api/auth";

import {
  Button,
  PageHeader,
  Form,
  Row,
  Breadcrumb,
  Col,
  notification,
  Avatar,
} from "antd";
import { SaveOutlined, InteractionOutlined } from "@ant-design/icons";

import "./Solution.scss";
import {
  getEntrepreneurshipByIdApi,
  UpdateEntrepreneurshipApi,
} from "../../../api/entrepreneurship.api";

import SelectSolutionForm from "../../../components/Admin/Solution/SelectSolutionForm";

import useAuth from "../../../hooks/useAuth";

const Solution = ({ socket, setReloadPerfil }) => {
  const { user, isLoading } = useAuth(); //hook
  const [formSolution] = Form.useForm();
  const [solution, setSolution] = useState([]);
  const token = getAccessTokenApi();
  const [reloadPersonals, setReloadPersonals] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const [loadings, setLoadings] = useState(false);
  const [userData, setUserData] = useState({});

  const [lastsocket, setLastsocket] = useState();

  useEffect(() => {
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
    socket.on("messageResponse", (data) => {
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
  }, [socket]);

  useEffect(() => {
    setLoadingData(true);
    getEntrepreneurshipByIdApi(token, user.id).then((response) => {
      setEntrepreneurshipData(response.entrepreneurship);
      setSolution(response.entrepreneurship.solutionId);
      formSolution.setFieldsValue(response.entrepreneurship);
      setLoadingData(false);
    });

    setReloadPersonals(false);
  }, []);

  const onFinish = (values) => {
    setLoadings(true);
    const userAdd = {
      ...values,
      // lat: location.lat,
      // lon: location.lng,
    };
    setUserData(userAdd);

    UpdateEntrepreneurshipApi(
      {
        solutionId: values.solutionId,
        solutionother: values.solutionother,
      },
      entrepreneurshipData.id
    ).then((result) => {
      if (result.status) {
        setSolution(values.solutionId);
        notification["success"]({
          message: result.message,
        });
        setLoadings(false);
        setReloadPerfil(true);
      } else {
        notification["error"]({
          message: result.message,
        });
        setLoadings(false);
      }
    });

    // console.log("Success values:", values);
    // console.log("Success:", userData);
    //next();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revisa los errores",
      duration: 3,
    });
  };

  return (
    <div className="solution">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Empresa</Breadcrumb.Item>
        <Breadcrumb.Item>Perfil</Breadcrumb.Item>
        <Breadcrumb.Item>Tipo de solución sostenible</Breadcrumb.Item>
      </Breadcrumb>
      <Form
        form={formSolution}
        name="solution"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <PageHeader
          className="site-page-header header-left"
          // onBack={() => null}
          style={{ textAlign: "left", justifyContent: "start" }}
          title={
            <>
              <Avatar
                icon={<InteractionOutlined />}
                style={{ backgroundColor: "#f42c1e", color: "white" }}
              />
              <span> PROYECTOS SOSTENIBLES PARA TU EMPRESA U ORGANIZACIÓN</span>
            </>
          }
          // subTitle="This is a subtitle"
        />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={10} xl={10}>
            <SelectSolutionForm
              atributoData={solution}
              setAtributoData={setSolution}
            ></SelectSolutionForm>
          </Col>
        </Row>

        <br></br>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                className="btnBig"
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loadings}
              >
                Guardar <SaveOutlined />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Solution;
