import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  InputNumber,
  Radio,
} from "antd";

import { getAccessTokenApi } from "../../../../../api/auth";
import {
  UpdateNeedApi,
  AddNeedApi,
  DeleteNeedApi,
} from "../../../../../api/need.api";
//import useAuth from "../../../../../hooks/useAuth";

import "./EditNeedForm.scss";
import SelectKnowledgetypeForm from "../../../../../components/Admin/Knowledge/SelectKnowledgeForm/SelectKnowledgeTypeForm";
import SelectRevenueListForm from "../../../../../components/Admin/Revenue/SelectRevenueForm/SelectRevenueListForm";
import SelectAgreementForm from "../../../../../components/Admin/Agreement/SelectAgreementForm";

export default function EditNeedForm(props) {
  const {
    need,
    setIsVisibleModal,
    setReloadNeeds,
    buttonName,
    addSlag,
    entrepreneurshipData,
  } = props;
  const [needData, setNeedData] = useState({});
  //const { user } = useAuth();
  //const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const token = getAccessTokenApi();
  const [formNeed] = Form.useForm();

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    // setNeedData({
    //   code: "",
    //   name: "",
    // });
  };

  useEffect(() => {
    if (need) {
      formNeed.setFieldsValue(need);
    } else {
      formNeed.setFieldsValue({
        entrepreneurshipId: null,
        knowledgetypeId: null,
        id: null,
        knowledgeId: null,
        other: null,
        description: null,
        revenueId: null,
        agreementId: null,
      });
    }
    // getEntrepreneurshipByIdApi(token, user.id).then((response) => {
    //   setEntrepreneurshipData(response.entrepreneurship);
    // });
  }, [need]);

  if (addSlag === "add") {
    formNeed.setFieldsValue({
      entrepreneurshipId: null,
      knowledgetypeId: null,
      id: null,
      knowledgeId: null,
      other: null,
      description: null,
      revenueId: null,
      agreementId: null,
    });
  }

  const addUpdateNeed = (e) => {
    e.preventDefault();
    let needUpdate = needData;
    if (!needUpdate.name || !needUpdate.code) {
      notification["error"]({
        message: "El nombre y el código es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddNeedApi(token, needUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadNeeds(true);
        setIsVisibleModal(false);
        //resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateNeedApi(token, needUpdate, need.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadNeeds(true);
        setIsVisibleModal(false);
        setNeedData({
          ...needData,
        });
      });
    } else {
      DeleteNeedApi(token, need.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadNeeds(true);
        setIsVisibleModal(false);
      });
    }
  };

  const onFinish = (values) => {
    const token = getAccessTokenApi();
    if (addSlag === "add") {
      AddNeedApi(token, {
        ...values,
        entrepreneurshipId: entrepreneurshipData.id,
      }).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadNeeds(true);
        setIsVisibleModal(false);
        //resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateNeedApi(
        token,
        { ...values, entrepreneurshipId: entrepreneurshipData.id },
        need.id
      ).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadNeeds(true);
        setIsVisibleModal(false);
        setNeedData({
          ...needData,
        });
      });
    } else {
      DeleteNeedApi(token, need.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadNeeds(true);
        setIsVisibleModal(false);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información ingresada",
      duration: 3,
    });
  };

  return (
    <div className="edit-need-form">
      <Form
        form={formNeed}
        name="need"
        className="form-edit"
        //onSubmit={addUpdateNeed}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{ remember: false }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <SelectKnowledgetypeForm
              // atributoData={city}
              // setAtributoData={setCity}
              //handleChange={handleChangeYonecesito}
              name={"knowledgeId"}
              nametype={"knowledgetypeId"}
              nameOther={"other"}
              edit={addSlag === "edit" || addSlag === "delete"}
              values={need}
              othertype={"othertype"}
            ></SelectKnowledgetypeForm>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item
              label="Descripción corta"
              name={"description"}
              rules={[
                {
                  required: true,
                  message: "Ingrese la descripción corta",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Descripción corta"
                maxLength={300}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <SelectRevenueListForm
              name={"revenueId"}
              nameOther={"other"}
              edit={addSlag === "edit" || addSlag === "delete"}
              values={need}
            ></SelectRevenueListForm>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <SelectAgreementForm
              name={"agreementId"}
              nameOther={"other"}
              edit={addSlag === "edit" || addSlag === "delete"}
              values={need}
            ></SelectAgreementForm>
          </Col>
          {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <SelectKnowledgeForm
              // atributoData={city}
              // setAtributoData={setCity}
              //handleChange={handleChangeYotengo}
              name={"knowledgeId"}
              nameOther={"other"}
              edit={addSlag === "edit" || addSlag === "delete"}
              values={have}
            ></SelectKnowledgeForm>
          </Col> */}
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
