import React, { useState, useEffect } from "react";
import { Select, Form, Input } from "antd";

import { getResourcesApi } from "../../../../api/resource.api";
//import { getOtherByIdApi } from "../../../../api/other.api";

import "./SelectResourceForm.scss";

const { Option } = Select;

export default function SelectResourceForm(props) {
  const { atributoData, setAtributoData, resourceValue } = props;
  const [resourceData, setResourceData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [resourceId, setResourceId] = useState();
  const [other, setOther] = useState(false);
  //const [resourceValue, setResourceValue] = useState();

  useEffect(() => {
    //console.log(atributoData);
    getResourcesApi().then((response) => {
      setResourceData(response.resources);
      //setResourceId(atributoData);
    });
    setLoadingData(false);
  }, [loadingData]);

  useEffect(() => {
    if (atributoData) {
      var isOther = atributoData.find((element) => {
        return element === 4;
      });
      if (isOther) {
        // getOtherByIdApi(token, entrepreneurshipId, "resource").then(
        //   (response) => {
        //     setResourceValue(response.other.description);
        //     console.log(response.other.description);
        //   }
        // );
        setOther(true);
      } else {
        setOther(false);
      }
    } else {
      setOther(false);
    }
  }, [atributoData]);

  const handleAtributoChange = (value) => {
    setResourceId(value);
    setAtributoData(value);
    var isOther = value.find((element) => {
      return element === 4;
    });
    if (isOther) {
      setOther(true);
    } else {
      setOther(false);
    }
  };

  return (
    <div className="select-resource-form">
      <Form.Item
        label="Recursos para crecer"
        name="resource"
        rules={[{ required: true, message: "Seleccione un recurso" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        //style={other && { marginBottom: "5px" }}
        style={{ marginBottom: other ? "5px" : "24px" }}
        initialValue={atributoData}
      >
        <Select
          placeholder="Seleccione el/los recurso/s para crecer"
          //value={resourceId}
          onChange={handleAtributoChange}
          mode="multiple"
          //disabled={disabled}
        >
          {resourceData && resourceData.map
            ? resourceData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
      {other && (
        <Form.Item
          //label="Otros"
          name="resourceOther"
          rules={[{ required: true, message: "Ingrese el recurso" }]}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          initialValue={resourceValue}
        >
          <Input
            //prefix={<UserOutlined />}
            placeholder="Recurso"
            //value={resourceValue}
            maxLength={200}
          />
        </Form.Item>
      )}
    </div>
  );
}
