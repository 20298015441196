import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import MenuUnfoldOutlined from "@ant-design/icons/MenuUnfoldOutlined";
import MenuFoldOutlined from "@ant-design/icons/MenuFoldOutlined";
import PoweroffOutlined from "@ant-design/icons/PoweroffOutlined";
import SettingOutlined from "@ant-design/icons/SettingOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import CILogo from "../../../assets/img/png/conecta2.png";
import { logout } from "../../../api/auth";
import useAuth from "../../../hooks/useAuth";

import "./MenuTop.scss";

export default function MenuTop(props) {
  const { menuCollapsed, setMenuCollapsed } = props;
  //const [admin, setAdmin] = useState(false);
  const [pathAdmin, setPathAdmin] = useState(false);
  const navigate = useNavigate();
  //const { user, isLoading } = useAuth(); //hook

  const useReactPath = () => {
    const [path, setPath] = React.useState(window.location.pathname);
    const listenToPopstate = () => {
      const winPath = window.location.pathname;
      setPath(winPath);
    };
    React.useEffect(() => {
      window.addEventListener("popstate", listenToPopstate);
      return () => {
        window.removeEventListener("popstate", listenToPopstate);
      };
    }, []);
    return path;
  };

  const path = useReactPath();

  const logoutUser = () => {
    logout();
    window.location.reload();
  };

  useEffect(() => {
    // if (user.role === 2) {
    //   setAdmin(true);
    // }
    if (path.substring(0, 6) === "/admin") {
      setPathAdmin(true);
    } else {
      setPathAdmin(false);
    }
  }, [path]);

  return (
    <div className="menu-top">
      <div className="menu-top__left">
        <img className="menu-top__left-logo" src={CILogo} alt="CI" />
        <Button type="link" onClick={() => setMenuCollapsed(!menuCollapsed)}>
          {menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div>
      <div className="menu-top__right">
        {/* {admin ? (
          !pathAdmin ? (
            <Button type="link" onClick={() => navigate("/admin")}>
              <SettingOutlined />
            </Button>
          ) : (
            <Button type="link" onClick={() => navigate("/user")}>
              <UserOutlined />
            </Button>
          )
        ) : (
          ""
        )} */}
        <Button type="link" onClick={logoutUser}>
          <PoweroffOutlined />
        </Button>
      </div>
    </div>
  );
}
