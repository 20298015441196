import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";

import {
  Table,
  Empty,
  Breadcrumb,
  PageHeader,
  notification,
  Avatar,
} from "antd";

import ShareAltOutlined from "@ant-design/icons/ShareAltOutlined";

import "./Eventos.scss";
import { getEventByUserApi } from "../../../api/event.api";

import { getEntrepreneurshipByIdApi } from "../../../api/entrepreneurship.api";
import useAuth from "../../../hooks/useAuth";
import moment from "moment";

export default function Eventos({ socket }) {
  const [events, setEvents] = useState([]);
  const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const { user } = useAuth();

  const [lastsocket, setLastsocket] = useState();

  useEffect(() => {
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
    socket.on("messageResponse", (data) => {
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
  }, [socket]);

  useEffect(() => {
    getEntrepreneurshipByIdApi(token, user.id).then((response) => {
      setEntrepreneurshipData(response.entrepreneurship);
      getEventByUserApi(token, response.entrepreneurship.id).then(
        (response) => {
          console.log(response.events);
          setEvents(response.events);
          setLoadingData(false);
        }
      );
    });
  }, [token, user.id]);

  const columns = [
    {
      title: "Código",
      width: 50,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      responsive: ["md"],
    },
    {
      title: "Nombre",
      width: 150,
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Descripción",
      width: 100,
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Ciudad",
      width: 150,
      dataIndex: ["city", "name"],
      key: "cityId",
    },
    {
      title: "Fecha",
      width: 130,
      dataIndex: "eventday",
      key: "eventday",
      render: (text) => <span> {moment(text).format("YYYY-MM-DD")}</span>,
    },
  ];

  return (
    <div className="residual">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Reciclaje</Breadcrumb.Item>
        <Breadcrumb.Item>Eventos networking</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        className="site-page-header header-left"
        // onBack={() => null}
        style={{ textAlign: "left", justifyContent: "start" }}
        // onBack={() => null}
        //style={{ textAlign: "center" }}
        title={
          <>
            <Avatar
              icon={<ShareAltOutlined />}
              style={{ backgroundColor: "#f42c1e", color: "white" }}
            />
            <span> Eventos networking</span>
            <br></br>
            <span style={{ fontSize: "14px" }}>
              Eventos a los cuales me he registrado para asistir.
            </span>
          </>
        }
        // subTitle="This is a subtitle"
      />
      <br />
      <Table
        columns={columns}
        dataSource={events}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No tienes eventos registrados"
            />
          ),
        }}
      />
    </div>
  );
}
