import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";

import { getUtilitysApi } from "../../../../api/utility.api";

import "./SelectUtilityForm.scss";

const { Option } = Select;

export default function SelectUtilityForm(props) {
  const { name, nameOther, edit, values } = props;
  const [utilityData, setUtilityData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [utilityId, setUtilityId] = useState();

  useEffect(() => {
    getUtilitysApi().then((response) => {
      setUtilityData(response.utilitys);
      //setUtilityId(atributoData);
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value) => {
    setUtilityId(value);
    //setAtributoData(value);
  };

  return (
    <div className="select-utility-form">
      <Form.Item
        label={"Uso del recurso"}
        name={name}
        rules={[
          { required: true, message: "Seleccione un uso para el recurso" },
        ]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        //className="biglabel"
      >
        <Select
          placeholder="Seleccione un uso del recurso"
          //value={utilityId}
          onChange={handleAtributoChange}
          //disabled={disabled}
        >
          {utilityData && utilityData.map
            ? utilityData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
