import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Row, Col, Card, Button, Avatar } from "antd";
import { Bar, Column, Pie } from "@ant-design/charts";
import { getAccessTokenApi } from "../../../api/auth";

import "./Indicadores.scss";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

import { getCountFriendsApi } from "../../../api/friend.api";
import {
  getCountMessagesApi,
  getCountConversationsApi,
} from "../../../api/message.api";
import { getCountRatesApi } from "../../../api/rate.api";
import {
  getPotentialsSummaryApi,
  getUsersActiveApi,
} from "../../../api/user.api";

import {
  ApiOutlined,
  SmileOutlined,
  MessageOutlined,
  RightOutlined,
} from "@ant-design/icons";

export default function Conexiones() {
  const token = getAccessTokenApi();
  //const { RangePicker } = DatePicker;
  const [disabled, setDisabled] = useState(true);
  const [countConections, setCountConections] = useState();
  const [countMessages, setCountMessages] = useState();
  const [countAgreements, setCountAgreements] = useState();
  const [countConversations, setCountConversations] = useState();
  const [potentials, setPotentials] = useState(0);
  const [potentialsNeed, setPotentialsNeed] = useState(0);
  const { Meta } = Card;

  useEffect(() => {
    getCountFriendsApi(token).then((response) => {
      setCountConections(response.count);
    });
    getCountMessagesApi(token, false).then((response) => {
      setCountMessages(response.count);
    });
    getCountConversationsApi(token).then((response) => {
      setCountConversations(response.count);
    });
    getCountRatesApi(token).then((response) => {
      setCountAgreements(response.count);
    });
    var potentialTotal = 0;
    var potentialNeedTotal = 0;
    getUsersActiveApi(token, true, 0, 0, 0, 0).then((response) => {
      let users = response.users;
      users.map((user) => {
        getPotentialsSummaryApi(token, user.id, 40, 10).then((response2) => {
          const have = response2.users.filter((item) => item.porcentaje >= 50);
          potentialTotal += have.length;
          setPotentials(potentialTotal);
          //setPotentials([...potentials, ...have]);
        });
        getPotentialsSummaryApi(token, user.id, 10, 40).then((response3) => {
          const need = response3.users.filter((item) => item.porcentaje >= 50);
          potentialNeedTotal += need.length;
          setPotentialsNeed(potentialNeedTotal);
          //setPotentialsNeed([...potentialsNeed, ...need]);
        });
      });
    });
  }, [token]);

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Gráficas</Breadcrumb.Item>
        <Breadcrumb.Item>Conexiones</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <Row gutter={24}>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#ec407a",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <ApiOutlined style={{ fontSize: "40px", color: "white" }} />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>{countConections}</h2>
                  </Avatar>
                }
                title="Conexiones"
                description="Aceptadas"
              />
            </Card>
          </Col>

          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#66bb6a",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <SmileOutlined style={{ fontSize: "40px", color: "white" }} />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>{countAgreements}</h2>
                  </Avatar>
                }
                title="Acuerdos"
                description="Concretados"
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#ef5350",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <MessageOutlined
                    style={{ fontSize: "40px", color: "white" }}
                  />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h4 style={{ color: "white" }}>{countConversations}</h4>
                  </Avatar>
                }
                title="Conversaciones"
                description="Establecidas"
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#26c6da",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <RightOutlined style={{ fontSize: "40px", color: "white" }} />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h4 style={{ color: "white" }}>{countMessages}</h4>
                  </Avatar>
                }
                title="Mensajes"
                description="Enviados"
              />
            </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card
              bodyStyle={{ padding: "70px 30px 70px 30px" }}
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#fb8c00",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <ApiOutlined style={{ fontSize: "40px", color: "white" }} />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={120}>
                    <h2 style={{ color: "white", fontSize: "40px" }}>
                      {potentials}
                    </h2>
                  </Avatar>
                }
                title="Yo tengo"
                description="Probabilidades de conexión de más del 50%"
              />
            </Card>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card
              bodyStyle={{ padding: "70px 30px 70px 30px" }}
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#fb8c00",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <ApiOutlined style={{ fontSize: "40px", color: "white" }} />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={120}>
                    <h2 style={{ color: "white", fontSize: "40px" }}>
                      {potentialsNeed}
                    </h2>
                  </Avatar>
                }
                title="Yo necesito"
                description="Probabilidades de conexión de más del 50%"
              />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
