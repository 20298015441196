import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Menu, Tooltip, Avatar, Col, Row, Progress, Tag } from "antd";
import { green, red, yellow } from "@ant-design/colors";

import HomeOutlined from "@ant-design/icons/HomeOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import LockOutlined from "@ant-design/icons/LockOutlined";
import ApiOutlined from "@ant-design/icons/ApiOutlined";
import MessageOutlined from "@ant-design/icons/MessageOutlined";
import CarryOutOutlined from "@ant-design/icons/CarryOutOutlined";
import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import ShareAltOutlined from "@ant-design/icons/ShareAltOutlined";

import "./MenuSiderReciclador";

function MenuSiderReciclador(props) {
  const { Sider } = Layout;
  //const { SubMenu } = Menu;
  const { menuCollapsed, data, complete } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [key, setKey] = useState(location.pathname);
  const [group, setGroup] = useState([]);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem("Dashboard", "/reciclador", <HomeOutlined />),
    getItem(
      <>
        Mi perfil&nbsp;
        <Tooltip title="En este apartado podrá modificar su información">
          <InfoCircleOutlined />
        </Tooltip>
      </>,
      "reciclador/perfil",
      <UserOutlined />,
      [
        getItem(
          <>
            {/* <div className="numberCircle">1</div> */}
            {complete?.user === 30 ? (
              <CheckCircleOutlined
                style={{ fontSize: "20px", color: "green" }}
              />
            ) : (
              <ExclamationCircleOutlined
                style={{ fontSize: "20px", color: "red" }}
              />
            )}
            <span className="nav-text">Datos de contacto</span>
          </>,
          "/reciclador/personal"
        ),
        getItem(
          <>
            {/* <div className="numberCircle">2</div> */}
            {complete?.empresa === 30 ? (
              <CheckCircleOutlined
                style={{ fontSize: "20px", color: "green" }}
              />
            ) : (
              <ExclamationCircleOutlined
                style={{ fontSize: "20px", color: "red" }}
              />
            )}
            <span className="nav-text">Empresa</span>
          </>,
          "/reciclador/emprendimiento"
        ),

        getItem(
          <>
            {/* <div className="numberCircle">3</div> */}
            {complete?.needresidual > 0 ? (
              <CheckCircleOutlined
                style={{ fontSize: "20px", color: "green" }}
              />
            ) : (
              <ExclamationCircleOutlined
                style={{ fontSize: "20px", color: "red" }}
              />
            )}
            <span className="nav-text">Recurso residual</span>
          </>,
          "/reciclador/residual"
        ),
      ]
    ),
    getItem("Cambiar contraseña", "/reciclador/change", <LockOutlined />),
    getItem("Conexiones", "/reciclador/conexiones", <ApiOutlined />),
    getItem("Conversaciones", "/reciclador/chat", <MessageOutlined />),
    getItem("Calendario", "/reciclador/calendar", <CarryOutOutlined />),
    getItem("Eventos networking", "/reciclador/events", <ShareAltOutlined />),
  ];
  const onClick = (e) => {
    console.log("click ", e);
    setGroup(e.keyPath);
    setKey(e.key);
    navigate(e.key);
  };
  //console.log(location);
  return (
    <Sider width={256} theme="light" collapsed={menuCollapsed}>
      <Row
        gutter={24}
        style={{
          marginTop: 75,
          paddingLeft: 20,
          paddingRight: 10,
          paddingBottom: 5,
        }}
      >
        <Col xs={4} sm={4} md={4} lg={3} xl={3}>
          <Avatar size="small" icon={<UserOutlined />} />
        </Col>
        {menuCollapsed ? (
          <></>
        ) : (
          <Col xs={20} sm={20} md={20} lg={21} xl={21}>
            {data?.name},{" "}
            <Tag color="orange">{data?.entrepreneurships[0]?.name}</Tag>
          </Col>
        )}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {menuCollapsed ? (
            <Progress
              percent={complete?.total}
              steps={3}
              size="small"
              strokeColor={[red[5], yellow[5], green[6]]}
            />
          ) : (
            <Progress
              percent={complete?.total}
              steps={10}
              strokeColor={[
                red[5],
                red[5],
                red[5],
                red[5],
                yellow[5],
                yellow[5],
                yellow[5],
                green[6],
                green[6],
                green[6],
              ]}
            />
          )}
        </Col>
        {menuCollapsed ? (
          <></>
        ) : (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              fontSize: 9,
            }}
          >
            {complete?.total > 95
              ? "Tu perfil está completo"
              : "Completa tu perfil para mejorar tus conexiones"}
          </Col>
        )}
      </Row>
      <Menu
        onClick={onClick}
        style={{
          marginTop: 5,
        }}
        //inlineCollapsed={menuCollapsed}
        defaultSelectedKeys={[key]}
        defaultOpenKeys={group}
        mode="inline"
        items={items}
      />
    </Sider>
  );
}

export default MenuSiderReciclador;
