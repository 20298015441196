import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Avatar,
  PageHeader,
  Tag,
  Button,
} from "antd";
import { Bar, Column } from "@ant-design/charts";
import {
  RestOutlined,
  BankOutlined,
  ContainerOutlined,
  RiseOutlined,
  NotificationOutlined,
  ConsoleSqlOutlined,
  LaptopOutlined,
} from "@ant-design/icons";

//import { Link } from "react-router-dom";
import { getCountUsersApi } from "../../api/user.api";

import { getAccessTokenApi } from "../../api/auth";
import useAuth from "../../hooks/useAuth";
// import fincaSvg from "../../assets/img/svg/finca.svg";
// import L from "leaflet";
import { getHaveGroupApi } from "../../api/have.api";
import { getMaxNeedApi, getNeedGroupApi } from "../../api/need.api";
import {
  //getUsersApi,
  getPotentialsApi,
  getUsersInfoApi,
} from "../../api/user.api";
import { getEntrepreneurshipsApi } from "../../api/entrepreneurship.api.js";

import { getMaxSectorApi } from "../../api/sector.api";

import {
  getMaxHaveresidualApi,
  getHaveresidualGroupApi,
} from "../../api/haveresidual.api";

import { getTypesGroupApi } from "../../api/type.api";

//import { getMaxResidualApi } from "../../api/residual.api";

import ModalUser from "../../components/Admin/ModalUser";
import "./Admin.scss";

export default function Admin({ socket }) {
  const token = getAccessTokenApi();
  const [countUserActive, setCountUserActive] = useState();
  const [users, setUsers] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [potentials, setPotentials] = useState();
  const [potentialsNeed, setPotentialsNeed] = useState();
  const { user, isLoading } = useAuth(); //hook
  const [userinfo, setUserinfo] = useState();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [updateInfo, setUpdateInfo] = useState(false);
  const [groupHave, setGroupHave] = useState();
  const [groupNeed, setGroupNeed] = useState();
  const [disabled, setDisabled] = useState(true);
  const [need, setNeed] = useState([]);
  const [maxactor, setMaxactor] = useState([]);
  const [maxresidual, setMaxresidual] = useState([]);
  const [maxresidualgroup, setMaxresidualgroup] = useState([]);
  const [types, setTypes] = useState([]);
  const [province, setProvince] = useState(0);
  const { Meta } = Card;

  //const [countEncuestas, setCountEncuestas] = useState();
  //const [fincas, setFincas] = useState([]);
  // useEffect(() => {
  //   getUsersInfoApi(token, user.id).then((response) => {
  //     //console.log(response);
  //     setUserinfo(response.user);
  //   });
  // }, [token, updateInfo]);

  useEffect(() => {
    // getCountFincaApi(token).then((response) => {
    //   setCountFincas(response.count);
    // });

    // getCountEncuestaApi(token).then((response) => {
    //   setCountEncuestas(response.count);
    // });
    setLoadingData(true);

    getUsersInfoApi(token, user.id).then((response) => {
      //console.log(response);
      setUserinfo(response.user);
    });

    getHaveresidualGroupApi(token).then((response) => {
      setMaxresidualgroup(response.haveresiduals);
      console.log(response.haveresiduals);
    });

    getTypesGroupApi(token, province).then((response) => {
      setTypes(response.types);
      console.log(response.types);
    });

    getEntrepreneurshipsApi(token).then((response) => {
      //console.log(response);
      setUsers(response.entrepreneurships);
      setLoadingData(false);
    });

    getPotentialsApi(user.id, 5, 40, 10).then((response) => {
      console.log(response);
      setPotentials(response.users);
    });

    getPotentialsApi(user.id, 5, 10, 40).then((response) => {
      console.log(response);
      setPotentialsNeed(response.users);
    });

    getCountUsersApi(token, true).then((response) => {
      setCountUserActive(response.count);
    });

    getMaxNeedApi(token).then((response) => {
      setNeed(response.need);
    });

    getNeedGroupApi(token, true).then((response) => {
      const data1 = [];
      console.log(response.needs);
      let needs1 = response.needs;
      needs1.map((need) =>
        data1.push({
          name: need.name,
          count: parseInt(need.count),
          type: "Demanda",
        })
      );
      //console.log(data1);
      setGroupNeed(data1);
      //setLoadingN(false);
    });
    getHaveGroupApi(token, true).then((response) => {
      const data2 = [];
      //console.log(response.haves);
      let haves1 = response.haves;
      haves1.map((have) =>
        data2.push({
          name: have.name,
          count: parseInt(have.count),
          type: "Oferta",
        })
      );
      //console.log(data2);
      setGroupHave(data2);
      //setLoadingH(false);
    });

    getMaxSectorApi(token).then((response) => {
      setMaxactor(response.sector);
    });

    getMaxHaveresidualApi(token).then((response) => {
      setMaxresidual(response.haveresidual);
    });

    // getCountUsersApi(token, false).then((response) => {
    //   setCountUserInactive(response.count);
    // });

    // getAllFincasApi(token).then((response) => {
    //   setFincas(response.fincas);
    // });
  }, [token]);

  // const iconFinca = new L.Icon({
  //   iconUrl: fincaSvg,
  //   iconRetinaUrl: fincaSvg,
  //   iconSize: [64, 64],
  //   iconAnchor: [32, 64],
  //   popupAnchor: [0, -46],
  //   shadowUrl: null,
  //   shadowSize: null,
  //   shadowAnchor: null,
  // });

  const config = {
    isStack: true,
    xField: "count",
    yField: "name",
    seriesField: "type",
    //height: 300,
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    content: "{value} horas",
  };

  const config2 = {
    xField: "name",
    yField: "count",
    height: 400,
    label: {
      position: "middle",
      // 'top', 'bottom', 'middle',
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      name: {
        alias: "Residuales",
      },
      count: {
        alias: "Cantidad",
      },
    },
  };

  const config3 = {
    // xField: "type",
    // yField: "value",
    // height: 400,
    // label: {
    //   position: "middle",
    //   // 'top', 'bottom', 'middle',
    //   style: {
    //     fill: "#FFFFFF",
    //     opacity: 0.6,
    //   },
    // },
    // xAxis: {
    //   label: {
    //     autoHide: true,
    //     autoRotate: false,
    //   },
    // },
    // meta: {
    //   type: {
    //     alias: "Sectores",
    //   },
    //   value: {
    //     alias: "Cantidad",
    //   },
    // },
    xField: "value",
    yField: "type",
    seriesField: "type",
    legend: {
      position: "top-left",
    },
  };

  const ref = useRef();

  // export image
  const downloadImage = () => {
    ref.current?.downloadImage();
  };

  const dataEj1 = [
    {
      name: "Biofuturo (Laboratorios de biotecnología)",
      count: 3,
      type: "Demanda",
    },
    {
      name: "Biofuturo (Software de bioinformática)",
      count: 3,
      type: "Demanda",
    },
    {
      name: "Biofuturo (invernaderos especializados)",
      count: 12,
      type: "Demanda",
    },
    {
      name: "SITC (Infraestructura de cloud computing )",
      count: 7,
      type: "Demanda",
    },
    { name: "SITC (Inteligencia artificial )", count: 8, type: "Demanda" },
    {
      name: "SITC (Incubadoras, aceleradoras y fondos de inversión)",
      count: 2,
      type: "Demanda",
    },
    { name: "SITC (Ciencia de datos)", count: 1, type: "Demanda" },
    {
      name: "Ciudad GovTech (Sistemas integrados de gestión municipal)",
      count: 8,
      type: "Demanda",
    },
    {
      name: "Ciudad GovTech (kioscos digitales de autoservicio)",
      count: 4,
      type: "Demanda",
    },
    {
      name: "Ciudad GovTech (Centros de datos para almacenamiento y procesamiento)",
      count: 3,
      type: "Demanda",
    },
    {
      name: "Salud y Bienestar (Laboratorios de investigación clínica)",
      count: 7,
      type: "Demanda",
    },
    { name: "Salud y Bienestar (Telemedicina)", count: 2, type: "Demanda" },
    { name: "Salud y Bienestar (Nanotecnología)", count: 3, type: "Demanda" },
    {
      name: "Hub Logístico  (sistemas de logística inversa)",
      count: 1,
      type: "Demanda",
    },
    {
      name: "Hub Logístico (sistemas de gestión de transporte eficiente)",
      count: 1,
      type: "Demanda",
    },
    {
      name: "Biofuturo (Laboratorios de biotecnología)",
      count: 6,
      type: "Oferta",
    },
    {
      name: "SITC (Infraestructura de cloud computing )",
      count: 7,
      type: "Oferta",
    },
    { name: "SITC (Inteligencia artificial )", count: 7, type: "Oferta" },
    {
      name: "SITC (Incubadoras, aceleradoras y fondos de inversión)",
      count: 8,
      type: "Oferta",
    },
    { name: "SITC (Ciencia de datos)", count: 2, type: "Oferta" },
    {
      name: "Biofuturo (Software de bioinformática)",
      count: 1,
      type: "Oferta",
    },
    {
      name: "Biofuturo (invernaderos especializados)",
      count: 3,
      type: "Oferta",
    },
    {
      name: "Ciudad GovTech (Sistemas integrados de gestión municipal)",
      count: 2,
      type: "Oferta",
    },
  ];

  const dataEj2 = [
    { type: "Biofuturo", value: 6 },
    { type: "Servicios Intensivos en Tecnología y Conocimiento", value: 3 },
    { type: "Ciudad GovTech", value: 7 },
    { type: "Salud y Bienestar", value: 3 },
    { type: "Ciudad Circular Resiliente y Sostenible", value: 9 },
    { type: "Hub Logístico", value: 11 },
    { type: "Turismo Inteligente", value: 14 },
    { type: "Economía Naranja", value: 2 },
    { type: "Economía Violeta", value: 3 },
    { type: "Otros", value: 1 },
  ];

  const dataEj3 = [
    { count: 4, name: "Biotecnólogos" },
    { count: 5, name: "Desarrolladores de Software y Aplicaciones" },
    { count: 11, name: "Analistas de Sistemas Gubernamentales" },
    { count: 3, name: "Profesionales de la Telemedicina" },
    { count: 5, name: "RAEE (Residuos de aparatos eléctricos y electrónicos)" },
    { count: 4, name: "Arquitectos Sostenibles" },
    { count: 9, name: "Ingenieros de Big Data en Logística" },
    { count: 4, name: "Desarrolladores de Experiencias Turísticas Digitales" },
    { count: 2, name: "Gestores Culturales" },
    { count: 6, name: "Especialistas en Género y Desarrollo" },
  ];

  return (
    <>
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        closable={false}
        width={1400}
      >
        {modalContent}
      </ModalUser>
      <PageHeader
        className="site-page-header left"
        title="Distrito de innovación  - Quito"
        //subTitle="Ecosistema sostenible en Ecuador"
        //tags={<Tag color="orange">{userinfo?.entrepreneurships[0]?.name}</Tag>}
        breadcrumb={
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>Empresa</Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </Breadcrumb>
        }
      />
      <div className="site-statistic-demo-card">
        <Row gutter={24}>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#ec407a",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <BankOutlined style={{ fontSize: "40px", color: "white" }} />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>9</h2>
                  </Avatar>
                }
                title="Actor más activo"
                description="Academia"
              />
            </Card>
          </Col>

          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#66bb6a",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <ConsoleSqlOutlined
                    style={{ fontSize: "40px", color: "white" }}
                  />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>15</h2>
                  </Avatar>
                }
                title="Mayor demanda"
                description="IA Inteligencia artificial"
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#ef5350",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <LaptopOutlined
                    style={{ fontSize: "40px", color: "white" }}
                  />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h4 style={{ color: "white" }}>11</h4>
                  </Avatar>
                }
                title="Mayor demanda profesional"
                description="Desarrolladores de APP"
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#26c6da",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <NotificationOutlined
                    style={{ fontSize: "40px", color: "white" }}
                  />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>18</h2>
                  </Avatar>
                }
                title="Mayor oferta profesional"
                description="Gestor cultural"
              />
            </Card>
          </Col>
        </Row>
        <br />
        <Card
          title={
            <span>
              Banco de recursos en oferta y demanda para gestión de la
              innovación por áreas
            </span>
          }
        >
          <Bar
            data={dataEj1}
            {...config}
            onReady={(plot) => {
              ref.current = plot;
              setDisabled(false);
            }}
          />
          <br />
          <Button
            disabled={disabled}
            htmlType="button"
            className="btn-submit"
            onClick={downloadImage}
          >
            Guardar gráfico
          </Button>
        </Card>
        <br />
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card
              title={
                <span>
                  Áreas de especialización para la innovación por distritos
                  tecnológicos
                </span>
              }
            >
              <Bar
                data={dataEj2}
                {...config3}
                // onReady={(plot) => {
                //   ref.current = plot;
                //   setDisabled(false);
                // }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card title={<span>Profesiones del futuro</span>}>
              <Column
                data={dataEj3}
                {...config2}
                // onReady={(plot) => {
                //   ref.current = plot;
                //   setDisabled(false);
                // }}
              />
            </Card>
          </Col>
        </Row>
        <PageHeader
          className="site-page-header left"
          title="Ecosistema sostenible en Ecuador"
          //subTitle="Ecosistema sostenible en Ecuador"
          //tags={<Tag color="orange">{userinfo?.entrepreneurships[0]?.name}</Tag>}
        />
        <Row gutter={24}>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#ec407a",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <BankOutlined style={{ fontSize: "40px", color: "white" }} />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>{maxactor.count}</h2>
                  </Avatar>
                }
                title="Actor más activo"
                description={maxactor.name}
              />
            </Card>
          </Col>

          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#66bb6a",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <RiseOutlined style={{ fontSize: "40px", color: "white" }} />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>{need.count}</h2>
                  </Avatar>
                }
                title="Mayor demanda"
                description={need.name}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#ef5350",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <RestOutlined style={{ fontSize: "40px", color: "white" }} />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h4 style={{ color: "white" }}>{maxresidual.count}</h4>
                  </Avatar>
                }
                title="Mayor Residuo (KG)"
                description={maxresidual.name}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#26c6da",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <ContainerOutlined
                    style={{ fontSize: "40px", color: "white" }}
                  />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>{countUserActive}</h2>
                  </Avatar>
                }
                title="Registros"
                description="Total registros con empresa"
              />
            </Card>
          </Col>
        </Row>
        <br />
        <Card
          title={
            <span>
              Banco de recursos en Oferta y Demanda para mejorar la cadena de
              valor (actividades primarias)
            </span>
          }
        >
          <Bar
            data={[...(groupHave ?? []), ...(groupNeed ?? [])]}
            {...config}
            onReady={(plot) => {
              ref.current = plot;
              setDisabled(false);
            }}
          />
          <br />
          <Button
            disabled={disabled}
            htmlType="button"
            className="btn-submit"
            onClick={downloadImage}
          >
            Guardar gráfico
          </Button>
        </Card>
        <br />
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card title={<span>Sectores económicos más registrados</span>}>
              <Bar
                data={types ?? []}
                {...config3}
                // onReady={(plot) => {
                //   ref.current = plot;
                //   setDisabled(false);
                // }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card title={<span>Residuos más registrados</span>}>
              <Column
                data={maxresidualgroup ?? []}
                {...config2}
                // onReady={(plot) => {
                //   ref.current = plot;
                //   setDisabled(false);
                // }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
