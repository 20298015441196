import React from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { apiKeyGoogle } from "../../api/config";

const options = {
  disableDefaultUI: true,
  scaleControl: true,
  zoomControl: true,
  mapTypeId: "roadmap",
  labels: true,
};

export default function Map(props) {
  const { setMap, children, defaultCenter } = props;
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKeyGoogle, // ,
    // ...otherOptions
  });

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that

    // const onLoad = React.useCallback(function onLoad(mapInstance) {
    //   // do something with map Instance
    //   setMapRef(mapInstance);
    // });

    const loadHandler = (map) => {
      setMap(map);
    };

    return (
      <GoogleMap
        id="circle-example"
        mapContainerStyle={{
          height: "500px",
          width: "100%",
        }}
        zoom={7}
        center={defaultCenter}
        options={options}
        onLoad={loadHandler}
      >
        {children}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>No se puede cargar el mapa este momento.</div>;
  }

  return isLoaded ? renderMap() : <div>Cargando...</div>;
}
