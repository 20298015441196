import React, { useState, useEffect } from "react";
import { Select, Form, Row, Col, Checkbox } from "antd";

import { getQuestionsGroupApi } from "../../../../api/question.api";

import "./SelectQuestion1Form.scss";

//const { Option } = Select;

export default function SelectQuestion1Form(props) {
  const { question, spanFixXs, spanFixsm, spanFixmd, spanFixlg, spanFixxl } =
    props;
  const [question1Data, setQuestion1Data] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  //const [question1Id, setQuestion1Id] = useState();
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    getQuestionsGroupApi(question).then((response) => {
      console.log(response.questions);
      setQuestion1Data(response.questions);
      //setQuestion1Id(atributoData);
    });
    setLoadingData(false);
  }, [loadingData, question]);

  // const handleAtributoChange = (value) => {
  //   setQuestion1Id(value);
  //   setAtributoData(value);
  // };

  const onChange = (checkedValues) => {
    setChecked(checkedValues);
  };

  const isDisabled = (id, max) => {
    if (max === 0) {
      return false;
    }
    return checked.length > max - 1 && checked.indexOf(id) === -1;
  };

  return (
    <div className="select-question1-form">
      {question1Data && question1Data.map
        ? question1Data.map((item) => (
            <Form.Item
              label={item.name}
              name={`question${item.id}group`}
              //rules={[{ required: true, message: "Seleccione una ciudad" }]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              key={`group1_${item.id}`}
              tooltip={item.help}
              //className="biglabel"
            >
              <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
                <Row>
                  {item.answers && item.answers.map
                    ? item.answers.map((answer) => (
                        <Col
                          xs={spanFixXs}
                          sm={spanFixsm}
                          md={spanFixmd}
                          lg={spanFixlg}
                          xl={spanFixxl}
                          key={`group1_${item.id}_${answer.id}`}
                        >
                          <Checkbox
                            value={answer.id}
                            disabled={isDisabled(
                              answer.id,
                              item.groupquestion.max
                            )}
                          >
                            {answer.name}
                          </Checkbox>
                        </Col>
                      ))
                    : ""}
                  {/* <Col span={8}>
                    <Checkbox
                      value="A"
                      disabled={isDisabled("A", item.groupquestion.max)}
                    >
                      A
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      value="B"
                      disabled={isDisabled("B", item.groupquestion.max)}
                    >
                      B
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      value="C"
                      disabled={isDisabled("C", item.groupquestion.max)}
                    >
                      C
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      value="D"
                      disabled={isDisabled("D", item.groupquestion.max)}
                    >
                      D
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      value="E"
                      disabled={isDisabled("E", item.groupquestion.max)}
                    >
                      E
                    </Checkbox>
                  </Col> */}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          ))
        : ""}
    </div>
  );
}
