import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Row, Col, DatePicker, Button } from "antd";
import { Bar } from "@ant-design/charts";
import { getAccessTokenApi } from "../../../api/auth";

import "./Indicadores.scss";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

import {
  getEntrepreneurshipFacturadoApi,
  getEntrepreneurshipFacturadoPasadoApi,
} from "../../../api/entrepreneurship.api";
import SelectProvinceAllForm from "../../../components/Admin/Province/SelectProvinceForm/SelectProvinceAllForm";
export default function Facturacion() {
  const token = getAccessTokenApi();
  //const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [disabled2, setDisabled2] = useState(true);
  const [province, setProvince] = useState(0);

  useEffect(() => {
    getEntrepreneurshipFacturadoPasadoApi(token, province).then((response) => {
      //console.log(response.entrepreneurships);
      setData(response.entrepreneurships);
    });

    getEntrepreneurshipFacturadoApi(token, province).then((response) => {
      //console.log(response.entrepreneurships);
      setData2(response.entrepreneurships);
    });
  }, [token, province]);

  const config = {
    //data,
    xField: "count",
    yField: "type",
    seriesField: "type",
    legend: {
      position: "top-left",
    },
    label: {
      position: "middle",
      style: {
        fill: "#000",
        opacity: 1,
      },
    },
  };

  const config2 = {
    //data2,
    xField: "count",
    yField: "type",
    seriesField: "type",
    legend: {
      position: "top-left",
    },
    label: {
      position: "middle",
      style: {
        fill: "#000",
        opacity: 1,
      },
      // formatter: function formatter(v) {
      //   return "$ " + v;
      // },
    },
  };

  // function onChange(date, dateString) {
  //   if (date) {
  //     setFechaInicial(date[0].startOf("month").format("YYYY-MM-DD"));
  //     setFechaFinal(date[1].endOf("month").format("YYYY-MM-DD"));
  //   } else {
  //     setFechaInicial(null);
  //     setFechaFinal(null);
  //   }
  // }

  const ref = useRef([]);

  //const ref2 = useRef();
  // export image
  const downloadImage = () => {
    ref.current[0]?.downloadImage();
  };

  const downloadImage2 = () => {
    ref.current[1]?.downloadImage();
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Diagnóstico</Breadcrumb.Item>
        <Breadcrumb.Item>Facturación</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <Row gutter={24}>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <SelectProvinceAllForm
              atributoData={province}
              setAtributoData={setProvince}
            ></SelectProvinceAllForm>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <br />
            <h2>¿Cuánto facturó el año pasado?</h2>

            <Bar
              data={data ?? []}
              {...config}
              onReady={(plot) => {
                ref.current[0] = plot;
                setDisabled(false);
              }}
            />
            <br />
            <Button
              disabled={disabled}
              htmlType="button"
              className="btn-submit"
              onClick={downloadImage}
            >
              Guardar gráfico
            </Button>
          </Col>
          <Col span={12}>
            <br />
            <h2>¿Cuánto espera facturar este año?</h2>
            <Bar
              data={data2 ?? []}
              {...config2}
              onReady={(plot) => {
                ref.current[1] = plot;
                setDisabled2(false);
              }}
            />
            <br />
            <Button
              disabled={disabled2}
              htmlType="button"
              className="btn-submit"
              onClick={downloadImage2}
            >
              Guardar gráfico
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
