import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  Statistic,
  Card,
  Row,
  Col,
  List,
  Image,
  Spin,
  Avatar,
  Progress,
  PageHeader,
  Tag,
  Typography,
  notification,
  Button,
  Modal,
} from "antd";
import { Bar } from "@ant-design/charts";
import { useNavigate } from "react-router-dom";
import {
  RestOutlined,
  BankOutlined,
  ContainerOutlined,
  RiseOutlined,
} from "@ant-design/icons";

//import { Link } from "react-router-dom";
import { getCountUsersApi } from "../../api/user.api";

import { getAccessTokenApi } from "../../api/auth";
import useAuth from "../../hooks/useAuth";
// import fincaSvg from "../../assets/img/svg/finca.svg";
// import L from "leaflet";
import { getHaveGroupApi } from "../../api/have.api";
import { getMaxNeedApi, getNeedGroupApi } from "../../api/need.api";
import {
  //getUsersApi,
  getPotentialsApi,
  getUsersInfoApi,
} from "../../api/user.api";
import { getEntrepreneurshipsApi } from "../../api/entrepreneurship.api.js";

import { getMaxSectorApi } from "../../api/sector.api";

import { getMaxHaveresidualApi } from "../../api/haveresidual.api";

import GoogleMapReact from "google-map-react";
import noAvatar from "../../assets/img/png/no-avatar.png";

//import "react-leaflet-markercluster/dist/styles.min.css";
import Pin from "../../assets/img/png/pin.png";
//import Marker from "../../../components/Common/marker";
import { apiKeyGoogle } from "../../api/config";
import { returnTruncate } from "../../utils/functions";
import { Link } from "react-router-dom";
import ModalUser from "../../components/Admin/ModalUser";
import Actualizacion from "../../components/Home/User/Actualizacion";
import "./User.scss";

const DefaultLocation = { lat: -1.831239, lng: -78.183406 };
const DefaultZoom = 7;

export default function User({ socket, complete }) {
  const token = getAccessTokenApi();
  const [countUserActive, setCountUserActive] = useState();
  const [users, setUsers] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [potentials, setPotentials] = useState();
  const [potentialsNeed, setPotentialsNeed] = useState();
  const { user, isLoading } = useAuth(); //hook
  const [userinfo, setUserinfo] = useState();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [updateInfo, setUpdateInfo] = useState(false);
  const [lastsocket, setLastsocket] = useState();
  const [groupHave, setGroupHave] = useState();
  const [groupNeed, setGroupNeed] = useState();
  const [disabled, setDisabled] = useState(true);
  const [need, setNeed] = useState([]);
  const [maxactor, setMaxactor] = useState([]);
  const [maxresidual, setMaxresidual] = useState([]);
  const { Text } = Typography;
  const { Meta } = Card;
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
    navigate("/user/personal");
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  useEffect(() => {
    console.log("complete");
    console.log(complete);
    if (complete !== undefined) {
      if (complete.total < 100) {
        showModal();
      }
    }
  }, [complete]);

  useEffect(() => {
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
    socket.on("messageResponse", (data) => {
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
  }, [socket]);

  //const [countEncuestas, setCountEncuestas] = useState();
  //const [fincas, setFincas] = useState([]);
  useEffect(() => {
    getUsersInfoApi(token, user.id).then((response) => {
      //console.log(response);
      setUserinfo(response.user);
    });
  }, [token, updateInfo]);

  useEffect(() => {
    // getCountFincaApi(token).then((response) => {
    //   setCountFincas(response.count);
    // });

    // getCountEncuestaApi(token).then((response) => {
    //   setCountEncuestas(response.count);
    // });
    setLoadingData(true);

    getUsersInfoApi(token, user.id).then((response) => {
      //console.log(response);
      setUserinfo(response.user);
      //setData(response.user);

      var tabStart = {
        user: 20,
        empresa: 0,
        have: 0,
        need: 0,
        haveresidual: 0,
        needresidual: 0,
        total: 0,
      };
      if (response.user.entrepreneurships.length > 0) {
        tabStart.empresa = 20;
        if (response.user.entrepreneurships[0].haves.length > 0) {
          tabStart.have = 15;
        }
        if (response.user.entrepreneurships[0].needs.length > 0) {
          tabStart.need = 15;
        }
        if (response.user.entrepreneurships[0].haveresiduals.length > 0) {
          tabStart.haveresidual = 15;
        }
        if (response.user.entrepreneurships[0].needresiduals.length > 0) {
          tabStart.needresidual = 15;
        }
      }

      tabStart.total =
        tabStart.user +
        tabStart.empresa +
        tabStart.have +
        tabStart.need +
        tabStart.haveresidual +
        tabStart.needresidual;

      //setComplete(tabStart);

      if (tabStart.total < 0) {
        setIsVisibleModal(true);
        setModalTitle(
          "Complete esta infomación para empezar a conectar con otras empresas"
        );
        setmodalContent(
          <Actualizacion
            userData={response.user}
            tabStart={tabStart}
            setIsVisibleModal={setIsVisibleModal}
            setUpdateInfo={setUpdateInfo}
            hasEntrepreneurshipData={tabStart > 0}
          />
        );
      }
    });

    getEntrepreneurshipsApi(token).then((response) => {
      //console.log(response);
      setUsers(response.entrepreneurships);
      setLoadingData(false);
    });

    getPotentialsApi(user.id, 5, 40, 10).then((response) => {
      console.log(response);
      setPotentials(response.users.filter((item) => item.porcentaje > 0));
    });

    getPotentialsApi(user.id, 5, 10, 40).then((response) => {
      console.log(response);
      setPotentialsNeed(response.users.filter((item) => item.porcentaje > 0));
    });

    getCountUsersApi(token, true).then((response) => {
      setCountUserActive(response.count);
    });

    getMaxNeedApi(token).then((response) => {
      setNeed(response.need);
    });

    getNeedGroupApi(token, true).then((response) => {
      const data1 = [];
      console.log(response.needs);
      let needs1 = response.needs;
      needs1.map((need) =>
        data1.push({
          name: need.name,
          count: parseInt(need.count),
          type: "Demanda",
        })
      );
      setGroupNeed(data1);
      //setLoadingN(false);
    });
    getHaveGroupApi(token, true).then((response) => {
      const data2 = [];
      //console.log(response.haves);
      let haves1 = response.haves;
      haves1.map((have) =>
        data2.push({
          name: have.name,
          count: parseInt(have.count),
          type: "Oferta",
        })
      );

      setGroupHave(data2);
      //setLoadingH(false);
    });

    getMaxSectorApi(token).then((response) => {
      setMaxactor(response.sector);
    });

    getMaxHaveresidualApi(token).then((response) => {
      setMaxresidual(response.haveresidual);
    });

    // getCountUsersApi(token, false).then((response) => {
    //   setCountUserInactive(response.count);
    // });

    // getAllFincasApi(token).then((response) => {
    //   setFincas(response.fincas);
    // });
  }, [token]);

  // const iconFinca = new L.Icon({
  //   iconUrl: fincaSvg,
  //   iconRetinaUrl: fincaSvg,
  //   iconSize: [64, 64],
  //   iconAnchor: [32, 64],
  //   popupAnchor: [0, -46],
  //   shadowUrl: null,
  //   shadowSize: null,
  //   shadowAnchor: null,
  // });

  const config = {
    isStack: true,
    xField: "count",
    yField: "name",
    seriesField: "type",
    height: 400,
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    content: "{value} horas",
  };

  const ref = useRef();

  // export image
  const downloadImage = () => {
    ref.current?.downloadImage();
  };

  return (
    <>
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        closable={false}
        width={1400}
      >
        {modalContent}
      </ModalUser>
      <PageHeader
        className="site-page-header left"
        title={userinfo?.name}
        subTitle="Este es tu dashboard de Economía circular y colaborativa para tu empresa:"
        tags={<Tag color="orange">{userinfo?.entrepreneurships[0]?.name}</Tag>}
        breadcrumb={
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>Empresa</Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </Breadcrumb>
        }
      />
      <div className="site-statistic-demo-card">
        <Row gutter={24}>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#ec407a",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <BankOutlined style={{ fontSize: "40px", color: "white" }} />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>{maxactor.count}</h2>
                  </Avatar>
                }
                title="Actor más activo"
                description={maxactor.name}
              />
            </Card>
          </Col>

          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#66bb6a",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <RiseOutlined style={{ fontSize: "40px", color: "white" }} />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>{need.count}</h2>
                  </Avatar>
                }
                title="Mayor demanda"
                description={need.name}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#ef5350",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <RestOutlined style={{ fontSize: "40px", color: "white" }} />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h4 style={{ color: "white" }}>{maxresidual.count}</h4>
                  </Avatar>
                }
                title="Mayor Residuo (KG)"
                description={maxresidual.name}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#26c6da",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <ContainerOutlined
                    style={{ fontSize: "40px", color: "white" }}
                  />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>{countUserActive}</h2>
                  </Avatar>
                }
                title="Registros"
                description="Total de registros"
              />
            </Card>
          </Col>
        </Row>
        <br />
        <Card
          title={
            <span>
              Banco de recursos en Oferta y Demanda para mejorar la cadena de
              valor (actividades primarias)
            </span>
          }
        >
          <Bar
            data={[...(groupHave ?? []), ...(groupNeed ?? [])]}
            {...config}
            onReady={(plot) => {
              ref.current = plot;
              setDisabled(false);
            }}
          />
          <br />
          <Button
            disabled={disabled}
            htmlType="button"
            className="btn-submit"
            onClick={downloadImage}
          >
            Guardar gráfico
          </Button>
        </Card>
        <br />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Card
              title={<span>Mapa de empresas</span>}
              // extra={
              //   <Button
              //     type="dashed"
              //     onClick={() => currentLocation()}
              //     icon={<AimOutlined />}
              //     size="small"
              //     className="mini"
              //     loading={searchlocation}
              //   >
              //     buscar mi ubicación
              //   </Button>
              // }
              bodyStyle={{ padding: "0" }}
              //style={{ height: "300px", width: "100%" }}
            >
              {/* <MapPicker
                defaultLocation={defaultLocation}
                zoom={zoom}
                mapTypeId="roadmap"
                style={{ height: "300px" }}
                onChangeLocation={handleChangeLocation}
                onChangeZoom={handleChangeZoom}
                // apiKey="AIzaSyDy8AE5RqF-ozGiI8J7r-U1vnb3AwQCY-o"
                apiKey={apiKeyGoogle}
              /> */}
              <div style={{ height: "412px", width: "100%" }}>
                <GoogleMapReact
                  defaultZoom={zoom}
                  defaultCenter={defaultLocation}
                  bootstrapURLKeys={{
                    key: apiKeyGoogle,
                    language: "es",
                    region: "ec",
                    libraries: [],
                  }}
                  //onClick={handleChangeLocation}
                  //center={location}
                >
                  {!loadingData ? (
                    users.map((user) => (
                      <Image
                        key={user.id}
                        width={32}
                        src={Pin}
                        lat={user.lat}
                        lng={user.lon}
                        preview={false}
                        style={{ marginTop: "-32px", marginLeft: "-16px" }}
                      />
                    ))
                  ) : (
                    // <GoogleMapReact.Marker
                    //   key={"mylocation"}
                    //   text={"Mi ubicación"}
                    //   lat={location.lat}
                    //   lng={location.lng}
                    // />
                    <Spin width={100} lat={location.lat} lng={location.lng} />
                  )}
                </GoogleMapReact>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={7} xl={7}>
            <Card
              title={<span>A quién puedo entregar recursos</span>}
              // extra={
              //   <Link to={"/user/conexiones"}>
              //     <RightOutlined />
              //   </Link>
              // }
            >
              <List
                itemLayout="horizontal"
                dataSource={potentials}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={noAvatar} />}
                      title={
                        <div className="truncate">
                          {item.name.toUpperCase() +
                            " " +
                            item.lastname.toUpperCase()}
                        </div>
                      }
                      description={
                        <Progress
                          percent={
                            item.porcentaje >= 100 ? 100 : item.porcentaje
                          }
                          strokeColor={
                            item.porcentaje > 50
                              ? "#1ABC9C"
                              : item.porcentaje > 30
                              ? "#F5B041"
                              : "#F9E79F"
                          }
                          size="small"
                        />
                      }
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={7} xl={7}>
            <Card
              title={<span>Quién me puede entregar recursos</span>}
              // extra={
              //   <Link to={"/user/conexiones"}>
              //     <RightOutlined />
              //   </Link>
              // }
            >
              <List
                itemLayout="horizontal"
                dataSource={potentialsNeed}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={noAvatar} />}
                      title={
                        <div class="truncate">
                          {item.name.toUpperCase() +
                            " " +
                            item.lastname.toUpperCase()}
                        </div>
                      }
                      description={
                        <Progress
                          percent={
                            item.porcentaje >= 100 ? 100 : item.porcentaje
                          }
                          strokeColor={
                            item.porcentaje > 50
                              ? "#1ABC9C"
                              : item.porcentaje > 30
                              ? "#F5B041"
                              : "#F9E79F"
                          }
                          size="small"
                        />
                      }
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
        <Modal
          title="Información de perfil incompleta"
          visible={open}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Ir a mi perfil"
        >
          <p>
            Por favor complete la información de su perfil para tener mayor
            probabilidad de conexiones
          </p>
        </Modal>
      </div>
    </>
  );
}
