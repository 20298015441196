import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";

import { GetCantonByProvinceApi } from "../../../../api/canton.api";

import "./SelectCantonForm.scss";

const { Option } = Select;

export default function SelectCantonForm(props) {
  const { atributoData, setAtributoData, provinceData } = props;
  const [cantonData, setCantonData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(false);
  const [cantonId, setCantonId] = useState();
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (provinceData > 0) {
      setLoadingData(true);
      GetCantonByProvinceApi(provinceData).then((response) => {
        setCantonData(response.cantons);
        setCantonId(atributoData);
        setLoadingData(false);
        setEnabled(true);
      });
    }
  }, [provinceData]);

  const handleAtributoChange = (value) => {
    setCantonId(value);
    setAtributoData(value);
  };

  return (
    <div className="select-canton-form">
      <Form.Item
        label="Cantón"
        name="cantonId"
        rules={[{ required: true, message: "Seleccione un cantón" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        disabled={!enabled}
      >
        <Select
          placeholder="Seleccione un cantón"
          value={cantonId}
          onChange={handleAtributoChange}
          showSearch
          loading={loadingData}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          disabled={!enabled}
        >
          {cantonData && cantonData.map
            ? cantonData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
