import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Row, Col, Button } from "antd";
import { Bar } from "@ant-design/charts";
import { getAccessTokenApi } from "../../../api/auth";

import "./Indicadores.scss";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

import { getDiagnosticSkillsGroupApi } from "../../../api/entrepreneurship.api";
import SelectProvinceAllForm from "../../../components/Admin/Province/SelectProvinceForm/SelectProvinceAllForm";

export default function DiagnosticoBlandas() {
  const token = getAccessTokenApi();
  //const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [province, setProvince] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalrows, setTotalrows] = useState(0);
  const [promedio, setPromedio] = useState(0.0);
  const ref = useRef();

  useEffect(() => {
    // if (fincaId.length > 0) {
    //   if (fechaInicial && fechaFinal) {
    //     getFincasAmbitoAvgApi(token, fechaInicial, fechaFinal, fincaId).then(
    //       (response) => {
    //         setData(response.fincas);
    //       }
    //     );
    //   } else {
    //     getFincasAmbitoAvgApi(token, 0, 0, fincaId).then((response) => {
    //       setData(response.fincas);
    //     });
    //   }
    // } else {
    //   if (fechaInicial && fechaFinal) {
    //     getFincasAmbitoAvgApi(token, fechaInicial, fechaFinal, 0).then(
    //       (response) => {
    //         setData(response.fincas);
    //       }
    //     );
    //   } else {
    //     getFincasAmbitoAvgApi(token, 0, 0, 0).then((response) => {
    //       setData(response.fincas);
    //     });
    //   }
    // }
    getDiagnosticSkillsGroupApi(token, province, 1).then((response) => {
      //console.log(response);
      let entrepreneurship1 = response.entrepreneurships;
      var subtotal = 0;
      entrepreneurship1.map(
        (entrepreneurship) =>
          (subtotal = subtotal + entrepreneurship.diagnostic)
      );

      var uniqueNames = [];
      for (var i = 0; i < entrepreneurship1.length; i++) {
        if (uniqueNames.indexOf(entrepreneurship1[i].entrepreneurship) === -1) {
          uniqueNames.push(entrepreneurship1[i].entrepreneurship);
        }
      }
      setTotalrows(entrepreneurship1.length);
      setTotal(uniqueNames.length);
      setPromedio(Math.round((subtotal / uniqueNames.length) * 100) / 100);
      setData(response.entrepreneurships);
    });
  }, [token, province]);

  const config = {
    data,
    isGroup: true,
    //xField: 'value',
    //yField: 'label',
    //seriesField: 'type',
    xField: "diagnostic",
    yField: "entrepreneurship",
    seriesField: "question",
    legend: {
      position: "top-left",
    },
    //maxBarWidth: 30,
    //minBarWidth: 20,
    //autoFit: true,
    height: totalrows * 35,
    label: {
      position: "middle",
      style: {
        fill: "#000",
        opacity: 1,
      },
    },
    meta: {
      entrepreneurship: {
        alias: "Emprendimiento",
      },
      diagnostic: {
        alias: "Total",
      },
    },
  };

  const DemoBar = () => {
    return (
      <Bar
        {...config}
        onReady={(plot) => {
          ref.current = plot;
          setDisabled(false);
        }}
      />
    );
  };

  // const config = {
  //   appendPadding: 10,
  //   data,
  //   angleField: "value",
  //   colorField: "type",
  //   radius: 0.75,
  //   label: {
  //     type: "spider",
  //     labelHeight: 28,
  //     content: "{name} - {value}\n{percentage}",
  //   },
  //   interactions: [
  //     {
  //       type: "element-selected",
  //     },
  //     {
  //       type: "element-active",
  //     },
  //   ],
  // };

  // const config = {
  //   appendPadding: 10,
  //   data,
  //   angleField: "value",
  //   colorField: "type",
  //   radius: 0.9,
  //   label: {
  //     type: "inner",
  //     offset: "-30%",
  //     content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
  //     style: {
  //       fontSize: 14,
  //       textAlign: "center",
  //     },
  //   },
  //   interactions: [
  //     {
  //       type: "element-active",
  //     },
  //   ],
  // };

  // function onChange(date, dateString) {
  //   if (date) {
  //     setFechaInicial(date[0].startOf("month").format("YYYY-MM-DD"));
  //     setFechaFinal(date[1].endOf("month").format("YYYY-MM-DD"));
  //   } else {
  //     setFechaInicial(null);
  //     setFechaFinal(null);
  //   }
  // }

  // export image
  const downloadImage = () => {
    ref.current?.downloadImage();
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Gráficas</Breadcrumb.Item>
        <Breadcrumb.Item>Diagnóstico</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        {/* <Row gutter={16}>
          <Col span={12}>
            <RangePicker
              style={{ width: "100%" }}
              picker="month"
              onChange={onChange}
            />
          </Col>
          <Col span={12}>
            { <SelectMultiFinca setFincaId={setFincaId}></SelectMultiFinca> }
          </Col>
        </Row> */}

        <br />
        <h2>
          Diagnóstico habilidades blandas ({total} emprendimientos, promedio{" "}
          {promedio})
        </h2>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <SelectProvinceAllForm
              atributoData={province}
              setAtributoData={setProvince}
            ></SelectProvinceAllForm>
          </Col>
        </Row>
        <DemoBar />
        <br />
        <Button
          disabled={disabled}
          htmlType="button"
          className="btn-submit"
          onClick={downloadImage}
        >
          Guardar gráfico
        </Button>
      </div>
    </div>
  );
}
