import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Button, Form, Card, Row, Col } from "antd";
import { getAccessTokenApi } from "../../../api/auth";
//import Pin from "../../../assets/img/png/pin.png";
//import { apiKeyGoogle } from "../../../api/config";
import { getUsersMapApi } from "../../../api/user.api";
//import GoogleMapReact from "google-map-react";
//import styled from "styled-components";
//import Marker from "../../../components/Common/marker";
//import { HeatMapOutlined } from "@ant-design/icons";
//import NewMarker from "../../../components/Common/newmarker";
import MapContainer from "../../../components/Common/MapContainer";

// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   //Marker,
//   //HeatMap,
// } from "react-google-maps";

import "./Indicadores.scss";
import SelectKnowledgeAllForm from "../../../components/Admin/Knowledge/SelectKnowledgeForm/SelectKnowledgeAllForm";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

//import { getNeedGroupApi } from "../../../api/need.api";

const DefaultLocation = { lat: -0.5012984084076219, lng: -76.97750904233827 };
//const DefaultZoom = 8;

export default function MapaUsuarioEmprendimiento() {
  const token = getAccessTokenApi();
  //const { RangePicker } = DatePicker;
  //const [data, setData] = useState([]);
  //const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  //const [location, setLocation] = useState(defaultLocation);
  //const [zoom, setZoom] = useState(DefaultZoom);
  //const [searchlocation, setSearchLocation] = useState(false);
  //const [users, setUsers] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [locations, setlocations] = useState([]);
  const [heatmapVisible, sethHeatmapVisible] = useState(false);
  const [need, setNeed] = useState(0);
  const [have, setHave] = useState(0);

  const {
    MarkerClusterer,
  } = require("react-google-maps/lib/components/addons/MarkerClusterer");

  const ref = useRef();

  useEffect(() => {
    getUsersMapApi(token, need, have, 0, 0, true, true).then((response) => {
      //console.log(response);
      const data1 = [];
      let users1 = response.users ?? [];
      users1.map((user) =>
        data1.push({
          location: { lat: user.lat, lng: user.lon },
          name: user.name,
          lastname: user.lastname,
          email: user.email,
          emprendimiento: user.entrepreneurships[0]?.name,
          perfil: user.rolename,
          ciudad: user.cityname,
          id: user.id,
        })
      );
      setlocations(data1);

      setLoadingData(false);
    });
  }, [token, need, have]);

  const toggleHeatMap = () => {
    sethHeatmapVisible(!heatmapVisible);
    if (ref.current !== undefined) {
      ref.current.heatmap.setMap(!heatmapVisible ? ref.current.map_ : null);
    }
  };

  // const CMap = withScriptjs(
  //   withGoogleMap((props) => (
  //     <GoogleMap
  //       defaultZoom={zoom}
  //       defaultCenter={DefaultLocation}
  //       //libraries={["visualization"]}
  //     >
  //       {props.children}
  //     </GoogleMap>
  //   ))
  // );

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Mapa</Breadcrumb.Item>
        <Breadcrumb.Item>Todos los emprendimientos</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <Form
          name="filters"
          initialValues={{ remember: false }}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectKnowledgeAllForm
                atributoData={have}
                setAtributoData={setHave}
                title={"Oferta"}
                name={"Oferta"}
              ></SelectKnowledgeAllForm>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectKnowledgeAllForm
                atributoData={need}
                setAtributoData={setNeed}
                title={"Demanda"}
                name={"Demanda"}
              ></SelectKnowledgeAllForm>
            </Col>
          </Row>
        </Form>
        <Card
          title={<span>Mapa del ecosistema de emprendimientos</span>}
          bodyStyle={{ padding: "0" }}
          // extra={
          //   <Button
          //     type={heatmapVisible ? "primary" : "dashed"}
          //     onClick={() => toggleHeatMap()}
          //     icon={<HeatMapOutlined />}
          //     size="small"
          //     className="mini"
          //     loading={searchlocation}
          //   >
          //     Mapa de calor
          //   </Button>
          // }
        >
          {!loadingData ? (
            <MapContainer
              defaultCenter={DefaultLocation}
              locations={locations}
            />
          ) : (
            <></>
          )}
          {/* <div style={{ height: "500px", width: "100%" }}>
            <GoogleMapReact
              ref={(el) => (ref.current = el)}
              defaultZoom={zoom}
              defaultCenter={defaultLocation}
              bootstrapURLKeys={{
                key: apiKeyGoogle,
                language: "es",
                region: "ec",
                libraries: [],
              }}
              heatmap={{
                positions: heatmapVisible ? locations : [],
                options: { radius: 40, opacity: 0.8 },
              }}
              heatmapLibrary={true}
              //onClick={handleChangeLocation}
              //center={location}
            >
              {!loadingData && !heatmapVisible ? (
                users.map((user) => (
                  // <Image
                  //   key={user.id}
                  //   width={32}
                  //   src={Pin}
                  //   lat={user.lat}
                  //   lng={user.lon}
                  //   preview={false}
                  //   style={{ marginTop: "-32px", marginLeft: "-16px" }}
                  // />
                  <Marker
                    key={user.id}
                    lat={user.lat}
                    lng={user.lon}
                    name={user.name}
                    lastname={user.lastname}
                    email={user.email}
                    emprendimiento={user.entrepreneurships[0]?.name}
                    perfil={user.rolename}
                    ciudad={user.cityname}
                  />
                ))
              ) : (
                // <GoogleMapReact.Marker
                //   key={"mylocation"}
                //   text={"Mi ubicación"}
                //   lat={location.lat}
                //   lng={location.lng}
                // />
                // <Spin width={100} lat={location.lat} lng={location.lng} />
                <></>
              )}
            </GoogleMapReact>
          </div> */}

          {/* {!loadingData ? (
            <CMap
              googleMapURL={
                "https://maps.googleapis.com/maps/api/js?key=" +
                apiKeyGoogle +
                "&v=3.exp&libraries=geometry,drawing,places,visualization"
              }
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `500px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              //libraries={["visualization"]}
              //center={defaultCenter}
            >
             
              <MarkerClusterer>
                {users.map((user) => (
                  // <Marker
                  //   key={user.id}
                  //   position={{ lat: user.lat, lng: user.lon }}
                  //   // onClick={() => {
                  //   //   setInfoW({
                  //   //     activeMarker: { lat: user.lat, lng: user.lon },
                  //   //     showingInfoWindow: true,
                  //   //   });
                  //   // }}
                  //   // icon={{
                  //   //   // path: google.maps.SymbolPath.CIRCLE,
                  //   //   url: require("../../../assets/img/png/pin.png"),
                  //   //   //fillColor: "#EB00FF",
                  //   //   //scale: 0.5,
                  //   //   anchor: new window.google.maps.Point(17, 46),

                  //   //   scaledSize: new window.google.maps.Size(37, 37),
                  //   // }}
                  // />
                  <NewMarker
                    key={user.id}
                    id={user.id}
                    lat={user.lat}
                    lon={user.lon}
                    name={user.name}
                    lastname={user.lastname}
                    email={user.email}
                    emprendimiento={user.entrepreneurships[0]?.name}
                    perfil={user.rolename}
                    ciudad={user.cityname}
                  />
                ))}
              </MarkerClusterer>
              
            </CMap>
          ) : (
            <></>
          )} */}
        </Card>
      </div>
    </div>
  );
}
