import React from "react";
import { Layout, Card, Tabs } from "antd";
//import { Redirect } from "react-router-dom";

import Logo from "../../../assets/img/png/conecta2.png";
//import RegisterForm from "../../../components/Admin/RegisterForm";
import LoginForm from "../../../components/Admin/LoginForm";

import { getAccessTokenApi } from "../../../api/auth";

import "./SignIn.scss";
//import { Content } from "antd/lib/layout/layout";
import { Navigate } from "react-router";
import RecoverForm from "../../../components/Admin/RecoverForm";
const { TabPane } = Tabs;

export default function SignIn() {
  const { Content, Footer } = Layout;
  //const { TabPane } = Tabs;

  if (getAccessTokenApi()) {
    //return <Navigate to="/user" />;
  }
  return (
    <Layout className="sign-in">
      <Content className="sign-in__content">
        <br />
        <br />
        <h1 className="sign-in__content-logo">
          <img src={Logo} alt="CI" />
        </h1>
        <br />
        <br />
        {/* <h2 className="sign-in__content-subtitle">
          Portal de administración Innovando Amazonía
        </h2> */}
        <div className="sign-in__content-tabs">
          <Tabs defaultActiveKey="1" type="card" size={350}>
            <TabPane tab="Acceso" key="1">
              <LoginForm />
            </TabPane>
            <TabPane tab="Recuperar clave" key="2">
              <RecoverForm />
            </TabPane>
          </Tabs>
        </div>
      </Content>
      {/* <Footer className="sign-in__footer">
        © Copyright IICA - Innovando Amazonía. <br></br>All Rights Reserved |
        Versión 1.0
      </Footer> */}
    </Layout>
  );
}
