import { basePath } from "./config";

export async function getUtilitysApi() {
  const url = `${basePath}/utilitys`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      //Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export function UpdateUtilityApi(token, utility, utilityId) {
  const url = `${basePath}/utility/update/${utilityId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(utility),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function AddUtilityApi(token, utility) {
  const url = `${basePath}/utility/create`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(utility),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function DeleteUtilityApi(token, utilityId) {
  const url = `${basePath}/utility/delete/${utilityId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function GetUtilityByTypeApi(id) {
  const url = `${basePath}/utility/type/${id}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      //Authorization: token,
    },
    //body: JSON.stringify(entrepreneurship),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
