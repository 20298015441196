import React, { useState, useEffect, useRef } from "react";
import { getAccessTokenApi } from "../../../api/auth";
import ModalUser from "../../../components/Admin/ModalUser";
import AddMeetForm from "../../../components/Home/User/Chat/AddMeet";
//import { MdOutlineVideoCall } from "react-icons/md";

import {
  Card,
  Row,
  Breadcrumb,
  notification,
  Col,
  Tag,
  Empty,
  Avatar as AvatarAnt,
} from "antd";
import { CalendarOutlined, UserOutlined } from "@ant-design/icons";
import "react-chat-elements/dist/main.css";
import {
  ChatList,
  Input,
  Button,
  Avatar,
  Navbar,
  MessageList,
  MeetingList,
  MeetingItem,
} from "react-chat-elements";

import "./Chat.scss";

import useAuth from "../../../hooks/useAuth";
import { getFriendsApi, getFriendsUserApi } from "../../../api/friend.api";
//import { UpdateFriendApi } from "../../../api/friend.api";
// import noAvatar from "../../../assets/img/png/no-avatar.png";
// import ApiOutlined from "@ant-design/icons/ApiOutlined";
// import UsergroupAddOutlined from "@ant-design/icons/UsergroupAddOutlined";
import {
  AddMessageApi,
  getMessagesApi,
  UpdateMessageApi,
  UpdateMessageStatusApi,
} from "../../../api/message.api";
import moment from "moment";

const Chat = ({ socket }) => {
  const token = getAccessTokenApi();
  const [loadings, setLoadings] = useState(true);
  const [potentials, setPotentials] = useState([]);
  const { user, isLoading } = useAuth();
  const [initLoading, setInitLoading] = useState(true);
  //const [initLoadingNeed, setInitLoadingNeed] = useState(true);
  //const [offset, setOffset] = useState(0);
  //const [more, setMore] = useState(true);
  //const [moreNeed, setMoreNeed] = useState(true);
  //const [loadingFriend, setLoadingFriend] = useState(false);
  const [messages, setMessages] = useState([]);
  const [friend, setFriend] = useState();
  const [loadingsMessages, setLoadingsMessages] = useState(false);
  const [sendMessages, setSendMessages] = useState(false);
  const [message, setMessage] = useState("");
  const [lastsocket, setLastsocket] = useState();
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalContent, setmodalContent] = useState();

  const [isMeet, setIsMeet] = useState(false);
  const [datemeet, setDatemeet] = useState(false);
  const [startmeet, setStartmeet] = useState(false);
  const [endmeet, setEndmeet] = useState(false);
  const [observationsmeet, setObservationsmeet] = useState(false);
  const [title, setTitle] = useState("");
  const { Meta } = Card;
  const [width, setWidth] = useState(500);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    socket.off("friendrequestResponse");
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
          duration: 0,
        });
      }
    });
    socket.off("messageResponse");
    socket.on("messageResponse", (data) => {
      console.log(data);
      console.log(friend);
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);

        if (friend && friend.friendId === data.friendId) {
          setMessages((messages) => [...messages, data.message]);
          setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
          }, 200);
          UpdateMessageApi(token, data.friendId, data.user).then((response) => {
            //setLoadings(true);
          });
        } else {
          notification["info"]({
            message: data.content,
            duration: 10,
          });
          setLoadings(true);
        }
      }
    });
  }, [socket, friend]);

  useEffect(() => {
    if (loadings) {
      getFriendsApi(token, user.id).then((response) => {
        console.log(response);
        getFriendsUserApi(token, user.id).then((response1) => {
          console.log(response1);

          const data1 = [];
          let friends = response.friends ?? [];
          let friends1 = response1.friends ?? [];
          friends.map((item) =>
            //console.log(item);
            data1.push({
              id: item.users1[0].id,
              key: item.users1[0].id,
              avatar: "../no-avatar.png",
              alt: item.users1[0].name,
              title: `${item.users1[0].name} ${item.users1[0].lastname}`,
              subtitle:
                item.messages.length > 0 ? item.messages[0].message : "",
              dateString:
                item.messages.length > 0
                  ? moment(item.messages[0].date).fromNow()
                  : "-",
              //date: item.messages[0].date,
              unread: item.noread,
              friendId: item.id,
            })
          );
          friends1.map((item) =>
            //console.log(item);
            data1.push({
              id: item.users2[0].id,
              key: item.users2[0].id,
              avatar: "../no-avatar.png",
              alt: item.users2[0].name,
              title: `${item.users2[0].name} ${item.users2[0].lastname}`,
              subtitle:
                item.messages.length > 0 ? item.messages[0].message : "",
              //dateString: "-",
              //date: item.messages[0].date,
              dateString:
                item.messages.length > 0
                  ? moment(item.messages[0].date).fromNow()
                  : "-",
              unread: item.noread,
              friendId: item.id,
            })
          );
          console.log(data1);
          setPotentials(data1);
          //setPotentials(response.users[0]?.friends);
          setLoadings(false);
          setInitLoading(false);
        });
      });
    }
    // });
  }, [token, loadings]);

  useEffect(() => {
    if (isMeet) {
      setIsMeet(false);
      setSendMessages(true);
      AddMessageApi(token, {
        message:
          "Reunión" +
          //friend.alt +
          " el " +
          datemeet.format("LL") +
          " " +
          startmeet.format("HH:mm") +
          " - " +
          endmeet.format("HH:mm"),
        status: 0,
        friendId: friend.friendId,
        userId: user.id,
        date: moment(),
        reunion: true,
        observations: observationsmeet,
        statusreunion: 0,
        //datereunion: datemeet.startOf("day"),
        start: moment(
          datemeet.format("DD/MM/YYYY") + " " + startmeet.format("HH:mm"),
          "DD/MM/YYYY HH:mm"
        ),
        end: moment(
          datemeet.format("DD/MM/YYYY") + " " + endmeet.format("HH:mm"),
          "DD/MM/YYYY HH:mm"
        ),

        //dateString: moment().fromNow(),
      }).then((response) => {
        console.log(response);

        setMessages((messages) => [
          ...messages,
          {
            id: response.messagedata.id,
            key: response.messagedata.id,
            position: "right",
            user: user.id,
            type: "meeting",
            text:
              "Reunión con " +
              friend.alt +
              " el " +
              datemeet.format("LL") +
              " " +
              startmeet.format("HH:mm") +
              " - " +
              endmeet.format("HH:mm"),
            title:
              "Reunión con " +
              friend.alt +
              " el " +
              datemeet.format("LL") +
              " " +
              startmeet.format("HH:mm") +
              " - " +
              endmeet.format("HH:mm"),
            date: moment(),
            dateString: moment().fromNow(),
            reunion: true,
            observations: observationsmeet,
            statusreunion: 0,
            //datereunion: datemeet.startOf("day"),
            start: moment(
              datemeet.format("DD/MM/YYYY") + " " + startmeet.format("HH:mm"),
              "DD/MM/YYYY HH:mm"
            ),
            end: moment(
              datemeet.format("DD/MM/YYYY") + " " + endmeet.format("HH:mm"),
              "DD/MM/YYYY HH:mm"
            ),
            subject: `${friend.alt} aún no acepta la reunión`,
            participants: [
              {
                id: "1",
                key: "1",
                title: friend.alt,
              },
              {
                id: "2",
                key: "2",
                title: user.name,
              },
            ],
            dataSource: [
              {
                id: "1",
                key: "1",
                avatar: "/user.png",
                title: friend.title,
                avatarFlexible: true,
                date: new Date(),
              },
              {
                id: "2",
                key: "2",
                avatar: "/user.png",
                title: user.name + " " + user.lastname,
                avatarFlexible: true,
                message: observationsmeet,
                date: new Date(),
              },
            ],
            collapseTitle: "Ocultar",
          },
        ]);

        socket.emit("message", {
          user: user.id,
          friendId: friend.friendId,
          destiny: friend.id,
          content: `${user.name} te ha enviado una solicitud de reunión`,
          message: {
            id: response.messagedata.id,
            key: response.messagedata.id,
            position: "left",
            type: "meeting",
            title:
              "Reunión" +
              //friend.alt +
              " el " +
              startmeet.format("LLLL") +
              " - " +
              endmeet.format("HH:mm"),
            text:
              "Reunión" +
              //friend.alt +
              " el " +
              startmeet.format("LLLL") +
              " - " +
              endmeet.format("HH:mm"),
            date: moment(),
            dateString: moment().fromNow(),
            reunion: true,
            observations: observationsmeet,
            statusreunion: 0,
            //retracted: true,
            subject: "Presiona aquí para aceptar o rechazar la reunión",
            user: user.id,
            participants: [
              {
                id: "1",
                title: friend.alt,
              },
              {
                id: "2",
                title: user.name,
              },
            ],
            dataSource: [
              {
                id: "1",
                avatar: "/user.png",
                title: friend.title,
                avatarFlexible: true,
                date: new Date(),
              },
              {
                id: "2",
                avatar: "/user.png",
                title: user.name + " " + user.lastname,
                message: observationsmeet,
                avatarFlexible: true,
                date: new Date(),
              },
            ],
            start: moment(
              datemeet.format("DD/MM/YYYY") + " " + startmeet.format("HH:mm"),
              "DD/MM/YYYY HH:mm"
            ),
            end: moment(
              datemeet.format("DD/MM/YYYY") + " " + endmeet.format("HH:mm"),
              "DD/MM/YYYY HH:mm"
            ),
            collapseTitle: "Ocultar",
          },
        });

        setSendMessages(false);
        setMessage(null);
        inputRef.current.value = null;
        //inputRef.clear();
        setTimeout(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 200);
        //setMessages(response.messages);
      });
    }
  }, [isMeet]);

  const onClick = (item) => {
    console.log(item);
    setFriend(item);
    setLoadingsMessages(true);
    getMessagesApi(token, item.friendId).then((response) => {
      console.log(response);
      const data1 = [];
      let messages = response.messages ?? [];
      var i;
      for (i = 0; i < messages.length; i++) {
        //loopData += `<li>${data[i].name}</li>`
        if (messages[i].reunion) {
          //if (messages[i].statusreunion === 0) {
          data1.push({
            id: messages[i].id,
            key: messages[i].id,
            position: user.id === messages[i].user.id ? "right" : "left",
            type: "meeting",
            user: messages[i].user.id,
            meetingID: messages[i].id,
            title:
              "Reunión con " +
              item.alt +
              " el " +
              moment(messages[i].start).format("LLLL") +
              " - " +
              moment(messages[i].end).format("HH:mm"),
            text: messages[i].message,
            date: messages[i].date,
            dateString: moment(messages[i].date).fromNow(),
            reunion: true,
            observations: messages[i].observations,
            statusreunion: messages[i].statusreunion,
            //retracted: true,
            subject:
              user.id === messages[i].user.id
                ? messages[i].statusreunion === 0
                  ? `${item.alt} aún no acepta la reunión`
                  : `${item.alt} ha aceptado la reunión`
                : messages[i].statusreunion === 0
                ? "Presiona aquí para aceptar o rechazar la reunión"
                : "Reunión aceptada",
            participants: [
              {
                id: "1",
                key: "1",
                title: item.alt,
              },
              {
                id: "2",
                key: "2",
                title: user.name,
              },
            ],
            dataSource: [
              {
                id: "1",
                key: "1",
                avatar: "/user.png",
                title: item.title,
                message: messages[i].observations,
                avatarFlexible: true,
                date: new Date(),
              },
              {
                id: "2",
                key: "2",
                avatar: "/user.png",
                title: user.name + " " + user.lastname,
                avatarFlexible: true,
                date: new Date(),
              },
            ],
            //forwarded: true,
            //replyButton: true,
            //removeButton: true,
            start: messages[i].start,
            end: messages[i].end,
            collapseTitle: "Ocultar",
            // actionButtons: [
            //   {
            //     onClickButton(id) {
            //       console.log(id);
            //     },
            //     Component: "Hola",
            //   },
            //   {
            //     onClickButton(id) {
            //       console.log(id);
            //     },
            //     Component: "Hola",
            //   },
            // ],
            //status: "sent",
          });
          // } else {
          // }
        } else {
          data1.push({
            id: messages[i].id,
            key: messages[i].id,
            position: user.id === messages[i].user.id ? "right" : "left",
            type: "text",
            title: messages[i].user.name,
            text: messages[i].message,
            date: messages[i].date,
            dateString: moment(messages[i].date).fromNow(),
            reunion: false,
            observations: "",
            statusreunion: 0,
            start: "",
            end: "",
            //status: "sent",
          });
        }
      }

      // messages.map(function (item) {
      //   if (item.reunion) {
      //   } else {
      //     data1.push({
      //       position: user.id === item.user.id ? "right" : "left",
      //       type: "text",
      //       title: item.user.name,
      //       text: item.message,
      //       date: item.date,
      //       dateString: moment(item.date).fromNow(),
      //       reunion: false,
      //       observations: "",
      //       statusreunion: 0,
      //       start: "",
      //       end: "",
      //       //status: "sent",
      //     });
      //   }
      // });
      setLoadingsMessages(false);
      setMessages(data1);

      UpdateMessageApi(token, item.friendId, item.id).then((response) => {
        setLoadings(true);
      });

      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    });
  };

  const sendMessage = () => {
    if (message && message.length > 0) {
      setSendMessages(true);
      AddMessageApi(token, {
        message: message,
        status: 0,
        friendId: friend.friendId,
        userId: user.id,
        date: moment(),
        reunion: false,
        observations: "",
        statusreunion: 0,
        // start: "",
        // end: "",
        //dateString: moment().fromNow(),
      }).then((response) => {
        console.log(response);

        setMessages((messages) => [
          ...messages,
          {
            position: "right",
            type: "text",
            title: user.name,
            text: message,
            date: moment(),
            dateString: moment().fromNow(),
            reunion: false,
            observations: "",
            statusreunion: 0,
            // start: "",
            // end: "",
          },
        ]);

        socket.emit("message", {
          user: user.id,
          friendId: friend.friendId,
          destiny: friend.id,
          content: `${user.name} te ha enviado un nuevo mensaje`,
          message: {
            position: "left",
            type: "text",
            title: user.name,
            text: message,
            date: moment(),
            dateString: moment().fromNow(),
            reunion: false,
            observations: "",
            statusreunion: 0,
            start: "",
            end: "",
          },
        });

        setSendMessages(false);
        setMessage(null);
        inputRef.current.value = null;
        //inputRef.clear();
        setTimeout(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 200);
        //setMessages(response.messages);
      });
    }
  };

  const addMeet = () => {
    // if (haves.length >= 3) {
    //   notification["error"]({
    //     message: "Puedes agregar hasta 3 recursos que tengas",
    //     duration: 3,
    //   });
    //   return false;
    // }
    setWidth(500);
    setTitle("Programar una reunión");
    setIsVisibleModal(true);
    setmodalContent(
      <AddMeetForm
        setIsVisibleModal={setIsVisibleModal}
        buttonName="Solicitar reunión"
        data={{
          message: "Reunión",
          status: 0,
          friendId: friend.friendId,
          userId: user.id,
          date: moment(),
          //dateString: moment().fromNow(),
        }}
        setIsMeet={setIsMeet}
        setDatemeet={setDatemeet}
        setStartmeet={setStartmeet}
        setEndmeet={setEndmeet}
        setObservationsmeet={setObservationsmeet}
      />
    );
  };

  const clickMessage = (message) => {
    if (message.user === user.id || message.statusreunion > 0) {
    } else {
      console.log(message);
      setWidth(600);
      setTitle("Aceptar reunión");
      setIsVisibleModal(true);
      setmodalContent(
        <Card
          bordered={false}
          size="small"
          actions={[
            <Button
              text={"Aceptar reunión"}
              onClick={() => acceptMeet(message.id)}
              title="Aceptar reunión"
            />,
          ]}
        >
          <Meta
            avatar={<AvatarAnt size={48} icon={<CalendarOutlined />} />}
            title={message.text}
            description={message.observations}
          />
          <br />
          <Tag icon={<UserOutlined />} color="#2db7f5">
            {message.dataSource[0].title}
          </Tag>
          <Tag icon={<UserOutlined />} color="#2db7f5">
            {message.dataSource[1].title}
          </Tag>
        </Card>
      );
    }
  };

  const acceptMeet = (id) => {
    setIsVisibleModal(false);
    UpdateMessageStatusApi(token, id, 1).then((response) => {
      if (response.status) {
        notification["info"]({
          message: response.message,
          duration: 10,
        });
        onClick(friend);
      } else {
        notification["error"]({
          message: response.message,
          duration: 10,
        });
      }
      console.log(response);
    });
  };

  return (
    <div>
      <div className="Conexiones">
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Reciclaje</Breadcrumb.Item>
          <Breadcrumb.Item>Conversaciones</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="site-statistic-demo-card">
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card title={<span>Mis conversaciones:</span>}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <ChatList
                    id={""}
                    className="chat-list"
                    dataSource={potentials}
                    onClick={onClick}
                    loading={loadings}
                  />

                  {/* <List
                    size="small"
                    //itemLayout="horizontal"
                    itemLayout="vertical"
                    dataSource={potentials}
                    loading={loadings}
                    bordered
                    //loadMore={loadMore}
                    renderItem={(item) => (
                      <List.Item extra={item.messages.length > 0 && "17:27"}>
                        <List.Item.Meta
                          avatar={<Avatar src={noAvatar} />}
                          title={
                            <>
                              {item.name.toUpperCase() +
                                " " +
                                item.lastname.toUpperCase()}
                            </>
                          }
                          description={
                            item.messages.length > 0 && (
                              <>
                                <CheckOutlined style={{ fontSize: "10px" }} />
                                <CheckOutlined
                                  style={{ fontSize: "10px" }}
                                />{" "}
                                "Buen dia"
                              </>
                            )
                          }
                        />
                      </List.Item>
                    )}
                  /> */}
                </Col>
                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                  <div
                    style={{
                      minHeight: "400px",
                      backgroundColor: "rgb(246, 246, 246)",
                      padding: "10px 10px 80px 10px",
                    }}
                  >
                    {!friend && (
                      <div>
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description="Seleccione un emprendedor para empezar a comunicarse!"
                        />
                      </div>
                    )}
                    {friend && (
                      <Navbar
                        left=<div>
                          <Avatar
                            src="../no-avatar.png"
                            alt="avatar"
                            size="small"
                            type="circle"
                          />
                          <div
                            style={{
                              marginTop: "6px",
                            }}
                          >
                            {friend ? friend.title : ""}
                          </div>
                        </div>
                        //right=
                        type="light"
                      />
                    )}
                    {friend && (
                      <div
                        style={{
                          height: "350px",
                          padding: "10px",
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        <MessageList
                          className="message-list"
                          lockable={true}
                          toBottomHeight={"100%"}
                          dataSource={messages}
                          onClick={clickMessage}
                          downButton={true}
                        />
                        <div ref={messagesEndRef} />
                      </div>
                    )}

                    {friend && (
                      <div
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          right: "20px",
                          width: "calc(100% - 40px)",
                        }}
                      >
                        <Input
                          placeholder="Ingresa el mensaje aquí..."
                          multiline={false}
                          id="message"
                          name="message"
                          referance={inputRef}
                          onChange={handleChange}
                          //value={message}
                          rightButtons={
                            <>
                              <Button
                                key={"1"}
                                text={"Enviar"}
                                onClick={sendMessage}
                                title="Enviar"
                                loading={sendMessages}
                              />
                              <Button
                                key={"2"}
                                text={"Solicitar reunión"}
                                onClick={addMeet}
                                title="Solicitar reunión"
                                loading={sendMessages}
                              />
                            </>
                          }
                        />
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <ModalUser
          title={title}
          isVisibleModal={isVisibleModal}
          setIsVisibleModal={setIsVisibleModal}
          width={width}
        >
          {modalContent}
        </ModalUser>
      </div>
    </div>
  );
};

export default Chat;
