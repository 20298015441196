import React, { useState, useEffect, useCallback } from "react";
import {
  Form,
  Spin,
  Row,
  Col,
  notification,
  PageHeader,
  Card,
  Image,
} from "antd";
import { PushpinOutlined, AimOutlined } from "@ant-design/icons";
import moment from "moment";
import GoogleMapReact from "google-map-react";

import { getAccessTokenApi } from "../../../../api/auth";

import "./MapUserForm.scss";
import { apiKeyGoogle } from "../../../../api/config";
import Pin from "../../../../assets/img/png/pin.png";

const DefaultLocation = { lat: -0.5012984084076219, lng: -76.97750904233827 };
const DefaultZoom = 13;

export default function EditUserForm(props) {
  const { user, setIsVisibleModal, setReloadUsers, addSlag } = props;
  const [userData, setUserData] = useState({});
  const [formPersonal] = Form.useForm();
  const [loadings, setLoadings] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const token = getAccessTokenApi();
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [searchlocation, setSearchLocation] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingsMap, setLoadingsMap] = useState(true);

  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    if (user) {
      const initialLocation = {
        lat: user.entrepreneurships[0]?.lat,
        lng: user.entrepreneurships[0]?.lon,
      };
      setLocation(initialLocation);
      if (loadingsMap) {
        setLoadingsMap(false);
      }
      setLoadingData(false);
    }
  }, [user]);
  //console.log(userData.admission == null);

  const onFinish = (values) => {
    setLoadings(true);
    const userAdd = {
      ...values,
      lat: location.lat,
      lon: location.lng,
    };
    setUserData(userAdd);

    // console.log("Success values:", values);
    // console.log("Success:", userData);
    //next();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información personal",
      duration: 3,
    });
  };

  return (
    <div className="map-user-form">
      <Form
        form={formPersonal}
        name="personal"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        initialValues={{ remember: false }}
        autoComplete="off"
      >
        <PageHeader
          className="site-page-header header-left"
          // onBack={() => null}
          style={{ textAlign: "left", justifyContent: "start" }}
          // onBack={() => null}
          title={
            <>
              <PushpinOutlined style={{ fontSize: "30px" }} />{" "}
              <span> Ubicación</span>
            </>
          }
          // subTitle="This is a subtitle"
        />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card
              //title={<span>Ubicación del emprendedor</span>}
              bodyStyle={{ padding: "0" }}
              //style={{ height: "300px", width: "100%" }}
            >
              {/* <MapPicker
                defaultLocation={defaultLocation}
                zoom={zoom}
                mapTypeId="roadmap"
                style={{ height: "300px" }}
                onChangeLocation={handleChangeLocation}
                onChangeZoom={handleChangeZoom}
                // apiKey="AIzaSyDy8AE5RqF-ozGiI8J7r-U1vnb3AwQCY-o"
                apiKey={apiKeyGoogle}
              /> */}
              <div style={{ height: "300px", width: "100%" }}>
                <GoogleMapReact
                  defaultZoom={zoom}
                  defaultCenter={defaultLocation}
                  bootstrapURLKeys={{
                    key: apiKeyGoogle,
                    language: "es",
                    region: "ec",
                    libraries: [],
                  }}
                  center={location}
                >
                  {!loadingData ? (
                    <Image
                      width={48}
                      src={Pin}
                      lat={location.lat}
                      lng={location.lng}
                      preview={false}
                      style={{ marginTop: "-48px", marginLeft: "-24px" }}
                    />
                  ) : (
                    // <GoogleMapReact.Marker
                    //   key={"mylocation"}
                    //   text={"Mi ubicación"}
                    //   lat={location.lat}
                    //   lng={location.lng}
                    // />
                    <Spin width={100} lat={location.lat} lng={location.lng} />
                  )}
                </GoogleMapReact>
              </div>
            </Card>
          </Col>
        </Row>
        <br></br>
      </Form>
    </div>
  );
}
