import { basePath } from "./config";

export function AddRateApi(token, rate) {
  const url = `${basePath}/rate/create`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(rate),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.rate;
    });
}

export function UpdateRateApi(token, friendId, userId) {
  const url = `${basePath}/rate/update/${friendId}/${userId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    //body: JSON.stringify(knowledge),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.rate;
    });
}

export function UpdateRateStatusApi(token, id, status) {
  const url = `${basePath}/rate/status/${id}/${status}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    //body: JSON.stringify(knowledge),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.rate;
    });
}

export function getRatesApi(token, friendid) {
  const url = `${basePath}/rates?id=${friendid}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.rate;
    });
}

export function getCountRatesApi(token, status) {
  const url = `${basePath}/rate-count`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
