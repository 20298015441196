import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Row, Col, Card, Button, Avatar } from "antd";
import { Bar, Column, Pie } from "@ant-design/charts";
import { getAccessTokenApi } from "../../../api/auth";

import "./Indicadores.scss";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

import {
  getHaveresidualTotalApi,
  getHaveresidualGroupApi,
  getMaxHaveresidualApi,
} from "../../../api/haveresidual.api";

import { getSolutionCountApi } from "../../../api/entrepreneurship.api";

import { RestOutlined } from "@ant-design/icons";

export default function Sostenibilidad() {
  const token = getAccessTokenApi();
  //const { RangePicker } = DatePicker;
  // const [data, setData] = useState([]);
  // const [data2, setData2] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [disabled3, setDisabled3] = useState(true);
  const [totalresidual, setTotalresidual] = useState();
  const [maxresidualgroup, setMaxresidualgroup] = useState([]);
  const [maxresidual, setMaxresidual] = useState([]);
  const [solution, setSolution] = useState([]);
  const [solutionHave, setSolutionHave] = useState([]);
  const { Meta } = Card;

  useEffect(() => {
    getHaveresidualGroupApi(token).then((response) => {
      setMaxresidualgroup(response.haveresiduals);
      console.log(response.haveresiduals);
    });

    getHaveresidualTotalApi(token).then((response) => {
      console.log(response);
      setTotalresidual(response.total);
    });

    getMaxHaveresidualApi(token).then((response) => {
      setMaxresidual(response.haveresidual);
    });

    getSolutionCountApi(token, 1).then((response) => {
      const data1 = [];
      //console.log(response.entrepreneurships);
      let needs1 = response.entrepreneurships;
      needs1.map((need) =>
        data1.push({
          name: need.name,
          count: parseInt(need.count),
          type: "Demanda",
        })
      );
      setSolution(data1);
      //console.log(response.entrepreneurships);
    });

    getSolutionCountApi(token, 3).then((response) => {
      const data1 = [];
      //console.log(response.entrepreneurships);
      let needs1 = response.entrepreneurships;
      needs1.map((need) =>
        data1.push({
          name: need.name,
          count: parseInt(need.count),
          type: "Oferta",
        })
      );
      setSolutionHave(data1);
      //console.log(response.entrepreneurships);
    });
  }, [token]);

  const config = {
    appendPadding: 10,
    //data,
    angleField: "count",
    colorField: "name",
    radius: 0.8,
    label: {
      type: "outer",
      content: "{name} {percentage}",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  };

  const config2 = {
    xField: "name",
    yField: "count",
    height: 260,
    label: {
      position: "middle",
      // 'top', 'bottom', 'middle',
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      name: {
        alias: "Residuales",
      },
      count: {
        alias: "Cantidad",
      },
    },
  };

  // const config3 = {
  //   xField: "name",
  //   yField: "count",
  //   height: 260,
  //   label: {
  //     position: "middle",
  //     // 'top', 'bottom', 'middle',
  //     style: {
  //       fill: "#FFFFFF",
  //       opacity: 0.6,
  //     },
  //   },
  //   xAxis: {
  //     label: {
  //       autoHide: true,
  //       autoRotate: false,
  //     },
  //   },
  //   meta: {
  //     name: {
  //       alias: "SOluciones",
  //     },
  //     count: {
  //       alias: "Cantidad",
  //     },
  //   },
  // };

  const config3 = {
    isStack: true,
    xField: "count",
    yField: "name",
    seriesField: "type",
    height: 400,
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    content: "{value} proyectos",
  };

  const ref = useRef([]);

  // export image
  const downloadImage = () => {
    ref.current[0]?.downloadImage();
  };
  const downloadImage2 = () => {
    ref.current[1]?.downloadImage();
  };
  const downloadImage3 = () => {
    ref.current[2]?.downloadImage();
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Gráficas</Breadcrumb.Item>
        <Breadcrumb.Item>Sostenibilidad</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        {/* <Row gutter={16}>
          <Col span={12}>
            <RangePicker
              style={{ width: "100%" }}
              picker="month"
              onChange={onChange}
            />
          </Col>
          <Col span={12}>
            { <SelectMultiFinca setFincaId={setFincaId}></SelectMultiFinca> }
          </Col>
        </Row> */}
        <Row gutter={24}>
          <Col xs={24} sm={18} md={18} lg={18} xl={18}>
            <Card title={<span>Categorías de residuos registrados</span>}>
              <Column
                data={maxresidualgroup ?? []}
                {...config2}
                onReady={(plot) => {
                  ref.current[0] = plot;
                  setDisabled(false);
                }}
              />
              <br />
              <Button
                disabled={disabled}
                htmlType="button"
                className="btn-submit"
                onClick={downloadImage}
              >
                Guardar gráfico
              </Button>
            </Card>
          </Col>

          <Col xs={24} sm={6} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#ef5350",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <RestOutlined style={{ fontSize: "40px", color: "white" }} />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h4 style={{ color: "white" }}>
                      {totalresidual ? totalresidual.count : 0}
                    </h4>
                  </Avatar>
                }
                title="KG"
                description="Total de resíduos"
              />
            </Card>
            <br />
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#ec407a",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <RestOutlined style={{ fontSize: "40px", color: "white" }} />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h4 style={{ color: "white" }}>{maxresidual.count}</h4>
                  </Avatar>
                }
                title="Mayor Residuo (KG)"
                description={maxresidual.name}
              />
            </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card
              title={<span>Disminución de CO2 por categorías de residuos</span>}
            >
              <Pie
                data={maxresidualgroup ?? []}
                {...config}
                onReady={(plot) => {
                  ref.current[1] = plot;
                  setDisabled(false);
                }}
              />
              <br />
              <Button
                disabled={disabled}
                htmlType="button"
                className="btn-submit"
                onClick={downloadImage2}
              >
                Guardar gráfico
              </Button>
            </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card
              title={
                <span>
                  Soluciones sostenibles más demandadas por la empresa
                </span>
              }
            >
              {/* <Column
                data={solution ?? []}
                {...config3}
                onReady={(plot) => {
                  ref.current[2] = plot;
                  setDisabled3(false);
                }}
              /> */}
              <Bar
                data={[...(solution ?? []), ...(solutionHave ?? [])]}
                {...config3}
                onReady={(plot) => {
                  ref.current[2] = plot;
                  setDisabled3(false);
                }}
              />

              <br />
              <Button
                disabled={disabled3}
                htmlType="button"
                className="btn-submit"
                onClick={downloadImage3}
              >
                Guardar gráfico
              </Button>
            </Card>
          </Col>
        </Row>
        {/* <Bar
          data={[...(groupHave2 ?? []), ...(groupNeed2 ?? [])]}
          {...config}
          onReady={(plot) => {
            ref.current[1] = plot;
            setDisabled2(false);
          }}
        /> */}
        {/* <br />
        <Button
          disabled={disabled2}
          htmlType="button"
          className="btn-submit"
          onClick={downloadImage2}
        >
          Guardar gráfico
        </Button> */}
      </div>
    </div>
  );
}
