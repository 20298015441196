import React, { useState, useEffect } from "react";
import { Select, Form, Input } from "antd";

import { getSolutionsApi } from "../../../../api/solution.api";

import "./SelectSolutionForm.scss";

const { Option } = Select;

export default function SelectSolutionForm(props) {
  const { atributoData, setAtributoData, deafultValue } = props;
  const [solutionData, setSolutionData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [solutionId, setSolutionId] = useState();
  const [other, setOther] = useState(false);

  useEffect(() => {
    getSolutionsApi().then((response) => {
      setSolutionData(response.solutions);
      setSolutionId(atributoData);
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value) => {
    setSolutionId(value);
    setAtributoData(value);
    if (value === 7) {
      setOther(true);
    } else {
      setOther(false);
    }
  };

  useEffect(() => {
    console.log(atributoData);
    if (atributoData) {
      if (atributoData === 7) {
        setOther(true);
      } else {
        setOther(false);
      }
    } else {
      setOther(false);
    }
  }, [atributoData]);

  return (
    <div className="select-solution-form">
      <Form.Item
        label="Tipo de solución sostenible "
        name="solutionId"
        // rules={[{ required: true, message: "Tipo de solución sostenible " }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        style={{ marginBottom: other ? "5px" : "24px" }}
      >
        <Select
          placeholder="Seleccione un tipo"
          value={solutionId}
          onChange={handleAtributoChange}
          loading={loadingData}
          //disabled={disabled}
        >
          {solutionData && solutionData.map
            ? solutionData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
      {other && (
        <Form.Item
          //label="Otros"
          name="solutionother"
          rules={[
            { required: true, message: "Ingrese la solución sostenible" },
          ]}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          initialValue={deafultValue}
        >
          <Input
            //prefix={<UserOutlined />}
            placeholder="Solución sostenible"
            //value={userData.name}
            maxLength={200}
          />
        </Form.Item>
      )}
    </div>
  );
}
