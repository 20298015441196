import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";

import { getAgreementsApi } from "../../../../api/agreement.api";

import "./SelectAgreementForm.scss";

const { Option } = Select;

export default function SelectAgreementForm(props) {
  const { name, nameOther, edit, values } = props;
  const [agreementData, setAgreementData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [agreementId, setAgreementId] = useState();

  useEffect(() => {
    getAgreementsApi().then((response) => {
      setAgreementData(response.agreements);
      // setAgreementId(atributoData);
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value) => {
    setAgreementId(value);
    //setAtributoData(value);
  };

  return (
    <div className="select-agreement-form">
      <Form.Item
        label={"Tipo de acuerdo"}
        name={name}
        rules={[{ required: true, message: "Seleccione un tipo de acuerdo" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        //className="biglabel"
      >
        <Select
          placeholder="Seleccione un tipo de acuerdo"
          //value={agreementId}
          onChange={handleAtributoChange}
          //disabled={disabled}
        >
          {agreementData && agreementData.map
            ? agreementData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
