import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import { getRolesApi } from "../../../../api/role.api";

import "./SelectRoleForm.scss";

const { Option } = Select;

export default function SelectRoleOneForm(props) {
  const { atributoData, setAtributoData } = props;
  const [roleData, setRoleData] = useState({});
  const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [roleId, setRoleId] = useState();

  useEffect(() => {
    getRolesApi(token).then((response) => {
      setRoleData(response.roles);
      setRoleId(atributoData);
      //   if (addSlag === "add") {
      //     setRoleId(null);
      //   } else {
      //     setRoleId(atributoData.roleId);
      //   }
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value) => {
    setRoleId(value);
    setAtributoData(value ?? 0);
  };

  return (
    <div className="select-role-form">
      <Form.Item
        label="Perfil"
        name="roleId"
        //rules={[{ required: true, message: "Seleccione un rol" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        //value={provinceId}
      >
        <Select
          placeholder="Seleccione un rol"
          value={roleId}
          onChange={handleAtributoChange}
          showSearch
          optionFilterProp="children"
          loading={loadingData}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          allowClear={true}
          //disabled={disabled}
        >
          {roleData && roleData.map
            ? roleData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
