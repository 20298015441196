import React, { useState } from "react";
import Map from "./Map";
import { Marker, MarkerClusterer } from "@react-google-maps/api";
// import uuidv4 from "uuid/v4";
import { v4 as uuidv4 } from "uuid";
import CustomMarker from "./CustomMarker";

// var locations2 = [
//   { lat: -37.774785, lng: 145.137978 },
//   { lat: -37.819616, lng: 144.968119 },
//   { lat: -38.330766, lng: 144.695692 },
//   { lat: -39.927193, lng: 175.053218 },
//   { lat: -41.330162, lng: 174.865694 },
//   { lat: -42.734358, lng: 147.439506 },
//   { lat: -42.734358, lng: 147.501315 },
//   { lat: -42.735258, lng: 147.438 },
//   { lat: -43.999792, lng: 170.463352 },
// ];

export default function MapContainer(props) {
  const { defaultCenter, locations } = props;
  const [map, setMap] = useState(null);
  const [locs, setLocs] = useState(locations);
  const [toggle, setToggle] = useState(false);

  React.useEffect(() => {
    if (map && locations.length > 0) {
      // map.panTo(...)
      //setLocs(locations);
      mapFitBounds();
    }
  }, [map]);

  React.useEffect(() => {
    if (map && locations.length > 0) {
      // map.panTo(...)
      //console.log(locations.length);
      setLocs(locations);
      //mapFitBounds();
    } else {
      if (map) {
        setLocs([]);
      }
    }
  }, [locations]);

  function mapFitBounds() {
    // console.log("mapFitBounds:map> ", map);
    if (!map || locations.length === 0) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    locs.map((loc) => {
      bounds.extend(
        new window.google.maps.LatLng(loc.location.lat, loc.location.lng)
      );
    });

    map.fitBounds(bounds);
  }

  //   function removeMarker() {
  //     setLocs((locs) => (toggle ? locations1 : locations2));
  //     setToggle(!toggle);
  //   }

  //   function removeSome() {
  //     let _locs = [...locs];
  //     const removeValFromIndex = [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20];

  //     for (var i = removeValFromIndex.length - 1; i >= 0; i--)
  //       _locs.splice(removeValFromIndex[i], 1);

  //     setLocs(_locs);
  //   }

  return (
    <div>
      <Map setMap={setMap} defaultCenter={defaultCenter}>
        <MarkerClusterer>
          {(clusterer) =>
            locs.map((loc) => (
              <CustomMarker
                key={uuidv4()}
                position={loc.location}
                clusterer={clusterer}
                name={loc.name}
                lastname={loc.lastname}
                email={loc.email}
                emprendimiento={loc.emprendimiento}
                perfil={loc.perfil}
                ciudad={loc.ciudad}
                id={loc.id}
              />
            ))
          }
        </MarkerClusterer>
      </Map>
      {/* <button style={{ margin: 8 }} onClick={() => removeMarker()}>
        {locs.length !== 0 ? "Remove all markers" : "Add all markers"}
      </button>
      <button style={{ margin: 8 }} onClick={() => removeSome()}>
        {locs.length !== 0 ? "Remove some markers" : "Add some markers"}
      </button> */}
    </div>
  );
}
