import React, { useState, useEffect } from "react";
import { Select, Form, Input, Col, Row } from "antd";

import { getKnowledgetypesApi } from "../../../../api/knowledgetype.api";
import { GetKnowledgeByTypeApi } from "../../../../api/knowledge.api";
import SelectKnowledgeForm from "./SelectKnowledgeForm";

import "./SelectKnowledgeForm.scss";

const { Option } = Select;

export default function SelectKnowledgeTypeForm(props) {
  const { name, nametype, nameOther, edit, values, othertype } = props;
  const [knowledgeData, setKnowledgeData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  //const [knowledgeId, setKnowledgeId] = useState();
  const [other, setOther] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [knowledgetypeId, setKnowledgetypeId] = useState();
  const [knowledgetypeData, setKnowledgetypeData] = useState({});

  useEffect(() => {
    console.log(values);
    getKnowledgetypesApi().then((response) => {
      setKnowledgetypeData(response.knowledgetypes);
      if (edit) {
        setKnowledgetypeId(values.knowledgetypeId);
        if (values.knowledgetypeId === 10) {
          setOther(true);
        } else {
          setOther(false);
        }
      }
      //console.log(response.knowledgetypes);
      //setKnowledgetypeId(atributoData);
      //console.log(atributoData);
    });
    setLoadingData(false);
  }, [loadingData]);

  useEffect(() => {
    console.log(values);

    if (edit) {
      setKnowledgetypeId(values.knowledgetypeId);
    } else {
      setKnowledgetypeId(null);
    }
  }, [values]);

  // useEffect(() => {
  //   console.log("1a");
  //   if (knowledgetypeId > 0) {
  //     GetKnowledgeByTypeApi(knowledgetypeId).then((response) => {
  //       setKnowledgeData(response.knowledges);
  //       setEnabled(true);
  //       //setKnowledgeId(atributoData);
  //     });
  //   }
  // }, [knowledgetypeId]);

  useEffect(() => {
    if (edit ?? false) {
      if (values.knowledgetypeId === 10) {
        setOther(true);
      } else {
        setOther(false);
      }
    } else {
      setOther(false);
    }
  }, [values]);

  // const handleAtributoChange = (value) => {
  //   setKnowledgeId(value);
  //   setAtributoData(value);
  // };

  // const handleAtributoChange = (value) => {
  //   //console.log(value);
  //   console.log("1c");
  //   if (value === 41) {
  //     setOther(true);
  //   } else {
  //     setOther(false);
  //   }
  // };

  const handleAtributoTypeChange = (value) => {
    console.log("1d");
    console.log(value);
    setKnowledgetypeId(value);
    if (value === 10) {
      setOther(true);
    } else {
      setOther(false);
    }
  };

  return (
    <div className="select-knowledge-form">
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Tipo de recurso"
            name={nametype}
            rules={[
              { required: true, message: "Seleccione un tipo de recurso" },
            ]}
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 24 }}
            style={{ marginBottom: other ? "5px" : "24px" }}
            //value={knowledgetypeId}
          >
            <Select
              placeholder="Seleccione un tipo de recurso"
              //value={knowledgetypeId}
              onChange={handleAtributoTypeChange}
              showSearch
              optionFilterProp="children"
              loading={loadingData}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              //disabled={disabled}
            >
              {knowledgetypeData && knowledgetypeData.map
                ? knowledgetypeData.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
          </Form.Item>
          {other && (
            <Form.Item
              //label="Otros"
              name={othertype}
              rules={[
                { required: true, message: "Ingrese el tipo de recurso" },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Tipo de recurso"
                //value={userData.name}
                maxLength={200}
              />
            </Form.Item>
          )}
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <SelectKnowledgeForm
            name={name}
            nameOther={nameOther}
            knowledgetypeId={knowledgetypeId}
            values={values}
            edit={edit}
          />
          {/* <Form.Item
            label="Recurso"
            name={name}
            rules={[{ required: true, message: "Seleccione un recurso" }]}
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 24 }}
            //style={other && { marginBottom: "5px" }}
            style={{ marginBottom: other ? "5px" : "24px" }}
            disabled={!enabled}
          >
            <Select
              placeholder="Seleccione un recurso"
              //value={knowledgeId}
              //onChange={handleChange}
              //onChange={handleAtributoChange}
              disabled={!enabled}
            >
              {knowledgeData && knowledgeData.map
                ? knowledgeData.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
          </Form.Item>
          {other && (
            <Form.Item
              //label="Otros"
              name={nameOther}
              rules={[{ required: true, message: "Ingrese el recurso" }]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Recurso"
                //value={userData.name}
                maxLength={200}
              />
            </Form.Item>
          )} */}
        </Col>
      </Row>
    </div>
  );
}
