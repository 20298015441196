import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import routes from "./config/routes";
import AuthProvider from "./providers/authProvider";
import socketClient from "socket.io-client";
import { basePath } from "./api/config";
//Layout
import LayoutAdmin from "./layouts/LayoutAdmin";
import LayoutBasic from "./layouts/LayoutBasic";
import LayoutUser from "./layouts/LayoutUser";
import LayoutReciclador from "./layouts/LayoutReciclador";
import LayoutProyecto from "./layouts/LayoutProyecto";

import AdminHome from "./pages/Admin";
import AdminSignIn from "./pages/Admin/SignIn";
import AdminUsers from "./pages/Admin/Users";
import AdminSectores from "./pages/Admin/Reportes/Sectores";
import AdminDiagnostico from "./pages/Admin/Reportes/Diagnostico";
import AdminDiagnosticoBlandas from "./pages/Admin/Reportes/DiagnosticoBlandas";
import AdminDiagnosticoEtapas from "./pages/Admin/Reportes/DiagnosticoEtapas";
import AdminEtapas from "./pages/Admin/Reportes/Etapas";
import AdminCiudades from "./pages/Admin/Reportes/Ciudades";
import AdminParroquias from "./pages/Admin/Reportes/Parroquias";
import AdminProvincias from "./pages/Admin/Reportes/Provincias";
import AdminRegistros from "./pages/Admin/Reportes/Registros";
import AdminRegistrosEvento from "./pages/Admin/Reportes/RegistrosEvento";
import AdminConocimientos from "./pages/Admin/Reportes/Conocimientos";
import AdminOferta from "./pages/Admin/Reportes/Oferta";
import AdminSostenibilidad from "./pages/Admin/Reportes/Sostenibilidad";
import AdminCapital from "./pages/Admin/Reportes/Capital";
import AdminFacturacion from "./pages/Admin/Reportes/Facturacion";
import AdminEmpleados from "./pages/Admin/Reportes/Empleo";
import AdminInversion from "./pages/Admin/Reportes/Inversion";
import AdminMapaUsuario from "./pages/Admin/Reportes/MapaUsuario";
import AdminMapaEmprendimientos from "./pages/Admin/Reportes/MapaUsuarioEmprendimientos";
import AdminEventos from "./pages/Admin/Eventos";
import AdminConexiones from "./pages/Admin/Reportes/Conexiones";

import HomeUser from "./pages/User";
import PersonalUser from "./pages/User/Personal";
import EmprendimientoUser from "./pages/User/Emprendimiento";
import HaveUser from "./pages/User/Have";
import NeedUser from "./pages/User/Need";
import ResidualUser from "./pages/User/Residual";
import ChangeUser from "./pages/User/Change";
import ConnectionsUser from "./pages/User/Conexiones";
import ChatUser from "./pages/User/Chat";
import CalendarUser from "./pages/User/Calendar";
import SolutionUser from "./pages/User/Solution";
import EventsUser from "./pages/User/Eventos";

import HomeReciclador from "./pages/Reciclador";
import PersonalReciclador from "./pages/Reciclador/Personal";
import EmprendimientoReciclador from "./pages/Reciclador/Emprendimiento";
import ResidualReciclador from "./pages/Reciclador/Residual";
import ChangeReciclador from "./pages/Reciclador/Change";
import ConnectionsReciclador from "./pages/Reciclador/Conexiones";
import ChatReciclador from "./pages/Reciclador/Chat";
import CalendarReciclador from "./pages/Reciclador/Calendar";
import EventsReciclador from "./pages/Reciclador/Eventos";

import HomeProyecto from "./pages/Proyecto";
import PersonalProyecto from "./pages/Proyecto/Personal";
import EmprendimientoProyecto from "./pages/Proyecto/Emprendimiento";
import ResidualProyecto from "./pages/Proyecto/Residual";
import ChangeProyecto from "./pages/Proyecto/Change";
import ConnectionsProyecto from "./pages/Proyecto/Conexiones";
import ChatProyecto from "./pages/Proyecto/Chat";
import CalendarProyecto from "./pages/Proyecto/Calendar";
import NeedProyecto from "./pages/Proyecto/Need";
import EventsProyecto from "./pages/Proyecto/Eventos";

import Home from "./pages/Home";
import Reciclador from "./pages/Recicladores";
import Proyectos from "./pages/Proyectos";
import Eventos from "./pages/Eventos";
// import Contact from "../pages/Contact";

//Other
import Error404 from "./pages/Error404";

import "./App.scss";

function App() {
  var socket = socketClient(basePath, {
    transports: ["websocket", "polling", "flashsocket"],
  });

  //var socket = io('http://yourDomain:port', { transports: ['websocket', 'polling', 'flashsocket'] });

  socket.on("connection", () => {
    console.log(`I'm connected with the back-end`);
  });
  return (
    <AuthProvider>
      {/* <Router>
        <Routes>
          {routes.map((route, index) => (
            <RouterWithSubRoutes key={index} {...route} />
          ))}
        </Routes>
      </Router> */}
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Error404 />} />
          <Route element={<LayoutBasic />}>
            {/* <Route index element={<Home />} /> */}
            {/* <Route path="/*" element={<Error404 />} /> */}
            <Route path="/admin/login" element={<AdminSignIn />} />
            <Route path="/" element={<AdminSignIn />} />
            <Route path="/empresas" element={<Home />} />
            <Route path="/recicladores" element={<Reciclador />} />
            <Route path="/proyectos" element={<Proyectos />} />
            <Route path="/networking/:evento" element={<Eventos />} />
          </Route>
          <Route element={<LayoutAdmin />}>
            {/* <Route path="/admin/*" element={<Error404 />} /> */}
            <Route path="/admin" element={<AdminHome />} />
            <Route path="/admin/users" element={<AdminUsers />} />
            <Route path="/admin/sectores" element={<AdminSectores />} />
            <Route path="/admin/diagnostico" element={<AdminDiagnostico />} />
            <Route
              path="/admin/diagnosticoblandas"
              element={<AdminDiagnosticoBlandas />}
            />
            <Route
              path="/admin/diagnosticoetapas"
              element={<AdminDiagnosticoEtapas />}
            />
            <Route path="/admin/etapas" element={<AdminEtapas />} />
            <Route path="/admin/ciudades" element={<AdminCiudades />} />
            <Route path="/admin/parroquias" element={<AdminParroquias />} />
            <Route path="/admin/provincias" element={<AdminProvincias />} />
            <Route path="/admin/registros" element={<AdminRegistros />} />
            <Route
              path="/admin/events/users/:url/:id"
              element={<AdminRegistrosEvento />}
            />
            <Route
              path="/admin/conocimientos"
              element={<AdminConocimientos />}
            />
            <Route path="/admin/oferta" element={<AdminOferta />} />
            <Route path="/admin/capital" element={<AdminFacturacion />} />
            <Route path="/admin/facturacion" element={<AdminCapital />} />
            <Route path="/admin/empleo" element={<AdminEmpleados />} />
            <Route path="/admin/inversion" element={<AdminInversion />} />
            <Route path="/admin/mapusuario" element={<AdminMapaUsuario />} />
            <Route
              path="/admin/sostenibilidad"
              element={<AdminSostenibilidad />}
            />
            <Route path="/admin/conexiones" element={<AdminConexiones />} />
            <Route
              path="/admin/mapemprendimiento"
              element={<AdminMapaEmprendimientos />}
            />
            <Route path="/admin/eventos/admin" element={<AdminEventos />} />
          </Route>
          <Route element={<LayoutUser socket={socket} />}>
            <Route path="/user/*" element={<Error404 />} />
            <Route path="/user" socket={socket} element={<HomeUser />} />
            <Route
              path="/user/personal"
              socket={socket}
              element={<PersonalUser />}
            />
            <Route
              path="/user/solution"
              socket={socket}
              element={<SolutionUser />}
            />
            <Route
              path="/user/emprendimiento"
              socket={socket}
              element={<EmprendimientoUser />}
            />
            <Route
              path="/user/yotengo"
              socket={socket}
              element={<HaveUser />}
            />
            <Route
              path="/user/yonecesito"
              socket={socket}
              element={<NeedUser />}
            />
            <Route
              path="/user/residual"
              socket={socket}
              element={<ResidualUser />}
            />
            <Route
              path="/user/change"
              socket={socket}
              element={<ChangeUser />}
            />
            <Route
              path="/user/conexiones"
              element={<ConnectionsUser socket={socket} />}
            />
            <Route path="/user/chat" element={<ChatUser socket={socket} />} />
            <Route
              path="/user/calendar"
              element={<CalendarUser socket={socket} />}
            />
            <Route
              path="/user/events"
              element={<EventsUser socket={socket} />}
            />
          </Route>
          <Route element={<LayoutReciclador socket={socket} />}>
            <Route path="/reciclador/*" element={<Error404 />} />
            <Route
              path="/reciclador"
              socket={socket}
              element={<HomeReciclador />}
            />
            <Route
              path="/reciclador/personal"
              socket={socket}
              element={<PersonalReciclador />}
            />
            <Route
              path="/reciclador/emprendimiento"
              socket={socket}
              element={<EmprendimientoReciclador />}
            />

            <Route
              path="/reciclador/residual"
              socket={socket}
              element={<ResidualReciclador />}
            />
            <Route
              path="/reciclador/change"
              socket={socket}
              element={<ChangeReciclador />}
            />
            <Route
              path="/reciclador/conexiones"
              element={<ConnectionsReciclador socket={socket} />}
            />
            <Route
              path="/reciclador/chat"
              element={<ChatReciclador socket={socket} />}
            />
            <Route
              path="/reciclador/calendar"
              element={<CalendarReciclador socket={socket} />}
            />
            <Route
              path="/reciclador/events"
              element={<EventsReciclador socket={socket} />}
            />
          </Route>
          <Route element={<LayoutProyecto socket={socket} />}>
            <Route path="/proyecto/*" element={<Error404 />} />
            <Route
              path="/proyecto"
              socket={socket}
              element={<HomeProyecto />}
            />
            <Route
              path="/proyecto/personal"
              socket={socket}
              element={<PersonalProyecto />}
            />
            <Route
              path="/proyecto/emprendimiento"
              socket={socket}
              element={<EmprendimientoProyecto />}
            />
            <Route
              path="/proyecto/yonecesito"
              socket={socket}
              element={<NeedProyecto />}
            />
            <Route
              path="/proyecto/residual"
              socket={socket}
              element={<ResidualProyecto />}
            />
            <Route
              path="/proyecto/change"
              socket={socket}
              element={<ChangeProyecto />}
            />
            <Route
              path="/proyecto/conexiones"
              element={<ConnectionsProyecto socket={socket} />}
            />
            <Route
              path="/proyecto/chat"
              element={<ChatProyecto socket={socket} />}
            />
            <Route
              path="/proyecto/calendar"
              element={<CalendarProyecto socket={socket} />}
            />
            <Route
              path="/proyecto/events"
              element={<EventsProyecto socket={socket} />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

// function RouterWithSubRoutes(route) {
//   return (
//     <Route
//       path={route.path}
//       //exact={route.exact}
//       render={(props) => <route.component routes={route.routes} {...props} />}
//     />
//   );
// }

export default App;
